import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CompanyUserGroupInProductAccessControlGroupService from '../../../services/api/CompanyUserGroupInProductAccessControlGroupService'
import { ApiCompanyUserGroupInProductAccessControlGroupState } from '../../../types'
import { COMPANY_USER_GROUP_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiCompanyUserGroupInProductAccessControlGroupState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteCompanyUserGroupInProductAccessControlGroup = createAsyncThunk('api/company-user-group-product-access-control-group/delete', async ({ token, companyUserGroupProductAccessControlGroupId, signal }: { token: string, companyUserGroupProductAccessControlGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupInProductAccessControlGroupService.deleteCompanyUserGroupInProductAccessControlGroup(token, companyUserGroupProductAccessControlGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const companyUserGroupInProductAccessControlGroupSlice = createSlice({
  name: 'api/company-user-group-product-access-control-group',
  initialState,
  reducers: {
    resetCompanyUserGroupInProductAccessControlGroupError: (state) => {
      state.error = null
    },
    resetCompanyUserGroupInProductAccessControlGroupMessage: (state) => {
      state.message = null
    },
    resetCompanyUserGroupInProductAccessControlGroupData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCompanyUserGroupInProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCompanyUserGroupInProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = COMPANY_USER_GROUP_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE
      })
      .addCase(deleteCompanyUserGroupInProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCompanyUserGroupInProductAccessControlGroupError, resetCompanyUserGroupInProductAccessControlGroupMessage, resetCompanyUserGroupInProductAccessControlGroupData } = companyUserGroupInProductAccessControlGroupSlice.actions

const { reducer } = companyUserGroupInProductAccessControlGroupSlice
export default reducer
