import ProductCategoryTagService from '../services/api/ProductCategoryTagService'
import { ProductCategoryTag } from '../types'

export const mapProductCategoryTags = (productCategoryTags: Array<ProductCategoryTag>) => {
  return productCategoryTags.map((productCategoryTag) => ({
    value: productCategoryTag.id,
    label: `${productCategoryTag.name} (${(productCategoryTag.productCategory?.name || '')})`
  }))
}

export const loadProductCategoryTagOptions = async (page: number, perPage: number, token: string, inputValue: string) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token) {
    try {
      const res = await ProductCategoryTagService.getProductCategoryTags(token, perPage, page, signal, inputValue)
      return mapProductCategoryTags(res.data.productCategoryTags)
    } catch (error) {
      return mapProductCategoryTags([])
    }
  } else {
    return mapProductCategoryTags([])
  }
}
