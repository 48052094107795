import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import * as userRoles from '../../../../constants/userRoles'
import { getCompanyUserGroupById, getUsersOfACompanyUserGroup } from '../../../../store/reducers/api/companyUserGroupReducer'
import Pagination from '../../../../components/Pagination'
import { TrashIcon } from '../../../../components/icons/TrashIcon'
import Progress from '../../../../components/loaders/Progress'
import { CompanyUserGroupUser } from '../../../../types'
import { deleteUserInCompanyUserGroup, resetUserInCompanyUserGroupMessage } from '../../../../store/reducers/api/userInCompanyUserGroupReducer'
import UserSelector from '../../../../components/AccessControls/CompanyUserGroups/UserSelector'
import { COMPANY_USER_GROUP_USERS_ADDITION_SUCCESS_MESSAGE, USER_COMPANY_USER_GROUP_DELETION_MESSAGE } from '../../../../constants/messages'
import { setToast } from '../../../../store/reducers/toastReducer'
import { dismissModal } from '../../../../utils/dismissModal'
import SkeletonTableRow from '../../../../components/loaders/skeleton/SkeletonTableRow'
import useDebounce from '../../../../utils/hooks/useDebounce'

const CompanyUserGroupDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiCompanyUserGroup.isLoading)
  const message = useAppSelector((state) => state.apiCompanyUserGroup.message)
  const companyUserGroup = useAppSelector((state) => state.apiCompanyUserGroup.companyUserGroup)
  const isLoadingCompanyUserGroupUsers = useAppSelector((state) => state.apiCompanyUserGroup.isLoadingCompanyUserGroupUsers)
  const companyUserGroupUsers = useAppSelector((state) => state.apiCompanyUserGroup.companyUserGroupUsers)
  const metadataCompanyUserGroupUsers = useAppSelector((state) => state.apiCompanyUserGroup.metadataCompanyUserGroupUsers)
  const isLoadingUserInCompanyUserGroup = useAppSelector((state) => state.apiUserInCompanyUserGroup.isLoading)
  const userInCompanyUserGroupMessage = useAppSelector((state) => state.apiUserInCompanyUserGroup.message)

  const [selectedCompanyUserGroupUser, setSelectedCompanyUserGroupUser] = useState<CompanyUserGroupUser | null>(null)
  const [perPageUsers, setPerPageUsers] = useState(10)
  const [pageUsers, setPageUsers] = useState(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const token = currentUser?.token
  const role = profile?.role || userRoles.USER

  const { companyUserGroupId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const handleUserShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageUsers(1)
    setPerPageUsers(Number(event.target.value))
  }

  const handleUserPageChange = (page: number) => {
    setPageUsers(page)
  }

  const handleCompanyUserGroupUsersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyUserGroupId && role === userRoles.ADMIN) {
      dispatch(getUsersOfACompanyUserGroup({ token, companyUserGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTerm }))
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyUserGroupId && role === userRoles.ADMIN) {
      dispatch(getCompanyUserGroupById({ companyUserGroupId, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyUserGroupId && role === userRoles.ADMIN) {
      dispatch(getUsersOfACompanyUserGroup({ token, companyUserGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTerm }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageUsers, pageUsers, debouncedSearchTerm])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [
      COMPANY_USER_GROUP_USERS_ADDITION_SUCCESS_MESSAGE
    ]
    if (token && message && allowedMessages.includes(message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && companyUserGroupId && role === userRoles.ADMIN) {
        dispatch(getUsersOfACompanyUserGroup({ token, companyUserGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTerm }))
      }
      dismissModal('usersAdditionModal')
    }
    return () => {
      controller.abort()
    }
  }, [message])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [
      USER_COMPANY_USER_GROUP_DELETION_MESSAGE
    ]
    if (token && userInCompanyUserGroupMessage && allowedMessages.includes(userInCompanyUserGroupMessage)) {
      const payload = {
        title: 'Success',
        message: userInCompanyUserGroupMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && companyUserGroupId && role === userRoles.ADMIN) {
        dispatch(getUsersOfACompanyUserGroup({ token, companyUserGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTerm }))
        dispatch(resetUserInCompanyUserGroupMessage())
      }
      dismissModal('userRemovalModal')
    }
  }, [userInCompanyUserGroupMessage])

  return (
    <main>
      <div className="card">
        <div className="m-4">
          <div className="navbar navbar-expand d-flex flex-column flex-sm-row">
            {
              isLoading
                ? (
                  <p className="placeholder-glow w-100 h5">
                    <span className="placeholder col-7 rounded"></span>
                  </p>
                  )
                : (
                  <p className="w-100 h5" title={`${companyUserGroup?.name || '---'}`}>
                    <i className="bi bi-info-square me-2"></i>{companyUserGroup?.name || '---'}
                  </p>
                  )
            }
            {
              isLoading
                ? (
                  <p className="placeholder-glow w-100 h5">
                    <span className="placeholder col-7 rounded"></span>
                  </p>
                  )
                : (
                  <p className="w-100 h5" title={`${companyUserGroup?.company?.name || '---'}`}>
                    <i className="bi bi-building me-2"></i>{companyUserGroup?.company?.name || '---'}
                  </p>
                  )
            }
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
            <p className="h5"><i className="bi bi-people me-2"></i>Users</p>
            {
              currentUser?.role === userRoles.ADMIN && (
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                    <Formik
                      validationSchema={searchSchema}
                      enableReinitialize
                      initialValues={{
                        search: ''
                      }}
                      onSubmit={({ search }, actions) => {
                        setSearchTerm(search)
                        if (pageUsers !== 1) {
                          setPageUsers(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <input
                              onChange={(event) => {
                                const search = event.target.value
                                handleChange(event)
                                setSearchTerm(search)
                                if (pageUsers !== 1) {
                                  setPageUsers(1)
                                  navigate('')
                                }
                              }}
                              maxLength={24}
                              onBlur={handleBlur}
                              value={values.search}
                              className={`form-control ${
                                errors.search && touched.search && errors.search
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="btnNavbarSearch"
                              name="search"
                            />
                            <button
                              className="btn btn-outline-dark"
                              id="btnNavbarSearch"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#usersAdditionModal"
                    title="Add User"
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>User</span>
                  </button>
                  <button
                    type="button"
                    title="Refresh"
                    aria-label="Refresh"
                    className="btn btn-outline-dark"
                    onClick={() => handleCompanyUserGroupUsersRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              )
            }
          </div>
          {isLoadingCompanyUserGroupUsers ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col">User Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col" className="text-center">
                    <div className="float-end">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoadingCompanyUserGroupUsers
                    ? (
                        Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={1} />)
                      )
                    : (
                        companyUserGroupUsers.length > 0
                          ? (
                              companyUserGroupUsers.map((companyUserGroupUser) => (
                            <tr key={companyUserGroupUser.id} className={selectedCompanyUserGroupUser?.id === companyUserGroupUser.id ? 'table-primary' : ''}>
                              <td>
                                <span
                                  title="Click To Copy User Id"
                                  onClick={() => navigator.clipboard.writeText(String(companyUserGroupUser.id))}
                                  className="user-select-all"
                                >
                                  {(companyUserGroupUser.id).substring(0, 8)}
                                </span>
                              </td>
                              <td>
                                <span
                                  title={'Click To Copy Name Of The User'}
                                  onClick={() => navigator.clipboard.writeText(String(`${companyUserGroupUser.user.firstName} ${companyUserGroupUser.user.lastName}`))}
                                  className="user-select-all"
                                >
                                  {`${companyUserGroupUser.user.firstName} ${companyUserGroupUser.user.lastName}`}
                                </span>
                              </td>
                              <td>
                                {companyUserGroupUser.user.email || ''}
                              </td>
                              <td className="text-center">
                                <div className="d-flex flex-row float-end" role="group" aria-label="User Action Buttons">
                                  {
                                    currentUser?.role === userRoles.ADMIN && (
                                      <button
                                        type="button"
                                        title="Remove User"
                                        className="btn btn-outline-danger btn-round"
                                        data-bs-toggle="modal"
                                        data-bs-target="#userRemovalModal"
                                        onClick={() => {
                                          setSelectedCompanyUserGroupUser(companyUserGroupUser)
                                        }}
                                      >
                                        <TrashIcon/>
                                      </button>
                                    )
                                  }
                                </div>
                              </td>
                            </tr>
                              ))
                            )
                          : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No users available yet
                          </td>
                        </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoadingCompanyUserGroupUsers}
            metadata={{
              limit: metadataCompanyUserGroupUsers.perPage,
              total: metadataCompanyUserGroupUsers.total,
              offset: ((metadataCompanyUserGroupUsers.page - 1) * (metadataCompanyUserGroupUsers.perPage))
            }}
            page={pageUsers}
            perPage={perPageUsers}
            handlePageChange={handleUserPageChange}
            handleShowEntries={handleUserShowEntries}
            module="companyUserGroupUsers"
            perPageOptions={[10, 25, 50, 100]}
            isTrackingPage
          />

        </div>
      </div>

      {/* Modals */}
      <div className="modal fade" id="usersAdditionModal" tabIndex={-1} aria-labelledby="usersAdditionModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="usersAdditionModalLabel">
                <i className="bi-plus-circle me-1"></i>{' '}
                Add Users
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <UserSelector isLoading={isLoading} companyUserGroupId={companyUserGroupId} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="userRemovalModal" tabIndex={-1} aria-labelledby="userRemovalModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="userRemovalModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Removal
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoading || isLoadingUserInCompanyUserGroup) && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to remove
                <span className="fw-bold">{` '${selectedCompanyUserGroupUser?.user.firstName} ${selectedCompanyUserGroupUser?.user.lastName} <${selectedCompanyUserGroupUser?.user.email}>' `}</span>
                from the company user group?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && selectedCompanyUserGroupUser !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteUserInCompanyUserGroup({ userCompanyUserGroupId: String(selectedCompanyUserGroupUser.id), token, signal }))
                  }
                }}
                disabled={(isLoadingUserInCompanyUserGroup)}
                aria-label="Remove"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default CompanyUserGroupDetails
