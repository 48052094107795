import { READ, READWRITE } from '../constants/permissions'
import * as userRoles from '../constants/userRoles'
import { AccessPermission, Module, Permission, Role } from '../types'

const hasPermission = (module: Module, role: Role = userRoles.USER, accessPermissions: Array<AccessPermission>, defaultAccessPermissions:Array<AccessPermission>, permission: Permission = READ) => {
  const allowed = {
    [READ]: [READ],
    [READWRITE]: [READ, READWRITE],
    '': ''
  }

  const allowedCompanyAdminModules = defaultAccessPermissions
    .filter((defaultAccessPermission: AccessPermission) => defaultAccessPermission.role === userRoles.COMPANYADMINISTRATOR && allowed[defaultAccessPermission.permission].includes(permission))
    .map((defaultAccessPermission: AccessPermission) => defaultAccessPermission.module)

  const allowedRoleModules = defaultAccessPermissions
    .filter((defaultAccessPermission: AccessPermission) => defaultAccessPermission.role === role && allowed[defaultAccessPermission.permission].includes(permission))
    .map((defaultAccessPermission: AccessPermission) => defaultAccessPermission.module)

  const overriddenRoleModules = accessPermissions
    .filter((accessPermission: AccessPermission) => accessPermission.role === role &&
    defaultAccessPermissions.find((defaultAccessPermission: AccessPermission) => defaultAccessPermission.module === accessPermission.module) &&
    !allowed[accessPermission.permission].includes(permission))
    .map((accessPermission: AccessPermission) => accessPermission.module)

  const isPermissionInDefault = (permission: AccessPermission) => {
    return defaultAccessPermissions.some(defaultAccessPermission => (
      defaultAccessPermission.role === permission.role &&
      defaultAccessPermission.module === permission.module
    ))
  }

  const addedRoleModules = accessPermissions
    .filter((permission) => !isPermissionInDefault(permission))
    .filter((accessPermission: AccessPermission) => accessPermission.role === role && allowed[accessPermission.permission].includes(permission))
    .map((accessPermission: AccessPermission) => accessPermission.module)

  if (role === userRoles.ADMIN) {
    return true
  }
  if (role === userRoles.COMPANYADMINISTRATOR && allowedCompanyAdminModules.includes(module)) {
    return true
  }
  if (allowedRoleModules.includes(module) && !overriddenRoleModules.includes(module)) {
    return true
  }
  if (addedRoleModules.includes(module)) {
    return true
  }
  return false
}

export default hasPermission
