import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_API_URL
const proxyServerURL = process.env.REACT_APP_PROXY_SERVER_URL

const apiClient: any = axios.create({
  baseURL: proxyServerURL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Target-URL': `${baseURL}/api/v1/merchant`
  },
  timeout: 20000
})

export default {
  getAllInbounds (token: string, perPage: number, offset: number, filter: string, signal: AbortSignal) {
    const params: {[key: string]: string | number} = {
      $top: perPage,
      $skip: offset,
      $orderBy: 'modificationInfo/createdAt desc',
      $filter: filter
    }

    const config = {
      signal,
      params,
      headers: {
        'auth-token': token
      }
    }

    return apiClient.get('/inbounds', config)
  },
  getASingleInbound (token: string, inboundId: string, signal: AbortSignal) {
    const config = {
      signal,
      headers: {
        'auth-token': token
      }
    }
    return apiClient.get(`/inbounds/${inboundId}`, config)
  },
  getShippingNotifications (token: string, inboundId: string, signal: AbortSignal) {
    const config = {
      signal,
      headers: {
        'auth-token': token
      }
    }
    return apiClient.get(`/inbounds/${inboundId}/shipping-notifications`, config)
  }
}
