import React from 'react'
import { AddressMode, CompanyMode, UserCompany } from '../../../types'
import OnboardingAddressEditor from '../OnboardingAddressEditor'

interface DeliveryAddressCreationProps {
  setMode: Function
  setTitle: Function
  company: UserCompany | null | undefined
  token: string | null
  isBillingAndDeliveryAddress: boolean
  setIsBillingAndDeliveryAddress: Function
  addressMode: AddressMode
  setAddressMode: Function
  setCompanyMode: React.Dispatch<React.SetStateAction<CompanyMode>>
}

const DeliveryAddressCreation = ({
  setMode, setTitle, company, token,
  isBillingAndDeliveryAddress, setIsBillingAndDeliveryAddress,
  addressMode, setAddressMode, setCompanyMode
} : DeliveryAddressCreationProps) => {
  return (
    <OnboardingAddressEditor
      setMode={setMode}
      setTitle={setTitle}
      company={company}
      token={token}
      addressType={'delivery'}
      isBillingAndDeliveryAddress={isBillingAndDeliveryAddress}
      setIsBillingAndDeliveryAddress={setIsBillingAndDeliveryAddress}
      addressTitle="Delivery"
      addressMode={addressMode}
      setAddressMode={setAddressMode}
      setCompanyMode={setCompanyMode}
    />
  )
}

export default DeliveryAddressCreation
