import axios from 'axios'
import { Order } from '../../types'
const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAllOrders (token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal) {
    const params = {
      limit: perPage,
      page,
      search: search || undefined
    }

    const config = {
      params,
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/orders?${filter}`, config)
  },
  async getTotalOrders (token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    let page = 1
    let pageCount = 2
    const accumulated: Array<Order> = []
    do {
      const { data } = await apiClient.get(`/orders?limit=${2500}&page=${page}`, signal)
      accumulated.push(...data.orders)
      pageCount = data.meta.pageCount
      page++
    } while (page <= pageCount)
    return accumulated
  },
  changeOrderVisibility (
    token: string | undefined,
    signal: AbortSignal,
    isVisible: boolean,
    orderId: string
  ) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      signal
    }

    return apiClient.put(
      `/orders/${orderId}`,
      { order: { isVisible } },
      config
    )
  }

}
