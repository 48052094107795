import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_API_URL
const proxyServerURL = process.env.REACT_APP_PROXY_SERVER_URL

const apiClient: any = axios.create({
  baseURL: proxyServerURL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Target-URL': `${baseURL}/api/v1/users`
  },
  timeout: 20000
})

export default {
  getCurrentUser (token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    const config = {
      signal
    }
    return apiClient.get('/current', config)
  }
}
