import dayjs from 'dayjs'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Progress from '../../components/loaders/Progress'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import CampaignEditor from '../../components/Campaigns/CampaignEditor'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { deleteCampaignById, resetCampaignError, resetCampaignMessage, updateCampaignById } from '../../store/reducers/api/campaignReducer'
import { addCompanyCampaign, getCompanyCampaigns, resetCompanyError } from '../../store/reducers/api/companyReducer'
import { Campaign, Module, Permission } from '../../types'
import * as userRoles from '../../constants/userRoles'
import { setToast } from '../../store/reducers/toastReducer'
import { errorStatusCodes } from '../../constants/statusCodes'
import { getMyProfile } from '../../store/reducers/api/profileReducer'
import Pagination from '../../components/Pagination'
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal'
import * as appModules from '../../constants/appModules'
import hasPermission from '../../utils/checkPermissions'
import { READ, READWRITE } from '../../constants/permissions'
import { dismissModal } from '../../utils/dismissModal'
import { getQuotaClassName } from '../../utils/getQuotaClassName'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { CampaignStatus } from '../../constants/campaignStatus'
import CampaignLandingModal from '../../components/Campaigns/Onboarding/CampaignLandingModal'
import { resetCampaignAddressError } from '../../store/reducers/api/campaignAddressReducer'
import { useCallbackPrompt } from '../../utils/hooks/useCallbackPrompt'
import DialogBox from '../../components/modals/DialogBox'
import { calculateCampaignOnboardingProgress } from '../../utils/campaignOnboardingProgress'

const MyCampaigns = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const campaigns = useAppSelector((state) => state.apiCompany.campaigns)
  const campaign = useAppSelector((state) => state.apiCompany.campaign)
  const metadata = useAppSelector((state) => state.apiCompany.campaignsMetadata)
  const isLoadingCompanyCampaigns = useAppSelector((state) => state.apiCompany.isLoadingCampaigns)
  const error = useAppSelector((state) => state.apiCompany.error)

  const isLoadingCampaign = useAppSelector((state) => state.apiCampaign.isLoading)
  const message = useAppSelector((state) => state.apiCampaign.message)
  const udpatedCampaign = useAppSelector((state) => state.apiCampaign.campaign)
  const errorCampaign = useAppSelector((state) => state.apiCampaign.error)
  const campaignAddressMessage = useAppSelector((state) => state.apiCampaignAddress.message)
  const campaignAddressError = useAppSelector((state) => state.apiCampaignAddress.error)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)

  const [initialCampaign, setInitialCampaign] = useState<Partial<Campaign>>({
    name: '',
    type: 'onboarding',
    status: 'draft',
    description: '',
    isQuotaEnabled: false,
    lastQuotaResetDate: null
  })
  const [editMode, setEditMode] = useState(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [cancelPressed, setCancelPressed] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation, confirmedNavigation] = useCallbackPrompt(showDialog)

  const token = currentUser?.token
  const userId = currentUser?.id
  const role = currentUser?.role || userRoles.USER
  const companyId = profile?.company?.id
  const companyOwnerId = profile?.company?.owner?.id
  const isOwner = userId === companyOwnerId
  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const handleMyCampaignsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((token && companyId) && (isAllowed(appModules.CAMPAIGNS))) {
      dispatch(getCompanyCampaigns({ id: String(companyId), token, perPage, page, signal }))
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      dispatch(getMyProfile({ token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((token && companyId) && (isAllowed(appModules.CAMPAIGNS))) {
      dispatch(getCompanyCampaigns({ id: String(companyId), token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, profile])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((campaign || udpatedCampaign) && (token && companyId) && (isAllowed(appModules.CAMPAIGNS))) {
      dispatch(getCompanyCampaigns({ id: String(companyId), token, perPage, page, signal }))
      dismissModal('campaignModal')
    }

    return () => {
      controller.abort()
    }
  }, [campaign, udpatedCampaign])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((token) && (message || campaignAddressMessage)) {
      const payload = {
        title: 'Success',
        message: message || campaignAddressMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetCampaignMessage())
      dismissModal('confirmationModal')
    }

    if ((message || campaignAddressMessage) && (token && companyId) && (isOwner || (isAllowed(appModules.CAMPAIGNS)))) {
      dispatch(getCompanyCampaigns({ id: String(companyId), token, perPage, page, signal }))
    }
  }, [message, campaignAddressMessage])

  useEffect(() => {
    if (error && error.errors) {
      const payload = {
        title: errorStatusCodes[error.statusCode],
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCompanyError())
    }
  }, [error])

  useEffect(() => {
    if (errorCampaign && errorCampaign.errors) {
      const payload = {
        title: errorStatusCodes[errorCampaign.statusCode],
        message: errorCampaign.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCampaignError())
    }
  }, [errorCampaign])

  useEffect(() => {
    if (campaignAddressError && campaignAddressError.errors) {
      const payload = {
        title: errorStatusCodes[campaignAddressError.statusCode],
        message: campaignAddressError.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetCampaignAddressError())
    }
  }, [campaignAddressError])

  useEffect(() => {
    if (campaigns.length > 0 && initialCampaign) {
      const updatedCampaign = campaigns.find(campaign => campaign.id === initialCampaign.id)
      if (updatedCampaign) {
        setInitialCampaign(updatedCampaign)
      }
    }
  }, [campaigns])

  useEffect(() => {
    if (cancelPressed) {
      setShowDialog(false)
      setCancelPressed(false)
    }
    if (confirmedNavigation) {
      dismissModal('campaignLandingModal')
    }
  }, [showDialog, showPrompt, cancelPressed])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-truck me-1"></i> My Campaigns</p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              {
                companyId && isAllowed(appModules.CAMPAIGNS, READWRITE) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#campaignModal"
                      title="Add Campaign"
                      aria-label="Add Campaign"
                      onClick={() => {
                        setEditMode(false)
                        setInitialCampaign({
                          name: '',
                          type: 'onboarding',
                          status: 'draft',
                          description: '',
                          isQuotaEnabled: false,
                          lastQuotaResetDate: null
                        })
                      }}
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1 d-none d-md-inline-block">Add Campaign</span>
                    </button>
                )
              }
                <button
                  type="button"
                  title="Refresh"
                  aria-label="Refresh"
                  className="btn btn-outline-dark ms-2 ms-md-3"
                  onClick={() => handleMyCampaignsRefresh()}
                >
                  <i className="fas fa-redo"></i>
                </button>
              </ul>
            </div>
            {isLoadingCompanyCampaigns ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col" rowSpan={2}>Campaign ID</th>
                    <th scope="col" rowSpan={2}>Name</th>
                    <th scope="col" rowSpan={2}>Status</th>
                    <th scope="col" colSpan={3} className="text-center m-0 p-0">Quota</th>
                    <th scope="col" rowSpan={2}>Date Created</th>
                    <th scope="col" rowSpan={2} className="text-center">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Remaining</th>
                    <th scope="col">Used</th>
                    <th scope="col">Purchased</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingCompanyCampaigns
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={8} actionQuantity={isAllowed(appModules.CAMPAIGNS, READWRITE) ? 2 : 0} />)
                        )
                      : (

                          campaigns.length > 0
                            ? (campaigns.map((campaign: Campaign) => (
                              <tr key={campaign.id} className={initialCampaign.id === campaign.id ? 'table-primary' : ''}>
                                <td>{(String(campaign.id)).substring(0, 8)}</td>
                                <td>
                                  <Link className="text-decoration-none" to={`/my-campaigns/${campaign.id}`}>
                                    {campaign.name}
                                  </Link>
                                </td>
                                <td>
                                  {
                                    campaign.status === 'draft'
                                      ? (
                                        <button
                                          className={`btn btn-sm fw-semibold position-relative text-bg-${CampaignStatus[campaign.status].colour}`}
                                          style={{
                                            padding: '0em 0.65em'
                                          }}
                                          type="button"
                                          title="Onboard Campaign"
                                          data-bs-toggle="modal"
                                          data-bs-target="#campaignLandingModal"
                                          onClick={() => {
                                            setInitialCampaign(campaign)
                                            setShowDialog(true)
                                          }}
                                        >
                                          {campaign.status}
                                          <span className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle badge-animation"></span>
                                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                            {calculateCampaignOnboardingProgress(campaign).totalPendingTasks}
                                            <span className="visually-hidden">Onboarding Action</span>
                                          </span>
                                        </button>
                                        )
                                      : (
                                        <span className={`badge text-bg-${CampaignStatus[campaign.status].colour}`}>
                                          {campaign.status}
                                        </span>
                                        )
                                  }
                                </td>
                                  {
                                  campaign.isQuotaEnabled
                                    ? (
                                      <>
                                        <td>
                                          <span className={`fw-bold badge ${getQuotaClassName(campaign.correctionQuota, campaign.quota, campaign.usedQuota)} text-center`}>{`${campaign.quota - (campaign.usedQuota + campaign.correctionQuota)}`}</span>
                                        </td>
                                        <td>
                                          <span className={`fw-bold badge ${getQuotaClassName(campaign.correctionQuota, campaign.quota, campaign.usedQuota)} text-center`}>{`${campaign.usedQuota + campaign.correctionQuota}`}</span>
                                        </td>
                                        <td>
                                          <span className={`fw-bold badge ${getQuotaClassName(campaign.correctionQuota, campaign.quota, campaign.usedQuota)} text-center`}>{`${campaign.quota}`}</span>
                                        </td>
                                      </>
                                      )
                                    : (
                                      <>
                                        <td className='text-center'>-</td>
                                        <td className='text-center'>-</td>
                                        <td className='text-center'>-</td>
                                      </>
                                      )
                                }
                                <td>{dayjs(campaign.createdAt).format('ll')}</td>
                                <td className="text-center">
                                  <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                    {isAllowed(appModules.CAMPAIGNS, READWRITE)
                                      ? (
                                        <>
                                          <button
                                            className="btn btn-outline-dark btn-round me-2"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#campaignModal"
                                            onClick={() => {
                                              setEditMode(true)
                                              setInitialCampaign(campaign)
                                            }}
                                          >
                                            <PencilIcon/>
                                          </button>
                                          <button
                                            className="btn btn-outline-danger btn-round"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#confirmationModal"
                                            onClick={() => {
                                              setInitialCampaign(campaign)
                                            }}
                                          >
                                            <TrashIcon/>
                                          </button>
                                        </>
                                        )
                                      : (
                                        <button
                                        className="btn btn-outline-dark btn-round"
                                        type="button"
                                        title="Details"
                                        onClick={() => {
                                          navigate(`/my-campaigns/${campaign.id}`)
                                        }}
                                        >
                                          <i className="bi bi-arrow-right"></i>
                                        </button>
                                        )
                                    }
                                  </div>
                                </td>
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No campaigns available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoadingCompanyCampaigns}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              isTrackingPage
            />
          </div>
        </div>
      </div>
      <div className="modal fade" id="campaignModal" tabIndex={-1} aria-labelledby="campaignModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="campaignModalLabel">
                <i className={`bi ${editMode ? 'bi-pencil-square' : 'bi-plus-circle'} me-1`}></i>{' '}
                {`${editMode ? 'Edit' : 'Add'} Campaign`}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoadingCompanyCampaigns || isLoadingCampaign) && <Progress />}
            <div className="modal-body">
              <CampaignEditor
                id={editMode ? String(initialCampaign.id) : String(companyId)}
                initialCampaign={initialCampaign}
                save={editMode ? updateCampaignById : addCompanyCampaign}
                editMode={editMode}
              />
            </div>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        isLoading = {isLoadingCampaign}
        deleteById={deleteCampaignById}
        id={String(initialCampaign?.id)}
        name={<><span className="fw-bold">{`'${initialCampaign?.name}'`}</span> campaign?</>}
        token={String(token)}
        autoDismiss={false}
      />

      <CampaignLandingModal campaign={initialCampaign} setShowDialog={setShowDialog} setCancelPressed={setCancelPressed} />
      <DialogBox
        showDialog={typeof showPrompt === 'boolean' ? showPrompt : false}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </main>
  )
}

export default MyCampaigns
