import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GreetingCardService from '../../../services/api/GreetingCardService'
import { ApiGreetingCardState } from '../../../types'
import { SALUTATION_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiGreetingCardState = {
  isLoading: false,
  error: null,
  greetingCard: null,
  greetingCards: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getAllGreetingCards = createAsyncThunk('api/greeting-cards/get', async ({ token, perPage, page, search, filter, signal }: { token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await GreetingCardService.getAllGreetingCards(token, perPage, page, search, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addGreetingCard = createAsyncThunk('api/greeting-cards/add', async ({ token, greetingCard, signal }: { token: string, greetingCard: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await GreetingCardService.addGreetingCard(token, greetingCard, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editAGreetingCardById = createAsyncThunk('api/greeting-cards/edit', async ({ token, greetingCardId, greetingCard, signal }: { token: string, greetingCardId: any, greetingCard: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await GreetingCardService.editGreetingCardById(token, greetingCardId, greetingCard, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteAGreetingCardById = createAsyncThunk('api/greeting-cards/delete', async ({ token, greetingCardId, signal }: { token: string, greetingCardId: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await GreetingCardService.deleteGreetingCardById(token, greetingCardId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const greetingCardSlice = createSlice({
  name: 'api/greeting-card',
  initialState,
  reducers: {
    resetGreetingCardError: (state) => {
      state.error = null
    },
    resetGreetingCardMessage: (state) => {
      state.message = null
    },
    resetGreetingCardData: (state) => {
      state.error = null
      state.greetingCard = null
      state.greetingCards = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGreetingCards.pending, (state) => {
        state.greetingCards = []
        state.isLoading = true
      })
      .addCase(getAllGreetingCards.fulfilled, (state, action) => {
        state.isLoading = false
        state.greetingCards = action.payload.greetingCards
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllGreetingCards.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addGreetingCard.pending, (state) => {
        state.greetingCards = []
        state.isLoading = true
      })
      .addCase(addGreetingCard.fulfilled, (state, action) => {
        state.isLoading = false
        state.greetingCard = action.payload.greetingCard
        state.error = null
        state.message = null
      })
      .addCase(addGreetingCard.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editAGreetingCardById.pending, (state) => {
        state.greetingCards = []
        state.isLoading = true
      })
      .addCase(editAGreetingCardById.fulfilled, (state, action) => {
        state.isLoading = false
        state.greetingCard = action.payload.greetingCard
        state.error = null
        state.message = null
      })
      .addCase(editAGreetingCardById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteAGreetingCardById.pending, (state) => {
        state.greetingCards = []
        state.isLoading = true
      })
      .addCase(deleteAGreetingCardById.fulfilled, (state, action) => {
        state.isLoading = false
        state.greetingCard = null
        state.error = null
        state.message = SALUTATION_DELETION_MESSAGE
      })
      .addCase(deleteAGreetingCardById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetGreetingCardError, resetGreetingCardMessage, resetGreetingCardData } = greetingCardSlice.actions

const { reducer } = greetingCardSlice
export default reducer
