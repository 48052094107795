export const ADMIN = 'Administrator'
export const CAMPAIGNMANAGER = 'CampaignManager'
export const COMPANYADMINISTRATOR = 'CompanyAdministrator'
export const USER = 'User'
export const EMPLOYEE = 'Employee'

export const UserRole = {
  None: { colour: 'success', text: '' },
  [CAMPAIGNMANAGER]: { colour: 'success', text: 'Campaign Manager' },
  [COMPANYADMINISTRATOR]: { colour: 'success', text: 'Company Administrator' },
  [USER]: { colour: 'success', text: 'User' },
  [ADMIN]: { colour: 'success', text: 'Administrator' },
  [EMPLOYEE]: { colour: 'success', text: 'Employee' }
}

export const rolesObject = {
  [ADMIN]: 'Administrator',
  [CAMPAIGNMANAGER]: 'Campaign Manager',
  [COMPANYADMINISTRATOR]: 'Company Administrator',
  [EMPLOYEE]: 'Employee',
  [USER]: 'User',
  '': ''
}

export const editableRoles = [
  { value: CAMPAIGNMANAGER, text: 'Campaign Manager' },
  { value: COMPANYADMINISTRATOR, text: 'Company Administrator' },
  { value: EMPLOYEE, text: 'Employee' },
  { value: USER, text: 'User' }
]
