import React from 'react'
import { Formik } from 'formik'
import { number, object } from 'yup'
import { resetUserData, sendVerifyCode, verifyEmail } from '../../../store/reducers/api/usersReducer'
import { useAppDispatch } from '../../../store/hooks'

interface EmailVerificationProps {
  isLoading: boolean
  email: string
  userId: string | null
  token: string | null
  setMode: Function
  setTitle: Function
  isVerified: boolean
}

const EmailVerification = ({ isLoading, email, userId, token, setMode, isVerified, setTitle }: EmailVerificationProps) => {
  const dispatch = useAppDispatch()

  const verificationSchema = object({
    otp: number()
      .typeError('OTP must be a number')
      .positive('OTP must be a positive number')
      .integer('OTP must be an integer')
      .when([], {
        is: () => !isVerified,
        then: number().required('OTP is required').typeError('OTP must be a number'),
        otherwise: number().typeError('OTP must be a number').optional()
      })
  })

  const verify = (email: string, otp: number) => {
    const controller = new AbortController()
    const signal = controller.signal
    const user = {
      email,
      otp
    }
    dispatch(resetUserData())
    if (userId && token) {
      dispatch(verifyEmail({ id: userId, token, user, signal }))
    }
  }

  return (
    <div className="">
      <Formik
        validationSchema={verificationSchema}
        initialValues={{ otp: '' }}
        onSubmit={({ otp }, actions) => {
          if (!isVerified) {
            verify(
              String(email),
              parseInt(otp, 10)
            )
          } else {
            setMode('assignOrCreateCompany')
          }
        }}
      >
        {({
          touched,
          errors,
          handleChange,
          handleBlur,
          values,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} >
            <div className="modal-body mh-280">
              <p>{isVerified ? 'Your email has been verified.' : `We sent a verification OTP to ${email}. You will be logged out on successful verification  and you'll be required to login with your new role.`}</p>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label
                    htmlFor="otp"
                    className="visually-hidden"
                  >
                    OTP
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.otp}
                    className={`form-control ${
                      errors.otp &&
                      touched.otp &&
                      errors.otp
                        ? 'is-invalid'
                        : ''
                    }`}
                    type="text"
                    id="otp"
                    name="otp"
                    disabled={isVerified}
                    placeholder={isVerified ? email : 'Enter OTP'}
                    autoComplete="off"
                  />
                  <div
                    id="validationOtpFeedback"
                    className="invalid-feedback"
                  >
                    {errors.otp}
                  </div>
                </div>
                <div className="col-md-6 d-grid d-md-block">
                  <button
                    type="button"
                    className="btn btn-outline-primary text-nowrap"
                    disabled={isLoading}
                    onClick={() => {
                      const controller =
                        new AbortController()
                      const signal = controller.signal
                      const user = {
                        email
                      }
                      if (token && userId) {
                        dispatch(
                          sendVerifyCode({
                            id: userId,
                            token,
                            user,
                            signal
                          })
                        )
                      }
                    }}
                  >
                    Resend Verification OTP
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary"
                onClick={() => {
                  setMode('logInToAccount')
                  setTitle('Account Login')
                }}
              >
                Back
              </button>
              <button type="submit" className="btn btn-primary">
                {isVerified ? 'Next' : 'Verify'}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default EmailVerification
