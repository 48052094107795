import ProductCategoryService from '../services/api/ProductCategoryService'
import { ProductCategoryTag } from '../types'

export const mapProductCategoryProductCategoryTags = (productCategoryProductCategoryTags: Array<Pick<ProductCategoryTag, 'id' | 'name' | 'type' | 'createdAt' | 'updatedAt'>>) => {
  return productCategoryProductCategoryTags.map((productCategoryTag) => ({
    value: productCategoryTag.id,
    label: `${productCategoryTag.name} - ${productCategoryTag.type}`
  }))
}

export const loadProductCategoryProductCategoryTagOptions = async (page: number, perPage: number, token: string, productCategoryId: string, inputValue: string) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token) {
    try {
      const res = await ProductCategoryService.getTagsOfProductCategory(token, perPage, page, productCategoryId, signal, inputValue)
      return mapProductCategoryProductCategoryTags(res.data.productCategoryTags)
    } catch (error) {
      return mapProductCategoryProductCategoryTags([])
    }
  } else {
    return mapProductCategoryProductCategoryTags([])
  }
}
