import React from 'react'
import { PendingOrderLine } from '../../types'

interface PendingOrderLinesViewerProps {
  pendingOrderLines: PendingOrderLine[]
}
const PendingOrderLinesViewer = ({ pendingOrderLines }: PendingOrderLinesViewerProps) => {
  return (
    <div className="table-responsive">
      <table className="table align-middle">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col" className="text-center col-1">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {
            pendingOrderLines.map(pendingOrderLine => (
              <tr key={pendingOrderLine.id}>
                <th scope="row" title={String(pendingOrderLine.id)} className="small user-select-all" onClick={() => navigator.clipboard.writeText(String(pendingOrderLine.id))}>
                  {(pendingOrderLine.id).slice(-8)}
                </th>
                <td>{pendingOrderLine.itemName}</td>
                <td className="text-center">
                  <span className="badge text-bg-secondary">{pendingOrderLine.quantity}</span>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default PendingOrderLinesViewer
