import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string, ref } from 'yup'
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom'

import Logo from '../../components/Logo'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { signUpUser, resetApiAuthError } from '../../store/reducers/api/authReducer'
import Progress from '../../components/loaders/Progress'
import Terms from '../../components/Terms'
import Privacy from '../../components/Privacy'
import { openModal } from '../../utils/openModal'
import { getAllLegalTexts } from '../../store/reducers/api/legalTextReducer'
import Spinner from '../../components/loaders/Spinner'
import { COMPANY_NOT_FOUND, INVALID_INVITATION_LINK } from '../../constants/messages'

const Register = () => {
  const isLoading = useAppSelector((state) => state.apiAuth.isLoading)
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const createdUser = useAppSelector((state) => state.apiAuth.createdUser)
  const error = useAppSelector((state) => state.apiAuth.error)
  const legalTexts = useAppSelector((state) => state.apiLegalText.legalTexts)
  const isLoadingLegalTexts = useAppSelector((state) => state.apiLegalText.isLoading)

  const [legalTextFilter, setLegalTextFilter] = useState('defaultTerms')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  function useQuery () {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  const companyId = query.get('companyId')

  const registerSchema = object({
    firstName: string()
      .required('First Name is required')
      .min(2, 'Enter a valid first name'),
    lastName: string()
      .required('Last Name is required')
      .min(2, 'Enter a valid last name'),
    email: string().email('Enter a valid email').required('Email is required'),
    password: string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: string()
      .required('Confirm Password is required')
      .oneOf([ref('password'), null], 'Passwords must match'),
    hasAgreed: string().oneOf(['true'], 'We want to make sure you understand our policies. Please read and agree to our terms and privacy policy before continuing.')
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const filter = `filter[type]=${legalTextFilter}`
    dispatch(getAllLegalTexts({ perPage: 20, page: 1, filter, signal }))

    return () => {
      controller.abort()
    }
  }, [legalTextFilter])

  useEffect(() => {
    if (currentUser?.token) {
      navigate('/', { })
    }
  }, [createdUser])

  useEffect(() => {
    if (createdUser) {
      navigate('/login', {
        state: {
          email: createdUser.email,
          message: `Hi ${createdUser.firstName}, your account has been created.\n Log in to continue.`
        }
      })
    }
  }, [createdUser])

  if (currentUser?.token) {
    return <Navigate to="/" />
  }
  return (
    <main className="bg-dark">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <Logo isWhite />
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header">
                      <h3 className="text-center font-weight-light my-4">
                        Create Account
                      </h3>
                    </div>
                    {isLoading && <Progress />}
                    <div className="card-body">
                      {
                        (error?.errors) && (
                          <div className="alert alert-danger capitalize-first-letter" role="alert">
                            {`${error.errors.message}. ${error.errors.message === COMPANY_NOT_FOUND ? INVALID_INVITATION_LINK : ''}`}
                          </div>
                        )
                      }
                      <Formik
                        validationSchema={registerSchema}
                        initialValues={{
                          firstName: '',
                          lastName: '',
                          email: '',
                          password: '',
                          confirmPassword: '',
                          hasAgreed: false
                        }}
                        onSubmit={({ firstName, lastName, email, password }, actions) => {
                          dispatch(resetApiAuthError())
                          const controller = new AbortController()
                          const signal = controller.signal
                          const user = {
                            firstName, lastName, email, password
                          }
                          dispatch(signUpUser({ user, companyId, signal }))
                          actions.setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="form-floating mb-3 mb-md-0">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    className={`form-control ${errors.firstName && touched.firstName && errors.firstName ? 'is-invalid' : ''}`}
                                    id="inputFirstName"
                                    type="text"
                                    name="firstName"
                                    aria-describedby="validationFirstNameFeedback"
                                  />
                                  <label htmlFor="inputFirstName">
                                    First name
                                  </label>
                                  <div id="validationFirstNameFeedback" className="invalid-feedback">
                                    {errors.firstName}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    className={`form-control ${errors.lastName && touched.lastName && errors.lastName ? 'is-invalid' : ''}`}
                                    id="inputLastName"
                                    type="text"
                                    name="lastName"
                                    aria-describedby="validationLastNameFeedback"
                                  />
                                  <label htmlFor="inputLastName">
                                    Last name
                                  </label>
                                  <div id="validationLastNameFeedback" className="invalid-feedback">
                                    {errors.lastName}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className={`form-control ${((errors.email && touched.email && errors.email) || error?.statusCode === 400) ? 'is-invalid' : ''}`}
                                id="inputEmail"
                                type="email"
                                name="email"
                                aria-describedby="validationEmailFeedback"
                              />
                              <label htmlFor="inputEmail">Email address</label>
                              <div id="validationEmailFeedback" className="invalid-feedback capitalize-first-letter">
                                {errors.email || error?.errors?.message}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-6">
                                <div className="form-floating mb-3 mb-md-0">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    className={`form-control ${errors.password && touched.password && errors.password ? 'is-invalid' : ''}`}
                                    id="inputPassword"
                                    type="password"
                                    name="password"
                                    aria-describedby="validationPasswordFeedback"
                                  />
                                  <label htmlFor="inputPassword">
                                    Password
                                  </label>
                                  <div id="validationPasswordFeedback" className="invalid-feedback">
                                    {errors.password}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-floating mb-3 mb-md-0">
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.confirmPassword}
                                    className={`form-control ${errors.confirmPassword && touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}`}
                                    id="inputConfirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    aria-describedby="validationConfirmPasswordFeedback"
                                  />
                                  <label htmlFor="inputConfirmPassword">
                                    Confirm Password
                                  </label>
                                  <div id="validationConfirmPasswordFeedback" className="invalid-feedback">
                                    {errors.confirmPassword}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className={`form-check ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                                  <input
                                    className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                                    type="checkbox"
                                    checked={values.hasAgreed}
                                    id="hasAgreed"
                                    name="hasAgreed"
                                    onChange={(e) => {
                                      handleChange(e)
                                      openModal('registerTermsModal')
                                    }}
                                  />
                                  <label className="form-check-label" htmlFor="hasAgreed">
                                    <span className="small">
                                      By creating an account I agree to the <button className="btn btn-link btn-sm text-primary m-0 p-0" data-bs-toggle="modal" type="button" data-bs-target="#registerTermsModal">Terms & Privacy Policy</button>.
                                    </span>
                                  </label>
                                </div>
                                <div
                                  id="validationHasAgreedFeedback"
                                  className="invalid-feedback"
                                >
                                  {errors.hasAgreed}
                                </div>
                              </div>
                            </div>
                            <div className="mt-2 mb-0">
                              <div className="d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                  disabled={isSubmitting || isLoading}
                                >
                                  Create Account
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <div className="card-footer text-center py-3">
                      <div className="small">
                        <Link to="/login">Have an account? Go to login</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="modal fade" id="registerTermsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="registerTermsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3" id="registerTermsModalLabel">Terms and Conditions</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {
                isLoadingLegalTexts
                  ? <Spinner />
                  : <Terms terms={legalTexts.find(legalText => legalText.type === 'terms' || legalText.type === 'defaultTerms')?.template} />
              }
            </div>
            <div className="modal-footer">
              <button type="button" onClick={() => setLegalTextFilter('defaultPrivacy')} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#registerPrivacyModal">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="registerPrivacyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="registerPrivacyModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3" id="registerPrivacyModalLabel">Privacy Policy</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {
                isLoadingLegalTexts
                  ? <Spinner />
                  : <Privacy privacyPolicy={legalTexts.find(legalText => (legalText.type === 'privacy' || legalText.type === 'defaultPrivacy'))?.template} />
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#registerTermsModal">Back</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Understood</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Register
