import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Product, ShoppingCartProduct } from '../../types'
import { formatPrice } from '../../utils/formatPrice'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'
import { getMinimumProductOrderQuantity } from '../../utils/getMinimumProductOrderQuantity'
import { getMaximumProductOrderQuantity } from '../../utils/getMaximumProductOrderQuantity'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { setShoppingCart, setSplitProduct } from '../../store/reducers/shoppingCartReducer'

interface ShoppingCartArticlesProps {
  onDragStart: (e: React.DragEvent<HTMLDivElement>, product: Product) => void,
    assignedItemIds: string[]
}

const ShoppingCartArticles = ({ onDragStart, assignedItemIds }: ShoppingCartArticlesProps) => {
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || 1 }))
  const filteredShoppingCartProductsWithQuantity = shoppingCartProductsWithQuantity.filter((product) => !(assignedItemIds.includes((product.spliteId) ? product.spliteId : product.id)))
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const calculateTotalQuantityInBundles = ({ shoppingCartProduct }:{shoppingCartProduct:ShoppingCartProduct}): number => {
    return shoppingCartBundles.reduce((total, bundle) => {
      const bundleProduct = bundle.products.find(p => (shoppingCartProduct.spliteId && p.spliteId) ? p.spliteId === shoppingCartProduct.spliteId : p.id === shoppingCartProduct.id)
      return total + (bundleProduct ? bundleProduct.quantity * bundle.quantity : 0)
    }, 0)
  }
  const maximumQuantity = 1000
  const dispatch = useAppDispatch()

  return (
    <div className = 'bg-light' style={{ maxWidth: '60.5rem' }}>
      <div>

        <div className="row">
          <div className="col">
            <div className="border rounded ">
              <div className="d-flex justify-content-center align-items-center border-bottom w-100 " style={{ paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                <p className="fw-bold me-auto my-auto">Products</p>
                <div className="d-flex justify-content-between align-items-center" style={{ width: '50%' }}>
                  <p className="fw-bold col-4 text-center my-auto">Amount</p>
                  <p className="fw-bold col-4 text-center my-auto">Price</p>
                  <p className="fw-bold col-4 text-end text-nowrap my-auto">Split Amount</p>
                </div>
              </div>
              <div className="bg-light p-3 w-100" style={{ height: '27rem', overflow: 'auto', scrollbarWidth: 'thin', msOverflowStyle: 'auto' }}>
              {
                  filteredShoppingCartProductsWithQuantity.length > 0
                    ? (
                        filteredShoppingCartProductsWithQuantity.map(shoppingCartProduct => (
                          <div
                            key={`shopping-cart-product${shoppingCartProduct.spliteId ?? shoppingCartProduct.id}`}
                            className="d-flex justify-content-between align-items-center bg-white p-2 mb-2 rounded w-100"
                            draggable
                            onDragStart={(e) => onDragStart(e, shoppingCartProduct)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                              e.preventDefault()
                            }}
                            style={{ cursor: 'grab' }}
                          >
                            <div className="col">
                              <span>{shoppingCartProduct.name}</span>
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: '50%' }}>
                              <div className="col-2 text-center" style={{ width: '4rem' }}>
                                <div className="form-group col-12">
                                  <input
                                    type="number"
                                    min={Math.max(calculateTotalQuantityInBundles({ shoppingCartProduct }), getMinimumProductOrderQuantity(shoppingCartProduct.minimumOrderQuantity, shoppingCartProduct.graduatedPrices), calculateTotalQuantityInBundles({ shoppingCartProduct }))}
                                    max={(shoppingCartProduct.isExceedStockEnabled ? getMaximumProductOrderQuantity(maximumQuantity, shoppingCartProduct.graduatedPrices) : Math.max((shoppingCartProduct.stock?.stockLevel || 0) - (shoppingCartProduct.stock?.stockLevelReserved || 0), 0) || 1)}
                                    value={shoppingCartProduct.quantity}
                                    onChange={(e) => {
                                      const max = Math.max((shoppingCartProduct.stock?.stockLevel || 0) - (shoppingCartProduct.stock?.stockLevelReserved || 0), 0) || 1
                                      let min = getMinimumProductOrderQuantity(shoppingCartProduct.minimumOrderQuantity, shoppingCartProduct.graduatedPrices)

                                      const totalShoppingCartProductInBundlesQuantity = calculateTotalQuantityInBundles({ shoppingCartProduct })

                                      min = Math.max(min, totalShoppingCartProductInBundlesQuantity)
                                      const value = Number(e.target.value)
                                      const quantity = shoppingCartProduct.isExceedStockEnabled ? Math.max(Math.min(value, getMaximumProductOrderQuantity(maximumQuantity, shoppingCartProduct.graduatedPrices)), min) : Math.max(Math.min(value, max), min)
                                      const foundProductInCartIndex = shoppingCartProductsWithQuantity.findIndex(cartProduct => (shoppingCartProduct.spliteId && cartProduct.spliteId) ? cartProduct.spliteId === shoppingCartProduct.spliteId : cartProduct.id === shoppingCartProduct.id)

                                      if (foundProductInCartIndex !== -1) {
                                        const updatedProductsInCart = [...shoppingCartProductsWithQuantity]
                                        updatedProductsInCart[foundProductInCartIndex] = {
                                          ...updatedProductsInCart[foundProductInCartIndex],
                                          quantity
                                        }
                                        const payload = {
                                          products: updatedProductsInCart
                                        }
                                        dispatch(setShoppingCart(payload))
                                      }
                                    }}
                                    name="quantity"
                                    className="form-control"
                                    aria-label={'Cart Item Quantity'}
                                    autoComplete="off"
                                    disabled={false}
                                  />
                                </div>
                              </div>
                              <div className="col-2 text-center">
                                <span>
                                  {formatPrice('EUR', navigator.language).format(
                                    calculateGraduatedPrice(
                                      shoppingCartProduct.graduatedPrices,
                                      shoppingCartProduct.quantity,
                                      shoppingCartProduct.netRetailPrice.amount
                                    ) * shoppingCartProduct.quantity
                                  )}
                                </span>
                              </div>
                              <div className="bg-light text-center">
                                <div className="bg-white self-justify">
                                  <div className="btn btn-round"
                                    onClick={() => {
                                      openModal('splitAmountModal')
                                      dismissModal('shoppingShippingDetailsModal')
                                      dispatch(setSplitProduct(shoppingCartProduct))
                                    }}
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    : (
                        <div className="text-center h-100 d-flex justify-content-center align-items-center">
                          <p className=''>No item has been added to your cart.</p>
                        </div>
                      )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartArticles
