import dayjs from 'dayjs'
import { DaysOfWeek } from '../enums/daysOfTheWeek'

const getNextBusinessDay = () => {
  let dayToCheck = dayjs()
  let businessDaysAdded = 0

  while (businessDaysAdded < 3) {
    dayToCheck = dayToCheck.add(1, 'day')
    // Only count it as a business day if it's not Saturday or Sunday
    if (dayToCheck.day() !== DaysOfWeek.Saturday && dayToCheck.day() !== DaysOfWeek.Sunday) {
      businessDaysAdded++
    }
  }

  return dayToCheck.toDate()
}

export const getDateOfDispatchMinimumDate = () => {
  return getNextBusinessDay()
}
