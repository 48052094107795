import React, { forwardRef, useEffect, useRef, useState } from 'react'
import Quill, { Range } from 'quill'
import 'quill/dist/quill.snow.css'

type EmailRichTextEditorProps = {
  stageWidth: string | number
  placeholders: string[]
  textContent: string
  handleTextChange: (value: string) => void
  readOnly: boolean
  border?: string
  className?: string
}

const EmailRichTextEditor = forwardRef<Quill | null, EmailRichTextEditorProps>(({
  stageWidth,
  placeholders,
  textContent,
  handleTextChange,
  className,
  readOnly,
  border
}, ref) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [, setRange] = useState<Range>()
  const [, setPreviousRange] = useState<Range>()
  const [, setHighlightedText] = useState<string | undefined>(undefined)

  function placeholderDropdown (placeholder: string) {
    const quill = ref && 'current' in ref && ref.current

    if (placeholder && quill && !readOnly) {
      quill.focus()
      const cursorPosition = quill.getSelection()?.index || 0

      quill.insertText(cursorPosition, placeholder)
      quill.setSelection(cursorPosition + placeholder.length)
    }
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'list',
    'indent',
    'color',
    'code-block'
  ]

  const toolbarOptions = [
    [
      { font: ['sans-serif', 'serif', 'monospace', 'montserrat', 'stanford'] },
      { size: ['small', false, 'large', 'huge'] }
    ],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ script: 'super' }, { script: 'sub' }, 'blockquote'],
    [{ align: [] }, { color: [] }],
    ['link'],
    [{ placeholderDropdown: placeholders }]
  ]

  useEffect(() => {
    if (ref && 'current' in ref && ref.current) {
      ref.current.enable(!readOnly)
    }
  }, [ref, readOnly])

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const editorContainer = container.appendChild(
      container.ownerDocument.createElement('div')
    )
    const quill = new Quill(editorContainer, {
      placeholder: 'Compose an email template...',
      theme: 'snow',
      formats,
      modules: {
        toolbar: {
          container: toolbarOptions,
          handlers: {
            placeholderDropdown
          }
        },
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: (range: Range) => {
                const noBreakSpace: string = '\u00A0'
                quill.insertText(range.index, noBreakSpace)
                quill.setSelection(range.index + 1)
                quill.setSelection(range.index + noBreakSpace.length)
                return false // Prevent default behavior
              }
            }
          }
        }
      }
    })

    if (ref && 'current' in ref) {
      ref.current = quill
    }

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      if (ref && 'current' in ref && ref.current) {
        const html = ref.current.root.innerHTML
        handleTextChange(html)
      }
    })

    quill.on(Quill.events.SELECTION_CHANGE, (range, oldRange, source) => {
      if (range) {
        setRange(range)
        if (range.length !== 0) {
          if (ref && 'current' in ref && ref.current) {
            const text = ref.current?.getText(range.index, range.length)
            setHighlightedText(text)
          }
        }
      }
      if (oldRange) {
        setPreviousRange(oldRange)
      }
    })

    return () => {
      if (ref && 'current' in ref) {
        ref.current = null
      }
      container.innerHTML = ''
    }
  }, [ref])

  useEffect(() => {
    if (ref && 'current' in ref && ref.current && ref.current.root.innerHTML !== textContent) {
      ref.current.clipboard.dangerouslyPasteHTML(textContent)
      handleTextChange(textContent)
    }
  }, [textContent])

  return (
    <div className={className}>
      <div
        ref={containerRef}
        className="rounded"
        style={{
          width: stageWidth,
          border
        }}
      >
      </div>
    </div>
  )
})

EmailRichTextEditor.displayName = 'EmailRichTextEditor'

export default EmailRichTextEditor
