import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProfileService from '../../../services/api/ProfileService'
import { ProfileState } from '../../../types'

const initialState: ProfileState = {
  isLoading: false,
  error: null,
  profile: null
}

export const getMyProfile = createAsyncThunk('api/profile/get', async ({ token, signal }: { token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProfileService.getMyProfile(token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const profileSlice = createSlice({
  name: 'api/profile',
  initialState,
  reducers: {
    resetProfileError: (state) => {
      state.error = null
    },
    resetProfileData: (state) => {
      state.error = null
      state.profile = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.isLoading = false
        state.profile = action.payload.user
        state.error = null
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProfileError, resetProfileData } = profileSlice.actions

const { reducer } = profileSlice
export default reducer
