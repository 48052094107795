import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../../store/hooks'

const Auth = () => {
  const auth = useAppSelector((state) => state.auth.auth)
  const baseOAuth2URL = process.env.REACT_APP_BASE_OAUTH2_API_URL
  const redirectURI = process.env.REACT_APP_JTL_REDIRECT_URL
  const clientID = process.env.REACT_APP_JTL_CLIENT_ID
  const scope = process.env.REACT_APP_JTL_SCOPE

  const requestAuthLink = `${baseOAuth2URL}/authorize?response_type=code&redirect_uri=${redirectURI}&client_id=${clientID}&scope=${scope}`

  if (auth.accessToken) {
    return <Navigate to="/" />
  }
  return (
    <div className="container h-100 p-4">
      <div className="border border-light p-3 mb-4">
        <div className="d-flex align-items-center justify-content-center" style={{ height: '350px' }}>
          <div className="col-lg-5">
            <div className="card shadow-lg border-0 rounded-lg mt-5">
              <div className="card-header">
                <h3 className="text-center font-weight-light my-4">
                  Login
                </h3>
              </div>
              <div className="card-body">
                <div className="d-grid gap-2 mx-auto">
                  <a className="btn btn-primary" role="button" href={requestAuthLink}>Authorize</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth
