import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RECIPIENT_DELETION_MESSAGE, RECIPIENT_UPDATE_SUCCESS_MESSAGE } from '../../../constants/messages'
import RecipientService from '../../../services/api/RecipientService'
import { RecipientState } from '../../../types'

const initialState: RecipientState = {
  isLoading: false,
  error: null,
  recipient: null,
  message: null
}

export const editRecipientAddress = createAsyncThunk('api/recipient/edit', async ({ id, token, recipient, signal }: { id: string, token: string, recipient: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await RecipientService.editRecipientAddress(id, token, recipient, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteRecipientAddress = createAsyncThunk('api/recipient/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal}) => {
  const res = await RecipientService.deleteRecipientAddress(id, token, signal)
  return res.data
})

const recipientSlice = createSlice({
  name: 'api/recipient',
  initialState,
  reducers: {
    resetRecipientError: (state) => {
      state.error = null
    },
    resetRecipientMessage: (state) => {
      state.message = null
    },
    resetRecipientData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editRecipientAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editRecipientAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.recipient = action.payload.recipient
        state.message = RECIPIENT_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(editRecipientAddress.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteRecipientAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteRecipientAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = RECIPIENT_DELETION_MESSAGE
      })
      .addCase(deleteRecipientAddress.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetRecipientError, resetRecipientMessage, resetRecipientData } = recipientSlice.actions

const { reducer } = recipientSlice
export default reducer
