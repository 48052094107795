import React from 'react'
import { inbounds } from '../../../types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const InboundOrder = ({ inbound, jfsku }: { inbound: inbounds.Inbound, jfsku: string }) => {
  const totalOrderedQuantity = (inbound?.items.filter((item) => item.jfsku === jfsku)
    .reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0)) || 0
  const totalQuantityOpen = (inbound?.items.filter((item) => item.jfsku === jfsku)
    .reduce((previousValue, currentValue) => previousValue + currentValue.quantityOpen, 0)) || 0

  const totalDeliveredQuantity = totalOrderedQuantity - totalQuantityOpen
  const date = inbound.modificationInfo ? dayjs.utc(inbound.modificationInfo.updatedAt).local().format('LL') : '---'

  return (
    <tr>
      <td>{inbound.inboundId}</td>
      <td className="text-center"><span className="badge text-bg-secondary">{totalDeliveredQuantity}</span></td>
      <td>{date}</td>
    </tr>
  )
}

export default InboundOrder
