import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { User, UserCompany } from '../../../types'
import { useAppDispatch } from '../../../store/hooks'
import { createACompany } from '../../../store/reducers/api/companyReducer'
import * as userRoles from '../../../constants/userRoles'

interface CompanyCreationProps {
  setMode: Function
  setTitle: Function
  setCompanyMode: Function
  company: UserCompany | null | undefined
  editBillingOrDeliveryAddress: boolean
  setEditBillingOrDeliveryAddress: Function
  profile: User | null
  token: string | null
}
const CompanyCreation = ({
  setCompanyMode,
  setMode,
  setTitle,
  company,
  editBillingOrDeliveryAddress,
  setEditBillingOrDeliveryAddress,
  token,
  profile
}: CompanyCreationProps) => {
  const dispatch = useAppDispatch()
  const [editDisabled, setEditDisabled] = useState(false)

  const isOwner = Boolean(company && company.owner.id === profile?.id)
  const allowedRoles = [userRoles.ADMIN, userRoles.COMPANYADMINISTRATOR]
  const isAllowed = () => (isOwner || allowedRoles.includes(profile?.role || userRoles.USER))
  const isAddressRadioButtonDisabled = !company || company.isDomainVerified === false || !isAllowed()

  const companySchema = object({
    name: string()
      .required('Company Name is required')
      .max(64, 'Company Name is too long'),
    suffix: string().max(16, 'Company Suffix is too long').nullable(),
    email: string().email('Enter a valid email').required('Email is required'),
    domain: string().matches(
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
      'Enter a valid domain name'
    ).required('Domain is required').nullable(),
    vat: string().max(64, 'VAT is too long').nullable()
  })

  useEffect(() => {
    if (company) {
      setEditDisabled(true)
    }
  }, [company])

  return (
    <div>
      <Formik
        validationSchema={companySchema}
        initialValues={{
          name: company?.name || '',
          suffix: company?.suffix || '',
          email: company?.email || '',
          domain: company?.domain || '',
          vat: company?.vat || ''
        }}
        onSubmit={({ name, suffix, email, domain, vat }, actions) => {
          if (!company?.id) {
            const controller = new AbortController()
            const signal = controller.signal
            const company = {
              name,
              suffix,
              email,
              domain,
              vat
            }
            if (token) {
              dispatch(createACompany({ token, company, signal })
              )
            }
          } else {
            if (editBillingOrDeliveryAddress && isAllowed()) {
              setMode('billingOrDeliveryAddress')
            } else {
              setMode('updateProfile')
              setTitle('Profile Information')
            }
          }
        }}
      >
        {({
          touched,
          errors,
          handleChange,
          handleBlur,
          values,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} >
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <p>
                      If your company exists, click the <span className="fw-medium">&apos;Join Company&apos;</span> button below to join your company.
                    </p>
                    <div className="col-md-6 d-grid d-md-block">
                      <button
                        title="Join a company"
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => {
                          setCompanyMode('companyAssignment')
                        }}
                      >
                        Join Company
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                {company && (isOwner
                  ? <p>You are the owner of <span className="fw-bold">{company.name}</span></p>
                  : <p>You have been assigned to <span className="fw-bold">{company.name}</span>.</p>)
                }
                <p className="text-primary fw-semibold small">{!company && "You will be logged out on successful company creation and you'll be required to login with your new role."}</p>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingCompanyName" className="form-label">
                      Company Name
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      type="text"
                      className={`form-control ${
                        errors.name && touched.name && errors.name
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingCompanyName"
                      name="name"
                      placeholder=""
                      disabled={editDisabled}
                      autoComplete="on"
                    />
                    <div
                      id="validationCompanyNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.name}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingCompanySuffix" className="form-label">
                      Company Suffix
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.suffix || ''}
                      type="text"
                      className={`form-control ${
                        errors.suffix && touched.suffix && errors.suffix
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingCompanySuffix"
                      name="suffix"
                      placeholder=""
                      maxLength={16}
                      disabled={editDisabled}
                      autoComplete="on"
                    />
                    <div
                      id="validationCompanySuffixFeedback"
                      className="invalid-feedback"
                    >
                      {errors.suffix}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingCompany" className="form-label">
                      Admin Email
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      type="email"
                      className={`form-control ${
                        errors.email && touched.email && errors.email
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingCompany"
                      name="email"
                      placeholder=""
                      maxLength={128}
                      disabled={editDisabled}
                      autoComplete="on"
                    />
                    <div
                      id="validationEmailFeedback"
                      className="invalid-feedback"
                    >
                      {errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingCompanyDomain" className="form-label">
                      Domain (example.com)
                    </label>
                    <input
                      aria-label="Domain"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.domain || ''}
                      type="text"
                      className={`form-control ${
                        errors.domain && touched.domain && errors.domain
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingCompanyDomain"
                      name="domain"
                      disabled={editDisabled}
                      autoComplete="on"
                    />
                    <div
                      id="validationDomainFeedback"
                      className="invalid-feedback"
                    >
                      {errors.domain}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="onboardingCompanyVat" className="form-label">
                      VAT <span className="small">(eg. DE123456789)</span>
                    </label>
                    <input
                      aria-label="vat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vat || ''}
                      type="text"
                      className={`form-control ${
                        errors.vat && touched.vat && errors.vat
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="onboardingCompanyVat"
                      name="vat"
                      disabled={editDisabled}
                      autoComplete="on"
                    />
                    <div id="validationVatFeedback" className="invalid-feedback">
                      {errors.vat}
                    </div>
                  </div>
                </div>
              </div>

              {!isAddressRadioButtonDisabled && <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <p className="mt-2">
                      Do you want to edit your billing and delivery address?<br/>
                      {(!isOwner && !company) && <span className="small text-primary fw-semibold">Create a company first in order to add an address<br/></span>}
                      {company?.isDomainVerified === false && <span className="small text-primary fw-semibold">Make sure your company domain is verified first in order to add an address. Contact the admin for company verification.</span>}
                    </p>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="editAddresses"
                        id="inlineRadioYes"
                        onChange={() => {
                          localStorage.setItem('editBillingOrDeliveryAddress', 'yes')
                          setEditBillingOrDeliveryAddress(true)
                        }}
                        value="yes"
                        checked={editBillingOrDeliveryAddress}
                        disabled={isAddressRadioButtonDisabled}
                        autoComplete="off"
                      />
                      <label className="form-check-label" htmlFor="inlineRadioYes">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="editAddresses"
                        id="inlineRadioNo"
                        onChange={() => {
                          localStorage.setItem('editBillingOrDeliveryAddress', 'no')
                          setEditBillingOrDeliveryAddress(false)
                        }}
                        value="no"
                        checked={!editBillingOrDeliveryAddress}
                        disabled={isAddressRadioButtonDisabled}
                        autoComplete="off"
                      />
                      <label className="form-check-label" htmlFor="inlineRadioNo">No</label>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary"
                onClick={() => {
                  setMode('verifyEmail')
                  setTitle('Email Verification')
                }}
              >
                Back
              </button>
              <button type="submit" className="btn btn-primary">
                {company?.id ? 'Next' : 'Create'}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyCreation
