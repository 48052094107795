import { Tooltip as BootstrapTooltip } from 'bootstrap'
import React, { useEffect, useRef } from 'react'

type Placement = 'right' | 'left' | 'top' | 'bottom' | 'auto'

export const Tooltip = (p: { children: React.ReactElement, text: string, placement: Placement }) => {
  const childRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (childRef.current) {
      const t = new BootstrapTooltip(childRef.current, {
        title: p.text,
        placement: p.placement,
        trigger: 'hover'
      })
      return () => {
        if (t) {
          t.dispose()
        }
      }
    }
  }, [p.text, p.placement])

  return React.cloneElement(p.children, { ref: childRef })
}
