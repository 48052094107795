import React from 'react'
import LogoBlack from '../assets/images/logo.png'
import LogoWhite from '../assets/images/logo-white.png'

const Logo = ({ isWhite, width = '300px', logoUrl }: { isWhite?: boolean, width?: string, logoUrl?:string }) => {
  return (
    <div className="text-center my-5">
      <img style={{ width }} src={logoUrl || (isWhite ? LogoWhite : LogoBlack)} alt="Logo" />
    </div>
  )
}

export default Logo
