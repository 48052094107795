export const countries = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentine',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijani',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cape Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'Christmas Island',
  'Cocos Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czech Republic',
  "Côte d'Ivoire",
  'Democratic Republic of Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabonese Republic',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Great Britain',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard and the McDonald Islands',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Macedonia',
  'Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian territories',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'St. Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United States Minor Outlying Islands',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vatican',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands of the United States',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]

export const countriesObject = [
  {
    country: 'Afghanistan',
    countryGerman: 'Afghanistan',
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    numeric: 4,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Åland Islands',
    countryGerman: 'Åland Islands',
    alpha2Code: 'AX',
    alpha3Code: 'ALA',
    numeric: 248
  },
  {
    country: 'Albania',
    countryGerman: 'Albanien',
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    numeric: 8,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Algeria',
    countryGerman: 'Algerien',
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    numeric: 12,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'American Samoa',
    countryGerman: 'Amerikanisch-Samoa',
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    numeric: 16
  },
  {
    country: 'Andorra',
    countryGerman: 'Andorra',
    alpha2Code: 'AD',
    alpha3Code: 'AND',
    numeric: 20,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Angola',
    countryGerman: 'Angola',
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    numeric: 24,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Anquilla',
    countryGerman: 'Anguilla',
    alpha2Code: 'AI',
    alpha3Code: 'AIA',
    numeric: 660,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Antarctica',
    countryGerman: 'Antarctica',
    alpha2Code: 'AQ',
    alpha3Code: 'ATA',
    numeric: 10
  },
  {
    country: 'Antigua and Barbuda',
    countryGerman: 'Antigua und Barbuda',
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    numeric: 28,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Argentine',
    countryGerman: 'Argentinien',
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    numeric: 32,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Armenia',
    countryGerman: 'Armenien',
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    numeric: 51,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Aruba',
    countryGerman: 'Aruba',
    alpha2Code: 'AW',
    alpha3Code: 'ABW',
    numeric: 533,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Australia',
    countryGerman: 'Australien',
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    numeric: 36,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Austria',
    countryGerman: 'Österreich',
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    numeric: 40,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Azerbaijani',
    countryGerman: 'Aserbaidschan',
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    numeric: 31,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Bahamas',
    countryGerman: 'Bahamas',
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    numeric: 44,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Bahrain',
    countryGerman: 'Bahrain',
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    numeric: 48,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Bangladesh',
    countryGerman: 'Bangladesch',
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    numeric: 50,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Barbados',
    countryGerman: 'Barbados',
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    numeric: 52,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Belarus',
    countryGerman: 'Belarus',
    alpha2Code: 'BY',
    alpha3Code: 'BLR',
    numeric: 112,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Belgium',
    countryGerman: 'Belgien',
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    numeric: 56,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Belize',
    countryGerman: 'Belize',
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    numeric: 84,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Benin',
    countryGerman: 'Benin',
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    numeric: 204,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Bermuda',
    countryGerman: 'Bermuda',
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    numeric: 60,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Bhutan',
    countryGerman: 'Bhutan',
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    numeric: 64,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Bolivia',
    countryGerman: 'Bolivien',
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    numeric: 68,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Bonaire, Sint Eustatius and Saba',
    countryGerman: 'Bonaire, Sint Eustatius and Saba',
    alpha2Code: 'BQ',
    alpha3Code: 'BES',
    numeric: 535,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Bosnia and Herzegovina',
    countryGerman: 'Bosnien-Herzegowina',
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    numeric: 70,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Botswana',
    countryGerman: 'Botsuana',
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    numeric: 72,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Bouvet Island',
    countryGerman: 'Bouvetinsel',
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    numeric: 74
  },
  {
    country: 'Brazil',
    countryGerman: 'Brasilien',
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    numeric: 76,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'British Indian Ocean Territory',
    countryGerman: 'Britisches Territorium im Indischen Ozean',
    alpha2Code: 'IO',
    alpha3Code: 'IOT',
    numeric: 86,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'British Virgin Islands',
    countryGerman: 'die Britischen Jungferninseln',
    alpha2Code: 'VG',
    alpha3Code: 'VGB',
    numeric: 92,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Brunei',
    countryGerman: 'Brunei',
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    numeric: 96,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Bulgaria',
    countryGerman: 'Bulgarien',
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    numeric: 100,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Burkina Faso',
    countryGerman: 'Burkina Faso',
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    numeric: 854,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Burundi',
    countryGerman: 'Burundi',
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    numeric: 108,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Cambodia',
    countryGerman: 'Kambodscha',
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    numeric: 116,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Cameroon',
    countryGerman: 'Kamerun',
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    numeric: 120,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Canada',
    countryGerman: 'Kanada',
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    numeric: 124,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Cape Verde',
    countryGerman: 'Kap Verde',
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    numeric: 132,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Cayman Islands',
    countryGerman: 'Kaimaninseln',
    alpha2Code: 'KY',
    alpha3Code: 'CYM',
    numeric: 136,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Central African Republic',
    countryGerman: 'Zentralafrikanische Republik',
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    numeric: 140,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Chad',
    countryGerman: 'Tschad',
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    numeric: 148,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Chile',
    countryGerman: 'Chile',
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    numeric: 152,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'China',
    countryGerman: 'China',
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    numeric: 156,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Christmas Island',
    countryGerman: 'Weihnachtsinsel',
    alpha2Code: 'CX',
    alpha3Code: 'CXR',
    numeric: 162
  },
  {
    country: 'Cocos Islands',
    countryGerman: 'Kokosinseln',
    alpha2Code: 'CC',
    alpha3Code: 'CCK',
    numeric: 166
  },
  {
    country: 'Colombia',
    countryGerman: 'Kolumbien',
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    numeric: 170,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Comoros',
    countryGerman: 'Komoren',
    alpha2Code: 'KM',
    alpha3Code: 'COM',
    numeric: 174,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Congo',
    countryGerman: 'Kongo',
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    numeric: 178,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Democratic Republic of Congo',
    countryGerman: 'Kongo',
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    numeric: 180,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Cook Islands',
    countryGerman: 'Cookinseln',
    alpha2Code: 'CK',
    alpha3Code: 'COK',
    numeric: 184
  },
  {
    country: 'Costa Rica',
    countryGerman: 'Costa Rica',
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    numeric: 188,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: "Côte d'Ivoire",
    countryGerman: "Côte d'Ivoire",
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    numeric: 384
  },
  {
    country: 'Croatia',
    countryGerman: 'Kroatien',
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    numeric: 191,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Cuba',
    countryGerman: 'Kuba',
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    numeric: 192,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Curaçao',
    countryGerman: 'Curaçao',
    alpha2Code: 'CW',
    alpha3Code: 'CUW',
    numeric: 531
  },
  {
    country: 'Cyprus',
    countryGerman: 'Zypern',
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    numeric: 196
  },
  {
    country: 'Czech Republic',
    countryGerman: 'Tschechische Republik',
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    numeric: 203,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Denmark',
    countryGerman: 'Dänemark',
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    numeric: 208,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Djibouti',
    countryGerman: 'Dschibuti',
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    numeric: 262,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Dominica',
    countryGerman: 'Dominica',
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    numeric: 212,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Dominican Republic',
    countryGerman: 'Dominikanische Republik',
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    numeric: 214,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Ecuador',
    countryGerman: 'Ecuador',
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    numeric: 218,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Egypt',
    countryGerman: 'Ägypten',
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    numeric: 818,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'El Salvador',
    countryGerman: 'El Salvador',
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    numeric: 222,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Equatorial Guinea',
    countryGerman: 'Äquatorialguinea',
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    numeric: 226,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Eritrea',
    countryGerman: 'Eritrea',
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    numeric: 232,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Estonia',
    countryGerman: 'Estland',
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    numeric: 233,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Ethiopia',
    countryGerman: 'Äthiopien',
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    numeric: 231,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Falkland Islands',
    countryGerman: 'Falklandinseln',
    alpha2Code: 'FK',
    alpha3Code: 'FLK',
    numeric: 238,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Faroe Islands',
    countryGerman: 'Färöer',
    alpha2Code: 'FO',
    alpha3Code: 'FRO',
    numeric: 234,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Fiji',
    countryGerman: 'Fidschi',
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    numeric: 242,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Finland',
    countryGerman: 'Finnland',
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    numeric: 246,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'France',
    countryGerman: 'Frankreich',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    numeric: 250,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'French Guiana',
    countryGerman: 'Französisch-Guayana',
    alpha2Code: 'GF',
    alpha3Code: 'GUF',
    numeric: 254,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'French Polynesia',
    countryGerman: 'Französisch-Polynesien',
    alpha2Code: 'PF',
    alpha3Code: 'PYF',
    numeric: 258,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'French Southern Territories',
    countryGerman: 'French Southern Territories',
    alpha2Code: 'TF',
    alpha3Code: 'ATF',
    numeric: 260,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Gabonese Republic',
    countryGerman: 'Gabun',
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    numeric: 266,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Gambia',
    countryGerman: 'Gambia',
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    numeric: 270,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Georgia',
    countryGerman: 'Georgien',
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    numeric: 268,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Germany',
    countryGerman: 'Deutschland',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    numeric: 276,
    shipping: {
      baseFee: 5.90,
      perBundle: 2.00
    }
  },
  {
    country: 'Ghana',
    countryGerman: 'Ghana',
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    numeric: 288,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Gibraltar',
    countryGerman: 'Gibraltar',
    alpha2Code: 'GI',
    alpha3Code: 'GIB',
    numeric: 292
  },
  {
    country: 'Great Britain',
    countryGerman: 'Großbritannien',
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    numeric: 826,
    shipping: {
      baseFee: 31.90,
      perBundle: 6.00
    }
  },
  {
    country: 'Greece',
    countryGerman: 'Griechenland',
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    numeric: 300,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Greenland',
    countryGerman: 'Grönland',
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    numeric: 304,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Grenada',
    countryGerman: 'Grenada',
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    numeric: 308,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Guadeloupe',
    countryGerman: 'Guadeloupe',
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    numeric: 312,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Guam',
    countryGerman: 'Guam',
    alpha2Code: 'GU',
    alpha3Code: 'GUM',
    numeric: 316
  },
  {
    country: 'Guatemala',
    countryGerman: 'Guatemala',
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    numeric: 320,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Guernsey',
    countryGerman: 'Guernsey',
    alpha2Code: 'GG',
    alpha3Code: 'GGY',
    numeric: 831
  },
  {
    country: 'Guinea',
    countryGerman: 'Guinea',
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    numeric: 324,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Guinea-Bissau',
    countryGerman: 'Guinea-Bissau',
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    numeric: 624,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Guyana',
    countryGerman: 'Guyana',
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    numeric: 328,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Haiti',
    countryGerman: 'Haiti',
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    numeric: 332,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Heard and the McDonald Islands',
    countryGerman: 'Heard und McDonaldinseln',
    alpha2Code: 'HM',
    alpha3Code: 'HMD',
    numeric: 334
  },
  {
    country: 'Honduras',
    countryGerman: 'Honduras',
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    numeric: 340,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Hong Kong',
    countryGerman: 'Hongkong',
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    numeric: 344
  },
  {
    country: 'Hungary',
    countryGerman: 'Ungarn',
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    numeric: 348,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Iceland',
    countryGerman: 'Island',
    alpha2Code: 'IS',
    alpha3Code: 'ISL',
    numeric: 352,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'India',
    countryGerman: 'Indien',
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    numeric: 356,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Indonesia',
    countryGerman: 'Indonesien',
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    numeric: 360,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Iran',
    countryGerman: 'Iran',
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    numeric: 364,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Iraq',
    countryGerman: 'Irak',
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    numeric: 368,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Ireland',
    countryGerman: 'Irland',
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    numeric: 372,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Isle of Man',
    countryGerman: 'Insel Man',
    alpha2Code: 'IM',
    alpha3Code: 'IMN',
    numeric: 0
  },
  {
    country: 'Israel',
    countryGerman: 'Israel',
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    numeric: 376,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Italy',
    countryGerman: 'Italien',
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    numeric: 380,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Jamaica',
    countryGerman: 'Jamaika',
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    numeric: 388,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Japan',
    countryGerman: 'Japan',
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    numeric: 392,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Jersey',
    countryGerman: 'Jersey',
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    numeric: 832
  },
  {
    country: 'Jordan',
    countryGerman: 'Jordanien',
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    numeric: 400,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Kazakhstan',
    countryGerman: 'Kasachstan',
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    numeric: 398,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Kenya',
    countryGerman: 'Kenia',
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    numeric: 404,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Kiribati',
    countryGerman: 'Kiribati',
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    numeric: 296,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Korea, North',
    countryGerman: 'Nord-Korea',
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    numeric: 408,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Korea, South',
    countryGerman: 'Süd-Korea',
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    numeric: 410,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Kuwait',
    countryGerman: 'Kuwait',
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    numeric: 414,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Kyrgyzstan',
    countryGerman: 'Kirgisistan',
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    numeric: 417,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Laos',
    countryGerman: 'Laos',
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    numeric: 418,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Latvia',
    countryGerman: 'Lettland',
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    numeric: 428,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Lebanon',
    countryGerman: 'Libanon',
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    numeric: 422,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Lesotho',
    countryGerman: 'Lesotho',
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    numeric: 426,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Liberia',
    countryGerman: 'Liberia',
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    numeric: 430,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Libya',
    countryGerman: 'Libyen',
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    numeric: 434,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Liechtenstein',
    countryGerman: 'Liechtenstein',
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    numeric: 438,
    shipping: {
      baseFee: 31.90,
      perBundle: 6.00
    }
  },
  {
    country: 'Lithuania',
    countryGerman: 'Litauen',
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    numeric: 440,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Luxembourg',
    countryGerman: 'Luxemburg',
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    numeric: 442,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Macau',
    countryGerman: 'Macau',
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    numeric: 446
  },
  {
    country: 'Macedonia',
    countryGerman: 'Mazedonien',
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    numeric: 807,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Madagascar',
    countryGerman: 'Madagaskar',
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    numeric: 450,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Malawi',
    countryGerman: 'Malawi',
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    numeric: 454,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Malaysia',
    countryGerman: 'Malaysia',
    alpha2Code: 'MY',
    alpha3Code: 'MYS',
    numeric: 458,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Maldives',
    countryGerman: 'Malediven',
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    numeric: 462,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Mali',
    countryGerman: 'Mali',
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    numeric: 466,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Malta',
    countryGerman: 'Malta',
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    numeric: 470,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Mariana Islands',
    countryGerman: 'Marianen',
    alpha2Code: 'MP',
    alpha3Code: 'MNP',
    numeric: 580
  },
  {
    country: 'Marshall Islands',
    countryGerman: 'Marshallinseln',
    alpha2Code: 'MH',
    alpha3Code: 'MHL',
    numeric: 584,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Martinique',
    countryGerman: 'Martinique',
    alpha2Code: 'MQ',
    alpha3Code: 'MTQ',
    numeric: 474,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Mauritania',
    countryGerman: 'Mauretanien',
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    numeric: 478,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Mauritius',
    countryGerman: 'Mauritius',
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    numeric: 480,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Mayotte',
    countryGerman: 'Mayotte',
    alpha2Code: 'YT',
    alpha3Code: 'MYT',
    numeric: 175,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Mexico',
    countryGerman: 'Mexiko',
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    numeric: 484,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Micronesia',
    countryGerman: 'Mikronesien',
    alpha2Code: 'FM',
    alpha3Code: 'FSM',
    numeric: 583,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Moldova',
    countryGerman: 'Moldau',
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    numeric: 498,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Monaco',
    countryGerman: 'Monaco',
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    numeric: 492,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Mongolia',
    countryGerman: 'Mongolei',
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    numeric: 496,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Montenegro',
    countryGerman: 'Montenegro',
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    numeric: 499,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Montserrat',
    countryGerman: 'Montserrat',
    alpha2Code: 'MS',
    alpha3Code: 'MSR',
    numeric: 500,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Morocco',
    countryGerman: 'Marokko',
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    numeric: 504,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Mozambique',
    countryGerman: 'Mosambik',
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    numeric: 508,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Myanmar',
    countryGerman: 'Myanmar',
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    numeric: 104,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Namibia',
    countryGerman: 'Namibia',
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    numeric: 516,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Nauru',
    countryGerman: 'Nauru',
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    numeric: 520,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Nepal',
    countryGerman: 'Nepal',
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    numeric: 524,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Netherlands',
    countryGerman: 'Niederlande',
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    numeric: 528,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Netherlands Antilles',
    countryGerman: 'Niederländische Antillen',
    alpha2Code: 'AN',
    alpha3Code: 'ANT',
    numeric: 530,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'New Caledonia',
    countryGerman: 'Neukaledonien',
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    numeric: 540,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'New Zealand',
    countryGerman: 'Neuseeland',
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    numeric: 554,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Nicaragua',
    countryGerman: 'Nicaragua',
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    numeric: 558,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Niger',
    countryGerman: 'Niger',
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    numeric: 562,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Nigeria',
    countryGerman: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    numeric: 566,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Niue',
    countryGerman: 'Niue',
    alpha2Code: 'NU',
    alpha3Code: 'NIU',
    numeric: 570
  },
  {
    country: 'Norfolk Island',
    countryGerman: 'Norfolkinsel',
    alpha2Code: 'NF',
    alpha3Code: 'NFK',
    numeric: 574
  },
  {
    country: 'Northern Ireland',
    countryGerman: 'Nordirland',
    alpha2Code: 'XI',
    alpha3Code: 'GBR',
    numeric: 826
  },
  {
    country: 'Norway',
    countryGerman: 'Norwegen',
    alpha2Code: 'NO',
    alpha3Code: 'NOR',
    numeric: 578,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Oman',
    countryGerman: 'Oman',
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    numeric: 512,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Pakistan',
    countryGerman: 'Pakistan',
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    numeric: 586,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Palau',
    countryGerman: 'Palau',
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    numeric: 585,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Palestinian territories',
    countryGerman: 'Palästina',
    alpha2Code: 'PS',
    alpha3Code: 'PSE',
    numeric: 275
  },
  {
    country: 'Panama',
    countryGerman: 'Panama',
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    numeric: 591,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Papua New Guinea',
    countryGerman: 'Papua-Neuguinea',
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    numeric: 598,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Paraguay',
    countryGerman: 'Paraguay',
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    numeric: 600,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Peru',
    countryGerman: 'Peru',
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    numeric: 604,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Philippines',
    countryGerman: 'Philippinen',
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    numeric: 608,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Pitcairn Islands Group',
    countryGerman: 'Pitcairninseln',
    alpha2Code: 'PN',
    alpha3Code: 'PCN',
    numeric: 612,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Poland',
    countryGerman: 'Polen',
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    numeric: 616,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Portugal',
    countryGerman: 'Portugal',
    alpha2Code: 'PT',
    alpha3Code: 'PRT',
    numeric: 620,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Puerto Rico',
    countryGerman: 'Puerto Rico',
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    numeric: 630
  },
  {
    country: 'Qatar',
    countryGerman: 'Katar',
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    numeric: 634,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Réunion',
    countryGerman: 'Réunion',
    alpha2Code: 'RE',
    alpha3Code: 'REU',
    numeric: 638,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Romania',
    countryGerman: 'Rumänien',
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    numeric: 642,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Russian Federation',
    countryGerman: 'Russland',
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    numeric: 643,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Rwanda',
    countryGerman: 'Ruanda',
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    numeric: 646,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Saint Barthélemy',
    countryGerman: 'Saint Barthélemy',
    alpha2Code: 'BL',
    alpha3Code: 'BLM',
    numeric: 652
  },
  {
    country: 'Saint Kitts and Nevis',
    countryGerman: 'St. Kitts und Nevis',
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    numeric: 659,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Saint Lucia',
    countryGerman: 'St. Lucia',
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    numeric: 662,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Saint Martin (French part)',
    countryGerman: 'Saint Martin (French part)',
    alpha2Code: 'MF',
    alpha3Code: 'MAF',
    numeric: 663
  },
  {
    country: 'Saint Pierre and Miquelon',
    countryGerman: 'St. Pierre und Miquelon',
    alpha2Code: 'PM',
    alpha3Code: 'SPM',
    numeric: 666,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Saint Vincent and the Grenadines',
    countryGerman: 'St. Vincent und die Grenadinen',
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    numeric: 670,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'San Marino',
    countryGerman: 'San Marino',
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    numeric: 674,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Sao Tome and Principe',
    countryGerman: 'Sao Tomé und Principe',
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    numeric: 678,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Saudi Arabia',
    countryGerman: 'Saudi-Arabien',
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    numeric: 682,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Senegal',
    countryGerman: 'Senegal',
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    numeric: 686,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Serbia',
    countryGerman: 'Serbien',
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    numeric: 688,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'Seychelles',
    countryGerman: 'Seychellen',
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    numeric: 690,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00,
      shipping: {
        baseFee: 55.90,
        perBundle: 25.00
      }
    }
  },
  {
    country: 'Sierra Leone',
    countryGerman: 'Sierra Leone',
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    numeric: 694
  },
  {
    country: 'Singapore',
    countryGerman: 'Singapur',
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    numeric: 702,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Sint Maarten (Dutch part)',
    countryGerman: 'Sint Maarten (Dutch part)',
    alpha2Code: 'SX',
    alpha3Code: 'SXM',
    numeric: 534
  },
  {
    country: 'Slovakia',
    countryGerman: 'Slowakei',
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    numeric: 703,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Slovenia',
    countryGerman: 'Slowenien',
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    numeric: 705,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Solomon Islands',
    countryGerman: 'Salomonen',
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    numeric: 90,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Somalia',
    countryGerman: 'Somalia',
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    numeric: 706,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'South Africa',
    countryGerman: 'SüdAfrika',
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    numeric: 710,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    countryGerman: 'Südgeorgien und die Südlichen Sandwichinseln',
    alpha2Code: 'GS',
    alpha3Code: 'SGS',
    numeric: 239
  },
  {
    country: 'South Sudan',
    countryGerman: 'South Sudan',
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    numeric: 728,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Spain',
    countryGerman: 'Spanien',
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    numeric: 724,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Sri Lanka',
    countryGerman: 'Sri Lanka',
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    numeric: 144,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'St. Helena',
    countryGerman: 'St. Helena',
    alpha2Code: 'SH',
    alpha3Code: 'SHN',
    numeric: 654,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Sudan',
    countryGerman: 'Sudan',
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    numeric: 729,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Suriname',
    countryGerman: 'Suriname',
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    numeric: 740,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Svalbard and Jan Mayen Islands',
    countryGerman: 'Svalbard und Jan Mayen',
    alpha2Code: 'SJ',
    alpha3Code: 'SJM',
    numeric: 744
  },
  {
    country: 'Swaziland',
    countryGerman: 'Swasiland',
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    numeric: 748,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Sweden',
    countryGerman: 'Schweden',
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    numeric: 752,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Switzerland',
    countryGerman: 'Schweiz',
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    numeric: 756,
    shipping: {
      baseFee: 31.90,
      perBundle: 6.00
    }
  },
  {
    country: 'Syria',
    countryGerman: 'Syrien',
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    numeric: 760,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Taiwan',
    countryGerman: 'Taiwan',
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    numeric: 158,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Tajikistan',
    countryGerman: 'Tadschikistan',
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    numeric: 762,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Tanzania',
    countryGerman: 'Tansania',
    alpha2Code: 'TZ',
    alpha3Code: 'TZA',
    numeric: 834,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Thailand',
    countryGerman: 'Thailand',
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    numeric: 764,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Timor-Leste',
    countryGerman: 'Timor-Leste',
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    numeric: 626,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Togo',
    countryGerman: 'Togo',
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    numeric: 768,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Tokelau',
    countryGerman: 'Tokelau',
    alpha2Code: 'TK',
    alpha3Code: 'TKL',
    numeric: 772
  },
  {
    country: 'Tonga',
    countryGerman: 'Tonga',
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    numeric: 776,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Trinidad and Tobago',
    countryGerman: 'Trinidad und Tobago',
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    numeric: 780,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Tunisia',
    countryGerman: 'Tunesien',
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    numeric: 788,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Turkey',
    countryGerman: 'Türkei',
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    numeric: 792,
    shipping: {
      baseFee: 38.90,
      perBundle: 10.00
    }
  },
  {
    country: 'Turkmenistan',
    countryGerman: 'Turkmenistan',
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    numeric: 795,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Turks and Caicos Islands',
    countryGerman: 'Turks- und Caicosinseln',
    alpha2Code: 'TC',
    alpha3Code: 'TCA',
    numeric: 796,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Tuvalu',
    countryGerman: 'Tuvalu',
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    numeric: 798,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Uganda',
    countryGerman: 'Uganda',
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    numeric: 800,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Ukraine',
    countryGerman: 'Ukraine',
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    numeric: 804,
    shipping: {
      baseFee: 29.90,
      perBundle: 8.00
    }
  },
  {
    country: 'United Arab Emirates',
    countryGerman: 'Vereinigte Arabische Emirate',
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    numeric: 784,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'United States',
    countryGerman: 'Vereinigte Staaten',
    alpha2Code: 'US',
    alpha3Code: 'USA',
    numeric: 840,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'United States Minor Outlying Islands',
    countryGerman: 'United States Minor Outlying Islands',
    alpha2Code: 'UM',
    alpha3Code: 'UMI',
    numeric: 581
  },
  {
    country: 'Uruguay',
    countryGerman: 'Uruguay',
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    numeric: 858,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Uzbekistan',
    countryGerman: 'Usbekistan',
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    numeric: 860,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Vanuatu',
    countryGerman: 'Vanuatu',
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    numeric: 548,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Vatican City State',
    countryGerman: 'Vatikanstadt',
    alpha2Code: 'VA',
    alpha3Code: 'VAT',
    numeric: 336,
    shipping: {
      baseFee: 16.90,
      perBundle: 5.00
    }
  },
  {
    country: 'Venezuela',
    countryGerman: 'Venezuela',
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    numeric: 862
  },
  {
    country: 'Viet Nam',
    countryGerman: 'Vietnam',
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    numeric: 704,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Virgin Islands of the United States',
    countryGerman: 'die Amerikanischen Jungferninseln',
    alpha2Code: 'VI',
    alpha3Code: 'VIR',
    numeric: 850
  },
  {
    country: 'Wallis and Futuna',
    countryGerman: 'Wallis und Futuna',
    alpha2Code: 'WF',
    alpha3Code: 'WLF',
    numeric: 876,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Western Sahara',
    countryGerman: 'Westsahara',
    alpha2Code: 'EH',
    alpha3Code: 'ESH',
    numeric: 732
  },
  {
    country: 'Western Samoa',
    countryGerman: 'Samoa',
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    numeric: 882,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Yemen',
    countryGerman: 'Jemen',
    alpha2Code: 'YE',
    alpha3Code: 'YEM',
    numeric: 887,
    shipping: {
      baseFee: 44.90,
      perBundle: 20.00
    }
  },
  {
    country: 'Zambia',
    countryGerman: 'Sambia',
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    numeric: 894,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  },
  {
    country: 'Zimbabwe',
    countryGerman: 'Simbabwe',
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    numeric: 716,
    shipping: {
      baseFee: 55.90,
      perBundle: 25.00
    }
  }
]
