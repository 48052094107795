import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AccessPermissionService from '../../../services/api/AccessPermissionService'
import { AccessPermission, ApiAccessPermissionState } from '../../../types'
import { ACCESS_PERMISSION_CREATION_SUCCESS_MESSAGE, ACCESS_PERMISSION_DELETION_MESSAGE, ACCESS_PERMISSION_UPDATE_SUCCESS_MESSAGE } from '../../../constants/messages'

const initialState: ApiAccessPermissionState = {
  isLoading: false,
  error: null,
  accessPermission: null,
  accessPermissions: [],
  defaultAccessPermissions: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getAllAccessPermissions = createAsyncThunk('api/access-permission/get', async ({ token, perPage, page, filter, signal }: { token: string, perPage: number, page: number, filter: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await AccessPermissionService.getAllAccessPermissions(token, perPage, page, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getDefaultAccessPermissions = createAsyncThunk('api/access-permission/default/get', async ({ token, signal }: { token: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await AccessPermissionService.getDefaultAccessPermissions(token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addAccessPermission = createAsyncThunk('api/access-permission/add', async ({ token, accessPermission, signal }: { token: string, accessPermission: Partial<AccessPermission>, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await AccessPermissionService.addAccessPermission(token, accessPermission, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editAccessPermissionById = createAsyncThunk('api/access-permission/edit', async ({ token, id, accessPermission, signal }: { token: string, id: string, accessPermission: Partial<AccessPermission>, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await AccessPermissionService.editAccessPermissionById(token, id, accessPermission, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteAccessPermissionById = createAsyncThunk('api/access-permission/delete', async ({ token, accessPermissionId, signal }: { token: string, accessPermissionId: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await AccessPermissionService.deleteAccessPermissionById(token, accessPermissionId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const accessPermissionSlice = createSlice({
  name: 'api/access-permission',
  initialState,
  reducers: {
    resetAccessPermissionError: (state) => {
      state.error = null
    },
    resetAccessPermissionMessage: (state) => {
      state.message = null
    },
    resetAccessPermissionData: (state) => {
      state.error = null
      state.accessPermission = null
      state.accessPermissions = []
      state.defaultAccessPermissions = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccessPermissions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllAccessPermissions.fulfilled, (state, action) => {
        state.isLoading = false
        state.accessPermissions = action.payload.accessPermissions
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllAccessPermissions.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getDefaultAccessPermissions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDefaultAccessPermissions.fulfilled, (state, action) => {
        state.isLoading = false
        state.defaultAccessPermissions = action.payload.accessPermissions
        state.error = null
        state.message = null
      })
      .addCase(getDefaultAccessPermissions.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addAccessPermission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addAccessPermission.fulfilled, (state, action) => {
        state.isLoading = false
        state.accessPermission = action.payload.accessPermission
        state.error = null
        state.message = ACCESS_PERMISSION_CREATION_SUCCESS_MESSAGE
      })
      .addCase(addAccessPermission.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editAccessPermissionById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editAccessPermissionById.fulfilled, (state, action) => {
        state.isLoading = false
        state.accessPermission = action.payload.accessPermission
        state.error = null
        state.message = ACCESS_PERMISSION_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(editAccessPermissionById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteAccessPermissionById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAccessPermissionById.fulfilled, (state, action) => {
        state.isLoading = false
        state.accessPermission = null
        state.error = null
        state.message = ACCESS_PERMISSION_DELETION_MESSAGE
      })
      .addCase(deleteAccessPermissionById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetAccessPermissionError, resetAccessPermissionMessage, resetAccessPermissionData } = accessPermissionSlice.actions

const { reducer } = accessPermissionSlice
export default reducer
