import axios from 'axios'
import { Nullable } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  deletePictureById (token: string, pictureId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/pictures/${pictureId}`, config)
  },
  getAllCards (token: string, perPage: number, pageToken: Nullable<string>, filter: string, signal: AbortSignal) {
    const config = {
      signal,
      params: {
        limit: perPage,
        pageToken
      }
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/pictures/cards?${filter}`, config)
  }
}
