import CompanyService from '../services/api/CompanyService'
import { CostCenter } from '../types'

export const mapCostCenters = (costCenters: Array<CostCenter>) => {
  return costCenters.map(costCenter => ({
    value: costCenter.center,
    label: costCenter.center
  }))
}

export const loadCostCenterOptions = async (id: string, page: number, perPage: number, token: string, inputValue: string, isAllowed: boolean) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token && isAllowed) {
    try {
      const res = await CompanyService.getCompanyCostCenters(id, token, perPage, page, inputValue, signal)
      return mapCostCenters(res.data.costCenters)
    } catch (error) {
      return mapCostCenters([])
    }
  } else {
    return mapCostCenters([])
  }
}
