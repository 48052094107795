import axios from 'axios'
import { DuplicatePostedOrder } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  duplicateOrders (token: string, postedOrders: DuplicatePostedOrder[], signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/pending-orders/duplicate', { postedOrders }, config)
  },
  createCatalogueOrders (token: string, pendingOrders: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/pending-orders', { pendingOrders }, config)
  }
}
