import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import OrderService from '../../../../services/api/dotnet/OrderService'
import { DotnetApiOrdersState } from '../../../../types'

const initialState: DotnetApiOrdersState = {
  isLoading: false,
  error: null,
  order: null,
  message: null
}

export const createOrders = createAsyncThunk('dotnet/orders/create', async ({ token, order, signal }: { token: string, order: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await OrderService.createOrder(token, order, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

const orderSlice = createSlice({
  name: 'dotnet/orders',
  initialState,
  reducers: {
    resetOrdersError: (state) => {
      state.error = null
    },
    resetOrdersMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrders.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(createOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = action.payload.status.message
      })
      .addCase(createOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetOrdersError, resetOrdersMessage } = orderSlice.actions

const { reducer } = orderSlice
export default reducer
