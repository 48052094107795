import React from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { countries } from '../../utils/countries'
import { Address } from '../../types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { updateUserAddress } from '../../store/reducers/api/usersReducer'
import { addAddressToEmployee } from '../../store/reducers/api/companyReducer'
import { phoneValidationPattern } from '../../constants/regexPatterns'

const AddressEditor = ({
  address,
  userId,
  companyId
}: {
  address: Address | null | undefined;
  userId: string;
  companyId?: string;
}) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiUsers.isLoading)
  const error = useAppSelector((state) => state.profile.error)

  const dispatch = useAppDispatch()

  const token = currentUser?.token
  const types = [{ type: 'billing', label: 'Billing' }, { type: 'delivery', label: 'Delivery' }, { type: 'billingAndDelivery', label: 'Billing and Delivery' }]

  const addressSchema = object({
    country: string().required('Country is required').oneOf(countries),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street and House Number are required')
      .max(64, 'Street and House Number are too long'),
    zip: string()
      .required('Zip is required')
      .max(16, 'Zip is too long'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    addressAddition: string()
      .nullable()
      .max(255, 'Address Addition is too long'),
    type: string().oneOf(types.map(type => type.type)).nullable()
  })
  return (
    <div>
      <Formik
        validationSchema={addressSchema}
        enableReinitialize
        initialValues={{
          country: address?.country || '',
          city: address?.city || '',
          street: address?.street || '',
          zip: address?.zip || '',
          phone: address?.phone || '',
          addressAddition: address?.addressAddition || '',
          type: address?.type || ''
        }}
        onSubmit={(
          { country, city, street, zip, phone, addressAddition, type },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const address = {
            country,
            city,
            street,
            zip,
            phone,
            addressAddition,
            type: type || null,
            affiliation: companyId ? 'company' : 'personal'
          }
          if (token && userId) {
            if (companyId) {
              dispatch(
                addAddressToEmployee({
                  companyId,
                  userId,
                  token,
                  address,
                  signal
                })
              )
            } else {
              dispatch(
                updateUserAddress({
                  id: userId,
                  token,
                  address,
                  signal
                })
              )
            }
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <h6 className="mb-4 text-uppercase">
              <i className="bi bi-pencil-square me-1"></i> Update Address
              Information
            </h6>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileStreet" className="form-label">
                    Street and House Number
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street}
                    type="text"
                    className={`form-control ${
                      (errors.street && touched.street) || error?.errors?.street
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileStreet"
                    name="street"
                    placeholder="Enter street and House Number"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileStreetFeedback"
                    className="invalid-feedback"
                  >
                    {errors.street || error?.errors?.street}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileZip" className="form-label">
                    Zip
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                    type="text"
                    className={`form-control ${
                      (errors.zip && touched.zip) || error?.errors?.zip
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileZip"
                    name="zip"
                    placeholder="Enter zip"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileZipFeedback"
                    className="invalid-feedback"
                  >
                    {errors.zip || error?.errors?.zip}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileCity" className="form-label">
                    City
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className={`form-control ${
                      (errors.city && touched.city) || error?.errors?.city
                        ? 'is-invalid'
                        : ''
                    }`}
                    value={values.city}
                    id="profileCity"
                    name="city"
                    placeholder="Enter city"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileCityFeedback"
                    className="invalid-feedback"
                  >
                    {errors.city || error?.errors?.city}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileAddressAddition" className="form-label">
                    Address Addition
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.addressAddition}
                    type="text"
                    className={`form-control ${
                      (errors.addressAddition &&
                      touched.addressAddition) || error?.errors?.addressAddition
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileAddressAddition"
                    name="addressAddition"
                    placeholder="Enter Address Addition"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfileAddressAdditionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.addressAddition || error?.errors?.addressAddition}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profilePhone" className="form-label">
                    Phone
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    type="text"
                    className={`form-control ${
                      (errors.phone && touched.phone) || error?.errors?.phone
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profilePhone"
                    name="phone"
                    placeholder="Enter Phone Number"
                    autoComplete="on"
                  />
                  <div
                    id="validationProfilePhoneFeedback"
                    className="invalid-feedback"
                  >
                    {errors.phone || error?.errors?.phone}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileCountry" className="form-label">
                    Country
                  </label>
                  <select
                    aria-label="Country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    className={`form-select ${
                      (errors.country && touched.country) || error?.errors?.country
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileCountry"
                    name="country"
                    autoComplete="off"
                  >
                    <option value="">Select Country</option>
                    {countries.map((country: string, index: number) => (
                      <option key={index}>{country}</option>
                    ))}
                  </select>
                  <div
                    id="validationProfileCountryFeedback"
                    className="invalid-feedback"
                  >
                    {errors.country || error?.errors?.country}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="profileType" className="form-label">
                    Type
                  </label>
                  <select
                    aria-label="Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                    className={`form-select ${
                      (errors.type && touched.type)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="profileType"
                    name="type"
                    autoComplete="off"
                  >
                    <option value="">Select Type</option>
                    {types.map((type, index: number) => (
                      <option key={index} value={type.type}>{type.label}</option>
                    ))}
                  </select>
                  <div
                    id="validationProfileCountryFeedback"
                    className="invalid-feedback"
                  >
                    {errors.type}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={isLoading || isSubmitting}
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default AddressEditor
