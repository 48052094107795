import React from 'react'
import { array, object, string } from 'yup'
import { Formik } from 'formik'
import Select from 'react-select'
import { countriesObject as countries } from '../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { CountryObject } from '../../types'

const ShippingDestinationEditor = ({ id, initialShippingDestinations, save }: { id: string, initialShippingDestinations: string[], save: Function }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCampaign.isLoadingShippingDestinations)

  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const recipientSchema = object({
    countries: array()
      .of(string().oneOf(countries.map(country => country.country)))
      .required('Countries are required')
      .min(1, 'Select at least one country')
  })

  const orderOptions = (values: readonly CountryObject[]) => {
    return values
      .filter((value) => initialShippingDestinations.includes(value.country))
      .concat(values.filter((value) => !initialShippingDestinations.includes(value.country)))
  }

  const saveShippingDestination = (campaignId: string, campaignShippingDestinations: string[], signal: AbortSignal) => {
    dispatch(save({ id: campaignId, token, campaignShippingDestinations, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={recipientSchema}
        enableReinitialize
        initialValues={{
          countries: initialShippingDestinations
        }}
        onSubmit={(
          { countries },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const campaignShippingDestinations = countries
          if (token && id && campaignShippingDestinations) {
            saveShippingDestination(id, campaignShippingDestinations, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="countries" className="form-label">
                    Countries
                  </label>
                  <Select
                    className={`${
                      touched.countries &&
                      errors.countries
                        ? 'is-invalid'
                        : ''
                    }`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: (errors.countries && touched.countries) ? '#dc3545' : provided.borderColor,
                        '&:hover': {
                          boxShadow: (errors.countries && touched.countries) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                          borderColor: (errors.countries && touched.countries) ? '#dc3545' : '#86b7fe'
                        }
                      }),
                      multiValue: (base, state) => {
                        return initialShippingDestinations.includes(state.data.country) ? { ...base, backgroundColor: 'gray' } : base
                      },
                      multiValueLabel: (base, state) => {
                        return initialShippingDestinations.includes(state.data.country)
                          ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                          : base
                      },
                      multiValueRemove: (base, state) => {
                        return initialShippingDestinations.includes(state.data.country) ? { ...base, display: 'none' } : base
                      }
                    }}
                    isClearable
                    inputId="countries"
                    name="countries"
                    aria-label="Countries"
                    options={orderOptions(countries)}
                    getOptionLabel={(country) => `${country.country}`}
                    getOptionValue={(country) => String(country.country)}
                    onChange={(selectedOption, actionMeta) => {
                      switch (actionMeta.action) {
                        case 'remove-value':
                        case 'pop-value':
                          if (initialShippingDestinations.includes(actionMeta.removedValue.country)) {
                            return
                          }
                          break
                        case 'clear':
                          selectedOption = countries.filter((v) => initialShippingDestinations.includes(v.country))
                          break
                      }
                      const selectedCountries = selectedOption ? selectedOption.map(option => option.country) : []
                      setFieldValue('countries', selectedCountries)
                    }}
                    onBlur={handleBlur}
                    value={orderOptions(countries.filter((country) => values.countries?.includes(country.country)))}
                    isMulti
                  />
                  <div
                    id="validationOrderCountriesFeedback"
                    className="invalid-feedback"
                  >
                    {errors.countries}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ShippingDestinationEditor
