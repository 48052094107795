import React, { useEffect, useState } from 'react'
import Progress from '../../loaders/Progress'
import { Campaign, CampaignAddressMode, CampaignOnboardingMode } from '../../../types'
import { useAppSelector } from '../../../store/hooks'
import CampaignAddressEditor from './steps/CampaignAddressEditor'

interface CampaignLandingModalProps {
  campaign: Partial<Campaign> | null
  setShowDialog: Function
  setCancelPressed: Function
}

const CampaignLandingModal = ({ campaign, setShowDialog, setCancelPressed }: CampaignLandingModalProps) => {
  const isLoading = useAppSelector((state) => state.apiCampaign.isLoading)
  const isDeleting = useAppSelector((state) => state.apiCampaignAddress.isLoading)
  const [mode, setMode] = useState<CampaignOnboardingMode>('campaignAddress')
  const [addressMode, setAddressMode] = useState<CampaignAddressMode>('billingAddress')
  const [title, setTitle] = useState('Billing Address')
  const [onboardingProgress, setOnboardingProgress] = useState(100)

  const hasBillingAddress = campaign?.campaignAddresses && campaign.campaignAddresses.find(address => address.type === 'billing')
  const hasReturnAddress = campaign?.campaignAddresses && campaign.campaignAddresses.find(address => address.type === 'return')
  const isFinalised = campaign?.status === 'submitted'

  const calculateProgress = () => {
    const totalProgress = {
      addCampaign: 25,
      billingAddressValue: campaign?.campaignAddresses && campaign.campaignAddresses.find(address => address.type === 'billing') ? 25 : 0,
      returnAddressValue: campaign?.campaignAddresses && campaign.campaignAddresses.find(address => address.type === 'return') ? 25 : 0,
      campaignSubmissionValue: (campaign?.status === 'submitted') ? 25 : 0
    }

    const totalProgressPercentage = Object.values(totalProgress).reduce((acc, curr) => acc + curr, 0)

    return {
      totalProgressPercentage
    }
  }

  useEffect(() => {
    setOnboardingProgress(calculateProgress().totalProgressPercentage)
  }, [campaign])

  useEffect(() => {
    const campaignLandingModalElement = document.getElementById('campaignLandingModal')
    if (campaignLandingModalElement) {
      campaignLandingModalElement.addEventListener('hidden.bs.modal', () => {
        setShowDialog(false)
      })
    }
  }, [])

  return (
    <div className="modal fade" id="campaignLandingModal" tabIndex={-1} aria-labelledby="campaignLandingModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div>
              <div className="row g-0">
                <div className="col-md-4 bg-secondary-subtle px-2">
                  <div className="modal-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <div className="progress my-4" role="progressbar" aria-label="Onboarding Progress" aria-valuenow={onboardingProgress} aria-valuemin={0} aria-valuemax={100}>
                          <div className={`progress-bar w-${onboardingProgress}`}></div>
                        </div>
                      </div>
                      <div className="ms-2">{`${onboardingProgress}%`}</div>
                    </div>
                    <ol className="list-group list-group-numbered">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          Add Campaign
                        </div>
                        <i className="bi bi-check2-circle"></i>
                      </li>
                      <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'campaignAddress' && addressMode === 'billingAddress' && 'active'}`}
                        onClick={() => {
                          setMode('campaignAddress')
                          setAddressMode('billingAddress')
                          setTitle('Billing Address')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          Add Billing Address
                        </div>
                        {hasBillingAddress ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-hourglass"></i>}
                      </button>
                      <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'campaignAddress' && addressMode === 'returnAddress' && 'active'}`}
                        onClick={() => {
                          setMode('campaignAddress')
                          setAddressMode('returnAddress')
                          setTitle('Return Address')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          Add Return Address
                        </div>
                        {(hasReturnAddress) ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-hourglass"></i>}
                      </button>

                      <button
                        className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${mode === 'campaignSubmission' && 'active'}`}
                        onClick={() => {
                          setMode('campaignSubmission')
                          setTitle('Campaign Finalisation')
                        }}
                      >
                        <div className="ms-2 me-auto">
                          Wait for Campaign Finalisation
                        </div>
                        {(isFinalised) ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-hourglass"></i>}
                      </button>
                    </ol>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="modal-header border-0">
                    <h1 className="modal-title fs-5" id="campaignLandingModalLabel">{`Campaign Setup - ${title}`}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setShowDialog(false)} aria-label="Close"></button>
                  </div>
                  {isLoading || isDeleting ? <Progress marginBottom={false} /> : <hr className="border border-primary border-1 opacity-50 my-0"></hr>}
                  <CampaignAddressEditor
                    setAddressMode={setAddressMode}
                    addressMode={addressMode}
                    setMode={setMode}
                    mode={mode}
                    setTitle={setTitle}
                    addressTitle={title}
                    campaign={campaign}
                    campaignAddresses={campaign?.campaignAddresses || []}
                    setCancelPressed={setCancelPressed}
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default CampaignLandingModal
