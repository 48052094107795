import React, { useState, Dispatch, SetStateAction } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ShoppingCartArticles from './ShoppingCartArticles'
import { ShoppingCartAddress } from '../../types'
import { TrashIcon } from '../icons/TrashIcon'
import { setShoppingAddresses } from '../../store/reducers/shoppingCartReducer'
import Calendar from 'react-datepicker'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'
import { isWeekday } from '../../utils/isWeekday'
interface ShoppingCartManagerProps {
  assignedItemIds: string[]
  handleDragStart: (e: React.DragEvent<HTMLDivElement>, product: any) => void
  isOneAddress: boolean
  selectedShoppingCartAddress: ShoppingCartAddress
  setSelectedShoppingCartAddress: Dispatch<SetStateAction<ShoppingCartAddress>>
}
const ShoppingCartManager: React.FC<ShoppingCartManagerProps> = ({
  assignedItemIds,
  handleDragStart,
  isOneAddress,
  setSelectedShoppingCartAddress,
  selectedShoppingCartAddress
}) => {
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.products)

  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const [modalAddress, setModalAddress] = useState<ShoppingCartAddress | null>(null)
  const [shippingDate, setShippingDate] = useState<Date | null>(null)

  const [updatedAddressesInCartStore, setRupdatedAddressesInCartStore] = useState<ShoppingCartAddress[] | undefined>(undefined)
  const [isDateFilled, setIsDateFilled] = useState<boolean>(true)

  const foundProduct = (productId: string) => {
    const productDetail = shoppingCartLeftOverProducts.find((product) => (product.spliteId) ? product.spliteId === productId : product.id === productId)
    return productDetail || { name: 'Product not found', quantity: 0 }
  }

  const handleDrop = (e: {
    preventDefault: () => void
    dataTransfer: { getData: (arg0: string) => any }
  }) => {
    e.preventDefault()
    const productId = e.dataTransfer.getData('productId')

    if (selectedShoppingCartAddress && productId) {
      const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(cartAddress => cartAddress.id === selectedShoppingCartAddress.id)
      if (foundShoppingAddressIndex !== -1) {
        const updatedAddressesInCart = [...shoppingCartAddresses]
        const updatedSelectedAssignmentAddress = updatedAddressesInCart[foundShoppingAddressIndex]
        const assignedProductIds = updatedSelectedAssignmentAddress.assignedLeftOverProductIds

        if (!assignedProductIds.includes(productId)) {
          const updatedAssignedProductIds = [...assignedProductIds, productId]
          updatedAddressesInCart[foundShoppingAddressIndex] = {
            ...updatedSelectedAssignmentAddress,
            assignedLeftOverProductIds: updatedAssignedProductIds
          }
          setRupdatedAddressesInCartStore(updatedAddressesInCart)
          if (!updatedSelectedAssignmentAddress.shippingDate) {
            setModalAddress(updatedSelectedAssignmentAddress)
            setShowModal(true)
          } else {
            const payload = {
              addresses: updatedAddressesInCart
            }

            dispatch(setShoppingAddresses(payload))
            setSelectedShoppingCartAddress(updatedAddressesInCart[foundShoppingAddressIndex])
          }
        }
      }
    }
  }

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault()
  }
  const handleSaveShippingDate = () => {
    if (modalAddress && shippingDate && updatedAddressesInCartStore) {
      const updatedAddresses = updatedAddressesInCartStore.map((address) =>
        address.id === modalAddress.id
          ? { ...address, shippingDate: shippingDate.toISOString() }
          : address
      )
      dispatch(setShoppingAddresses({ addresses: updatedAddresses }))
      setSelectedShoppingCartAddress(updatedAddresses.find(address => address.id === modalAddress.id)!)
      setShowModal(false)
      setShippingDate(null)
    }
  }

  return (
    <div className="overflow-scroll">
      <div className='d-flex justify-content-between my-3 w-100'>
        <div className='col-md-5 my-2 mx-2 d-flex flex-column justify-content-between'>
          <div className='flex-grow-1'></div>
          <ShoppingCartArticles onDragStart={handleDragStart} assignedItemIds={assignedItemIds} />
        </div>
        <i className='bi bi-arrow-right fs-1 my-auto text-muted p-3'></i>
        <div className='col-md-6'>
          <div className='title col'>
            <div className='nav nav-pills'>
              <div
                style={{
                  width: '50rem',
                  whiteSpace: 'nowrap',
                  scrollbarWidth: 'thin',
                  msOverflowStyle: 'auto',
                  overflowY: 'hidden',
                  position: 'relative'
                }}
                className='w-100 d-flex hide-scrollbar'
              >
                {(!isOneAddress) && (shoppingCartAddresses.length > 0) && (
                  shoppingCartAddresses.map((shoppingCartAddress, index) => (
                    <a
                      key={index}
                      role='button'
                      style={{ minWidth: '33.33%' }}
                      className={`nav-link ${shoppingCartAddress.id === selectedShoppingCartAddress.id ? 'active text-white' : ''} border`}
                      onClick={() => setSelectedShoppingCartAddress(shoppingCartAddress)}
                    >
                      <div className='w-100'>
                        <p
                          style={{ fontSize: '0.875rem' }}
                          className={`fw-normal mb-1 ${shoppingCartAddress.id === selectedShoppingCartAddress.id ? 'text-white' : 'text-muted'}`}
                        >
                          {shoppingCartAddress.street}
                        </p>
                        <h6
                          style={{ fontSize: '0.875rem' }}
                          className={`fw-bold ${shoppingCartAddress.id === selectedShoppingCartAddress.id ? 'text-white' : 'text-black'}`}
                        >
                          {`${shoppingCartAddress.country}, ${shoppingCartAddress.city}`}
                        </h6>
                      </div>
                    </a>
                  ))
                )}
              </div>
            </div>
            <div
              className='table-responsive border rounded bg-light'
              style={{ height: '30rem', position: 'relative' }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div
                className='d-flex flex-column align-items-center justify-content-center h-100 bg-light'
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                  padding: '1rem',
                  overflow: 'auto'
                }}
              >
                <i className='bi bi-inbox text-primary' style={{ fontSize: '2rem' }}></i>
                <p className='text-center'>Drag Products to this area to assign them a shipping</p>
              </div>
              <div style={{ zIndex: 10, position: 'absolute', top: 0, width: '100%' }}>
                <table className='table align-middle mb-0 bg-dark'>
                  <tbody>
                    {shoppingCartAddresses.length > 0 && selectedShoppingCartAddress?.assignedLeftOverProductIds?.length > 0 && (
                      selectedShoppingCartAddress.assignedLeftOverProductIds.map(assignedLeftOverProductId => (
                        <tr key={`shopping-details-assigned-product-${assignedLeftOverProductId}`}>
                          <td>{foundProduct(assignedLeftOverProductId)?.name}</td>
                          <td className='text-end'>
                            <div className='d-flex flex-row float-end'>
                              <button
                                type='button'
                                title='Remove Assigned Article'
                                className='btn btn-outline-danger btn-round'
                                onClick={() => {
                                  const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(cartAddress => cartAddress.id === selectedShoppingCartAddress.id)
                                  if (foundShoppingAddressIndex !== -1) {
                                    const updatedAddressesInCart = [...shoppingCartAddresses]
                                    const updatedSelectedAssignmentAddress = updatedAddressesInCart[foundShoppingAddressIndex]
                                    const assignedLeftOverProductIds = updatedSelectedAssignmentAddress.assignedLeftOverProductIds

                                    const updatedAssignedLeftOverProductIds = assignedLeftOverProductIds.filter(leftOverProductId => leftOverProductId !== assignedLeftOverProductId)
                                    updatedAddressesInCart[foundShoppingAddressIndex] = {
                                      ...updatedAddressesInCart[foundShoppingAddressIndex],
                                      assignedLeftOverProductIds: updatedAssignedLeftOverProductIds
                                    }

                                    dispatch(setShoppingAddresses({ addresses: updatedAddressesInCart }))
                                    setSelectedShoppingCartAddress(updatedAddressesInCart[foundShoppingAddressIndex])
                                  }
                                }}
                              >
                                <TrashIcon />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <>
            <div className='modal-backdrop fade show' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
            <div className='modal fade show d-flex align-items-center justify-content-center' id='shoppingCartModal' tabIndex={-1} aria-labelledby='shoppingCartModalLabel' aria-hidden='true' style={{ display: 'block' }}>
              <div className='modal-dialog' style={{ width: '500px', height: '200px' }}>
              <div className='modal-content'>
                <div className='modal-header'>
                <h1 className='modal-title fs-5' id='shoppingCartModalLabel'><i className='bi bi-plus-circle me-1'></i>Select a shipping date</h1>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => {
                    setShippingDate(null)
                    setShowModal(false)
                  }}
                  aria-label='Close'
                ></button>
                </div>
                <div className='modal-body'>
                <div className='w-100'>
                  <div className='col-md-6'>
                  <div className='mb-3'>
                    <label htmlFor='shippingDate' className='form-label text-muted'>
                    Shipping Date
                    </label>
                    <div>
                    <Calendar
                      id='shippingDate'
                      name='shippingDate'
                      onChange={(date: Date) => setShippingDate(date)}
                      selected={shippingDate}
                      className={`form-control ${!isDateFilled && 'border-danger'}`}
                      minDate={new Date()}
                      dateFormat={'dd/MM/yyyy'}
                      autoComplete={'off'}
                      calendarStartDay={DaysOfWeek.Monday}
                      placeholderText='Select Date'
                      filterDate={(date: Date) => isWeekday(date)}
                    />
                    </div>
                  </div>
                  </div>
                </div>
                </div>
                <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => {
                    if (shippingDate) {
                      handleSaveShippingDate()
                    } else {
                      setIsDateFilled((prev) => { return !prev })
                    }
                  }}
                >
                  <i className='bi bi-save me-1'></i>Save
                </button>
                </div>
              </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ShoppingCartManager
