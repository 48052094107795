import React from 'react'

type AlertProps = {
  message: string
  type: 'danger' | 'success' | 'primary' | 'secondary' | 'warning' | 'info' | 'light' | 'dark'
}

const Alert = ({ message, type }: AlertProps) => {
  return (
    <div className={`alert alert-${type}`} role="alert">
      {message}
    </div>
  )
}

export default Alert
