import dayjs from 'dayjs'
import React, { ChangeEvent, useEffect, useState } from 'react'
import Progress from '../../components/loaders/Progress'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getCompanyAddresses } from '../../store/reducers/api/companyReducer'
import { Address } from '../../types'
import * as userRoles from '../../constants/userRoles'
import { setToast } from '../../store/reducers/toastReducer'
import CompanyAddressEditor from '../../components/editors/CompanyAddressEditor'
import { errorStatusCodes } from '../../constants/statusCodes'
import { getMyProfile } from '../../store/reducers/api/profileReducer'
import Pagination from '../../components/Pagination'

const AllMyCampaigns = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const addresses = useAppSelector((state) => state.apiCompany.addresses)
  const address = useAppSelector((state) => state.apiCompany.address)
  const metadata = useAppSelector((state) => state.apiCompany.addressesMetadata)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const error = useAppSelector((state) => state.apiCompany.error)

  const isLoadingCampaign = useAppSelector((state) => state.apiCampaign.isLoading)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)

  const [editMode, setEditMode] = useState(false)
  const [initialAddress, setInitialAddress] = useState<Address>({
    id: '',
    phone: '',
    country: '',
    city: '',
    street: '',
    zip: '',
    vat: '',
    addressAddition: '',
    type: null
  })

  const token = currentUser?.token
  const userId = currentUser?.id
  const role = currentUser?.role || userRoles.USER
  const companyId = profile?.company?.id
  const companyOwnerId = profile?.company?.owner?.id
  const allowedCampaignRoles = [userRoles.COMPANYADMINISTRATOR, userRoles.CAMPAIGNMANAGER]
  const isOwner = userId === companyOwnerId

  const allowedRoles = [userRoles.COMPANYADMINISTRATOR]

  const dispatch = useAppDispatch()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      dispatch(getMyProfile({ token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if ((token && companyId) && (isOwner || (allowedCampaignRoles.includes(role)))) {
      dispatch(getCompanyAddresses({ id: String(companyId), token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, address, profile])

  useEffect(() => {
    if (error && error.errors) {
      const payload = {
        title: errorStatusCodes[error.statusCode],
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [error])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-truck me-1"></i> My Addresses</p>
              {
                companyId && (isOwner || allowedRoles.includes(role)) && (
                  <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#companyAddressModal"
                      onClick={() => {
                        setEditMode(false)
                        setInitialAddress({
                          id: '',
                          phone: '',
                          country: '',
                          city: '',
                          street: '',
                          zip: '',
                          vat: '',
                          addressAddition: '',
                          type: null
                        })
                      }}
                    >
                      <i className="bi bi-plus-circle me-1"></i> Address
                    </button>
                  </ul>
                )
              }
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Country</th>
                    <th scope="col">City</th>
                    <th scope="col">Street</th>
                    <th scope="col">Zip</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Updated</th>
                    <th scope="col" className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingCampaign
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={8} />)
                        )
                      : (
                          addresses.length > 0
                            ? (addresses.map((address: Address, index: number) => (
                              <tr key={address.id}>
                                <td>{index + 1}</td>
                                <td>{address.country || '-'}</td>
                                <td>{address.city || '-'}</td>
                                <td>{address.street || '-'}</td>
                                <td>{address.zip || '-'}</td>
                                <td>{address.phone || '-'}</td>
                                <td>
                                  {dayjs(address.updatedAt).format('ll')}
                                </td>
                                <td className="text-center">
                                  <div className="btn-group" role="group" aria-label="Actions">
                                    <button
                                      className="btn"
                                      type="button"
                                      title="Edit Recipient Address"
                                      data-bs-toggle="modal"
                                      data-bs-target="#companyAddressModal"
                                      onClick={() => {
                                        setEditMode(true)
                                        setInitialAddress(address)
                                      }}
                                    >
                                      <i className="bi bi-pencil-square"></i>
                                    </button>
                                    {/* <button
                                      className="btn"
                                      type="button"
                                      title="Delete Company Address"
                                      data-bs-toggle="modal"
                                      data-bs-target="#confirmationModal"
                                      onClick={() => {
                                        setInitialAddress(address)
                                      }}
                                    >
                                      <i className="bi bi-trash text-danger"></i>
                                    </button> */}
                                  </div>
                                </td>
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={8} className="text-center">
                                  No addresses available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
            />
          </div>
        </div>
      </div>
      <div className="modal fade" id="companyAddressModal" tabIndex={-1} aria-labelledby="companyAddressModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companyAddressModalLabel">
                <i className={`bi ${editMode ? 'bi-pencil-square' : 'bi-plus-circle'} me-1`}></i>{' '}
                {`${editMode ? 'Edit' : 'Add'} Address`}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <CompanyAddressEditor address={initialAddress} companyId={String(companyId)} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="confirmationModal" tabIndex={-1} aria-labelledby="confirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="confirmationModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingCampaign && <Progress />}
            <div className="modal-body">
              <p>
                {'Are you sure you want to delete the address?'}
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  // eslint-disable-next-line no-console
                  console.log(initialAddress)
                }}
                disabled={isLoadingCampaign}
                data-bs-dismiss="modal"
                aria-label="Delete"
              >
                <i className="bi bi-trash me-2"></i>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AllMyCampaigns
