import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductCategoryTagInProductAccessControlGroupService from '../../../services/api/ProductCategoryTagInProductAccessControlGroupService'
import { ApiProductCategoryTagInProductAccessControlGroupState } from '../../../types'
import { PRODUCT_CATEGORY_TAG_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiProductCategoryTagInProductAccessControlGroupState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteProductCategoryTagInProductAccessControlGroup = createAsyncThunk('api/product-category-tag-product-access-control-group/delete', async ({ token, productCategoryTagProductAccessControlGroupId, signal }: { token: string, productCategoryTagProductAccessControlGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryTagInProductAccessControlGroupService.deleteProductCategoryTagInProductAccessControlGroup(token, productCategoryTagProductAccessControlGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const productCategoryTagInProductAccessControlGroupSlice = createSlice({
  name: 'api/product-category-tag-product-access-control-group',
  initialState,
  reducers: {
    resetProductCategoryTagInProductAccessControlGroupError: (state) => {
      state.error = null
    },
    resetProductCategoryTagInProductAccessControlGroupMessage: (state) => {
      state.message = null
    },
    resetProductCategoryTagInProductAccessControlGroupData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProductCategoryTagInProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProductCategoryTagInProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_CATEGORY_TAG_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE
      })
      .addCase(deleteProductCategoryTagInProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductCategoryTagInProductAccessControlGroupError, resetProductCategoryTagInProductAccessControlGroupMessage, resetProductCategoryTagInProductAccessControlGroupData } = productCategoryTagInProductAccessControlGroupSlice.actions

const { reducer } = productCategoryTagInProductAccessControlGroupSlice
export default reducer
