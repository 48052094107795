import React from 'react'
import { object, string } from 'yup'
import { Formik } from 'formik'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Role } from '../../types'
import { resetCompanyError } from '../../store/reducers/api/companyReducer'
import { editableRoles } from '../../constants/userRoles'

const CompanyUserEditor = ({ id, initialUser, save }: { id: string, initialUser: { email: string, role: Role | undefined }, save: any }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isUpdating = useAppSelector((state) => state.apiCompany.isLoading)
  const error = useAppSelector((state) => state.apiCompany.error)

  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const userCompanySchema = object({
    email: string().email('Enter a valid email')
      .required('Email is required'),
    role: string().required('Role is required').oneOf(editableRoles.map(role => role.value))
  })

  const saveUser = (id: string, user: any, signal: AbortSignal) => {
    dispatch(save({ id, token, user, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={userCompanySchema}
        enableReinitialize
        initialValues={{
          ...initialUser
        }}
        onSubmit={(
          { email, role },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const user = {
            email,
            role
          }
          if (token && id) {
            saveUser(id, user, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="form-label"
                  >
                    Email
                  </label>
                  <input
                    onChange={(event) => {
                      handleChange(event)
                      dispatch(resetCompanyError())
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    type="email"
                    className={`form-control ${
                      (errors.email &&
                      touched.email &&
                      errors.email) || error?.errors?.message
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="email"
                    name="email"
                    placeholder=""
                    maxLength={128}
                  />
                  <div
                    id="validationEmailFeedback"
                    className="invalid-feedback"
                  >
                    {errors.email || error?.errors?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="role"
                    className="form-label"
                  >
                    User Role
                  </label>
                  <select
                    aria-label="User Role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                    defaultValue={undefined}
                    className={`form-select ${
                      errors.role &&
                      touched.role &&
                      errors.role
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="role"
                    name="role"
                  >
                    <option value={undefined}>Select Role</option>
                    {
                      editableRoles.map((role) => (<option key={role.text} value={role.value}>{role.text}</option>))
                    }
                  </select>
                  <div
                    id="validationUserRoleFeedback"
                    className="invalid-feedback"
                  >
                    {errors.role}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isUpdating
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyUserEditor
