import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ArticleService from '../../../../services/api/dotnet/ArticleService'
import { DotnetApiArticlesState } from '../../../../types'
import {
  ARTICLE_CATEGORY_CREATION_SUCCESS_MESSAGE, ARTICLE_CATEGORY_UPDATE_SUCCESS_MESSAGE,
  VOLUME_BASED_SALE_CREATION_SUCCESS_MESSAGE, VOLUME_BASED_SALE_UPDATE_SUCCESS_MESSAGE
} from '../../../../constants/messages'

const initialState: DotnetApiArticlesState = {
  isLoading: false,
  error: null,
  article: null,
  articles: [],
  categories: [],
  volumeBasedSalesPrices: [],
  childArticles: [],
  billOfMaterials: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const createArticle = createAsyncThunk('dotnet/article/create', async ({ token, article, signal }: { token: string, article: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.createArticle(token, article, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const editArticle = createAsyncThunk('dotnet/article/edit', async ({ articleId, token, article, signal }: { articleId: string | bigint, token: string, article: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.editArticle(token, { ...article, articlesId: articleId }, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const getAllArticles = createAsyncThunk('dotnet/articles/get', async ({ token, perPage, offset, search, signal }: { token: string, perPage: number, offset: number, search: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.getAllArticles(token, perPage, offset, search, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const getArticleById = createAsyncThunk('dotnet/article/get', async ({ token, articleId, signal }: { token: string, articleId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.getArticleById(token, articleId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const getArticleCategories = createAsyncThunk('dotnet/articleCategories/get', async ({ token, perPage, offset, signal }: { token: string, perPage: number, offset: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.getArticleCategories(token, perPage, offset, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const createArticleCategory = createAsyncThunk('dotnet/article/createArticleCategory', async ({ token, articleCategory, signal }: { token: string, articleCategory: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.createArticleCategory(token, articleCategory, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const editArticleCategory = createAsyncThunk('dotnet/article/editArticleCategory', async ({ articleCategoryId, token, articleCategory, signal }: { articleCategoryId: string, token: string, articleCategory: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.editArticleCategory(token, { ...articleCategory, articleCategoryId: String(articleCategoryId) }, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const getArticleVolumeBasedSalesPricesByArticleId = createAsyncThunk('dotnet/volumeBasedSalesPrices/get', async ({ token, articleId, perPage, offset, signal }: { token: string, articleId: string, perPage: number, offset: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.getArticleVolumeBasedSalesPricesByArticleId(token, articleId, perPage, offset, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const getBillOfMaterialbyArticleId = createAsyncThunk('dotnet/billOfMaterialbyArticleId/get', async ({ token, articleId, perPage, offset, signal }: { token: string, articleId: string, perPage: number, offset: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.getBillOfMaterialbyArticleId(token, articleId, perPage, offset, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const getChildArticlesbyArticleId = createAsyncThunk('dotnet/childArticlesbyArticleId/get', async ({ token, articleId, perPage, offset, signal }: { token: string, articleId: string, perPage: number, offset: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.getChildArticlesbyArticleId(token, articleId, perPage, offset, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const editArticleVolumeBasedSalesPrice = createAsyncThunk('dotnet/article/editArticleVolumeBasedSalesPrice', async ({ token, volumeBasedSalesPrice, signal }: { token: string, volumeBasedSalesPrice: object, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.editArticleVolumeBasedSalesPrice(token, volumeBasedSalesPrice, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const createArticleVolumeBasedSalesPrice = createAsyncThunk('dotnet/article/createArticleVolumeBasedSalesPrice', async ({ token, volumeBasedSalesPrice, signal }: { token: string, volumeBasedSalesPrice: object, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ArticleService.createArticleVolumeBasedSalesPrice(token, volumeBasedSalesPrice, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

const articlesSlice = createSlice({
  name: 'dotnet/articles',
  initialState,
  reducers: {
    resetArticlesError: (state) => {
      state.error = null
    },
    resetArticlesMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createArticle.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(createArticle.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = action.payload.status.message
      })
      .addCase(createArticle.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editArticle.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(editArticle.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = action.payload.status.message
      })
      .addCase(editArticle.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAllArticles.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getAllArticles.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.articles = action.payload.pageCollection
        state.metadata = {
          page: (action.payload.pageIndex / action.payload.pageSize) + 1,
          pageCount: Math.ceil(action.payload.itemsCount / action.payload.pageSize),
          perPage: action.payload.pageSize,
          total: action.payload.itemsCount
        }
      })
      .addCase(getAllArticles.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getArticleById.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getArticleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.article = action.payload.data[0]
      })
      .addCase(getArticleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getArticleCategories.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getArticleCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.categories = action.payload.pageCollection
        state.metadata = {
          page: (action.payload.pageIndex / action.payload.pageSize) + 1,
          pageCount: Math.ceil(action.payload.itemsCount / action.payload.pageSize),
          perPage: action.payload.pageSize,
          total: action.payload.itemsCount
        }
      })
      .addCase(getArticleCategories.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createArticleCategory.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(createArticleCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = ARTICLE_CATEGORY_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createArticleCategory.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editArticleCategory.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(editArticleCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = ARTICLE_CATEGORY_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(editArticleCategory.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getArticleVolumeBasedSalesPricesByArticleId.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getArticleVolumeBasedSalesPricesByArticleId.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.volumeBasedSalesPrices = action.payload.pageCollection
      })
      .addCase(getArticleVolumeBasedSalesPricesByArticleId.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editArticleVolumeBasedSalesPrice.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(editArticleVolumeBasedSalesPrice.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = VOLUME_BASED_SALE_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(editArticleVolumeBasedSalesPrice.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createArticleVolumeBasedSalesPrice.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(createArticleVolumeBasedSalesPrice.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = VOLUME_BASED_SALE_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createArticleVolumeBasedSalesPrice.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getBillOfMaterialbyArticleId.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getBillOfMaterialbyArticleId.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.billOfMaterials = action.payload.pageCollection
      })
      .addCase(getBillOfMaterialbyArticleId.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getChildArticlesbyArticleId.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getChildArticlesbyArticleId.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.childArticles = action.payload.pageCollection
      })
      .addCase(getChildArticlesbyArticleId.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetArticlesError, resetArticlesMessage } = articlesSlice.actions

const { reducer } = articlesSlice
export default reducer
