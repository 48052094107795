import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MaintenanceModeService from '../../../services/api/MaintenanceModeService'
import { ApiMaintenanceModeState } from '../../../types'
import { MAINTENANCE_MODE_CREATION_SUCCESS_MESSAGE, MAINTENANCE_MODE_DELETION_MESSAGE, MAINTENANCE_MODE_UPDATE_SUCCESS_MESSAGE } from '../../../constants/messages'

const initialState: ApiMaintenanceModeState = {
  isLoading: false,
  error: null,
  maintenanceMode: null,
  maintenanceModes: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getAllMaintenanceModes = createAsyncThunk('api/maintenance-modes/get', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await MaintenanceModeService.getAllMaintenanceModes(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addMaintenanceMode = createAsyncThunk('api/maintenance-modes/add', async ({ token, maintenanceMode, signal }: { token: string, maintenanceMode: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await MaintenanceModeService.addMaintenanceMode(token, maintenanceMode, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editMaintenanceModeById = createAsyncThunk('api/maintenance-modes/edit', async ({ token, maintenanceModeId, maintenanceMode, signal }: { token: string, maintenanceModeId: any, maintenanceMode: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await MaintenanceModeService.editMaintenanceModeById(token, maintenanceModeId, maintenanceMode, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteMaintenanceModeById = createAsyncThunk('api/maintenance-modes/delete', async ({ token, maintenanceModeId, signal }: { token: string, maintenanceModeId: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await MaintenanceModeService.deleteMaintenanceModeById(token, maintenanceModeId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const maintenanceModeSlice = createSlice({
  name: 'api/maintenanceMode',
  initialState,
  reducers: {
    resetMaintenanceModeError: (state) => {
      state.error = null
    },
    resetMaintenanceModeMessage: (state) => {
      state.message = null
    },
    resetMaintenanceModeData: (state) => {
      state.error = null
      state.maintenanceMode = null
      state.maintenanceModes = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMaintenanceModes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllMaintenanceModes.fulfilled, (state, action) => {
        state.isLoading = false
        state.maintenanceModes = action.payload.maintenanceModes
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllMaintenanceModes.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(addMaintenanceMode.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addMaintenanceMode.fulfilled, (state, action) => {
        state.isLoading = false
        state.maintenanceMode = action.payload.maintenanceMode
        state.message = MAINTENANCE_MODE_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addMaintenanceMode.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editMaintenanceModeById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editMaintenanceModeById.fulfilled, (state, action) => {
        state.isLoading = false
        state.maintenanceMode = action.payload.maintenanceMode
        state.message = MAINTENANCE_MODE_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(editMaintenanceModeById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(deleteMaintenanceModeById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteMaintenanceModeById.fulfilled, (state) => {
        state.isLoading = false
        state.maintenanceMode = null
        state.error = null
        state.message = MAINTENANCE_MODE_DELETION_MESSAGE
      })
      .addCase(deleteMaintenanceModeById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
  }
})

export const { resetMaintenanceModeError, resetMaintenanceModeMessage, resetMaintenanceModeData } = maintenanceModeSlice.actions

const { reducer } = maintenanceModeSlice
export default reducer
