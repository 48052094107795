import dayjs from 'dayjs'
import { Order, User } from '../types'
import { getDeliveryDate, getShippedDate } from './getShipmentDates'
import { rolesObject } from '../constants/userRoles'

export const getNormalizedOrderData = (orders: Order[]) => {
  const allKeys: Set<string> = new Set()
  const flattenedData: Order[] = orders.map((order: Order) => {
    const flattenedOrder: any = {
      'Order Id': order.id,
      'Transaction Id': order.outboundId,
      Status: order.status,
      'Date Created': order.createdAtByUser ? dayjs(order.createdAtByUser).format('DD/MM/YYYY') : dayjs(order.createdAt).format('DD/MM/YYYY'),
      'Date Commissioned': order.deliveryDate ? dayjs(order.deliveryDate).format('DD/MM/YYYY') : '',
      'Date Shipped': getShippedDate(order),
      'Date Delivered': getDeliveryDate(order),
      'First Name': order.shippingAddress.firstname,
      'Last Name': order.shippingAddress.lastname,
      'Company Name': order.shippingAddress.company,
      Street: order.shippingAddress.street,
      'Address Addition': order.shippingAddress.extraAddressLine,
      Zip: order.shippingAddress.zip,
      City: order.shippingAddress.city,
      Country: order.shippingAddress.country,
      Note: order.internalNote,
      ...order.items.reduce((acc: any, item, index) => {
        acc[`Item ${index + 1} Name`] = item.name
        acc[`Item ${index + 1} Quantity`] = item.quantity
        acc[`Item ${index + 1} Serial Number`] = ''
        acc[`Item ${index + 1} JFSKU`] = item.jfsku
        return acc
      }, {}),
      'Created By': order.createdByFullName
    }

    // Add keys of this order to the allKeys set
    Object.keys(flattenedOrder).forEach(key => allKeys.add(key))

    return flattenedOrder
  })

  // Convert Set to Array and ensure 'Created By' is the last key
  let headers = Array.from(allKeys)
  headers = headers.filter(key => key !== 'Created By') // Remove 'Created By' if it's in the middle
  if (headers.length > 0) {
    headers.push('Created By') // Add 'Created By' at the end
  }

  const csvData = [
    headers,
    ...flattenedData.map((item: any) => {
      return headers.map((key: any) => item[key] || '')
    })
  ]

  return csvData
}

export const getMappedUserData = (users: Array<User>, type: 'all' | 'company', companyName = '') => {
  return users.map((user: User) => ({
    'User Id': user.id,
    Name: `${user.firstName} ${user.lastName}`,
    Company: type === 'company' ? companyName : user.company?.name ?? '',
    Email: user.email,
    'Date Created': dayjs(user.createdAt).format('DD/MM/YYYY'),
    Role: rolesObject[user.role],
    Verified: user.isVerified,
    Active: user.isActive,
    'Birth Date': user.birthDate ? dayjs(user.birthDate).format('DD/MM/YYYY') : '',
    'Hire Date': user.hireDate ? dayjs(user.hireDate).format('DD/MM/YYYY') : '',
    'Start Date': user.startDate ? dayjs(user.startDate).format('DD/MM/YYYY') : '',
    'Release Date': user.releaseDate ? dayjs(user.releaseDate).format('DD/MM/YYYY') : ''
  }))
}
