import React from 'react'
import { Formik } from 'formik'
import ModalBox from '../../ModalBox'
import { dismissModal } from '../../../utils/dismissModal'
import CSVDownloader from '../../CSVExporter/CSVDownloader'
import { getAllPagesForOrders } from '../../../store/reducers/api/orderReducer'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { getAllPagesForCampaignOrders, getAllPagesForCampaignOrdersForBundle } from '../../../store/reducers/api/campaignReducer'

interface OrderExportModalProps {
  exportType: 'allPages' | 'currentPage'
  setExportType: React.Dispatch<React.SetStateAction<'allPages' | 'currentPage'>>
  exportData: any[]
  component: 'orders' | 'campaignOrdersForBundle' | 'campaignOrders'
}
const OrderExportModal = ({ exportType, setExportType, exportData, component }: OrderExportModalProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const dispatch = useAppDispatch()

  const componentType = {
    orders: 'orders',
    campaignOrdersForBundle: 'campaign orders for bundle',
    campaignOrders: 'campaign orders'
  }

  return (
    <div>
      <ModalBox
        modalHeader="Export Orders To CSV"
        id="exportToCSV"
      >
        <Formik
          initialValues={{
            export: ''
          }}
          onSubmit={async (values) => {}}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <input
                    type="radio"
                    name="export"
                    value="currentPage"
                    checked={exportType === 'currentPage'}
                    className="form-check-input"
                    onChange={(event) => {
                      handleChange(event.target.value)
                      setFieldValue('export', event.target.value)
                      setExportType('currentPage')
                    }}
                    onBlur={handleBlur}
                  />
                  <span className="mx-2">Current Page</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="export"
                    value="allPages"
                    className="form-check-input"
                    checked={exportType === 'allPages'}
                    onChange={(event) => {
                      handleChange(event.target.value)
                      setFieldValue('export', event.target.value)
                      setExportType('allPages')
                    }}
                    onBlur={handleBlur}
                  />
                  <span className="mx-2">All Pages</span>
                </label>
              </div>
            </form>
          )}
        </Formik>
        <div className="mt-4 text-end">
          {exportType !== 'allPages'
            ? (
              <CSVDownloader
                btnText="Export"
                data={exportData}
                fileName={`All ${componentType[component]} export ${currentUser?.company ? currentUser?.company.name : ''} ${dayjs().format('DD.MM.YYYY')}`}
              />
              )
            : (
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (component === 'orders') {
                    dispatch(getAllPagesForOrders(true))
                  }
                  if (component === 'campaignOrdersForBundle') {
                    dispatch(getAllPagesForCampaignOrdersForBundle(true))
                  }
                  if (component === 'campaignOrders') {
                    dispatch(getAllPagesForCampaignOrders(true))
                  }
                  dismissModal('exportToCSV')
                  setExportType('currentPage')
                }}
              >
                Export
              </button>
              )}
        </div>
      </ModalBox>
    </div>
  )
}

export default OrderExportModal
