import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import InboundService from '../../../services/InboundService'
import { useAppSelector } from '../../../store/hooks'
import { inbounds, Nullable } from '../../../types'

type InboundDeliveryProps = {
  inboundId: string
  jfsku: string
}

const InboundDelivery = ({ inboundId, jfsku }: InboundDeliveryProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const token = currentUser?.token

  const [inbound, setInbound] = useState<Nullable<inbounds.Inbound>>(null)
  const [notification, setNotification] = useState<Array<any>>([])
  const [, setError] = useState<any>([])
  const [orderedQuantity, setOrderedQuantity] = useState(0)
  const [deliveredQuantity, setDeliveredQuantity] = useState(0)

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      InboundService.getASingleInbound(token, inboundId, signal).then((response: any) => {
        setInbound(response.data)
      }).catch((error: any) => setError(error.message))
      InboundService.getShippingNotifications(token, inboundId, signal).then((response: any) => {
        setNotification(response.data)
      }).catch((error: any) => setError(error.message))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const totalOrderedQuantity = (inbound?.items.filter((item) => item.jfsku === jfsku)
      .reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0)) || 0
    const totalQuantityOpen = (inbound?.items.filter((item) => item.jfsku === jfsku)
      .reduce((previousValue, currentValue) => previousValue + currentValue.quantityOpen, 0)) || 0

    const totalDeliveredQuantity = totalOrderedQuantity - totalQuantityOpen
    setOrderedQuantity(totalOrderedQuantity)
    setDeliveredQuantity(totalDeliveredQuantity)
  }, [inbound])

  return (
    <tr>
      <td>{inbound?.inboundId}</td>
      <td>{orderedQuantity}</td>
      <td>{deliveredQuantity}</td>
      <td>{notification.length ? dayjs().format('llll') : '-'}</td>
      <td>{notification.length ? dayjs().format('llll') : '-'}</td>
    </tr>
  )
}

export default InboundDelivery
