import React, { ChangeEvent, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import * as userRoles from '../../../constants/userRoles'
import { CompanyUserGroup, Permission } from '../../../types'
import {
  addCompanyUserGroup,
  deleteCompanyUserGroupById,
  editCompanyUserGroupById,
  getCompanyUserGroups
} from '../../../store/reducers/api/companyUserGroupReducer'
import { getCompanies } from '../../../store/reducers/api/companyReducer'
import {
  COMPANY_USER_GROUP_CREATION_SUCCESS_MESSAGE,
  COMPANY_USER_GROUP_UPDATE_SUCCESS_MESSAGE,
  COMPANY_USER_GROUP_DELETION_MESSAGE
} from '../../../constants/messages'
import { setToast } from '../../../store/reducers/toastReducer'
import { dismissModal } from '../../../utils/dismissModal'
import { READWRITE } from '../../../constants/permissions'
import Progress from '../../../components/loaders/Progress'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'
import { PencilIcon } from '../../../components/icons/PencilIcon'
import { TrashIcon } from '../../../components/icons/TrashIcon'
import Pagination from '../../../components/Pagination'
import CompanyUserGroupEditor from '../../../components/AccessControls/CompanyUserGroups/CompanyUserGroupEditor'
import useDebounce from '../../../utils/hooks/useDebounce'

const CompanyUserGroups = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiCompanyUserGroup.isLoading)
  const companyUserGroups = useAppSelector((state) => state.apiCompanyUserGroup.companyUserGroups)
  const metadata = useAppSelector((state) => state.apiCompanyUserGroup.metadata)
  const message = useAppSelector((state) => state.apiCompanyUserGroup.message)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [perPageCompanies] = useState(100)
  const [pageCompanies] = useState(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const [initialCompanyUserGroup, setInitialCompanyUserGroup] = useState<Partial<CompanyUserGroup>>({
    name: '',
    description: '',
    company: {
      id: '',
      name: '',
      email: '',
      domain: ''
    }
  })

  const token = currentUser?.token
  const role = profile?.role || userRoles.USER

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const handleCompanyUserGroupRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      if (role === userRoles.ADMIN) {
        dispatch(getCompanyUserGroups({ token, perPage, page, signal }))
      }
    }
  }

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const isAllowed = (permission: Permission) => {
    return role === userRoles.ADMIN
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      if (role === userRoles.ADMIN) {
        dispatch(getCompanyUserGroups({ token, perPage, page, signal, search: debouncedSearchTerm }))
      }
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTerm])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && role === userRoles.ADMIN) {
      token && dispatch(getCompanies({ token, perPage: perPageCompanies, page: pageCompanies, signal }))
    }
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [
      COMPANY_USER_GROUP_CREATION_SUCCESS_MESSAGE,
      COMPANY_USER_GROUP_UPDATE_SUCCESS_MESSAGE,
      COMPANY_USER_GROUP_DELETION_MESSAGE
    ]
    if (token && message && allowedMessages.includes(message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getCompanyUserGroups({ token, perPage, page, signal }))
      dismissModal('companyUserGroupModal')
    }
    return () => {
      controller.abort()
    }
  }, [message])

  return (
    <div className="card">
      <div className="m-4">
        <div className="navbar navbar-expand mb-3">
          <p className="h5 me-2"><i className="bi bi-person-rolodex me-2"></i>Company User Groups</p>
          <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
            <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
              <Formik
                validationSchema={searchSchema}
                enableReinitialize
                initialValues={{
                  search: ''
                }}
                onSubmit={({ search }, actions) => {
                  setSearchTerm(search)
                  if (page !== 1) {
                    setPage(1)
                    navigate('')
                  }

                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <input
                        onChange={(event) => {
                          const search = event.target.value
                          handleChange(event)
                          setSearchTerm(search)
                          if (page !== 1) {
                            setPage(1)
                            navigate('')
                          }
                        }}
                        maxLength={24}
                        onBlur={handleBlur}
                        value={values.search}
                        className={`form-control ${
                          errors.search && touched.search && errors.search
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="btnNavbarSearch"
                        name="search"
                      />
                      <button
                        className="btn btn-outline-dark"
                        id="btnNavbarSearch"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            {
              isAllowed(READWRITE) && (<button
                type="button"
                className="btn btn-outline-primary btn-sm text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#companyUserGroupModal"
                title="Add Company User Group"
                aria-label="Add Company User Group"
                onClick={() => {
                  setInitialCompanyUserGroup({
                    name: '',
                    description: '',
                    company: {
                      id: '',
                      name: '',
                      email: '',
                      domain: ''
                    }
                  })
                }}
              >
                <i className="bi bi-plus-circle"></i>
                <span className="d-none d-md-inline-block ms-1">Add Company User Group</span>
              </button>)
            }
            <button
              type="button"
              title="Refresh"
              aria-label="Refresh"
              className="btn btn-outline-dark ms-2 ms-md-3"
              onClick={() => handleCompanyUserGroupRefresh()}
            >
              <i className="fas fa-redo"></i>
            </button>
          </ul>
        </div>
        {(isLoading) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
        <div className="table-responsive">
          <table className="table table-hover table-centered table-nowrap align-middle">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Company</th>
                <th scope="col" className="text-nowrap">Date Created</th>
                {isAllowed(READWRITE) && <th scope="col" className="text-end">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {
                (isLoading)
                  ? (
                      Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={5} actionQuantity={2} />)
                    )
                  : (
                      companyUserGroups.length > 0
                        ? (companyUserGroups.map((companyUserGroup: CompanyUserGroup) => (
                          <tr key={companyUserGroup.id} className={initialCompanyUserGroup.id === companyUserGroup.id ? 'table-primary' : ''}>
                            <td>
                              <span
                                title="Click To Copy Company User Id"
                                onClick={() => navigator.clipboard.writeText(String(companyUserGroup.id))}
                                className="user-select-all"
                              >
                                {(companyUserGroup.id).substring(0, 8)}
                              </span>
                            </td>
                            <td>
                              <Link title="View Company User Group" className="text-decoration-none" to={`/access-controls/company-user-groups/${companyUserGroup.id}`}>
                                {companyUserGroup.name}
                              </Link>
                            </td>
                            <td>{companyUserGroup.company.name}</td>
                            <td className="text-nowrap">{dayjs(companyUserGroup.createdAt).format('ll')}</td>
                            {isAllowed(READWRITE) &&
                            <td className="text-end">
                              <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                <button
                                  className="btn btn-outline-dark btn-round me-2"
                                  type="button"
                                  title="Edit Company User Group"
                                  data-bs-toggle="modal"
                                  data-bs-target="#companyUserGroupEditModal"
                                  onClick={() => {
                                    setInitialCompanyUserGroup(companyUserGroup)
                                  }}
                                >
                                  <PencilIcon/>
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-round"
                                  type="button"
                                  title="Delete Company User Group"
                                  data-bs-toggle="modal"
                                  data-bs-target="#companyUserGroupConfirmationModal"
                                  onClick={() => {
                                    setInitialCompanyUserGroup(companyUserGroup)
                                  }}
                                >
                                  <TrashIcon/>
                                </button>
                              </div>
                            </td>}
                          </tr>
                          ))
                          )
                        : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              No company user groups available yet
                            </td>
                          </tr>
                          )
                    )
              }
            </tbody>
          </table>
        </div>
        <Pagination
          isLoading={isLoading}
          metadata={{
            limit: metadata.perPage,
            total: metadata.total,
            offset: ((metadata.page - 1) * (metadata.perPage))
          }}
          page={page}
          perPage={perPage}
          handlePageChange={handlePageChange}
          handleShowEntries={handleShowEntries}
          isTrackingPage
        />
      </div>

      <div className="modal fade" id="companyUserGroupModal" tabIndex={-1} aria-labelledby="companyUserGroupModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companyUserGroupModalLabel">
                <i className={'bi bi-plus-circle me-1'}></i>{' '}
                {'Add Company User Group'}
              </h5>
              <button type="button"
                onClick={() => {}}
                className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              >
              </button>
            </div>
            {(isLoading) && <Progress />}
            <div className="modal-body">
              <CompanyUserGroupEditor
                id=''
                initialCompanyUserGroup={initialCompanyUserGroup}
                save={addCompanyUserGroup}
                isEdit={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="companyUserGroupEditModal" tabIndex={-1} aria-labelledby="companyUserGroupEditModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companyUserGroupEditModalLabel">
                <i className={'bi bi-plus-circle me-1'}></i>{' '}
                {'Edit Company User Group'}
              </h5>
              <button type="button"
                onClick={() => {}}
                className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              >
              </button>
            </div>
            {(isLoading) && <Progress />}
            <div className="modal-body">
              <CompanyUserGroupEditor
                id={String(initialCompanyUserGroup.id)}
                initialCompanyUserGroup={initialCompanyUserGroup}
                save={editCompanyUserGroupById}
                isEdit={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="companyUserGroupConfirmationModal" tabIndex={-1} aria-labelledby="companyUserGroupConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="companyUserGroupConfirmationModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoading) && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to delete
                <span className="fw-bold">{` '${initialCompanyUserGroup.name}' `}</span>
                company user group?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && initialCompanyUserGroup.id !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteCompanyUserGroupById({ companyUserGroupId: String(initialCompanyUserGroup.id), token, signal }))
                  }
                }}
                disabled={(isLoading)}
                data-bs-dismiss="modal"
                aria-label="Delete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyUserGroups
