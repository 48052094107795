import React, { useState, useRef, useEffect } from 'react'
import { Stage, Layer, Image } from 'react-konva'
import Spinner from '../loaders/Spinner'

interface ImageEditorProps {
  image: HTMLImageElement | undefined
  stageRef: any
  stageWidth: number
  stageHeight: number
  orientation: 'landscape' | 'portrait'
}

const ImageEditor = ({ image, stageHeight, stageWidth, stageRef, orientation }: ImageEditorProps) => {
  const [text, setText] = useState('Click to edit')
  const [imageWidth, setImageWidth] = useState(1)
  const [imageHeight, setImageHeight] = useState(1)

  const layerRef = useRef<any>(null)

  const handleTextChange = (event: any) => {
    setText(event?.target.value)
  }

  useEffect(() => {
    if (image) {
      setImageWidth(image.width)
      setImageHeight(image.height)
    }
  }, [image])

  if (!image) {
    return <Spinner />
  }

  return (
    <div className="row m-0 p-0" style={{ width: stageWidth + 28, height: stageHeight }}>
      <span className="border rounded">
        <Stage
          ref={stageRef}
          width={stageWidth}
          height={stageHeight}
        >
          <Layer ref={layerRef}>
            <Image
              image={image}
              width={imageWidth * (orientation === 'portrait' ? stageWidth / imageWidth : stageHeight / imageHeight)}
              height={imageHeight * (orientation === 'portrait' ? stageWidth / imageWidth : stageHeight / imageHeight)}
            />
          </Layer>
        </Stage>
      </span>
      <div className="modal fade mt-2" id="textModal" tabIndex={-1} aria-labelledby="textModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col">
                <textarea className="form-control" value={text} onChange={handleTextChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageEditor
