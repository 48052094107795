export const hexToRGB = (hex: string) => {
  // Remove the # symbol if it's included
  hex = hex.replace(/^#/, '')

  // Convert shorthand hex color to full hex color
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1')
  }

  // Parse the hex string to get the individual RGB values
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  // Return the RGB values as an object
  return { r, g, b }
}
