import { createSlice } from '@reduxjs/toolkit'
import { ShoppingCartState } from '../../types'

const initialState: ShoppingCartState = {
  products: [],
  addresses: [],
  bundles: [],
  leftOverProducts: [],
  bundleConfiguratorMode: null,
  isBulkOrder: true,
  splitProduct: null
}

const shoppingCartSlice = createSlice({
  name: 'shopping-cart',
  initialState,
  reducers: {
    setShoppingCart: (state, action) => {
      const { products } = action.payload
      state.products = products || []
    },
    removeFromShoppingCart: (state, action) => {
      const { product } = action.payload
      state.products = state.products.filter(item => item.id !== product.id) || []
    },
    resetShoppingCart: (state) => {
      state.products = []
    },
    setShoppingAddresses: (state, action) => {
      const { addresses } = action.payload
      state.addresses = addresses
    },
    resetShoppingAddresses: (state) => {
      state.addresses = []
    },
    setBundleConfiguratorMode: (state, action) => {
      const { bundleConfiguratorMode } = action.payload
      state.bundleConfiguratorMode = bundleConfiguratorMode
    },
    resetBundleConfiguratorMode: (state) => {
      state.bundleConfiguratorMode = null
    },
    setIsBulkOrder: (state, action) => {
      const isBulkOrder = action.payload
      state.isBulkOrder = isBulkOrder
    },
    setShoppingCartBundles: (state, action) => {
      const { bundles } = action.payload
      state.bundles = bundles
    },
    resetShoppingCartBundles: (state) => {
      state.bundles = []
    },
    setShoppingCartLeftOverProducts: (state, action) => {
      const { leftOverProducts } = action.payload
      state.leftOverProducts = leftOverProducts
    },
    resetShoppingCartLeftOverProducts: (state) => {
      state.leftOverProducts = []
    },
    setSplitProduct: (state, action) => {
      state.splitProduct = action.payload
    },
    clearSplitProduct: (state) => {
      state.splitProduct = null
    }
  }
})

export const {
  setSplitProduct,
  clearSplitProduct,
  setShoppingCart,
  resetShoppingCart,
  setShoppingAddresses,
  resetShoppingAddresses,
  setBundleConfiguratorMode,
  resetBundleConfiguratorMode,
  setShoppingCartBundles,
  resetShoppingCartBundles,
  setShoppingCartLeftOverProducts,
  resetShoppingCartLeftOverProducts,
  setIsBulkOrder,
  removeFromShoppingCart
} = shoppingCartSlice.actions

const { reducer } = shoppingCartSlice
export default reducer
