import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductInProductCategoryService from '../../../services/api/ProductInProductCategoryService'
import { ApiProductInProductCategoryState } from '../../../types'
import { PRODUCT_IN_PRODUCT_CATEGORY_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiProductInProductCategoryState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteProductInProductCategoryGroup = createAsyncThunk('api/product-product-category/delete', async ({ token, productProductCategoryId, signal }: { token: string, productProductCategoryId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductInProductCategoryService.deleteProductInProductCategory(token, productProductCategoryId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const productInProductCategorySlice = createSlice({
  name: 'api/product-product-category',
  initialState,
  reducers: {
    resetProductInProductCategoryError: (state) => {
      state.error = null
    },
    resetProductInProductCategoryMessage: (state) => {
      state.message = null
    },
    resetProductInProductCategoryData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProductInProductCategoryGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProductInProductCategoryGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_IN_PRODUCT_CATEGORY_DELETION_MESSAGE
      })
      .addCase(deleteProductInProductCategoryGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductInProductCategoryError, resetProductInProductCategoryMessage, resetProductInProductCategoryData } = productInProductCategorySlice.actions

const { reducer } = productInProductCategorySlice
export default reducer
