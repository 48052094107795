import React from 'react'

const TableHead = ({ columns }: { columns: Array<{ name: string, className: string, onChange?: Function, checked?: boolean }> }) => (
  <thead>
    <tr>
      {
        columns.map((column, index) => {
          if (column.name === 'Select') {
            return (<th key={index} >
              <input
                type="checkbox"
                title="Select all orders"
                aria-label="Select all orders"
                checked={column.checked}
                onChange={() => {
                  column.onChange && column.onChange()
                }}
                id="allOrdersCheckbox"
              />
            </th>)
          }
          return (<th key={index} scope="col" className={`${column.className} text-nowrap`}>{column.name}</th>)
        })
      }
    </tr>
  </thead>
)

export default TableHead
