import React, { useCallback, useState } from 'react'
import { FieldArray, Formik } from 'formik'
import { array, bool, number, object, string } from 'yup'
import AsyncSelect from 'react-select/async'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { debounce } from '../../../utils/debounce'
import { Bundle } from '../../../types'
import PictureUploader from '../PictureUploader'
import { containsObject } from '../../../utils/containsObject'
import { loadProductOptions } from '../../../utils/loadProductOptions'
import { USER } from '../../../constants/userRoles'
import { TrashIcon } from '../../icons/TrashIcon'

const stringToBoolean = (value: 'true' | 'false' | undefined | boolean) => (String(value) === 'true')

const BundleEditor = ({ id, bundle, editMode, save, isAllowedToReadProducts }: { id: any, bundle: Bundle | null, editMode: boolean, save: Function, isAllowedToReadProducts: boolean }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiUsers.isLoading)
  const error = useAppSelector((state) => state.profile.error)

  const dispatch = useAppDispatch()

  const [perPage] = useState(100)
  const [page] = useState(1)

  const token = currentUser?.token
  const role = currentUser?.role || USER
  const companyId = currentUser?.company?.id

  const bundleSchema = object({
    name: string().required('Name is required').max(128, (value) => `Name must be at most ${value.max} characters`),
    description: string().max(200, (value) => `Description must be at most ${value.max} characters`),
    price: number().min(0, (value) => `Price must be greater than or equal to ${value.min}`).max(1000000, (value) => `Price must be less than or equal to ${value.max}`),
    isLocked: bool().default(false),
    isBillOfMaterials: bool().default(false),
    items: array().of(object({
      name: string().required('Name is required'),
      jfsku: string().required('JFSKU is required'),
      merchantSku: string().required('Merchant SKU is required')
    })).required('Items are required').min(1, (value) => `Select at least ${value.min} item`)
  })

  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadProductOptions(inputValue, page, perPage, String(token), role, isAllowedToReadProducts, companyId).then(options => callback(options))
    }, 800),
    []
  )

  return (
    <div className="row">
      <div className="col">
        <Formik
          validationSchema={bundleSchema}
          enableReinitialize
          initialValues={{
            name: bundle?.name || '',
            description: bundle?.description || '',
            price: bundle?.price || 0,
            jfsku: bundle?.jfsku || '',
            merchantSku: bundle?.merchantSku || '',
            isLocked: bundle?.isLocked,
            isBillOfMaterials: bundle?.isBillOfMaterials,
            specifications: bundle?.specifications,
            items: bundle?.specifications?.billOfMaterialsComponents.map((item) => ({
              name: item.name,
              jfsku: item.jfsku,
              merchantSku: item.merchantSku
            })) || []
          }}
          onSubmit={(
            { name, description, price, isLocked, isBillOfMaterials, jfsku, merchantSku, specifications, items },
            actions
          ) => {
            const controller = new AbortController()
            const signal = controller.signal
            const bundle = {
              name,
              description,
              price,
              isLocked,
              isBillOfMaterials,
              jfsku,
              merchantSku,
              specifications: {
                ...specifications,
                billOfMaterialsComponents: items.map(({ name, jfsku, merchantSku }) => ({ name, jfsku, merchantSku }))
              }
            }

            if (token && id) {
              dispatch(save({ id, token, bundle, signal }))
            }
            actions.setSubmitting(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="bundleName" className="form-label">
                      Name
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      type="text"
                      className={`form-control ${
                        (errors.name && touched.name) || error?.errors?.name
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="bundleName"
                      name="name"
                      placeholder="Enter name"
                      autoComplete="off"
                    />
                    <div
                      id="validationBundleNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.name || error?.errors?.name}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="bundleDescription" className="form-label">
                      Description
                    </label>
                    <textarea
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      className={`form-control ${
                        (errors.description && touched.description) || error?.errors?.description
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="bundleDescription"
                      name="description"
                      placeholder="Enter description"
                    />
                    <div
                      id="validationBundleDescriptionFeedback"
                      className="invalid-feedback"
                    >
                      {errors.description || error?.errors?.description}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="bundlePrice" className="form-label">
                      Price
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={Number(values.price)}
                      type="number"
                      className={`form-control ${
                        (errors.price && touched.price) || error?.errors?.price
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="bundlePrice"
                      name="price"
                      placeholder="Enter price"
                    />
                    <div
                      id="validationBundlePriceFeedback"
                      className="invalid-feedback"
                    >
                      {errors.price || error?.errors?.price}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label
                      htmlFor="isLocked"
                      className="form-label"
                    >
                      Is Locked
                    </label>
                    <select
                      aria-label="Is Locked"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={String(values.isLocked)}
                      className={`form-select ${
                        errors.isLocked &&
                        touched.isLocked &&
                        errors.isLocked
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="isLocked"
                      name="isLocked"
                    >
                      <option value="">Select Is Locked Status</option>
                      <option value={'true'}>True</option>
                      <option value={'false'}>False</option>
                    </select>
                    <div
                      id="validationIsLockedFeedback"
                      className="invalid-feedback"
                    >
                      {errors.isLocked}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label
                      htmlFor="isBillOfMaterials"
                      className="form-label"
                    >
                      Is Bill of Materials
                    </label>
                    <select
                      aria-label="Is Bill of Materials"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={String(values.isBillOfMaterials)}
                      className={`form-select ${
                        errors.isBillOfMaterials &&
                        touched.isBillOfMaterials &&
                        errors.isBillOfMaterials
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="isBillOfMaterials"
                      name="isBillOfMaterials"
                      disabled={!bundle?.jfsku}
                    >
                      <option value="">Select Is Bill of Materials Status</option>
                      <option value={'true'}>True</option>
                      <option value={'false'}>False</option>
                    </select>
                    <div
                      id="validationIsBillOfMaterialsFeedback"
                      className="invalid-feedback"
                    >
                      {errors.isBillOfMaterials}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="items" className="form-label">
                      Items
                    </label>
                    <FieldArray
                      name="items"
                      render={arrayHelpers => (
                        <div className="mt-2">
                          <AsyncSelect
                            className={`${
                              (errors.items && touched.items)
                                ? 'is-invalid'
                                : ''
                            }`}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: (errors.items && touched.items) ? '#dc3545' : provided.borderColor,
                                '&:hover': {
                                  boxShadow: (errors.items && touched.items) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                                  borderColor: (errors.items && touched.items) ? '#dc3545' : '#86b7fe'
                                }
                              })
                            }}
                            cacheOptions
                            isClearable
                            loadOptions={loadOptionsDebounced}
                            defaultOptions
                            onChange={(selectedOption: any) => {
                              if (selectedOption && !containsObject(selectedOption.value, values.items)) {
                                arrayHelpers.push(selectedOption.value)
                              }
                            }}
                            onBlur={handleBlur}
                            inputId="items"
                            name="items"
                            isDisabled={stringToBoolean(values.isBillOfMaterials) || stringToBoolean(values.isLocked)}
                          />
                          <ul className="list-group mt-2">
                          {
                            values.items.map((item: any, index) => (
                              <li key={index} className="list-group-item d-flex justify-content-between align-items-center" aria-current="true">
                                {item.name}
                                <div className="d-flex flex-row float-end" role="group" aria-label="Item Action Buttons">
                                  {
                                      <button
                                        type="button"
                                        title="Remove Item"
                                        className="btn btn-outline-danger btn-round btn-sm ms-5"
                                        onClick={() => {
                                          arrayHelpers.remove(index)
                                        }}
                                        disabled={stringToBoolean(values.isBillOfMaterials) || stringToBoolean(values.isLocked)}
                                      >
                                        <TrashIcon/>
                                      </button>
                                  }
                                </div>
                              </li>
                            ))
                          }
                          </ul>
                        </div>
                      )}
                    />
                    <div className="text-danger small" id="validationItemsFeedback">
                      {typeof errors.items === 'string' && (touched.items && errors.items)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary mt-2"
                  disabled={isLoading || isSubmitting}
                >
                  <i className="bi bi-save"></i> Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      {
        (bundle && editMode) &&
        (<div className="col">
          <PictureUploader id={id} />
        </div>)
      }
    </div>
  )
}

export default BundleEditor
