import axios from 'axios'
import { CompanyProductAccessControlGroup, CompanyUserGroupProductAccessControlGroup, ProductAccessControlGroupEdit, ProductCategoryTagProductAccessControlGroup, UserProductAccessControlGroup } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getProductAccessControlGroups (token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/product-access-control-groups', config)
  },
  getProductAccessControlGroupById (token: string, productAccessControlGroupId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-access-control-groups/${productAccessControlGroupId}`, config)
  },
  addProductAccessControlGroup (token: string, productAccessControlGroup: ProductAccessControlGroupEdit, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/product-access-control-groups', { productAccessControlGroup }, config)
  },
  editProductAccessControlGroupById (token: string, productAccessControlGroupId: string, productAccessControlGroup: ProductAccessControlGroupEdit, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/product-access-control-groups/${productAccessControlGroupId}`, { productAccessControlGroup }, config)
  },
  deleteProductAccessControlGroupById (token: string, productAccessControlGroupId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/product-access-control-groups/${productAccessControlGroupId}`, config)
  },
  addProductCategoryTagsToProductAccessControlGroup (token: string, productAccessControlGroupId: string, productCategoryTagProductAccessControlGroup: ProductCategoryTagProductAccessControlGroup, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/product-access-control-groups/${productAccessControlGroupId}/product-category-tags`, { productCategoryTagProductAccessControlGroup }, config)
  },
  getProductCategoryTagsOfProductAccessControlGroup (token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-access-control-groups/${productAccessControlGroupId}/product-category-tags`, config)
  },
  addUsersToProductAccessControlGroup (token: string, productAccessControlGroupId: string, userProductAccessControlGroup: UserProductAccessControlGroup, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/product-access-control-groups/${productAccessControlGroupId}/users`, { userProductAccessControlGroup }, config)
  },
  getUsersOfProductAccessControlGroup (token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-access-control-groups/${productAccessControlGroupId}/users`, config)
  },
  addCompaniesToProductAccessControlGroup (token: string, productAccessControlGroupId: string, companyProductAccessControlGroup: CompanyProductAccessControlGroup, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/product-access-control-groups/${productAccessControlGroupId}/companies`, { companyProductAccessControlGroup }, config)
  },
  getCompaniesOfProductAccessControlGroup (token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-access-control-groups/${productAccessControlGroupId}/companies`, config)
  },
  addCompanyUserGroupsToProductAccessControlGroup (token: string, productAccessControlGroupId: string, companyUserGroupProductAccessControlGroup: CompanyUserGroupProductAccessControlGroup, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/product-access-control-groups/${productAccessControlGroupId}/company-user-groups`, { companyUserGroupProductAccessControlGroup }, config)
  },
  getCompanyUserGroupsOfProductAccessControlGroup (token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/product-access-control-groups/${productAccessControlGroupId}/company-user-groups`, config)
  }
}
