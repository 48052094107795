import React from 'react'
import { Formik } from 'formik'
import { number, object, string } from 'yup'
import { editableRoles } from '../../../constants/userRoles'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useParams } from 'react-router-dom'
import { createCampaignOrderLimit } from '../../../store/reducers/api/campaignReducer'
import { CampaignOrderLimit, Role } from '../../../types'

const OrderLimit = ({ initialOrderLimit }: { initialOrderLimit: Partial<CampaignOrderLimit> }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCampaign.isLoadingOrderLimits)

  const { campaignId }: any = useParams()
  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const orderLimitSchema = object().shape({
    limit: number().min(1).label('Limit').required(),
    role: string().label('Role').required()
  })

  const addCampaignOrderLimit = (role: Role | undefined, limit: number | undefined): void => {
    const controller = new AbortController()
    const signal = controller.signal
    const campaignOrderLimit = { role, limit }
    if (token && campaignId) {
      dispatch(createCampaignOrderLimit({ id: campaignId, token, campaignOrderLimit, signal }))
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={orderLimitSchema}
        initialValues={{ ...initialOrderLimit }}
        onSubmit={({ limit, role }, actions) => {
          addCampaignOrderLimit(role, limit)
          actions.setSubmitting(false)
        }}
      >{({ handleChange, handleBlur, errors, touched, handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-3 flex flex-col mx-6">
              <label htmlFor="role" className="form-label">
                Role
              </label>
                <select
                  aria-label="Role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.role}
                  className={`form-control ${
                    touched.role && errors.role
                      ? 'is-invalid'
                      : ''
                  }`}
                  id="role"
                  name="role"
                >
                  <option value="">Select Role</option>
                  {editableRoles.map((role, index) => <option key={index} value={role.value}>{role.text}</option>)}
                </select>
                <div
                  className="invalid-feedback"
                >
                  {errors.role}
                </div>
              </div>
              <div className="mb-3 flex flex-col mx-6">
                <label
                  htmlFor="limit"
                  className="form-label"
                >
                  Order limit
                </label>
                <input
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.limit}
                  className={`form-control ${
                    touched.limit && errors.limit
                      ? 'is-invalid'
                      : ''
                  }`}
                  id="limit"
                  name="limit"
                  placeholder="Limit"
                  min={1}
                />
                <div
                  className="invalid-feedback"
                >
                  {errors.limit}
                </div>
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary mt-2"
                  disabled={isSubmitting || isLoading}
                >
                  <i className="bi bi-save text-white"></i> Save
                </button>
              </div>
          </form>
      )}
      </Formik>
    </div>
  )
}

export default OrderLimit
