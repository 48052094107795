import React, { useCallback, useState } from 'react'
import { bool, object, string } from 'yup'
import { Formik } from 'formik'
import AsyncSelect from 'react-select/async'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { CardSetting, GreetingCard } from '../../types'
import * as userRoles from '../../constants/userRoles'
import { debounce } from '../../utils/debounce'
import GreetingCardService from '../../services/api/GreetingCardService'
import { validateBarcode } from '../../utils/validateBarcode'

const CardSettingsEditor = ({ campaignId, initialCardSettings, save }: { campaignId: string, initialCardSettings: Partial<CardSetting>, save: Function }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)

  const [perPage] = useState(100)
  const [page] = useState(1)

  const token = currentUser?.token
  const role = currentUser?.role

  const dispatch = useAppDispatch()

  const cardSettingsSchema = object().shape({
    isEnabled: bool().label('Card Configurator Enabled').required().nullable(),
    isFrontSelectable: bool().label('Front Selectable').required().nullable(),
    isRotationEnabled: bool().label('Rotation Enabled').required().nullable(),
    isBackEditable: bool().label('Back Editable').required().nullable(),
    isAutoProcessingEnabled: bool().label('Auto Processing Enabled').required().nullable(),
    exportOrientation: string().label('Export Orientation').oneOf(['portrait', 'landscape']).required().nullable(),
    exportSides: string().label('Export Sides').oneOf(['both', 'front', 'back']).required().nullable(),
    supplierEmail: string().label('Supplier Email').email().required().nullable(),
    articleId: string().label('Article').nullable().length(11).uppercase(),
    eanBarcode: string().label('EAN Barcode').matches(/^\d{8}(\d{5}|\d{6})?$/, 'Enter a valid ean barcode')
      .test('isValidEAN', 'Invalid barcode, ensure all digits are correct', validateBarcode).nullable(),
    upcBarcode: string().label('Barcode').matches(/^\d{6}(\d{2}|\d{6})?$/, 'Enter a valid upc barcode')
      .test('isValidUPC', 'Invalid barcode, ensure all digits are correct', validateBarcode).nullable()
  })

  const saveCardSettings = (id: string, cardSetting: any, signal: AbortSignal) => {
    dispatch(save({ id, token, cardSetting, signal }))
  }

  const mapGreetingCards = (greetingCards: Array<GreetingCard>) => {
    return greetingCards.map(greetingCard => ({
      value: greetingCard.jtlfpid,
      label: greetingCard.articleName,
      url: greetingCard.url,
      id: greetingCard.id
    }))
  }

  const loadGreetingCardOptions = async (page: number, perPage: number, token: string, inputValue: string) => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      try {
        const res = await GreetingCardService.getAllGreetingCards(token, perPage, page, inputValue, '', signal)
        return mapGreetingCards(res.data.greetingCards)
      } catch (error) {
        return mapGreetingCards([])
      }
    } else {
      return mapGreetingCards([])
    }
  }

  const loadOptionsDebounced: any = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadGreetingCardOptions(page, perPage, String(token), inputValue)
        .then(options => callback(options))
    }, 800),
    []
  )

  return (
    <div>
      <Formik
        validationSchema={cardSettingsSchema}
        enableReinitialize
        initialValues={{
          ...initialCardSettings
        }}
        onSubmit={(
          {
            isEnabled, isFrontSelectable, isRotationEnabled, isBackEditable,
            isAutoProcessingEnabled, exportOrientation, exportSides, supplierEmail,
            articleId, defaultFront, eanBarcode, upcBarcode
          },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const cardSettings = {
            isEnabled,
            isFrontSelectable,
            isRotationEnabled,
            isBackEditable,
            isAutoProcessingEnabled,
            exportOrientation,
            exportSides,
            supplierEmail,
            articleId,
            defaultFront,
            eanBarcode: eanBarcode || null,
            upcBarcode: upcBarcode || null
          }

          if (token && campaignId) {
            saveCardSettings(campaignId, cardSettings, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            {
              role === userRoles.ADMIN && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="isEnabled"
                          className="form-label"
                        >
                          Card Configurator Enabled
                        </label>
                        <select
                          aria-label="Card Configurator Enabled"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isEnabled)}
                          className={`form-select ${
                            errors.isEnabled &&
                            touched.isEnabled
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="isEnabled"
                          name="isEnabled"
                        >
                          <option value="">Select Card Configurator Enabled</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsEnabledFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isEnabled}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="isFrontSelectable"
                          className="form-label"
                        >
                          Front Selectable
                        </label>
                        <select
                          aria-label="Front Selectable"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isFrontSelectable)}
                          className={`form-select ${
                            errors.isFrontSelectable &&
                            touched.isFrontSelectable
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="isFrontSelectable"
                          name="isFrontSelectable"
                        >
                          <option value="">Select Front Selectable</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsFrontSelectableFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isFrontSelectable}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="isRotationEnabled"
                          className="form-label"
                        >
                          Rotation Enabled
                        </label>
                        <select
                          aria-label="Rotation Enabled"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isRotationEnabled)}
                          className={`form-select ${
                            errors.isRotationEnabled &&
                            touched.isRotationEnabled
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="isRotationEnabled"
                          name="isRotationEnabled"
                        >
                          <option value="">Select Rotation Enabled</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsRotationEnabledFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isRotationEnabled}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="isBackEditable"
                          className="form-label"
                        >
                          Back Editable
                        </label>
                        <select
                          aria-label="Back Editable"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isBackEditable)}
                          className={`form-select ${
                            errors.isBackEditable &&
                            touched.isBackEditable
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="isBackEditable"
                          name="isBackEditable"
                        >
                          <option value="">Select Back Editable</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsBackEditableFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isBackEditable}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="isAutoProcessingEnabled"
                          className="form-label"
                        >
                          Auto Processing Enabled
                        </label>
                        <select
                          aria-label="Auto Processing Enabled"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={String(values.isAutoProcessingEnabled)}
                          className={`form-select ${
                            errors.isAutoProcessingEnabled &&
                            touched.isAutoProcessingEnabled
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="isAutoProcessingEnabled"
                          name="isAutoProcessingEnabled"
                        >
                          <option value="">Select AutoProcessing Enabled</option>
                          <option value={'true'}>Yes</option>
                          <option value={'false'}>No</option>
                        </select>
                        <div
                          id="validationIsAutoProcessingEnabledFeedback"
                          className="invalid-feedback"
                        >
                          {errors.isAutoProcessingEnabled}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          htmlFor="articleId"
                          className="form-label"
                        >
                          Article <span className="small text-primary">(Set Default Front)</span>
                        </label>
                          <AsyncSelect
                            aria-label='Article'
                            inputId="articleId"
                            cacheOptions
                            loadOptions={loadOptionsDebounced}
                            defaultOptions
                            onChange={(selectedOption: any) => {
                              setFieldValue('articleId', selectedOption.value)
                              setFieldValue('defaultFront', selectedOption.url)
                            }}
                            placeholder={values.articleId || 'Select Article'}
                            className={`${
                              ((errors.articleId) && touched.articleId)
                                ? 'is-invalid'
                                : ''
                            }`}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: (errors.articleId && touched.articleId) ? '#dc3545' : provided.borderColor,
                                '&:hover': {
                                  borderColor: (errors.articleId && touched.articleId) ? '#dc3545' : provided.borderColor
                                }
                              })
                            }}
                          />
                        <div
                          id="validationArticleIdFeedback"
                          className="invalid-feedback"
                        >
                          {errors.articleId}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border p-3 rounded">
                    <p className="fw-bold">Export Settings</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="exportOrientation"
                            className="form-label"
                          >
                            Export Orientation
                          </label>
                          <select
                            aria-label="Export Orientation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={String(values.exportOrientation)}
                            className={`form-select ${
                              errors.exportOrientation &&
                              touched.exportOrientation
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="exportOrientation"
                            name="exportOrientation"
                          >
                            <option value="">Select Export Orientation</option>
                            <option value={'portrait'}>Portrait</option>
                            <option value={'landscape'}>Landscape</option>
                          </select>
                          <div
                            id="validationExportOrientationFeedback"
                            className="invalid-feedback"
                          >
                            {errors.exportOrientation}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="exportSides"
                            className="form-label"
                          >
                            Export Sides
                          </label>
                          <select
                            aria-label="Export Sides"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={String(values.exportSides)}
                            className={`form-select ${
                              errors.exportSides &&
                              touched.exportSides
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="exportSides"
                            name="exportSides"
                          >
                            <option value="">Select Export Sides</option>
                            <option value={'both'}>Both</option>
                            <option value={'front'}>Front</option>
                            <option value={'back'}>Back</option>
                          </select>
                          <div
                            id="validationExportSidesFeedback"
                            className="invalid-feedback"
                          >
                            {errors.exportSides}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            htmlFor="eanBarcode"
                            className="form-label"
                          >
                            EAN Barcode <span className="small text-muted">(8, 13, 14)</span>
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.eanBarcode || ''}
                            type="text"
                            className={`form-control ${
                              errors.eanBarcode &&
                              touched.eanBarcode
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="eanBarcode"
                            name="eanBarcode"
                            placeholder=""
                            autoComplete="off"
                          />
                          <div
                            id="validationEanBarcodeFeedback"
                            className="invalid-feedback"
                          >
                            {errors.eanBarcode}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label
                            htmlFor="upcBarcode"
                            className="form-label"
                          >
                            UPC Barcode <span className="small text-muted">(upc-a, upc-e)</span>
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.upcBarcode || ''}
                            type="text"
                            className={`form-control ${
                              errors.upcBarcode &&
                              touched.upcBarcode
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="upcBarcode"
                            name="upcBarcode"
                            placeholder=""
                            autoComplete="off"
                          />
                          <div
                            id="validationUpcBarcodeFeedback"
                            className="invalid-feedback"
                          >
                            {errors.upcBarcode}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            htmlFor="supplierEmail"
                            className="form-label"
                          >
                            Supplier Email
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.supplierEmail || ''}
                            type="text"
                            className={`form-control ${
                              errors.supplierEmail &&
                              touched.supplierEmail
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="supplierEmail"
                            name="supplierEmail"
                            placeholder=""
                            autoComplete="off"
                          />
                          <div
                            id="validationSupplierEmailFeedback"
                            className="invalid-feedback"
                          >
                            {errors.supplierEmail}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            }

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CardSettingsEditor
