import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { StockProduct } from '../../types'
dayjs.extend(utc)

const SerialNumber = ({ stockProduct }: { stockProduct: StockProduct }) => {
  const { product } = stockProduct
  const productName = product.productDescription.name
  const merchantSku = product.merchantSku
  const jfsku = product.jfsku

  return (
    <tr>
      <td>{merchantSku}</td>
      <td>
        <Link title="View Product Details" className="text-decoration-none" to={`/inventory/${jfsku}`}>
          {productName}
        </Link>
      </td>
      <td>{stockProduct.serialNumber}</td>
    </tr>
  )
}

export default SerialNumber
