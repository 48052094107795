import * as userRoles from '../constants/userRoles'
import ProductService from '../services/api/ProductService'
import CompanyService from '../services/api/CompanyService'
import { Product, Role } from '../types'

const mapProducts = (products: Array<Product>) => {
  return products.map(product => ({
    value: {
      name: product.name,
      jfsku: product.jfsku,
      merchantSku: product.merchantSku
    },
    label: product.name
  }))
}

export const mapProductsWithIds = (products: Array<Product>) => {
  return products.map((product) => ({
    value: product.id,
    label: product.name
  }))
}

export const loadProductOptions = async (inputValue: string, page: number, perPage: number, token: string, role: Role, isAllowed: boolean, companyId?: string, mapWithIds = false) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token && companyId && isAllowed) {
    try {
      const res = await CompanyService.getCompanyProducts(companyId, token, perPage, page, inputValue, signal)
      return mapWithIds ? mapProductsWithIds(res.data.products) : mapProducts(res.data.products)
    } catch (error) {
      return mapProducts([])
    }
  }

  if (token && role === userRoles.ADMIN) {
    try {
      const res = await ProductService.getAllProducts(token, perPage, page, inputValue, signal)
      return mapWithIds ? mapProductsWithIds(res.data.products) : mapProducts(res.data.products)
    } catch (error) {
      return mapProducts([])
    }
  }

  return mapProducts([])
}
