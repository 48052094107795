import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Alert from '../../../components/Alert'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { verifyAuth } from '../../../store/reducers/authReducer'

const Callback = () => {
  const auth = useAppSelector((state) => state.auth.auth)
  const [message, setMessage] = useState('')
  const [isAlertVisible, setisAlertVisible] = useState(false)

  const dispatch = useAppDispatch()

  const useQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  const code = query.get('code')

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (code) {
      setisAlertVisible(false)
      dispatch(verifyAuth({ code, signal }))
    } else {
      setisAlertVisible(true)
      setMessage('The authorization code is missing from the callback url. Kindly try again.')
    }
    return () => {
      controller.abort()
    }
  }, [code])

  if (auth.accessToken) {
    return <Navigate to="/" />
  }
  return (
    <div className="container h-100 p-4">
      {isAlertVisible && <Alert type="danger" message={message} />}
      <div className="border border-light p-3 mb-4">
        <div className="d-flex align-items-center justify-content-center" style={{ height: '350px' }}>
          <div className="col-lg-5">
            <div className="card shadow-lg border-0 rounded-lg mt-5">
              <div className="card-header">
                <h3 className="text-center font-weight-light my-4">
                  Authenticating
                </h3>
              </div>
              <div className="card-body">
                <div className="d-grid gap-2 mx-auto">
                  <div className="text-center">
                    <div className="spinner-border text-danger" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Callback
