import React from 'react'
import { Product } from '../../../types'
import Placeholder from '../../../assets/images/placeholder.png'
import { useAppDispatch } from '../../../store/hooks'
import dayjs from 'dayjs'
import { setToast } from '../../../store/reducers/toastReducer'

type AdditionalArticleItemProps = {
  product: Product
  additionalArticles: Product[]
  addAdditionalArticle: Function
  removeAdditionalArticle: Function
}

const AdditionalArticleItem = ({ product, addAdditionalArticle, additionalArticles, removeAdditionalArticle }: AdditionalArticleItemProps) => {
  const dispatch = useAppDispatch()

  const handleSelection = () => {
    const availableStock = Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0)

    if (availableStock > 0) {
      if (additionalArticles.find((additionalArticle: Product) => (additionalArticle.id === product.id))) {
        removeAdditionalArticle(product)
      } else {
        addAdditionalArticle(product)
      }
    } else {
      const payload = {
        title: 'Out of Stock',
        message: `There is no available stock for ${product.name}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'warning'
      }
      dispatch(setToast(payload))
    }
  }

  return (
    <div
      className="col"
      key={product.jfsku}
    >
      <div
        onClick={() => handleSelection()}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleSelection()
          }
        }}
        role="button"
        tabIndex={0}
        aria-label={`Select ${product.name}`}
        className={`card h-100 product-card ${additionalArticles.find(additionalArticle => additionalArticle.id === product.id) ? 'selected' : ''}`}
      >
        {
          product && product.pictures && product?.pictures.length > 0
            ? (
              <img
                src={product.pictures[0].publicUrl}
                className="card-img-top"
                alt={product.name}
              />
              )
            : (<img src={Placeholder} alt="Placeholder thumbnail" style={{ objectFit: 'cover' }} className="card-img-top" />)
        }
        <div
          className="h-100 d-flex align-content-end flex-wrap"
        >
          <div className="position-relative d-none" title="Availability Date">
            <span className="badge rounded-pill text-bg-secondary position-absolute bottom-0 end-0 m-1">
              <i className="bi bi-clock"></i> 0 days
            </span>
          </div>
          <div className="card-body">
            <h6 className={`card-title ${Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0) > 0 ? '' : 'text-muted'}`}>
              {product.name}
            </h6>
          </div>
        </div>
        <ul className="list-group list-group-flush m-1">
          <li className={`list-group-item d-flex small justify-content-between align-items-center ${Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0) > 0 ? '' : 'text-muted'}`}>
            <span><span className="fw-bold">In Stock:</span> {product.stock?.stockLevel || 0}</span> - <span><span className="fw-bold">Available:</span> {Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0)}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AdditionalArticleItem
