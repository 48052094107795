import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL
const currentUser = localStorage.getItem('currentUser')

const token = currentUser ? JSON.parse(currentUser).token : null

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` })
  },
  timeout: 20000
})

export default {
  getCompanyInvoice (
    perPage: number,
    page: number,
    search: string,
    signal: AbortSignal,
    orderBy: string = '',
    filter: string = ''
  ) {
    const config : any = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    if (filter) {
      config.params[orderBy] = filter
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/invoices/', config)
  }
}
