import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PASSWORD_UPDATE_SUCCESS_MESSAGE } from '../../../constants/messages'
import AuthService from '../../../services/api/AuthService'
import { ApiAuthState } from '../../../types'

const initialState: ApiAuthState = {
  isLoading: false,
  error: null,
  currentUser: JSON.parse(String(localStorage.getItem('currentUser'))) || null,
  createdUser: null,
  message: null
}

export const loginUser = createAsyncThunk('api/auth/login', async ({ user, signal }: { user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await AuthService.loginUser(user, signal)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response.data)
  }
})

export const signUpUser = createAsyncThunk('api/auth/signup', async ({ user, companyId, signal }: { user: any, companyId: string | null, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await AuthService.signUpUser(user, companyId, signal)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response.data)
  }
})

export const forgotPassword = createAsyncThunk('api/auth/forgot-password', async ({ user, signal }: { user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await AuthService.forgotPassword(user, signal)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response.data)
  }
})

export const resetPassword = createAsyncThunk('api/auth/reset-password', async ({ user, token, signal }: { user: any, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await AuthService.resetPassword(user, token, signal)
    return res.data
  } catch (error: any) {
    return rejectWithValue(error?.response.data)
  }
})

const authSlice = createSlice({
  name: 'api/auth',
  initialState,
  reducers: {
    resetApiAuthError: (state) => {
      state.error = null
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
      localStorage.setItem('currentUser', JSON.stringify(action.payload))
    },
    resetCurrentUser: (state) => {
      state.currentUser = null
    },
    setCreatedUser: (state, action) => {
      state.createdUser = action.payload
    },
    resetCreatedUser: (state) => {
      state.createdUser = null
    },
    logoutCurrentUser: (state) => {
      localStorage.clear()
      state.currentUser = null
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user, token
        } = action.payload
        const currentUser = {
          ...user,
          token
        }
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
        state.currentUser = currentUser
        state.error = null
      })
      .addCase(loginUser.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.createdUser = user
        state.error = null
      })
      .addCase(signUpUser.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.message = user.message
        state.error = null
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.message = PASSWORD_UPDATE_SUCCESS_MESSAGE
        state.createdUser = user
        state.error = null
      })
      .addCase(resetPassword.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const {
  resetApiAuthError, setCurrentUser, resetCurrentUser,
  setCreatedUser, resetCreatedUser, logoutCurrentUser
} = authSlice.actions

const { reducer } = authSlice
export default reducer
