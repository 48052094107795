import axios from 'axios'

const baseURL = process.env.REACT_APP_DOTNET_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/v1/articles`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  createArticle (token: string, article: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/create', article, config)
  },
  editArticle (token: string, article: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put('/edit', article, config)
  },
  getAllArticles (token: string, perPage: number, offset: number, search: string, signal: AbortSignal) {
    const config = {
      params: {
        pageSize: perPage,
        offset,
        searchTerm: search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/articles', config)
  },
  getArticleById (token: string, articleId: string, signal: AbortSignal) {
    const config = {
      params: {
        id: articleId
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/${articleId}`, config)
  },
  getArticleCategories (token: string, perPage: number, offset: number, signal: AbortSignal) {
    const config = {
      params: {
        pageSize: perPage,
        offset
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/articleCategories', config)
  },
  createArticleCategory (token: string, articleCategory: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/createArticleCategory', articleCategory, config)
  },
  editArticleCategory (token: string, articleCategory: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put('/editArticleCategory', articleCategory, config)
  },
  getArticleVolumeBasedSalesPricesByArticleId (token: string, articleId: string, perPage: number, offset: number, signal: AbortSignal) {
    const config = {
      params: {
        articleId,
        pageSize: perPage,
        offset
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/articleVolumeBasedSalePricebyArticleId', config)
  },
  editArticleVolumeBasedSalesPrice (token: string, volumeBasedSalesPrice: object, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put('/editArticleVolumeBasedSalePrice', volumeBasedSalesPrice, config)
  },
  createArticleVolumeBasedSalesPrice (token: string, volumeBasedSalesPrice: object, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/createArticleVolumeBasedSalePrice', volumeBasedSalesPrice, config)
  },
  getBillOfMaterialbyArticleId (token: string, articleId: string, perPage: number, offset: number, signal: AbortSignal) {
    const config = {
      params: {
        articleId,
        pageSize: perPage,
        offset
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/articleBillOfMaterialbyArticleId', config)
  },
  getChildArticlesbyArticleId (token: string, articleId: string, perPage: number, offset: number, signal: AbortSignal) {
    const config = {
      params: {
        articleId,
        pageSize: perPage,
        offset
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/childArticlesbyArticleId', config)
  }
}
