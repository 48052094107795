import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CompanyUserGroupService from '../../../services/api/CompanyUserGroupService'
import { ApiCompanyUserGroupState, CompanyUserGroupEdit, UserCompanyUserGroup } from '../../../types'
import { COMPANY_USER_GROUP_CREATION_SUCCESS_MESSAGE, COMPANY_USER_GROUP_DELETION_MESSAGE, COMPANY_USER_GROUP_UPDATE_SUCCESS_MESSAGE, COMPANY_USER_GROUP_USERS_ADDITION_SUCCESS_MESSAGE } from '../../../constants/messages'

const initialState: ApiCompanyUserGroupState = {
  isLoading: false,
  isLoadingCompanyUserGroupUsers: false,
  error: null,
  companyUserGroup: null,
  companyUserGroups: [],
  companyUserGroupUsers: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  metadataCompanyUserGroupUsers: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getCompanyUserGroups = createAsyncThunk('api/company-user-groups/get', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupService.getCompanyUserGroups(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const getCompanyUserGroupById = createAsyncThunk('api/company-user-group/get', async ({ token, companyUserGroupId, signal }: { token: string, companyUserGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupService.getCompanyUserGroupById(token, companyUserGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyUserGroup = createAsyncThunk('api/company-user-group/add', async ({ token, companyUserGroup, signal }: { token: string, companyUserGroup: CompanyUserGroupEdit, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupService.addCompanyUserGroup(token, companyUserGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editCompanyUserGroupById = createAsyncThunk('api/company-user-group/edit', async ({ token, companyUserGroupId, companyUserGroup, signal }: { token: string, companyUserGroupId: string, companyUserGroup: CompanyUserGroupEdit, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupService.editCompanyUserGroupById(token, companyUserGroupId, companyUserGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteCompanyUserGroupById = createAsyncThunk('api/company-user-group/delete', async ({ token, companyUserGroupId, signal }: { token: string, companyUserGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupService.deleteCompanyUserGroupById(token, companyUserGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addUsersToCompanyUserGroup = createAsyncThunk('api/company-user-group/users/add', async ({ token, companyUserGroupId, userCompanyUserGroup, signal }: { token: string, companyUserGroupId: string, userCompanyUserGroup: UserCompanyUserGroup, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupService.addUsersToCompanyUserGroup(token, companyUserGroupId, userCompanyUserGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getUsersOfACompanyUserGroup = createAsyncThunk('api/company-user-group/users/get', async ({ token, companyUserGroupId, page, perPage, signal, search }: { token: string, companyUserGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string}, { rejectWithValue }) => {
  try {
    const res = await CompanyUserGroupService.getUsersOfACompanyUserGroup(token, perPage, page, companyUserGroupId, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const companyUserGroupSlice = createSlice({
  name: 'api/company-user-group',
  initialState,
  reducers: {
    resetCompanyUserGroupError: (state) => {
      state.error = null
    },
    resetCompanyUserGroupMessage: (state) => {
      state.message = null
    },
    resetCompanyUserGroupData: (state) => {
      state.error = null
      state.companyUserGroup = null
      state.companyUserGroups = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyUserGroups.pending, (state) => {
        state.companyUserGroups = []
        state.isLoading = true
      })
      .addCase(getCompanyUserGroups.fulfilled, (state, action) => {
        state.isLoading = false
        state.companyUserGroups = action.payload.companyUserGroups
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getCompanyUserGroups.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyUserGroupById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCompanyUserGroupById.fulfilled, (state, action) => {
        state.isLoading = false
        state.companyUserGroup = action.payload.companyUserGroup
        state.error = null
        state.message = null
      })
      .addCase(getCompanyUserGroupById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyUserGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCompanyUserGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.companyUserGroup = action.payload.companyUserGroup
        state.error = null
        state.message = COMPANY_USER_GROUP_CREATION_SUCCESS_MESSAGE
      })
      .addCase(addCompanyUserGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editCompanyUserGroupById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editCompanyUserGroupById.fulfilled, (state, action) => {
        state.isLoading = false
        state.companyUserGroup = action.payload.companyUserGroup
        state.error = null
        state.message = COMPANY_USER_GROUP_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(editCompanyUserGroupById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteCompanyUserGroupById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCompanyUserGroupById.fulfilled, (state, action) => {
        state.isLoading = false
        state.companyUserGroup = null
        state.error = null
        state.message = COMPANY_USER_GROUP_DELETION_MESSAGE
      })
      .addCase(deleteCompanyUserGroupById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addUsersToCompanyUserGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addUsersToCompanyUserGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = COMPANY_USER_GROUP_USERS_ADDITION_SUCCESS_MESSAGE
      })
      .addCase(addUsersToCompanyUserGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getUsersOfACompanyUserGroup.pending, (state) => {
        state.isLoadingCompanyUserGroupUsers = true
        state.companyUserGroupUsers = []
      })
      .addCase(getUsersOfACompanyUserGroup.fulfilled, (state, action) => {
        state.isLoadingCompanyUserGroupUsers = false
        state.error = null
        state.message = null
        state.metadataCompanyUserGroupUsers = action.payload.meta
        state.companyUserGroupUsers = action.payload.companyUserGroupUsers
      })
      .addCase(getUsersOfACompanyUserGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingCompanyUserGroupUsers = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCompanyUserGroupError, resetCompanyUserGroupMessage, resetCompanyUserGroupData } = companyUserGroupSlice.actions

const { reducer } = companyUserGroupSlice
export default reducer
