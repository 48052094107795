import React from 'react'
import defaultPrivacyPolicy from '../../constants/privacyPolicy.json'

const Privacy = ({ privacyPolicy = defaultPrivacyPolicy }: { privacyPolicy?: { title: string, sections: Array<{ title: string, content: string }>}}) => {
  return (
    <div>
      {
        privacyPolicy.sections.map((section, index) => (
          <div key={index}>
            <h2 className="fs-4">{section.title}</h2>
            <p>{section.content}</p>
          </div>
        ))
      }
    </div>
  )
}

export default Privacy
