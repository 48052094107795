import axios from 'axios'
import { ProductCategoryTag } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getProductCategoryTags (token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/product-category-tags', config)
  },
  deleteProductCategoryTagById (token: string, productCategoryTagId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/product-category-tags/${productCategoryTagId}`, config)
  },
  editProductCategoryTagById (token: string, productCategoryTagId: string, productCategoryTag: Partial<ProductCategoryTag>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/product-category-tags/${productCategoryTagId}`, { productCategoryTag }, config)
  }
}
