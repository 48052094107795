import React from 'react'
import { formatPrice } from '../../utils/formatPrice'
import { useAppSelector } from '../../store/hooks'
import { ShoppingCartProduct } from '../../types'
import ShoppingCartItem from './ShoppingCartItem'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'
import { getMinimumProductOrderQuantity } from '../../utils/getMinimumProductOrderQuantity'
import BundleConfigurator from './BundleConfigurator'
import { getTotalCartProducts } from '../../utils/getTotalCartProducts'

const ShoppingCart = () => {
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || getMinimumProductOrderQuantity((shoppingCartProduct.minimumOrderQuantity ?? 1), shoppingCartProduct.graduatedPrices) }))

  const calculateTotalPrice = (cartProducts: ShoppingCartProduct[]) => {
    const total = cartProducts.reduce((accumulator, currentValue) => accumulator + (calculateGraduatedPrice(currentValue.graduatedPrices, currentValue.quantity, currentValue.netRetailPrice.amount) * currentValue.quantity), 0)
    return total
  }

  return (
    <div>
      <div className="row justify-content-center m-0">
        <div className="col-lg-7 col-md-10 col-sm-11">
          <div className="horizontal-steps mt-4 mb-4 pb-5" id="tooltip-container">
            <div className="horizontal-steps-content">
              <div
                className={'step-item current'}
              >
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Your Cart
                </span>
              </div>
              <div className={'step-item'}>
                <span
                  data-bs-container="#tooltip-container"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-title=""
                >
                  Shipping Details
                </span>
              </div>
              <div
                className={'step-item'}
              >
                <span>Payment</span>
              </div>
            </div>
            <div className="process-line" style={{ width: '0%' }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-8 mb-4">
          <div className="table-responsive border border-bottom-0 rounded">
            <table className="table align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col">Products</th>
                  <th scope="col">Quantity</th>
                  <th scope="col" className="text-nowrap text-end">Total Price</th>
                  <th scope="col" className="text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  shoppingCartProductsWithQuantity.length > 0
                    ? (
                        shoppingCartProductsWithQuantity.map(shoppingCartProduct => (
                          <ShoppingCartItem key={`${shoppingCartProduct.spliteId ?? shoppingCartProduct.id}-shopping-cart-item`} shoppingCartProduct={shoppingCartProduct} />
                        ))
                      )
                    : (
                      <tr className="text-center">
                        <td colSpan={4}>No item has been added to your cart.</td>
                      </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="col">
          <div className="border rounded p-2">
            <div className="row">
              <div className="d-flex justify-content-between mt-2">
                <h6 className="fw-bold">Cart Summary</h6>
                <p>{`${getTotalCartProducts(shoppingCartProductsWithQuantity)} ${getTotalCartProducts(shoppingCartProductsWithQuantity) === 1 ? 'item' : 'items'}`}</p>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="fw-semibold text-muted">Subtotal</h6>
                <p className="fw-bold">{formatPrice('EUR', navigator.language).format(calculateTotalPrice(shoppingCartProductsWithQuantity))}</p>
              </div>
              <div className="d-flex justify-content-between d-none">
                <h6 className="fw-semibold text-muted">Shipping</h6>
                <p className="fw-bold">{formatPrice('EUR', navigator.language).format(0)}</p>
              </div>
            </div>
            <div className="row border-top gx-3">
              <div className="d-flex justify-content-between my-2">
                <h6 className="fw-semibold text-muted">Total</h6>
                <p className="fw-bold">
                  <span className="text-muted me-1 fw-lighter small">(exc. VAT)</span>
                  {formatPrice('EUR', navigator.language).format(calculateTotalPrice(shoppingCartProductsWithQuantity))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BundleConfigurator />
    </div>
  )
}

export default ShoppingCart
