import React, { ChangeEvent, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import parse from 'html-react-parser'
import minMax from 'dayjs/plugin/minMax'

import {
  CarrierCode, shipping, Item, Order, ShippingMethod,
  StockProduct, OrderStatus as ShipmentOrderStatus, Module, Permission
} from '../../../types'
import { OrderStatus, OrderStatusDHL } from '../../../constants/orderStatus'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { DHL_TRACKING_URL, DPD_TRACKING_URL } from '../../../constants/urls'
import { UNAUTHORIZED_401_MESSAGE, FORBIDDEN_403_MESSAGE, ORDER_DUPLICATE_MESSAGE, ORDER_VISIBILITY_CHANGE_SUCCESS_MESSAGE } from '../../../constants/messages'
import Spinner from '../../loaders/Spinner'
import TrackingProgressbar from '../../TrackingProgressbar'
import { setToast } from '../../../store/reducers/toastReducer'
import { carrierLogos } from '../../../constants/carrierLogos'
import ShipmentService from '../../../services/api/ShipmentService'
import { customShippingMethods } from '../../../constants/customShippingMethods'
import SkeletonTableRow from '../../loaders/skeleton/SkeletonTableRow'
import Pagination from '../../Pagination'
import StockProductService from '../../../services/api/dotnet/StockProductService'
import {
  resetPendingOrderError,
  resetPendingOrderMessage
} from '../../../store/reducers/api/pendingOrderQueueReducer'
import Progress from '../../loaders/Progress'
import { dismissModal } from '../../../utils/dismissModal'
import * as userRoles from '../../../constants/userRoles'
import * as appModules from '../../../constants/appModules'
import SerialNumber from '../SerialNumber'
import hasPermission from '../../../utils/checkPermissions'
import { READ } from '../../../constants/permissions'
import PendingOrderDuplicateEditor from '../../PendingOrders/Duplicate/PendingOrderDuplicateEditor'
import { addLeadingZero } from '../../../utils/addLeadingZero'
import { changeOrderVisibility, resetOrderMessage } from '../../../store/reducers/api/orderReducer'
dayjs.extend(minMax)
dayjs.extend(localizedFormat)

const TableRow = ({
  type,
  order,
  colSpan,
  selectedOutboundIds,
  setSelectedOutboundIds,
  selectedOrderId,
  setSelectedOrderId
}: {
  type: string
  order: Order
  index: number
  colSpan: number
  selectedOutboundIds: Array<string>
  setSelectedOutboundIds: Function
  selectedOrderId: string | null
  setSelectedOrderId: React.Dispatch<React.SetStateAction<string | null>>
}) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const shippingMethods = useAppSelector((state) => state.apiShippingMethod.jtlShippingMethods)
  const isLoadingPendingOrderQueue = useAppSelector((state) => state.apiPendingOrderQueue.isLoading)
  const pendingOrderQueueError = useAppSelector((state) => state.apiPendingOrderQueue.error)
  const pendingOrderQueueMessage = useAppSelector((state) => state.apiPendingOrderQueue.message)
  const fulfillmentOrderMessage = useAppSelector((state) => state.dotnetApiFulfillmentOrders.message)
  const fulfillmentOrderError = useAppSelector((state) => state.dotnetApiFulfillmentOrders.error)
  const isLoadingOrderVisibility = useAppSelector((state) => state.apiOutbound.isLoadingOrderVisibility)
  const message = useAppSelector((state) => state.apiOutbound.message)
  interface ShipmentData {
    id: string
    trackingId: string
    statusCode: string
    data: shipping.Shipment[]
  }
  const [shipmentData, setShipmentData] = useState<Array<ShipmentData>>([])
  const [shipmentStatusCode, setShipmentStatusCode] = useState<string | null>(null)
  const [isLoadingTrackingURLs, setIsLoadingTrackingURLs] = useState(false)
  const [trackingURLs, setTrackingURLs] = useState<Array<string>>([])
  const [carrierCode, setCarrierCode] = useState<CarrierCode | ''>('')
  const [isLoadingShipment, setIsLoadingShipment] = useState(true)
  const [isLoadingStockProducts, setIsLoadingStockProducts] = useState(true)
  const [stockProducts, setStockProducts] = useState<StockProduct[]>([])
  const [metadata, setMetadata] = useState({
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  })

  const [perPage, setPerPage] = useState(5)
  const [page, setPage] = useState(1)

  const token = currentUser?.token
  const role = currentUser?.role || userRoles.USER
  const userId = currentUser?.id
  const companyOwnerId = currentUser?.company?.owner?.id

  const isOwner = companyOwnerId && userId === companyOwnerId
  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []

  const dispatch = useAppDispatch()

  const handleToggleVisibility = () => {
    dispatch(changeOrderVisibility({ token, isVisible: !order.isVisible, orderId: order.id, signal: new AbortController().signal }))
  }

  const isAllowed = (module: Module, permission: Permission = READ) => {
    return isOwner || hasPermission(module, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const getStockProductsByMerchantOutboundNumber = async (merchantOutboundNumber: string, perPage: number, page: number, signal: AbortSignal) => {
    try {
      const response = await StockProductService.getOrderStockProductsByMerchantOutboundNumber(String(token), merchantOutboundNumber, perPage, page, signal)
      setMetadata(response.data.meta)
      setStockProducts(response.data.stockProducts)
      setIsLoadingStockProducts(false)
    } catch (error: any) {
      if (error.message !== 'canceled') {
        setIsLoadingStockProducts(false)
      }
    }
  }

  const updateShipmentStatusCode = (shipments: ShipmentData[]) => {
    const mappedOrderShipmentsStatusCodes = shipments.map(shipment => shipment.statusCode)
    if (mappedOrderShipmentsStatusCodes.every(statusCode => statusCode === mappedOrderShipmentsStatusCodes[0])) {
      setShipmentStatusCode(mappedOrderShipmentsStatusCodes[0])
    } else {
      const shipmentStatuses = (shipmentData.length > shipments.length ? shipmentData : shipments)
        .flatMap((data) => data.statusCode)
      if (shipmentStatuses.includes('pre-transit')) {
        setShipmentStatusCode('pre-transit')
      } else {
        setShipmentStatusCode('transit')
      }
    }
  }

  const getShippingInfo = async (
    signal: AbortSignal,
    getTrackingStatus?: boolean
  ) => {
    setIsLoadingTrackingURLs(true)
    setShipmentData(order.shipments)
    try {
      const orderShippingIdsArray = (order.trackingId?.split(',') ?? []).map(trackingId => trackingId.trim())
      const shippingMethodId = order.shippingMethodId
      const shippingMethod = shippingMethods.concat(customShippingMethods).find((shippingMethod: ShippingMethod) => shippingMethod.shippingMethodId === shippingMethodId)

      setCarrierCode((shippingMethod?.carrierCode) || (shippingMethod?.carrierName || ''))

      if (shippingMethod && (shippingMethod?.carrierCode === 'DHL' || shippingMethod?.carrierName === 'DHL')) {
        if (getTrackingStatus) {
          updateShipmentStatusCode(order.shipments)
          const mappedOrderShipmentsTrackingIds = order.shipments.map(shipment => shipment.trackingId)

          const promises = orderShippingIdsArray
            .filter(trackingId => !mappedOrderShipmentsTrackingIds.includes(trackingId))
            .map(trackingId => ShipmentService.getShipmentById(String(currentUser?.token), trackingId.split(' ').join(''), signal))

          Promise.all(promises)
            .then(results => {
              const resultsDataShipments: ShipmentData[] = results.map(result => result.data.shipment)

              const orderTrackingIds = order.shipments.map(data => data.trackingId)

              const newShipmentData = resultsDataShipments
                .filter(resultsDataShipment => !orderTrackingIds.includes(resultsDataShipment.trackingId))

              setShipmentData((prevState) => {
                updateShipmentStatusCode([...prevState, ...newShipmentData])
                return [...prevState, ...newShipmentData]
              })
              setIsLoadingShipment(false)
            })
            .catch((error: any) => {
              if (error.message !== 'canceled') {
                setIsLoadingShipment(false)
              }
            })

          if (orderShippingIdsArray.length === mappedOrderShipmentsTrackingIds.length) {
            setIsLoadingShipment(false)
          }
        }
        const trackingUrls = orderShippingIdsArray.map(trackingId => `${DHL_TRACKING_URL}${trackingId}`)
        setTrackingURLs(trackingUrls)
      }
      if (shippingMethod && (shippingMethod?.carrierCode === 'DPD' || shippingMethod?.carrierName === 'DPD')) {
        const trackingUrls = orderShippingIdsArray.map(trackingId => `${DPD_TRACKING_URL}${trackingId}`)
        setTrackingURLs(trackingUrls)
      }
      if ((shippingMethod && (shippingMethod?.carrierCode !== 'DHL' && shippingMethod?.carrierName !== 'DHL')) || !shippingMethod) {
        setIsLoadingShipment(false)
      }
    } catch (error: any) {
      if (error && error.message === UNAUTHORIZED_401_MESSAGE) {
        const payload = {
          title: 'Session Expired',
          message: 'Refresh your session',
          isVisible: true,
          timestamp: dayjs().format('LT')
        }
        dispatch(setToast(payload))
      }
      if (error && error.message === FORBIDDEN_403_MESSAGE) {
        const payload = {
          title: 'Verification Pending',
          message: 'Please verify your email address in the profile section',
          type: 'danger',
          isVisible: true,
          timestamp: dayjs().format('LT')
        }
        dispatch(setToast(payload))
      }
    } finally {
      setIsLoadingTrackingURLs(false)
    }
  }

  const billOfMaterials = order.items.filter((item: Item) => item.itemType === 'BillOfMaterials').sort((a: Item, b: Item) => b.quantity - a.quantity)

  const groupByBillOfMaterials = order.items
    .filter((item: Item) => item.itemType === 'Product')
    .reduce((group: any, item: any) => {
      const { billOfMaterialsId = 'product' } = item
      group[billOfMaterialsId] = group[billOfMaterialsId] ?? []
      group[billOfMaterialsId].push(item)
      return group
    }, {})

  const handleSelectedOutboundIdsChange = (outboundId: string, orderStatus: ShipmentOrderStatus) => {
    if (orderStatus === 'Shipped') {
      const payload = {
        title: 'Info',
        message: `The order for '${order.shippingAddress?.company || (`${order.shippingAddress.firstname || ''} ${order.shippingAddress.lastname || ''}`)}' has already been shipped`,
        type: 'info',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    } else {
      setSelectedOutboundIds((prevActiveOutboundIds: Array<string>) => {
        if (prevActiveOutboundIds.includes(outboundId)) {
          return prevActiveOutboundIds.filter((activeOutboundId) => activeOutboundId !== outboundId)
        } else {
          return [...prevActiveOutboundIds, outboundId]
        }
      })
    }
  }

  const getCreatedAtDate = (date: Date): string => {
    if (date) {
      return dayjs(date).format('LL')
    }
    return '-'
  }

  const getShippedDate = (shipmentData: ShipmentData[]): string => {
    if (order.status === 'Shipped') {
      if (shipmentData.length > 0) {
        const shippedKeywords = ['onward transport', "Shipment arrived in the recipient's region", 'letter center']
        const allEvents = shipmentData.flatMap((data) => data.data[0].events)
        let foundEvents: shipping.Event[] = []
        const foundEventsWithCenterOfOrigin = allEvents.filter((event) => event.statusCode === 'transit' && event.description?.includes('center of origin'))
        const foundEventsWithKeywords = allEvents.filter((event) => event.statusCode === 'transit' && shippedKeywords.some(keyword => event.description?.includes(keyword)))

        if (foundEventsWithCenterOfOrigin.length > 0) {
          foundEvents = foundEventsWithCenterOfOrigin
        } else {
          foundEvents = foundEventsWithKeywords
        }

        if (foundEvents.length > 0) {
          const shippedDates = foundEvents.map(foundEvent => dayjs(foundEvent.timestamp))
          const latestDate = dayjs.max(shippedDates)

          return dayjs(latestDate).format('LL')
        }
      }
    }
    return '-'
  }

  const getDeliveryDate = (shipmentData: ShipmentData[]): string => {
    if (order.status === 'Shipped') {
      if (shipmentData.length > 0) {
        const allEvents = shipmentData.flatMap((data) => data.data[0].events)
        const foundEvents = allEvents.filter((event) => event.statusCode === 'delivered')

        if (foundEvents.length > 0) {
          const shippedDates = foundEvents.map(foundEvent => dayjs(foundEvent.timestamp))
          const latestDate = dayjs.max(shippedDates)

          return dayjs(latestDate).format('LL')
        }
      }
    }
    return '-'
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (order.status === 'Shipped') {
      getShippingInfo(signal, true)
    } else {
      setIsLoadingShipment(false)
    }
    return () => {
      controller.abort()
    }
  }, [shippingMethods])

  useEffect(() => {
    if (pendingOrderQueueError && pendingOrderQueueError.errors) {
      const payload = {
        title: 'Error',
        message: pendingOrderQueueError.errors.message,
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
      dispatch(resetPendingOrderError())
    }
  }, [pendingOrderQueueError])

  useEffect(() => {
    if (pendingOrderQueueMessage === ORDER_DUPLICATE_MESSAGE) {
      const payload = {
        title: 'Success',
        message: pendingOrderQueueMessage,
        type: 'success',
        isVisible: true,
        timestamp: dayjs().format('LT'),
        delay: 30000
      }
      dispatch(setToast(payload))
      dispatch(resetPendingOrderMessage())
      dismissModal(`duplicationConfirmationModal-${order.outboundId}`)
    }
  }, [pendingOrderQueueMessage])

  useEffect(() => {
    if (fulfillmentOrderError && fulfillmentOrderError.errors) {
      const payload = {
        title: 'Error',
        message: fulfillmentOrderError.errors.message,
        type: 'danger',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }
  }, [fulfillmentOrderError])

  useEffect(() => {
    if (fulfillmentOrderMessage) {
      const payload = {
        title: 'Success',
        message: fulfillmentOrderMessage,
        type: 'success',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
    }
  }, [fulfillmentOrderMessage])

  useEffect(() => {
    if (message && message === ORDER_VISIBILITY_CHANGE_SUCCESS_MESSAGE) {
      const payload = {
        title: 'Success',
        message,
        type: 'success',
        isVisible: true,
        timestamp: dayjs().format('LT')
      }
      dispatch(setToast(payload))
      dispatch(resetOrderMessage())
    }
  }, [message])

  return (
    <>
      <tr>
        <th scope="row">
          <input
            type="checkbox"
            aria-label="Checkbox for orders"
            name={order.outboundId}
            id={order.outboundId}
            checked={selectedOutboundIds.includes(order.outboundId)}
            onChange={() => {
              handleSelectedOutboundIdsChange(order.outboundId, order.status)
            }}
          />
        </th>
        <td className="col-3">
          {order.shippingAddress?.company || (`${order.shippingAddress.firstname || ''} ${order.shippingAddress.lastname || ''}`)}
        </td>
        <td className="col-1">
          <span
            tabIndex={0}
            className="shipping-info-details link-primary text-decoration-none user-select-none text-nowrap"
            data-bs-toggle="collapse"
            data-bs-target={`#${type}-${order.outboundId}`}
            role="button"
            aria-expanded="false"
            aria-controls={`${type}-${order.outboundId}`}
            onClick={(event: React.MouseEvent) => {
              if (isAllowed(appModules.PRODUCTS)) {
                const controller = new AbortController()
                const signal = controller.signal
                if (order && event?.currentTarget.ariaExpanded === 'true') {
                  event?.currentTarget.classList.add('show')
                  getStockProductsByMerchantOutboundNumber(order.merchantOutboundNumber, perPage, page, signal)
                } else {
                  event?.currentTarget.classList.remove('show')
                  controller.abort()
                }
              }
            }}
          >
            <span className="accordion-button-text me-1"></span>order details
          </span>
        </td>
        <td className="col-1">
          {
            isLoadingShipment
              ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border spinner-border-sm text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                )
              : (
                <span className={`badge text-bg-${(shipmentStatusCode && OrderStatusDHL[shipmentStatusCode].colour) || OrderStatus[order.status].colour}`}>
                  {(shipmentStatusCode && OrderStatusDHL[shipmentStatusCode].text) || OrderStatus[order.status].text}
                </span>
                )
          }
        </td>
        <td className="col-2">{getShippedDate(shipmentData)}
        </td>
        <td className="col-1">{getDeliveryDate(shipmentData)}</td>
        <td className="col-2">{order.createdByFullName || '-'}</td>
        <td className="text-center pt-3 col-1">
          <span className="badge text-bg-secondary">
            {order.items.filter((item: Item) => item.itemType === 'BillOfMaterials').reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0) || Math.max(...order.items.map(item => item.quantity))}
          </span>
        </td>
        <td className="text-center col-1">
          <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
            <button
              className="btn btn-outline-dark btn-round me-2"
              type="button"
              title="Duplicate Order"
              data-bs-toggle="modal"
              data-bs-target={`#duplicationConfirmationModal-${order.outboundId}`}
              disabled={!order.attributes.find(item => item.key === 'order_id' || item.key === 'FFN-Externe-Nummer')}
            >
              <i className="fas fa-regular fa-clone text-lg"></i>
            </button>
            <div className="dropdown">
              <button
                className="btn btn-outline-dark btn-round"
                type="button"
                disabled={role !== userRoles.ADMIN && (order.status !== 'Shipped' && trackingURLs.length === 0)}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  setSelectedOrderId(order.id)
                }}
              >
                <i className={isLoadingOrderVisibility && selectedOrderId === order.id ? 'bi bi-hourglass-split' : 'bi bi-three-dots-vertical'}></i>
              </button>
              <ul className="dropdown-menu">
                {
                  role === userRoles.ADMIN && (
                    <li className="dropdown-item  cursor-pointer border-bottom"
                      onClick={() => {
                        if (!isLoadingOrderVisibility) {
                          handleToggleVisibility()
                        }
                      }}
                    >
                      <div
                        className="form-check"
                      >
                        <input
                          className="form-check-input text-primary"
                          id="changeOrderVisibility"
                          type="checkbox"
                          checked={order.isVisible}
                          disabled={isLoadingOrderVisibility}
                          onChange={() => {

                          }}
                        />
                        <label className="form-check-label" htmlFor="changeOrderVisibility">
                          Visible
                        </label>
                      </div>
                    </li>
                  )
                }
                {
                  isLoadingTrackingURLs
                    ? (<Spinner />)
                    : (
                        trackingURLs.length > 0
                          ? trackingURLs.map((trackingURL, index) => (
                              <li key={index}>
                                <a
                                  className="dropdown-item"
                                  href={trackingURL}
                                  role="button"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <i className="fa-solid fa-location-crosshairs me-1"></i>
                                  Track {index + 1}
                                </a>
                              </li>
                          ))
                          : <li><span className="dropdown-item">Tracking link not available</span></li>
                      )
                }
              </ul>
            </div>
            {/* Duplicate Order Modal */}
            <div className="modal fade" id={`duplicationConfirmationModal-${order.outboundId}`} tabIndex={-1} aria-labelledby={`duplicationConfirmationModalLabel-${order.outboundId}`} aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id={`duplicationConfirmationModalLabel-${order.outboundId}`}>Duplicate Order</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  {isLoadingPendingOrderQueue && <Progress />}
                  <div className="modal-body text-start">
                    <p>
                      {'Are you sure you want to duplicate the order for '}
                      <span className="fw-bold">
                        {`'${order.shippingAddress?.company || (`${order.shippingAddress.firstname || ''} ${order.shippingAddress.lastname || ''}`)}'`}
                      </span>
                      {'?'}
                    </p>
                    <PendingOrderDuplicateEditor isLoading={isLoadingPendingOrderQueue} order={order} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr className="collapse" id={`${type}-${order.outboundId}`}>
        <td colSpan={colSpan} className="table-secondary">
          <TrackingProgressbar orderStatus={order.status} shippedOrderStatus={shipmentStatusCode ?? ''} />
          <div className="row py-0 px-1 m-0">
            <div className="col-lg-8">
              <div className="col-lg-12">
                <div className="card mb-2">
                  <div className="card-body">
                    <h4 className="header-title mb-2">
                      Order Details
                    </h4>
                    <div className="table-responsive">
                      {
                        billOfMaterials.map((item: Item) => (
                          <div key={item.outboundItemId}>
                            <h5 className="header-title mt-3 font-weight-bold">{item.name}</h5>
                            <table className="table mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th>Item</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  groupByBillOfMaterials[item.outboundItemId].map((item: Item, index: number) => (
                                    <tr key={index}>
                                      {
                                        <>
                                          <td>{item.name}</td>
                                          <td>{item.quantity}</td>
                                        </>
                                      }
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        ))
                      }
                      {
                        groupByBillOfMaterials?.product && (
                          <>
                            <h5 className="header-title mt-2 font-weight-bold">-</h5>
                            <table className="table mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th>Item</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  groupByBillOfMaterials.product.map((item: Item) => (
                                    <tr key={item.outboundItemId}>
                                      {
                                        <>
                                          <td>{item.name}</td>
                                          <td>{item.quantity}</td>
                                        </>
                                      }
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="col-lg-12">
                  <div className="card mb-2">
                    <div className="card-body">
                      <h5 className="header-title mb-3">Note</h5>
                      <p>{order.internalNote ?? 'No note available'}</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card mb-2">
                    <div className="card-body">
                      <h5 className="header-title mb-3">Shipment Status</h5>
                      {
                        (carrierCode === 'DHL')
                          ? (
                            <>
                              <p>
                                {
                                  shipmentStatusCode
                                    ? (<span className="text-capitalize fw-bold text-primary">{shipmentStatusCode}</span>)
                                    : ('We are expecting your shipment data soon.')
                                }
                              </p>
                              <h5 className="">Shipment Updates</h5>
                              {
                                shipmentData.map((shipment, index) => {
                                  return (
                                    <ul className="list-group mb-4" key={shipment.trackingId}>
                                      <li className="list-group-item fw-semibold text-primary">Shipment {index + 1}</li>
                                      {
                                        shipment.data[0]?.events.map((event: shipping.Event, index: number) => {
                                          const addressLocality = event?.location?.address?.addressLocality
                                          return (
                                            <li key={index} className="list-group-item">
                                              <p>
                                                <span className="small">
                                                  {dayjs(event.timestamp).format('LLL')}
                                                </span><br/>
                                                <span className="text-capitalize">
                                                  <b>{event.statusCode}</b></span> - {parse((event?.remark || event?.description) || 'No status')} <span className="text-danger">{addressLocality && `- ${addressLocality}`}
                                                </span>
                                              </p>
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  )
                                })
                              }
                            </>
                            )
                          : (
                            <p>
                              Delivery status is not available for {carrierCode} shipments. In case of a question, please reach out to <a href={`mailto:${process.env.REACT_APP_BLT_SUPPORT_EMAIL || ''}`}>{process.env.REACT_APP_BLT_SUPPORT_EMAIL || ''}</a>
                            </p>
                            )
                      }
                    </div>
                  </div>
                </div>

                {isAllowed(appModules.PRODUCTS) && <div className="col-lg-12 mb-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="navbar navbar-expand mt-2">
                        <p className="h5">
                          <i className="bi bi-archive me-1"></i> Serial Numbers <br />
                        </p>
                      </div>
                      <div className="table-responsive mt-1">
                        <table className="table table-bordered table-centered mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>SKU</th>
                              <th>Name</th>
                              <th>Serial Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              isLoadingStockProducts
                                ? (
                                    Array.from(Array(perPage).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={3} actionQuantity={0} />)
                                  )
                                : stockProducts.length > 0
                                  ? (
                                      stockProducts.map((stockProduct) => (
                                      <SerialNumber stockProduct={stockProduct} key={stockProduct.id}/>
                                      ))
                                    )
                                  : (
                                    <tr>
                                      <td colSpan={3} className="text-center">
                                        No serial numbers available yet
                                      </td>
                                    </tr>
                                    )
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-2">
                        <Pagination
                          isLoading={isLoadingStockProducts}
                          metadata={{
                            limit: metadata.perPage,
                            total: metadata.total,
                            offset: ((metadata.page - 1) * (metadata.perPage))
                          }}
                          page={page}
                          perPage={perPage}
                          perPageOptions={[5, 10, 25, 50]}
                          handlePageChange={handlePageChange}
                          handleShowEntries={handleShowEntries}
                          module={`StockProducts-${order.outboundId}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="col-lg-12">
                <div className="card mb-2">
                  <div className="card-body">
                    <h5 className="header-title mb-3">Shipping Information</h5>
                    <div className="row">
                      <div className="col">
                        <h6>
                          {(`${(order.shippingAddress.firstname || '') || (order.shippingAddress?.company || '')} ${order.shippingAddress.lastname || ''}`)}
                        </h6>

                        <address className="mb-0 font-14 address-lg">
                          {order.shippingAddress.street}<br/>
                          {`${order.shippingAddress.city}, ${order.shippingAddress.country} ${order.shippingAddress.zip}`}<br/>
                          <abbr title="Email">Email: {order.shippingAddress?.email ? <a href={`mailto:${order.shippingAddress?.email}`}>{order.shippingAddress?.email}</a> : 'Email N/A'}<br/></abbr>
                        </address>
                      </div>
                      <div className="col text-center">
                        {carrierCode && <img className="img-thumbnail" src={carrierLogos[carrierCode]} alt="Carrier Logo" />}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-2">
                        {
                          isLoadingTrackingURLs
                            ? (<Spinner />)
                            : (order.status === 'Shipped' && trackingURLs.length > 0
                                ? (
                                  <div className="list-group">
                                    {
                                      trackingURLs.map((trackingURL: string, index: number) => (
                                        <div key={index} className="p-0 border-0 mb-2">
                                          <span className="me-1 font-monospace small">Shipment {trackingURLs.length >= 10 ? addLeadingZero(index + 1) : (index + 1)}:</span>
                                          <a href={trackingURL} rel="noreferrer" target="_blank" >
                                            <span className="me-2 small">View Tracking</span>
                                          </a>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              navigator.clipboard.writeText(trackingURL)
                                            }}
                                            className="btn btn-outline-secondary btn-sm"
                                          >
                                            <i className="fa fa-clone" title="copy to clipboard"></i>
                                          </button>
                                        </div>
                                      ))
                                    }
                                    </div>
                                  )
                                : (null))
                            }
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card mb-2">
                  <div className="card-body">
                    <h5 className="header-title mb-3">Order Information</h5>
                    <ul className="list-group">
                      <li className="list-group-item"><span className="fw-bold">Company name: </span>{order.company?.name ?? '---'}</li>
                      <li className="list-group-item">
                        <span className="fw-bold">Transaction ID: </span>
                        {
                          order.attributes?.find(attr => attr.key === 'order_id')?.value ?? '---'
                        }
                      </li>
                      <li className="list-group-item"><span className="fw-bold">Order ID:  </span>{order.outboundId ?? '---'}</li>
                      <li className="list-group-item"><span className="fw-bold">Date created: </span>{getCreatedAtDate(order.createdAtByUser || order.createdAt)}</li>
                      <li className="list-group-item"><span className="fw-bold">Date commissioned: </span>{order.deliveryDate ? dayjs(order.deliveryDate).format('LL') : '-'}</li>
                      <li className="list-group-item"><span className="fw-bold">Date shipped: </span>{getShippedDate(shipmentData)}</li>
                      <li className="list-group-item"><span className="fw-bold">Date delivered: </span>{getDeliveryDate(shipmentData)}</li>
                      <li className="list-group-item"><span className="fw-bold">Created by: </span>{order.createdByFullName || '-'}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

export default TableRow
