import { countriesObject } from './countries'

export const calculateShippingCost = (destinationCountry: string, totalBundles: number) => {
  const countryObject = countriesObject
    .find(country => (country.country.toUpperCase() === destinationCountry.toUpperCase() ||
    country.alpha2Code.toUpperCase() === destinationCountry.toUpperCase() ||
    country.alpha3Code.toUpperCase() === destinationCountry.toUpperCase() ||
    country.countryGerman.toUpperCase() === destinationCountry.toUpperCase()))

  if (!countryObject || !countryObject.shipping) {
    return 0
  }

  const { baseFee, perBundle } = countryObject.shipping
  let shippingCost = baseFee

  if (totalBundles > 1) {
    shippingCost += totalBundles * perBundle
  }

  return shippingCost
}
