import React from 'react'
import { string, object } from 'yup'
import { Formik } from 'formik'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { CostCenter } from '../../types'
import { resetCompanyError } from '../../store/reducers/api/companyReducer'

const CostCenterEditor = ({ id, initialCostCenter, save }: { id: string, initialCostCenter: Partial<CostCenter>, save: any }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isUpdating = useAppSelector((state) => state.apiCostCenter.isLoading)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const error = useAppSelector((state) => state.apiCompany.error)

  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const costCenterSchema = object({
    center: string().label('Center').required()
  })

  const saveCostCenter = (id: string, costCenter: any, signal: AbortSignal) => {
    dispatch(save({ id, token, costCenter, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={costCenterSchema}
        enableReinitialize
        initialValues={{
          ...initialCostCenter
        }}
        onSubmit={(
          { center },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const costCenter = {
            center
          }
          if (token && id) {
            saveCostCenter(id, costCenter, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="center"
                    className="form-label"
                  >
                    Center
                  </label>
                  <input
                    onChange={(event) => {
                      handleChange(event)
                      dispatch(resetCompanyError())
                    }}
                    onBlur={handleBlur}
                    value={values.center}
                    type="text"
                    className={`form-control ${
                      (errors.center &&
                      touched.center &&
                      errors.center) || error?.errors?.message
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="center"
                    name="center"
                    placeholder=""
                    maxLength={32}
                  />
                  <div
                    id="validationCostCenterFeedback"
                    className="invalid-feedback"
                  >
                    {errors.center || error?.errors?.message}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isUpdating || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CostCenterEditor
