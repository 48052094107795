import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  ADDRESS_ADDITION_SUCCESS_MESSAGE,
  ADDRESS_UPDATE_SUCCESS_MESSAGE, COMPANY_ASSIGNMENT_SUCCESS_MESSAGE, EMAIL_VERIFICATION_CONFIRMATION,
  PASSWORD_CHANGE_CONFIRMATION, USER_ACTIVE_STATE_UPDATE_SUCCESS_MESSAGE,
  USER_CREATION_SUCCESS_MESSAGE,
  USER_DELETION_SUCCESS_MESSAGE,
  USER_EMAIL_VERIFICATION_STATUS_UPDATE_SUCCESS_MESSAGE,
  USER_PASSWORD_RESET_SUCCESS_MESSAGE, USER_ROLE_UPDATE_SUCCESS_MESSAGE,
  USER_UPDATE_SUCCESS_MESSAGE
} from '../../../constants/messages'
import UserService from '../../../services/api/UserService'
import { UserState } from '../../../types'
import * as statusCodes from '../../../constants/statusCodes'

const initialState: UserState = {
  isLoading: false,
  error: null,
  user: null,
  userReset: null,
  users: [],
  areAllPagesForUsers: false,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  message: '',
  address: null,
  addresses: [],
  addressesMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const updateUserById = createAsyncThunk('api/user/update', async ({ id, token, user, signal }: { id: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUserById(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const sendVerifyCode = createAsyncThunk('api/user/getCode', async ({ id, token, user, signal } : { id: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.sendVerifyCode(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const verifyEmail = createAsyncThunk('api/user/verifyEmail', async ({ id, token, user, signal } : { id: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.verifyEmail(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUserAddress = createAsyncThunk('api/user/postAddress', async ({ id, token, address, signal } : { id: string, token: string, address: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUserAddressById(id, token, address, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getUserAddresses = createAsyncThunk('api/user/getAddresses', async ({ id, token, perPage, page, signal, search, filter } : { id: string, token: string, perPage: number, page: number, signal: AbortSignal, search?: string, filter?: string }, { rejectWithValue }) => {
  try {
    const res = await UserService.getUserAddressesById(id, token, perPage, page, signal, search, filter)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAllUsers = createAsyncThunk('api/users/get', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await UserService.getAllUsers(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUserRoleById = createAsyncThunk('api/user/role', async ({ id, token, user, signal } : { id: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUserRoleById(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUserCompanyById = createAsyncThunk('api/user/company', async ({ id, token, user, signal } : { id: string, token: string, user: { companyId: string }, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUserCompanyById(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUserEmailVerificationById = createAsyncThunk('api/user/email-verification', async ({ id, token, user, signal } : { id: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUserEmailVerificationById(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUserActivationById = createAsyncThunk('api/user/activation', async ({ id, token, user, signal } : { id: string, token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUserActivationById(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createUser = createAsyncThunk('api/user/create', async ({ token, user, signal } : { token: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.createUser(token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteUserById = createAsyncThunk('api/user/delete', async ({ token, id, signal } : { token: string, id: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.deleteUserById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateUserPasswordById = createAsyncThunk('api/user/password/update', async ({ token, id, user, signal } : { token: string, id: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.updateUserPasswordById(id, token, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const adminUpdateUserPasswordById = createAsyncThunk('api/user/id/password-reset-admin', async ({ token, id, user, signal }: { token: string, id: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.adminUpdateUserPasswordById(token, id, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const joinCompanyByInviteCode = createAsyncThunk('api/user/id/company-invite', async ({ token, id, user, signal }: { token: string, id: string, user: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserService.joinCompanyByInviteCode(token, id, user, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const userSlice = createSlice({
  name: 'api/user',
  initialState,
  reducers: {
    resetUserError: (state) => {
      state.error = null
    },
    resetUserMessage: (state) => {
      state.message = ''
    },
    getAllPagesForUsers: (state, { payload }) => {
      state.areAllPagesForUsers = payload
    },
    resetUserData: (state) => {
      state.error = null
      state.user = null
      state.userReset = null
      state.users = []
      state.message = ''
    },
    resetUserReset: (state) => {
      state.userReset = null
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserById.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user,
          address
        } = action.payload
        state.address = address
        state.user = user
        state.message = USER_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateUserById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.error
      })
    builder
      .addCase(sendVerifyCode.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendVerifyCode.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = action.payload.user.message
      })
      .addCase(sendVerifyCode.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = EMAIL_VERIFICATION_CONFIRMATION
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateUserAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = action.payload.statusCode === statusCodes.CREATED ? ADDRESS_ADDITION_SUCCESS_MESSAGE : ADDRESS_UPDATE_SUCCESS_MESSAGE
        state.address = action.payload.address
      })
      .addCase(updateUserAddress.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getUserAddresses.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserAddresses.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.addresses = action.payload.addresses
        state.addressesMetadata = action.payload.meta
      })
      .addCase(getUserAddresses.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.users = action.payload.users
        state.metadata = action.payload.meta
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateUserRoleById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserRoleById.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.user = user
        state.message = USER_ROLE_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateUserRoleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateUserCompanyById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserCompanyById.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.message = `User company and role for ${user.email} updated successfully`
        state.error = null
      })
      .addCase(updateUserCompanyById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateUserEmailVerificationById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserEmailVerificationById.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.user = user
        state.message = USER_EMAIL_VERIFICATION_STATUS_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateUserEmailVerificationById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateUserActivationById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserActivationById.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.user = user
        state.message = USER_ACTIVE_STATE_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateUserActivationById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.user = user
        state.message = USER_CREATION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(createUser.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteUserById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteUserById.fulfilled, (state) => {
        state.isLoading = false
        state.message = USER_DELETION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(deleteUserById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateUserPasswordById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserPasswordById.fulfilled, (state, action) => {
        state.isLoading = false
        const {
          user
        } = action.payload
        state.user = user
        state.error = null
        state.message = PASSWORD_CHANGE_CONFIRMATION
      })
      .addCase(updateUserPasswordById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(adminUpdateUserPasswordById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(adminUpdateUserPasswordById.fulfilled, (state, action) => {
        const {
          user
        } = action.payload
        state.userReset = user
        state.message = USER_PASSWORD_RESET_SUCCESS_MESSAGE
        state.isLoading = false
      }).addCase(adminUpdateUserPasswordById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(joinCompanyByInviteCode.pending, (state) => {
        state.isLoading = true
      })
      .addCase(joinCompanyByInviteCode.fulfilled, (state, action) => {
        state.message = COMPANY_ASSIGNMENT_SUCCESS_MESSAGE
        state.isLoading = false
      }).addCase(joinCompanyByInviteCode.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetUserError, resetUserMessage, resetUserData, resetUserReset, getAllPagesForUsers } = userSlice.actions

const { reducer } = userSlice
export default reducer
