import React from 'react'
import BillingAddressCreation from './steps/BillingAddressCreation'
import { AddressMode, CompanyMode, UserCompany } from '../../types'
import DeliveryAddressCreation from './steps/DeliveryAddressCreation'

interface BillingOrDeliveryAddressCreationProps {
  setMode: Function
  setTitle: Function
  company: UserCompany | null | undefined
  token: string | null
  addressMode: AddressMode
  setAddressMode: Function
  isBillingAndDeliveryAddress: boolean
  setIsBillingAndDeliveryAddress: Function
  setCompanyMode: React.Dispatch<React.SetStateAction<CompanyMode>>
}

const BillingOrDeliveryAddressCreation = ({
  setMode, setTitle, company, token,
  isBillingAndDeliveryAddress, setIsBillingAndDeliveryAddress,
  addressMode, setAddressMode, setCompanyMode
}: BillingOrDeliveryAddressCreationProps) => {
  const renderAddressMode = (mode: AddressMode) => {
    const selectedMode = {
      billingAddress: <BillingAddressCreation setMode={setMode} setTitle={setTitle} company={company} token={token} isBillingAndDeliveryAddress={isBillingAndDeliveryAddress} setIsBillingAndDeliveryAddress={setIsBillingAndDeliveryAddress} addressMode={addressMode} setAddressMode={setAddressMode} setCompanyMode={setCompanyMode} />,
      deliveryAddress: <DeliveryAddressCreation setMode={setMode} setTitle={setTitle} company={company} token={token} isBillingAndDeliveryAddress={isBillingAndDeliveryAddress} setIsBillingAndDeliveryAddress={setIsBillingAndDeliveryAddress} addressMode={addressMode} setAddressMode={setAddressMode} setCompanyMode={setCompanyMode} />
    }

    return selectedMode[mode]
  }
  return renderAddressMode(addressMode)
}

export default BillingOrDeliveryAddressCreation
