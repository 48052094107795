import React from 'react'
import { ProductStock } from '../../../types'

const TableColumnStock = ({ stock }: { stock: ProductStock | null }) => {
  return (
    <td>
      <span>{stock?.stockLevel || 0} / {Math.max((stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0), 0)}</span>
    </td>
  )
}

export default TableColumnStock
