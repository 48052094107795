import React, { ChangeEvent, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useAppSelector } from '../../store/hooks'
import { ApiMetadata, BillOfMaterialsComponent, Bundle, Product, stocks } from '../../types'
import Spinner from '../loaders/Spinner'
import Pagination from '../Pagination'
import ItemCard from './ItemCard'
import Placeholder from '../../assets/images/placeholder.png'
import BillOfMaterialsItem from './BillOfMaterialsItem'
import ProductLoader from '../loaders/ProductLoader'

type ItemListViewProps = {
  bundle: Bundle
  originalActiveBundle: Bundle
  addItem: (item: BillOfMaterialsComponent, bundle: Bundle) => void
  deleteItem: (jfsku: string, bundle: Bundle) => void
  setItemDeletedStatus: (activeBundle: Bundle, jfsku: string, isDeleted: boolean) => void
  selectedBundleIndex: number
  updateItemQuantity: (activeBundle: Bundle, jfsku: string, quantity: number) => void
  totalBundles: number
  page: number
  perPage: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  setPerPage: React.Dispatch<React.SetStateAction<number>>
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  isLoadingCompanyProducts: boolean
  products: Product[]
  metadata: ApiMetadata
}

const ItemListView = ({
  addItem,
  deleteItem,
  setItemDeletedStatus,
  bundle,
  originalActiveBundle,
  selectedBundleIndex,
  updateItemQuantity,
  totalBundles,
  page,
  perPage,
  setPage,
  setPerPage,
  setSearchTerm,
  isLoadingCompanyProducts,
  products,
  metadata
}: ItemListViewProps) => {
  const isLoading = useAppSelector((state) => state.apiProduct.isLoading)

  const [editCustomBundleItem, setEditCustomBundleItem] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [selectedProductStock, setSelectedProductStock] = useState<stocks.Stock | null>(null)
  const [updatedQuantity, setUpdatedQuantity] = useState(1)
  const [selectedColor, setSelectedColor] = useState<string | null>(null)
  const [selectedSize, setSelectedSize] = useState<string | null>(null)

  const showBillOfMaterialsStock = false

  const colors = [
    {
      name: 'Red',
      value: '#f00'
    },
    {
      name: 'Green',
      value: '#0f0'
    },
    {
      name: 'Blue',
      value: '#00f'
    },
    {
      name: 'Yellow',
      value: '#ff0'
    },
    {
      name: 'White',
      value: '#fff'
    },
    {
      name: 'Black',
      value: '#000'
    }
  ]
  const sizes = [
    {
      name: 'S',
      value: 'S'
    },
    {
      name: 'M',
      value: 'M'
    },
    {
      name: 'L',
      value: 'L'
    },
    {
      name: 'XL',
      value: 'XL'
    },
    {
      name: 'XXL',
      value: 'XXL'
    }
  ]

  const prices = [
    {
      quantity: 50,
      pricePerUnit: 16.99
    },
    {
      quantity: 100,
      pricePerUnit: 15.63
    },
    {
      quantity: 250,
      pricePerUnit: 14.34
    },
    {
      quantity: 500,
      pricePerUnit: 14.12
    }
  ]

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setEditCustomBundleItem(false)
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setEditCustomBundleItem(false)
    setPage(page)
  }

  const searchSchema = object({
    search: string()
      .max(256, 'Search Name is too long')
  })

  return (
    <div className="row">
      {bundle.isLocked === false
        ? (<div className="col-12 mb-4">
        <div className="card p-4">
          <div className="mb-4">
            <p className="text-muted">
              Select an available item to add to the bundle <span className="fw-bold text-primary me-1">{bundle.name ?? '--'} <span className="small">({`Bundle ${selectedBundleIndex + 1} of ${totalBundles}`})</span></span><span className="badge bg-primary">{bundle.quantity} {bundle.quantity === 1 ? 'Box' : 'Boxes'}</span>
            </p>
            <div className="">
              <Formik
                validationSchema={searchSchema}
                enableReinitialize
                initialValues={{
                  search: ''
                }}
                onSubmit={({ search }, actions) => {
                  setSearchTerm(search)
                  setPage(1)
                  setEditCustomBundleItem(false)

                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <input
                        onChange={(event) => {
                          const search = event.target.value
                          handleChange(event)
                          setSearchTerm(search)
                          setPage(1)
                        }}
                        maxLength={256}
                        onBlur={handleBlur}
                        value={values.search}
                        className={`form-control ${
                          errors.search &&
                          touched.search &&
                          errors.search
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder="Search company products..."
                        aria-label="Search company products"
                        aria-describedby="companyProductsSearch"
                        name="search"
                        autoComplete="on"
                      />
                      <button
                        className="btn btn-outline-dark"
                        id="companyProductsSearch"
                        type="submit"
                        title="Search"
                        disabled={isSubmitting}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
            {
              editCustomBundleItem
                ? (
                  <div className="border rounded">
                    <div className="position-relative">
                      <button type="button" className="btn-close position-absolute top-0 end-0 m-1" onClick={() => setEditCustomBundleItem(false)} aria-label="Cancel"></button>
                    </div>
                    <div className="container">
                      <div className="row pt-4">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                          <div className="card">
                            {
                              selectedProduct && selectedProduct.pictures && selectedProduct?.pictures.length > 0
                                ? (
                                  <img
                                    src={selectedProduct.pictures[0].publicUrl}
                                    className="img-fluid"
                                    alt={selectedProduct.name}
                                  />
                                  )
                                : (<img src={Placeholder} alt="Placeholder thumbnail" className="img-fluid" />)
                            }
                            <div className="card-body">
                              <h5 className="card-title">{selectedProduct?.name ?? ''}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col mb-4">
                          <div className="row">
                            <p className="h5">Choose Color ({selectedColor ?? '-'})</p>
                            <div className="d-flex flex-row flex-wrap">
                              {
                                colors.map(color => (
                                  <button
                                    className={`btn me-2 mb-2 ${selectedColor === color.name && 'active'}`}
                                    key={color.name}
                                    title={color.name}
                                    aria-pressed={selectedColor === color.name}
                                    style={{
                                      background: color.value,
                                      borderWidth: selectedColor === color.name ? '4px' : '1px',
                                      borderColor: selectedColor === color.name ? '#D3D4D4' : '#D3D4D4',
                                      borderRadius: '50%',
                                      width: '2.5rem',
                                      height: '2.5rem '
                                    }}
                                    onClick={() => {
                                      setSelectedColor(color.name)
                                    }}
                                  />
                                ))
                              }
                            </div>
                          </div>
                          <div className="row mt-2">
                            <p className="h5">Choose Size ({selectedSize ?? '-'})</p>
                            <div className="d-flex flex-row">
                              {
                                sizes.map(size => (
                                  <button
                                    className={`btn btn-outline-dark btn-sm me-2 ${selectedSize === size.name && 'active'}`}
                                    key={size.name}
                                    title={size.name}
                                    aria-pressed={selectedSize === size.name}
                                    onClick={() => {
                                      setSelectedSize(size.name)
                                    }}
                                  >
                                    {size.name}
                                  </button>
                                ))
                              }
                            </div>
                          </div>
                          <div className="row mt-4">
                            <p>
                              <span className="fw-bold">In Stock/ Available:</span> {selectedProductStock?.stockLevel || 0}/{(selectedProductStock?.stockLevel || 0) - (selectedProductStock?.stockLevelReserved || 0)}
                            </p>
                            <p className="h5">Quantity</p>
                            <div className="form-group row">
                              <div className="col-4">
                                <input
                                  type="number"
                                  min={1}
                                  max={((selectedProductStock?.stockLevel || 0) - (selectedProductStock?.stockLevelReserved || 0)) || 1}
                                  value={updatedQuantity}
                                  onChange={(event) => {
                                    if (selectedProduct?.jfsku) {
                                      setUpdatedQuantity(Number(event.target.value))
                                      updateItemQuantity(bundle, selectedProduct.jfsku, Number(event.target.value))
                                    }
                                  }}
                                  className="form-control"
                                  aria-label="Item Quantity"
                                  name="quantity"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                          <p className="h5">Description</p>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Velit laoreet id donec ultrices tincidunt. Lorem ipsum dolor sit amet consectetur adipiscing. Pulvinar mattis nunc sed blandit libero volutpat. Est placerat in egestas erat. Risus at ultrices mi tempus imperdiet. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Sed vulputate odio ut enim blandit volutpat maecenas volutpat blandit. Faucibus purus in massa tempor nec feugiat nisl. Pellentesque nec nam aliquam sem et tortor consequat id porta. Ac orci phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Senectus et netus et malesuada. Ornare arcu odio ut sem nulla pharetra diam sit.</p>
                        </div>
                      </div>
                      <div className="row table-responsive p-2">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="row">Quantity</th>
                              {
                                prices.map(price => (
                                  <td key={price.quantity}>{price.quantity}</td>
                                ))
                              }
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">Price per unit</th>
                              {
                                prices.map(price => (
                                  <td key={price.quantity}>{price.pricePerUnit}</td>
                                ))
                              }
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            setEditCustomBundleItem(false)
                          }}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            if (selectedProduct) {
                              const mappedProduct = {
                                name: selectedProduct.name,
                                jfsku: selectedProduct.jfsku,
                                merchantSku: selectedProduct.merchantSku,
                                quantity: updatedQuantity,
                                isCustomItem: true,
                                isDeleted: false
                              }
                              addItem(mappedProduct, bundle)
                            }
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  )
                : (
                  <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-4" id="companyProducts">
                    {isLoadingCompanyProducts
                      ? (<ProductLoader repetition={4} />)
                      : (products.length > 0
                          ? (
                              products.map((product) => (
                                <ItemCard
                                  key={product.id}
                                  product={product}
                                  bundle={bundle}
                                  addItem={addItem}
                                  deleteItem={deleteItem}
                                  setEditCustomBundleItem={setEditCustomBundleItem}
                                  setSelectedProduct={setSelectedProduct}
                                  setSelectedProductStock={setSelectedProductStock}
                                />
                              ))
                            )
                          : (
                            <div className="col">
                              <p className="text-primary">No products found</p>
                            </div>
                            ))
                      }
                    </div>
                  )
            }
          <div className="row mt-4">
            <Pagination
              isLoading={isLoadingCompanyProducts}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              perPageOptions={[4, 8, 12, 24]}
            />
          </div>
        </div>
      </div>)
        : (
          <div className="col-12">
            <p className="text-muted">
              <span className="fw-bold text-primary me-1">{bundle.name ?? '--'} <span className="small">({`Bundle ${selectedBundleIndex + 1} of ${totalBundles}`})</span></span><span className="badge bg-primary">{bundle.quantity} {bundle.quantity === 1 ? 'Box' : 'Boxes'}</span> <br />
            </p>
          </div>
          )
      }

      <div className="col-12">
        <div className="mb-3">
          <div className="p-0 table-responsive">
            <table className="table">
              {bundle.isLocked && <caption className="small text-primary"><span className="ms-1" title="Locked">{bundle.isLocked && <i className="bi bi-lock"></i>}</span> Selected items are not editable as this bundle is locked.</caption>}
              <thead>
                <tr>
                  <th scope="col">Selected Item <span className="badge bg-primary">{(bundle?.specifications?.billOfMaterialsComponents.filter(item => !item.isDeleted) ?? []).length}</span></th>
                  {showBillOfMaterialsStock && <th scope="col">In Stock / Available</th>}
                  <th scope="col" className="col-2">Amount</th>
                  <th scope="col" className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  bundle && bundle.specifications && bundle.specifications?.billOfMaterialsComponents
                    ? (
                        bundle.specifications?.billOfMaterialsComponents.map((item) => {
                          const found = originalActiveBundle.specifications?.billOfMaterialsComponents.find(i => i.jfsku === item.jfsku)
                          return (
                            <BillOfMaterialsItem
                              key={item.jfsku}
                              bundle={bundle}
                              item={item}
                              found={found}
                              deleteItem={deleteItem}
                              setItemDeletedStatus={setItemDeletedStatus}
                              updateItemQuantity={updateItemQuantity}
                              showStock={showBillOfMaterialsStock}
                            />
                          )
                        })
                      )
                    : (
                        isLoading
                          ? <Spinner />
                          : (
                            <tr>
                              <td colSpan={showBillOfMaterialsStock ? 4 : 3}><div className="text-center"><span>No items available yet</span></div></td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12">
        <p className="text-muted">
          <span className="fw-bold text-primary">{bundle.name ?? '--'} <span className="small">({`Bundle ${selectedBundleIndex + 1} of ${totalBundles}`})</span></span><span className="badge bg-primary ms-2">{bundle.quantity} {bundle.quantity === 1 ? 'Box' : 'Boxes'}</span>
        </p>
      </div>
    </div>
  )
}

export default ItemListView
