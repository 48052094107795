import React from 'react'
import SkeletonElement from '../../../components/loaders/skeleton/SkeletonElement'
import Pagination from '../../../components/Pagination'

const DetailsLoader = () => {
  return (
    <div className="container-fluid px-4 py-4">
      <div className="card">
        <div className="m-4">
          <div className="navbar navbar-expand d-flex flex-column flex-sm-row align-items-start">
            <div className="col-6"><SkeletonElement type="title" /></div>
            <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              <button className="btn btn-outline-primary btn-sm disabled me-2 text-nowrap" aria-disabled="true">
                <i className="bi bi-folder-plus"></i>
                <span className="ms-1">Bulk Create</span>
              </button>
              <button className="btn btn btn-outline-primary btn-sm disabled text-nowrap" aria-disabled="true">
                <i className="bi bi-plus-circle"></i>
                <span className="ms-1">Create Order</span>
              </button>
            </ul>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="m-4">
          <div className="col-6"><SkeletonElement type="title" /></div>
          <div className="row mt-4">
            <div className="col">
              <ul className="list-group">
                <li className="list-group-item list-group-item-action"><div className="col-9"><SkeletonElement type="text" /></div></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="m-4">
          <div className="navbar navbar-expand d-flex flex-column flex-sm-row align-items-start">
            <div className="col-4"><SkeletonElement type="text" /></div>
          </div>
          <div>
            <div className="table-responsive">
              <table className="table caption-top">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Updated</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row"><div className="col-2"><SkeletonElement type="text" /></div></th>
                    <td><div className="col-3"><SkeletonElement type="text" /></div></td>
                    <td><div className="col-4"><SkeletonElement type="text" /></div></td>
                    <td><div className="col-3"><SkeletonElement type="text" /></div></td>
                  </tr>
                  <tr>
                    <th scope="row"><div className="col-2"><SkeletonElement type="text" /></div></th>
                    <td><div className="col-3"><SkeletonElement type="text" /></div></td>
                    <td><div className="col-4"><SkeletonElement type="text" /></div></td>
                    <td><div className="col-3"><SkeletonElement type="text" /></div></td>
                  </tr>
                  <tr>
                    <th scope="row"><div className="col-2"><SkeletonElement type="text" /></div></th>
                    <td><div className="col-3"><SkeletonElement type="text" /></div></td>
                    <td><div className="col-4"><SkeletonElement type="text" /></div></td>
                    <td><div className="col-3"><SkeletonElement type="text" /></div></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={true}
              metadata={{
                limit: 10,
                total: 10,
                offset: 0
              }}
              page={1}
              perPage={10}
              handlePageChange={() => {}}
              handleShowEntries={() => {}}
              module="detailsLoader"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsLoader
