import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { resetToast } from '../store/reducers/toastReducer'

const Toast = () => {
  const show = useAppSelector((state) => state.toast.isVisible)
  const title = useAppSelector((state) => state.toast.title)
  const message = useAppSelector((state) => state.toast.message)
  const timestamp = useAppSelector((state) => state.toast.timestamp)
  const type = useAppSelector((state) => state.toast.type)
  const delay = useAppSelector((state) => state.toast.delay)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(resetToast())
    }, delay)
    return () => clearTimeout(timer)
  }, [show, title, message, timestamp, type, delay])

  return (
  // <div aria-live="polite" aria-atomic="true" className="position-relative">
      <div className="toast-container position-fixed top-5 end-0 p-3">

        <div
          className={`toast fade ${show ? 'show' : 'hide'} text-bg-${type}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <i className="bi bi-bell-fill rounded me-2"></i>
            <strong className="me-auto">{title}</strong>
            <small className="text-muted">{(timestamp)?.toString()}</small>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => {
                dispatch(resetToast())
              }}
            />
          </div>
          <div className="toast-body">
            {message}
          </div>
        </div>

      </div>
  // </div>
  )
}

export default Toast
