import React, { ChangeEventHandler, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Metadata } from '../types'

interface PaginationProps {
  isLoading: boolean
  metadata: Metadata
  page: number
  perPage: number
  handlePageChange: (page: number) => void
  handleShowEntries: ChangeEventHandler<HTMLSelectElement>
  perPageOptions?: Array<number>
  module?: string
  isTrackingPage?: boolean
}

const Pagination = ({
  isLoading,
  metadata,
  page,
  perPage,
  handlePageChange,
  handleShowEntries,
  perPageOptions = [10, 25, 50, 100],
  module = '',
  isTrackingPage
}: PaginationProps) => {
  const totalItemsString = metadata.total.toLocaleString()
  const totalPages = Math.ceil(metadata.total / metadata.limit)
  const loadingFirstPageRange = ((page - 1) * perPage + 1).toLocaleString()
  const loadingLastPageRange = (
    page === 1 ? Math.min(perPage, metadata.total) : (page * metadata.limit > metadata.total ? metadata.total : page * metadata.limit)
  ).toLocaleString()
  const loadingRange =
    loadingFirstPageRange === loadingLastPageRange
      ? loadingFirstPageRange
      : `${(page > totalPages ? `>${totalPages}` : loadingFirstPageRange)} - ${loadingLastPageRange}`
  const firstPageRange = (metadata.total > 0 ? (page === 1 ? page : metadata.offset + 1) : 0).toLocaleString()
  const lastPageRange =
    page * metadata.limit > metadata.total ? metadata.total.toLocaleString() : (page * metadata.limit).toLocaleString()
  const range = firstPageRange === lastPageRange ? firstPageRange : `${(page > totalPages ? `>${totalPages}` : firstPageRange)} - ${lastPageRange}`

  const navigate = useNavigate()
  const { search } = useLocation()

  useEffect(() => {
    if (isTrackingPage) {
      const currentPage = new URLSearchParams(search).get('page')
      currentPage ? handlePageChange(Number(currentPage)) : handlePageChange(1)
    }
  }, [search])

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <div className="small">
          {isLoading
            ? (<p>{`${loadingRange === '>0 - 0' ? 0 : loadingRange} of ${totalItemsString}`}</p>)
            : (<p>{`${range} of ${metadata.total.toLocaleString()}`}</p>)
          }
        </div>
      </div>
      <nav className="me-2 p-2" aria-label="Page navigation">
        <ul className="pagination text-nowrap ">
          <li className={`page-item ${page <= 1 ? 'disabled' : ''}`}>
            <button
              disabled={page <= 1}
              className="page-link"
              onClick={() => {
                handlePageChange(page - 1)
                if (isTrackingPage) {
                  navigate(`?page=${page - 1}`)
                }
              }}
              onDoubleClick={() => {
                handlePageChange(1)
                if (isTrackingPage) {
                  navigate('?page=1')
                }
              }}
              aria-label="Previous"
            >
              <i className="bi bi-chevron-left"></i>
              <span className="d-none d-md-inline-block ms-1">Previous</span>
            </button>
          </li>
          <li className={`page-item ${page >= Math.ceil(metadata.total / metadata.limit) ? 'disabled' : ''}`}>
            <button
              disabled={page >= Math.ceil(metadata.total / metadata.limit)}
              className="page-link"
              onClick={() => {
                handlePageChange(page + 1)
                if (isTrackingPage) {
                  navigate(`?page=${page + 1}`)
                }
              }}
              onDoubleClick={() => {
                handlePageChange(Math.ceil(metadata.total / metadata.limit))
                if (isTrackingPage) {
                  navigate(`?page=${Math.ceil(metadata.total / metadata.limit)}`)
                }
              }}
              aria-label="Next"
            >
              <span className="d-none d-md-inline-block">Next</span>
              <i className="bi bi-chevron-right ms-1"></i>
            </button>
          </li>
        </ul>
      </nav>
      <div className="form-group py-2">
        <label htmlFor={`showEntries${module}`} className="me-2 ms-1 d-none d-md-inline-block">
          Show entries
        </label>
        <select
          id={`showEntries${module}`}
          value={perPage}
          onChange={(event) => {
            handleShowEntries(event)
            handlePageChange(1)
            if (isTrackingPage) {
              navigate('?page=1')
            }
          }}
          className="form-select-entries d-inline-block"
          aria-label="Show entries"
        >
          {perPageOptions.map((perPageOption: number) => (
            <option key={perPageOption} value={perPageOption}>
              {perPageOption}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Pagination
