import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductColorService from '../../../services/api/ProductColorService'
import { ApiProductColorState } from '../../../types'

const initialState: ApiProductColorState = {
  isLoading: false,
  error: null,
  productColor: null,
  productColors: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getProductColors = createAsyncThunk('api/product-colors', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductColorService.getProductColors(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const productColorSlice = createSlice({
  name: 'api/product-color',
  initialState,
  reducers: {
    resetProductColorError: (state) => {
      state.error = null
    },
    resetProductColorMessage: (state) => {
      state.message = null
    },
    resetProductColorData: (state) => {
      state.error = null
      state.productColor = null
      state.productColors = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductColors.pending, (state) => {
        state.productColors = []
        state.isLoading = true
      })
      .addCase(getProductColors.fulfilled, (state, action) => {
        state.isLoading = false
        state.productColors = action.payload.productColors
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getProductColors.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductColorError, resetProductColorMessage, resetProductColorData } = productColorSlice.actions

const { reducer } = productColorSlice
export default reducer
