import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { ToastState } from '../../types'

dayjs.extend(localizedFormat)

const initialState: ToastState = {
  isVisible: false,
  message: '',
  title: '',
  timestamp: dayjs().format('LLLL'),
  type: 'info',
  delay: 10000,
  hasUpdate: false,
  serviceWorkerRegistration: null
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action) => {
      const { isVisible, message, timestamp, title, type = 'info', delay = 10000 } = action.payload
      state.isVisible = isVisible
      state.message = message
      state.timestamp = timestamp
      state.title = title
      state.type = type
      state.delay = delay
    },
    resetToast: (state) => {
      state.isVisible = false
      state.message = ''
      state.title = ''
      state.timestamp = ''
      state.type = 'info'
      state.delay = 10000
    },
    setHasUpdate: (state, action) => {
      state.hasUpdate = true
      state.serviceWorkerRegistration = action.payload
    },
    resetHasUpdate: (state) => {
      state.hasUpdate = false
    }
  }
})

export const { setToast, resetToast, setHasUpdate, resetHasUpdate } = toastSlice.actions

const { reducer } = toastSlice
export default reducer
