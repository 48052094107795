import React, { ReactNode, useEffect, useState } from 'react'
import { bool, date, object, ref, string } from 'yup'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { MaintenanceMode } from '../../types'
import DatePicker from '../DatePicker'

dayjs.extend(utc)

type MaintenanceModeEditorProps = { id: string, initialMaintenanceMode: Partial<MaintenanceMode>, save: Function, isEdit: boolean }

const MaintenanceModeEditor = ({ id, initialMaintenanceMode, save }: MaintenanceModeEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)

  const [reason, setReason] = useState(initialMaintenanceMode.reason)
  const [isActive, setIsActive] = useState(initialMaintenanceMode.isActive)
  const [startDate, setStartDate] = useState(dayjs(initialMaintenanceMode.startDate).toDate())
  const [endDate, setEndDate] = useState(dayjs(initialMaintenanceMode.endDate).toDate())

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const maintenanceModeSchema = object({
    reason: string().label('Reason').required(),
    startDate: date().label('Start Date').required().when('isActive', {
      is: true,
      then: date().min(dayjs().format(), `Start Date must be later than now: ${dayjs().format('YYYY-MM-DD hh:mm:ss A')}`),
      otherwise: date()
    }),
    endDate: date().min(ref('startDate'), ({ min }) => `End Date must be after the Start Date: ${dayjs(min).format('YYYY-MM-DD hh:mm:ss A')}`).label('End Date').required(),
    isActive: bool().label('Is Active').required().typeError('Is Active is required')
  })

  const saveMaintenanceMode = (id: string, maintenanceMode: Partial<MaintenanceMode>, signal: AbortSignal) => {
    dispatch(save({ maintenanceModeId: id, token, maintenanceMode, signal }))
  }

  useEffect(() => {
    setReason(initialMaintenanceMode.reason)
    setIsActive(initialMaintenanceMode.isActive)
    if (initialMaintenanceMode.startDate && initialMaintenanceMode.endDate) {
      setStartDate(dayjs(initialMaintenanceMode.startDate).toDate())
      setEndDate(dayjs(initialMaintenanceMode.endDate).toDate())
    }
  }, [initialMaintenanceMode])

  return (
    <div>
      <Formik
        validationSchema={maintenanceModeSchema}
        enableReinitialize
        initialValues={{
          reason,
          isActive,
          startDate,
          endDate
        }}
        onSubmit={(
          { reason, startDate, endDate, isActive },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal

          const maintenanceMode = {
            reason,
            startDate,
            endDate,
            isActive
          }

          if (token) {
            saveMaintenanceMode(id, maintenanceMode, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="maintenanceModeReason"
                    className="form-label"
                  >
                    Reason
                  </label>
                  <input
                    onChange={(event) => {
                      setReason(event.target.value)
                    }}
                    onBlur={handleBlur}
                    value={reason}
                    type="text"
                    className={`form-control ${
                      (touched.reason &&
                      errors.reason)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="maintenanceModeReason"
                    name="reason"
                    placeholder=""
                    maxLength={64}
                    autoComplete="off"
                  />
                  <div
                    id="validationMaintenanceModeNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.reason}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="maintenanceModeIsActive"
                    className="form-label"
                  >
                    Is Active
                  </label>
                  <select
                    aria-label="Maintenance Mode Is Active"
                    onChange={(event) => {
                      const value = event.target.value === 'true'
                      setIsActive(value)
                    }}
                    onBlur={handleBlur}
                    value={String(isActive)}
                    className={`form-select ${
                      touched.isActive &&
                      errors.isActive
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="maintenanceModeIsActive"
                    name="isActive"
                    autoComplete="off"
                  >
                    <option value={undefined}>Select Is Active</option>
                    <option value={'true'}>Yes</option>
                    <option value={'false'}>No</option>
                  </select>
                  <div
                    id="validationMaintenanceModeIsActiveFeedback"
                    className="invalid-feedback"
                  >
                    {errors.isActive}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="maintenanceModeStartDate" className="form-label">
                    Start Date
                  </label>
                  <div
                    className={`${
                      errors.startDate &&
                      touched.startDate
                        ? 'is-invalid'
                        : ''
                    }`}
                  >
                    <DatePicker
                      date={startDate}
                      setDate={setStartDate}
                      minDate={(dayjs().local().toDate())}
                      className={`${
                        errors.startDate &&
                        touched.startDate
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="maintenanceModeStartDate"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy h:mm aa"
                      timeIntervals={15}
                    />
                  </div>
                  <div
                    id="validationMaintenanceModeStartDateFeedback"
                    className="invalid-feedback"
                  >
                    {errors.startDate as ReactNode}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="maintenanceModeEndDate" className="form-label">
                    End Date
                  </label>
                  <div
                    className={`${
                      errors.endDate &&
                      touched.endDate
                        ? 'is-invalid'
                        : ''
                    }`}
                  >
                    <DatePicker
                      date={endDate}
                      setDate={setEndDate}
                      minDate={(dayjs().local().toDate())}
                      className={`${
                        errors.endDate &&
                        touched.endDate
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="maintenanceModeEndDate"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy h:mm aa"
                      timeIntervals={15}
                    />
                  </div>
                  <div
                    id="validationMaintenanceModeEndDateFeedback"
                    className="invalid-feedback"
                  >
                    {errors.endDate as ReactNode}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default MaintenanceModeEditor
