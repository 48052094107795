import { Formik } from 'formik'
import React from 'react'
import { object, string } from 'yup'
import Select from 'react-select'
import { CompanyUserGroup } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import * as userRoles from '../../../constants/userRoles'

type CompanyUserGroupEditorProps = { id: string, initialCompanyUserGroup: Partial<CompanyUserGroup>, save: Function, isEdit: boolean }

const CompanyUserGroupEditor = ({ id, initialCompanyUserGroup, save, isEdit }: CompanyUserGroupEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const companies = useAppSelector((state) => state.apiCompany.companies)

  const token = currentUser?.token
  const role = profile?.role

  const dispatch = useAppDispatch()

  const companyUserGroupSchema = object({
    name: string().label('Name').required(),
    description: string().label('Description').nullable(),
    companyId: string().label('Company').uuid().required()
  })

  const saveCompanyUserGroup = (id: string, companyUserGroup: Partial<CompanyUserGroup>, signal: AbortSignal) => {
    dispatch(save({ companyUserGroupId: id, token, companyUserGroup, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={companyUserGroupSchema}
        enableReinitialize
        initialValues={{
          ...initialCompanyUserGroup,
          companyId: initialCompanyUserGroup.company?.id
        }}
        onSubmit={(
          { name, description, companyId },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal

          const companyUserGroup: any = {
            name,
            description: description || null
          }

          if (!isEdit) {
            companyUserGroup.companyId = companyId
          }

          if (token) {
            saveCompanyUserGroup(id, companyUserGroup, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            {role === userRoles.ADMIN && (<div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="companyUserGroupCompany"
                    className="form-label"
                  >
                    Company
                  </label>
                  <Select
                    className={`${
                      touched.companyId &&
                      errors.companyId
                        ? 'is-invalid'
                        : ''
                    }`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor,
                        '&:hover': {
                          borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor
                        }
                      })
                    }}
                    isClearable
                    inputId="companyUserGroupCompany"
                    name="companyId"
                    aria-label="Company"
                    options={companies}
                    getOptionLabel={(company) => `${company.name} - ${company.domain ?? 'Domain not set'}`}
                    getOptionValue={(company) => String(company.id)}
                    onChange={(selectedOption) => {
                      const selectedCompanyId = selectedOption?.id ?? ''
                      setFieldValue('companyId', selectedCompanyId)
                    }}
                    onBlur={handleBlur}
                    isLoading={isLoading}
                    value={companies.find((company) => company.id === values.companyId)}
                    isDisabled={isEdit}
                  />
                  <div
                    id="validationCompanyUserGroupCompanyFeedback"
                    className="invalid-feedback"
                  >
                    {errors.companyId}
                  </div>
                </div>
              </div>
            </div>)}
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="companyUserGroupName"
                    className="form-label"
                  >
                    Name
                  </label>
                  <input
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      (touched.name &&
                      errors.name)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="companyUserGroupName"
                    name="name"
                    placeholder=""
                    maxLength={128}
                    autoComplete="off"
                  />
                  <div
                    id="validationCompanyUserGroupNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="companyUserGroupDescription"
                    className="form-label"
                  >
                    Description
                  </label>
                  <input
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description || ''}
                    type="text"
                    className={`form-control ${
                      (touched.description &&
                      errors.description)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="companyUserGroupDescription"
                    name="description"
                    placeholder=""
                    maxLength={128}
                    autoComplete="off"
                  />
                  <div
                    id="validationCompanyUserGroupDescriptionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.description}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyUserGroupEditor
