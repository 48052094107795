import React from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { resetHasUpdate } from '../store/reducers/toastReducer'
import { updateServiceWorker } from '../serviceWorkerRegistration'
dayjs.extend(localizedFormat)

const ServiceWorkerToast = () => {
  const hasUpdate = useAppSelector((state) => state.toast.hasUpdate)
  const serviceWorkerRegistration = useAppSelector((state) => state.toast.serviceWorkerRegistration)

  const dispatch = useAppDispatch()

  return (
    <div className="toast-container position-fixed bottom-0 end-0 p-3">
      <div
        className={`toast fade ${hasUpdate ? 'show' : 'hide'} text-bg-secondary`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <i className="bi bi-bell-fill rounded me-2"></i>
          <strong className="me-auto"></strong>
          <small className="text-muted">{(dayjs().format('LLLL'))?.toString()}</small>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={() => {
              dispatch(resetHasUpdate())
            }}
          />
        </div>
        <div className="toast-body">
          A new version of the app is available.
          <div className="mt-2 pt-2 border-top">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                updateServiceWorker(serviceWorkerRegistration)
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ServiceWorkerToast
