import axios from 'axios'

const baseOAuth2URL = process.env.REACT_APP_BASE_OAUTH2_API_URL
const redirectURI = process.env.REACT_APP_JTL_REDIRECT_URL
const clientID = process.env.REACT_APP_JTL_CLIENT_ID
const clientSecret = process.env.REACT_APP_JTL_CLIENT_SECRET

const apiClient: any = axios.create({
  baseURL: `${baseOAuth2URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  verifyAuthorization (code: string, signal: AbortSignal) {
    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('code', code)
    params.append('redirect_uri', String(redirectURI))

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const config = {
      headers,
      signal,
      auth: {
        username: clientID,
        password: clientSecret
      }
    }

    return apiClient.post('token', params, config)
  },
  refreshAuthorization (refreshToken: string, signal: AbortSignal) {
    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('refresh_token', refreshToken)

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const config = {
      headers,
      signal,
      auth: {
        username: clientID,
        password: clientSecret
      }
    }

    return apiClient.post('token', params, config)
  }
}
