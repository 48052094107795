import React from 'react'
import { Link } from 'react-router-dom'
import errorMaintenance from '../../assets/images/website-maintenance.svg'

const GenericError = () => {
  return (
    <div id="layoutError">
      <div id="layoutError_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="text-center mt-4">
                  <img
                    className="mb-4 img-error"
                    src={errorMaintenance}
                  />
                  <p className="lead">
                    An error has occured. We are working to resolve it as soon as possible. Try refreshing the page by clicking the button below.
                  </p>
                  <p>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                        Refresh
                    </button>
                  </p>
                  <p className="lead">
                    If the issue persists, click the link below to return to the dashboard.
                  </p>
                  <Link to="/">
                    <i className="fas fa-arrow-left me-1"></i>
                    Return to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default GenericError
