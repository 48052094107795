import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import FulfillmentOrderService from '../../../../services/api/dotnet/FulfillmentOrderService'
import { DotnetApiFulfillmentOrdersState } from '../../../../types'
import { FULFILLMENT_ORDERS_CANCELLED_SUCCESS_MESSAGE } from '../../../../constants/messages'

const initialState: DotnetApiFulfillmentOrdersState = {
  isLoading: false,
  error: null,
  message: null
}

export const cancelFulfillmentOrders = createAsyncThunk('dotnet/api/fulfillment-orders/cancel', async ({ token, outboundIds, signal }: { token: string, outboundIds: Array<string>, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await FulfillmentOrderService.cancelFulfillmentOrders(token, outboundIds, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const fulfillmentOrdersSlice = createSlice({
  name: 'dotnet/api/fulfillment-orders',
  initialState,
  reducers: {
    resetFulfillmentOrdersError: (state) => {
      state.error = null
    },
    resetFulfillmentOrdersMessage: (state) => {
      state.message = null
    },
    resetFulfillmentOrdersData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelFulfillmentOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(cancelFulfillmentOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = FULFILLMENT_ORDERS_CANCELLED_SUCCESS_MESSAGE
      })
      .addCase(cancelFulfillmentOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetFulfillmentOrdersError, resetFulfillmentOrdersMessage, resetFulfillmentOrdersData } = fulfillmentOrdersSlice.actions

const { reducer } = fulfillmentOrdersSlice
export default reducer
