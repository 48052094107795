import React, { useEffect } from 'react'
import { Modal } from 'bootstrap'

interface DialogBoxProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
  modalName?: string
}

const DialogBox: React.FC<DialogBoxProps> = ({ showDialog, cancelNavigation, confirmNavigation, modalName = 'dialogBox' }) => {
  useEffect(() => {
    const modalElement = document.getElementById(modalName)

    if (modalElement) {
      const modal = new Modal(modalElement)

      if (showDialog) {
        modal.show()
      } else {
        modal.hide()
      }
    }
  }, [showDialog, modalName])
  return (
    <div className="modal fade" data-bs-backdrop="static" id={modalName} tabIndex={-1} aria-labelledby={`${modalName}Label`} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title text-danger" id={`${modalName}Label`}>
              <i className="bi bi-trash text-danger me-2"></i>Confirm Action
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => cancelNavigation()} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p>
              {'Are you sure you want to navigate away?'}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => cancelNavigation()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                confirmNavigation()
              }}
              data-bs-dismiss="modal"
              aria-label="yes"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DialogBox
