import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Select from 'react-select'
import { countriesObject } from '../../../../utils/countries'
import { Address, Campaign, CampaignAddressMode, CampaignOnboardingMode } from '../../../../types'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { createCampaignAddress } from '../../../../store/reducers/api/campaignReducer'
import { deleteCampaignAddress } from '../../../../store/reducers/api/campaignAddressReducer'
import { phoneValidationPattern } from '../../../../constants/regexPatterns'

interface CampaignAddressEditorProps {
  addressTitle: string
  setTitle: Function
  campaign: Partial<Campaign> | null
  campaignAddresses: Address[]
  setMode: Function
  addressMode: CampaignAddressMode
  setAddressMode: Function
  mode: CampaignOnboardingMode
  setCancelPressed: Function
}

const CampaignAddressEditor = ({
  setTitle,
  addressTitle,
  campaignAddresses,
  campaign,
  setMode,
  mode,
  addressMode,
  setAddressMode,
  setCancelPressed
}: CampaignAddressEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const [hasReturnShippingAddress, setHasReturnShippingAddress] = useState(false)
  const [returnAddress, setReturnAddress] = useState<Address | undefined | null>(null)
  const [billingAddress, setBillingAddress] = useState<Address | undefined | null>(null)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const countries = ([{ value: '', label: '' }]).concat(countriesObject.map(country => ({ value: country.country, label: country.country })))

  const addressSchema = object({
    billing: object({
      companyName: string().label('Company').nullable(),
      country: string().required('Country is required').oneOf(countries.map(country => country.value)),
      city: string()
        .required('City is required')
        .max(32, 'City name is too long'),
      street: string()
        .required('Street and House Number are required')
        .max(64, 'Street and House Number are too long'),
      zip: string()
        .required('Zip is required')
        .max(16, 'Zip is too long'),
      phone: string()
        .nullable()
        .matches(phoneValidationPattern, 'Enter a valid phone number'),
      addressAddition: string()
        .nullable()
        .max(255, 'Address Addition is too long'),
      email: string().label('Email').email().nullable(),
      costCenter: string().label('Cost Center').nullable(),
      vat: string().label('USTID').nullable()
    }),
    returning: object({
      companyName: string().label('Company').nullable(),
      country: string().when([], {
        is: () => hasReturnShippingAddress,
        then: string().required('Country is required').oneOf(countries.map(country => country.value)),
        otherwise: string().optional()
      }),
      city: string().when([], {
        is: () => hasReturnShippingAddress,
        then: string().required('City is required').max(32, 'City name is too long'),
        otherwise: string().optional()
      }),
      street: string().when([], {
        is: () => hasReturnShippingAddress,
        then: string().required('Street and House Number are required').max(64, 'Street and House Number are too long'),
        otherwise: string().optional()
      }),
      zip: string().when([], {
        is: () => hasReturnShippingAddress,
        then: string().required('Zip is required').max(16, 'Zip is too long'),
        otherwise: string().optional()
      }),
      phone: string()
        .nullable()
        .matches(phoneValidationPattern, 'Enter a valid phone number'),
      addressAddition: string()
        .nullable()
        .max(255, 'Address Addition is too long'),
      email: string().label('Email').email().nullable(),
      costCenter: string().label('Cost Center').nullable(),
      vat: string().label('USTID').nullable()
    })
  })

  useEffect(() => {
    const returnAddress = campaignAddresses.find(address => address.type === 'return')
    const billingAddress = campaignAddresses.find(address => address.type === 'billing')
    setReturnAddress(returnAddress)
    setBillingAddress(billingAddress)
    if (returnAddress) {
      setHasReturnShippingAddress(true)
    } else {
      setHasReturnShippingAddress(false)
    }
  }, [campaign])

  return (
    <div>
      <Formik
        validationSchema={addressSchema}
        enableReinitialize
        initialValues={{
          billing: {
            companyName: billingAddress?.companyName || '',
            country: billingAddress?.country || '',
            city: billingAddress?.city || '',
            street: billingAddress?.street || '',
            zip: billingAddress?.zip || '',
            phone: billingAddress?.phone || '',
            addressAddition: billingAddress?.addressAddition || '',
            email: billingAddress?.email || '',
            costCenter: billingAddress?.costCenter || '',
            vat: billingAddress?.vat || ''
          },
          returning: {
            companyName: returnAddress?.companyName || '',
            country: returnAddress?.country || '',
            city: returnAddress?.city || '',
            street: returnAddress?.street || '',
            zip: returnAddress?.zip || '',
            phone: returnAddress?.phone || '',
            addressAddition: returnAddress?.addressAddition || '',
            email: returnAddress?.email || '',
            costCenter: returnAddress?.costCenter || '',
            vat: returnAddress?.vat || ''
          }
        }}
        onSubmit={(
          { billing, returning },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const campaignId = campaign?.id
          const campaignAddresses: Partial<Address>[] = [{
            companyName: billing.companyName || null,
            country: billing.country,
            city: billing.city,
            street: billing.street,
            zip: billing.zip,
            phone: billing.phone || null,
            addressAddition: billing.addressAddition || null,
            email: billing.email || null,
            costCenter: billing.costCenter || null,
            vat: billing.vat || null,
            type: 'billing'
          }]

          if (hasReturnShippingAddress) {
            campaignAddresses.push({
              companyName: returning.companyName || null,
              country: returning.country,
              city: returning.city,
              street: returning.street,
              zip: returning.zip,
              phone: returning.phone || null,
              addressAddition: returning.addressAddition || null,
              email: returning.email || null,
              costCenter: returning.costCenter || null,
              vat: returning.vat || null,
              type: 'return'
            })
          }

          if (token && campaignId) {
            if (addressMode === 'returnAddress' && hasReturnShippingAddress === false && returnAddress) {
              dispatch(deleteCampaignAddress({ id: returnAddress.id, token, signal }))
            } else {
              dispatch(createCampaignAddress({ id: campaignId, token, campaignAddresses, signal }))
            }
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setTouched,
          setErrors
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <p className="mb-3 h6">
                {mode === 'campaignAddress' && addressTitle}
              </p>
              {
                mode === 'campaignAddress' && addressMode === 'billingAddress' && (
                  <>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressCompanyName" className="form-label">
                            Company
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing.companyName}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.companyName && touched.billing?.companyName)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressCompanyName"
                            name="billing.companyName"
                            placeholder="Company"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressStreetFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.companyName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressStreet" className="form-label">
                            Street and House Number
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing.street}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.street && touched.billing?.street)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressStreet"
                            name="billing.street"
                            placeholder="Enter street and House Number"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressStreetFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.street}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressZip" className="form-label">
                            Zip
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing.zip}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.zip && touched.billing?.zip)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressZip"
                            name="billing.zip"
                            placeholder="Enter zip"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressZipFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.zip}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressCity" className="form-label">
                            City
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.city && touched.billing?.city)
                                ? 'is-invalid'
                                : ''
                            }`}
                            value={values.billing?.city}
                            id="campaignAddressCity"
                            name="billing.city"
                            placeholder="Enter city"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressCityFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.city}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressAddressAddition" className="form-label">
                            Address Addition
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing?.addressAddition}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.addressAddition &&
                              touched.billing?.addressAddition)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressAddressAddition"
                            name="billing.addressAddition"
                            placeholder="Enter Address Addition"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressAddressAdditionFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.addressAddition}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressPhone" className="form-label">
                            Phone
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing.phone}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.phone && touched.billing?.phone)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressPhone"
                            name="billing.phone"
                            placeholder="Enter Phone Number"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressPhoneFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.phone}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressCountry" className="form-label">
                            Country
                          </label>
                          <Select
                            className={`${
                              touched.billing?.country &&
                              errors.billing?.country
                                ? 'is-invalid'
                                : ''
                            }`}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: (errors.billing?.country && touched.billing?.country) ? '#dc3545' : provided.borderColor,
                                '&:hover': {
                                  borderColor: (errors.billing?.country && touched.billing?.country) ? '#dc3545' : provided.borderColor
                                }
                              })
                            }}
                            isClearable
                            inputId="campaignAddressCountry"
                            name="billing.country"
                            aria-label="Country"
                            options={countries}
                            getOptionLabel={(country) => `${country.value}`}
                            getOptionValue={(country) => String(country.value)}
                            onChange={(selectedOption) => {
                              const selectedCountry = selectedOption?.value ?? ''
                              setFieldValue('billing.country', selectedCountry)
                            }}
                            onBlur={handleBlur}
                            value={countries.find((country) => country.value === values.billing.country)}
                          />
                          <div
                            id="validationCampaignAddressCountryFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.country}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressEmail" className="form-label">
                            Email <span className="small">(for receipt of invoice)</span>
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing.email}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.email && touched.billing?.email)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressEmail"
                            name="billing.email"
                            placeholder="Enter Email"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressEmailFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.email}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressCostCenter" className="form-label">
                            Cost Center
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing.costCenter}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.costCenter && touched.billing?.costCenter)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressCostCenter"
                            name="billing.costCenter"
                            placeholder="Enter Cost Center"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressCostCenterFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.costCenter}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="campaignAddressVat" className="form-label">
                            USTID
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.billing.vat}
                            type="text"
                            className={`form-control ${
                              (errors.billing?.vat && touched.billing?.vat)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="campaignAddressVat"
                            name="billing.vat"
                            placeholder="Enter USTID"
                            autoComplete="on"
                          />
                          <div
                            id="validationCampaignAddressVatFeedback"
                            className="invalid-feedback"
                          >
                            {errors.billing?.vat}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
              {
                mode === 'campaignAddress' && addressMode === 'returnAddress' && (
                  <>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <p>
                            Would you like to provide a business address in the event of a returned shipment?<br />
                            Otherwise the shipment will be returned to us.
                          </p>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="returnAddress"
                              id="returnAddressRadioYes"
                              onChange={() => {
                                setHasReturnShippingAddress(true)
                              }}
                              value="yes"
                              checked={hasReturnShippingAddress}
                              autoComplete="off"
                            />
                            <label className="form-check-label" htmlFor="returnAddressRadioYes">Yes</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="returnAddress"
                              id="returnAddressRadioNo"
                              onChange={() => {
                                setHasReturnShippingAddress(false)
                                setTouched({}, false)
                                setErrors({ billing: {} })
                              }}
                              value="no"
                              checked={!(hasReturnShippingAddress)}
                              autoComplete="off"
                            />
                            <label className="form-check-label" htmlFor="returnAddressRadioNo">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="returnAddressCompanyName" className="form-label">
                            Company
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.returning.companyName}
                            type="text"
                            className={`form-control ${
                              (errors.returning?.companyName && touched.returning?.companyName)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="returnAddressCompanyName"
                            name="returning.companyName"
                            placeholder="Company"
                            autoComplete="on"
                          />
                          <div
                            id="validationReturnAddressStreetFeedback"
                            className="invalid-feedback"
                          >
                            {errors.returning?.companyName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="returnAddressStreet" className="form-label">
                            Street and House Number
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.returning.street}
                            type="text"
                            className={`form-control ${
                              (errors.returning?.street && touched.returning?.street)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="returnAddressStreet"
                            name="returning.street"
                            placeholder="Enter street and House Number"
                            autoComplete="on"
                          />
                          <div
                            id="validationReturnAddressStreetFeedback"
                            className="invalid-feedback"
                          >
                            {errors.returning?.street}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="returnAddressZip" className="form-label">
                            Zip
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.returning.zip}
                            type="text"
                            className={`form-control ${
                              (errors.returning?.zip && touched.returning?.zip)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="returnAddressZip"
                            name="returning.zip"
                            placeholder="Enter zip"
                            autoComplete="on"
                          />
                          <div
                            id="validationReturnAddressZipFeedback"
                            className="invalid-feedback"
                          >
                            {errors.returning?.zip}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="returnAddressCity" className="form-label">
                            City
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            className={`form-control ${
                              (errors.returning?.city && touched.returning?.city)
                                ? 'is-invalid'
                                : ''
                            }`}
                            value={values.returning?.city}
                            id="returnAddressCity"
                            name="returning.city"
                            placeholder="Enter city"
                            autoComplete="on"
                          />
                          <div
                            id="validationReturnAddressCityFeedback"
                            className="invalid-feedback"
                          >
                            {errors.returning?.city}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="returnAddressAddition" className="form-label">
                            Address Addition
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.returning?.addressAddition}
                            type="text"
                            className={`form-control ${
                              (errors.returning?.addressAddition &&
                              touched.returning?.addressAddition)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="returnAddressAddition"
                            name="returning.addressAddition"
                            placeholder="Enter Address Addition"
                            autoComplete="on"
                          />
                          <div
                            id="validationReturnAddressAdditionFeedback"
                            className="invalid-feedback"
                          >
                            {errors.returning?.addressAddition}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="returnAddressPhone" className="form-label">
                            Phone
                          </label>
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.returning.phone}
                            type="text"
                            className={`form-control ${
                              (errors.returning?.phone && touched.returning?.phone)
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="returnAddressPhone"
                            name="returning.phone"
                            placeholder="Enter Phone Number"
                            autoComplete="on"
                          />
                          <div
                            id="validationReturnAddressPhoneFeedback"
                            className="invalid-feedback"
                          >
                            {errors.returning?.phone}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="returnAddressCountry" className="form-label">
                            Country
                          </label>
                          <Select
                            className={`${
                              touched.returning?.country &&
                              errors.returning?.country
                                ? 'is-invalid'
                                : ''
                            }`}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: (errors.returning?.country && touched.returning?.country) ? '#dc3545' : provided.borderColor,
                                '&:hover': {
                                  borderColor: (errors.returning?.country && touched.returning?.country) ? '#dc3545' : provided.borderColor
                                }
                              })
                            }}
                            isClearable
                            inputId="returnAddressCountry"
                            name="returning.country"
                            aria-label="Country"
                            options={countries}
                            getOptionLabel={(country) => `${country.value}`}
                            getOptionValue={(country) => String(country.value)}
                            onChange={(selectedOption) => {
                              const selectedCountry = selectedOption?.value ?? ''
                              setFieldValue('returning.country', selectedCountry)
                            }}
                            onBlur={handleBlur}
                            value={countries.find((country) => country.value === values.returning.country)}
                          />
                          <div
                            id="validationReturnAddressCountryFeedback"
                            className="invalid-feedback"
                          >
                            {errors.returning?.country}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
              {
                mode === 'campaignSubmission' && (
                  <div className="row align-items-center modal-body mh-280 mb-3">
                    <div className="d-flex">
                      <div className="me-3 align-self-center">
                        {
                          campaign?.status === 'draft'
                            ? (
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
                              </svg>
                              )
                            : (
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
                                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                              </svg>
                              )
                        }
                      </div>
                      <div className="align-self-center">
                        {
                          campaign?.status === 'draft'
                            ? (
                              <span>Thank you. We have received all the necessary information. <br />Your campaign will be finalised soon.</span>
                              )
                            : (
                              <span>Thank you. Your campaign has been finalised.</span>
                              )
                        }
                      </div>
                    </div>
                  </div>
                )
              }
              <span className="text-danger small">
                {Object.keys(errors.billing || {}).length > 0 && ('Fix the errors in the billing address before saving')}
              </span>
            </div>
            <div className="modal-footer">
            {mode === 'campaignAddress' && (
              <>
                {addressMode === 'returnAddress'
                  ? (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setAddressMode('billingAddress')
                        setTitle('Billing Address')
                      }}
                    >
                      Back
                    </button>
                    )
                  : (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setAddressMode('returnAddress')
                        setTitle('Return Address')
                      }}
                    >
                      Next
                    </button>
                    )}

                {addressMode === 'returnAddress' && (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                )}
              </>
            )}

            {
              mode === 'campaignSubmission' && (
                <>
                  <button type="button" className="btn btn-secondary"
                    onClick={() => {
                      setMode('campaignAddress')
                      setAddressMode('returnAddress')
                      setTitle('Return Address')
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => setCancelPressed(true)}
                  >
                    Confirm
                  </button>
                </>
              )
            }
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CampaignAddressEditor
