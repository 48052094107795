import React from 'react'
import Placeholder from '../../assets/images/placeholder.png'

const ProductLoader = ({ repetition }: { repetition: number }) => {
  return (
    <>
      {Array.from(Array(repetition).keys()).map((n: number) => (
        <div
          className="col"
          key={n}
        >
          <div
            aria-label="Product Loader"
            className="card h-100 product-card"
          >
            <img src={Placeholder} alt="Placeholder thumbnail" style={{ objectFit: 'cover' }} className="card-img-top" />
            <div
              className="h-100 d-flex align-content-end flex-wrap"
            >
              <div className="position-relative d-none" title="Availability Date">
                <span className="badge rounded-pill text-bg-secondary position-absolute bottom-0 end-0 m-1">
                  <i className="bi bi-clock"></i> 0 days
                </span>
              </div>
              <div className="card-body">
                <h5 className="card-title placeholder-glow">
                  <span className="placeholder col-6"></span>
                </h5>
              </div>
            </div>
            <ul className="list-group list-group-flush m-1">
              <li className="list-group-item small">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border spinner-border-sm text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </>
  )
}

export default ProductLoader
