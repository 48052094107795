import React, { useState, useEffect } from 'react'
import { BillOfMaterialsComponent, Bundle, stocks } from '../../types'
import { TrashIcon } from '../icons/TrashIcon'
import { useAppSelector } from '../../store/hooks'
import ProductService from '../../services/api/ProductService'
import RestoreIcon from '../icons/RestoreIcon'

interface BillOfMaterialsComponentProps {
  bundle: Bundle
  item: BillOfMaterialsComponent
  found: BillOfMaterialsComponent | undefined
  deleteItem: (jfsku: string, bundle: Bundle) => void
  setItemDeletedStatus: (activeBundle: Bundle, jfsku: string, isDeleted: boolean) => void
  updateItemQuantity: (activeBundle: Bundle, jfsku: string, quantity: number) => void
  showStock: boolean
}
const BillOfMaterialsItem = ({ item, bundle, found, deleteItem, setItemDeletedStatus, updateItemQuantity, showStock }: BillOfMaterialsComponentProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const [stock, setStock] = useState<stocks.Stock | null>(null)
  const [isLoadingStock, setIsLoadingStock] = useState(true)

  const getStock = async (
    productId: string,
    signal: AbortSignal
  ) => {
    try {
      setIsLoadingStock(true)
      const { data: { stock } }: { data: { statusCode: number, success: boolean, stock: stocks.Stock } } =
        await ProductService.getProductStock(String(currentUser?.token), productId, signal)
      setStock(stock)
      setIsLoadingStock(false)
    } catch (error: any) {
      if (error.message !== 'canceled') {
        setIsLoadingStock(false)
      }
    }
  }

  useEffect(() => {
    if (showStock) {
      const controller = new AbortController()
      const signal = controller.signal
      if (item) {
        getStock(item.jfsku, signal)
      }

      return () => {
        controller.abort()
      }
    }
  }, [])

  return (
    <tr
      className={`${found ? (item.isDeleted ? 'table-danger' : (item.isCustomItem ? 'table-success' : '')) : 'table-success'}`}
      aria-current="true"
    >
      <td scope="row" className="col-8">{item.name} {item.isDeleted ? <span className="small text-danger">(removed)</span> : (item.isCustomItem && <span className="small text-success">(added)</span>)}</td>
      {
        showStock && (
          <td className="col-2">
            {
              isLoadingStock
                ? (
                  <div className="d-flex justify-content-center mb-4">
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  )
                : (
                  <div className="text-center">
                    {stock?.stockLevel || 0}/{(stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0)}
                  </div>
                  )
                }
          </td>
        )
      }
      <td className="col-1">
        <div className="form-group">
          <input
            type="number"
            disabled={true}
            min={1}
            max={1}
            value={item.quantity}
            onChange={(e) => {
              updateItemQuantity(bundle, item.jfsku, Number(e.target.value))
            }}
            className="form-control"
            aria-label="Bill of Materials Item Quantity"
            name="quantity"
            autoComplete="off"
          />
        </div>
      </td>
      <td className="text-center">
        <div className="btn-group" role="group" aria-label="Item Action Buttons">
          <button
            disabled={bundle.isLocked || ((bundle.specifications?.billOfMaterialsComponents.filter(item => !item.isDeleted) ?? []).length === 1 && !item.isDeleted)}
            type="button"
            title={item.isDeleted ? 'Restore Item' : 'Remove Item'}
            className={`btn ${item.isDeleted ? 'btn-outline-secondary' : 'btn-outline-danger'} btn-round-sm`}
            onClick={() => {
              if (item.isCustomItem) {
                deleteItem(item.jfsku, bundle)
              } else {
                setItemDeletedStatus(bundle, item.jfsku, !item.isDeleted)
              }
            }}
          >
            {item.isDeleted ? <RestoreIcon /> : <TrashIcon />}
          </button>
        </div>
      </td>
    </tr>
  )
}

export default BillOfMaterialsItem
