import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SalutationService from '../../../services/api/SalutationService'
import { ApiSalutationState } from '../../../types'
import { SALUTATION_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiSalutationState = {
  isLoading: false,
  error: null,
  salutation: null,
  salutations: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getAllSalutations = createAsyncThunk('api/salutations/get', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await SalutationService.getAllSalutations(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addSalutation = createAsyncThunk('api/salutations/add', async ({ token, salutation, signal }: { token: string, salutation: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await SalutationService.addSalutation(token, salutation, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editASalutationById = createAsyncThunk('api/salutations/edit', async ({ token, salutationId, salutation, signal }: { token: string, salutationId: any, salutation: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await SalutationService.editSalutationById(token, salutationId, salutation, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteASalutationById = createAsyncThunk('api/salutations/delete', async ({ token, salutationId, signal }: { token: string, salutationId: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await SalutationService.deleteSalutationById(token, salutationId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const salutationSlice = createSlice({
  name: 'api/salutation',
  initialState,
  reducers: {
    resetSalutationError: (state) => {
      state.error = null
    },
    resetSalutationMessage: (state) => {
      state.message = null
    },
    resetSalutationData: (state) => {
      state.error = null
      state.salutation = null
      state.salutations = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSalutations.pending, (state) => {
        state.salutations = []
        state.isLoading = true
      })
      .addCase(getAllSalutations.fulfilled, (state, action) => {
        state.isLoading = false
        state.salutations = action.payload.salutations
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllSalutations.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addSalutation.pending, (state) => {
        state.salutations = []
        state.isLoading = true
      })
      .addCase(addSalutation.fulfilled, (state, action) => {
        state.isLoading = false
        state.salutation = action.payload.salutation
        state.error = null
        state.message = null
      })
      .addCase(addSalutation.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editASalutationById.pending, (state) => {
        state.salutations = []
        state.isLoading = true
      })
      .addCase(editASalutationById.fulfilled, (state, action) => {
        state.isLoading = false
        state.salutation = action.payload.salutation
        state.error = null
        state.message = null
      })
      .addCase(editASalutationById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteASalutationById.pending, (state) => {
        state.salutations = []
        state.isLoading = true
      })
      .addCase(deleteASalutationById.fulfilled, (state, action) => {
        state.isLoading = false
        state.salutation = null
        state.error = null
        state.message = SALUTATION_DELETION_MESSAGE
      })
      .addCase(deleteASalutationById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetSalutationError, resetSalutationMessage, resetSalutationData } = salutationSlice.actions

const { reducer } = salutationSlice
export default reducer
