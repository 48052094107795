import React, { ChangeEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import SkeletonImage from '../../loaders/skeleton/SkeletonImage'
import AnonymousImage from '../../Canvas/AnonymousImage'
import Pagination from '../../Pagination'
import { getAllGreetingCards } from '../../../store/reducers/api/greetingCardReducer'
import { BillOfMaterialsComponent, Bundle } from '../../../types'
import { Formik } from 'formik'
import { object, string } from 'yup'
import useDebounce from '../../../utils/hooks/useDebounce'

interface GreetingCardsProps {
  selectedCartBundle: Bundle | null
  selectedBundles: Bundle[]
  addItem: (item: BillOfMaterialsComponent, bundle: Bundle) => void
  removeItem: (jfsku: string, bundle: Bundle) => void
  templateTypeFilter: 'company' | 'general'
  companyId: string | undefined
}

const GreetingCards = ({ selectedBundles, selectedCartBundle, addItem, removeItem, templateTypeFilter, companyId } : GreetingCardsProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const greetingCards = useAppSelector((state) => state.apiGreetingCard.greetingCards)
  const metadata = useAppSelector((state) => state.apiGreetingCard.metadata)
  const isLoadingGreetingCards = useAppSelector((state) => state.apiGreetingCard.isLoading)

  const [perPage, setPerPage] = useState(12)
  const [page, setPage] = useState(1)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const searchSchema = object({
    search: string()
      .max(256, 'Search Name is too long')
  })

  const hasGreetingCard = (greetingCardJFSKU: string) => {
    if (selectedCartBundle) {
      const foundBundle = selectedBundles.find(selectedBundle => selectedBundle.jfsku === selectedCartBundle?.jfsku)
      if (foundBundle && foundBundle.specifications) {
        return foundBundle.specifications?.billOfMaterialsComponents.map(component => component.jfsku).includes(greetingCardJFSKU)
      }
      return false
    }
    return false
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const search = debouncedSearchTerm
    const filter = (companyId && templateTypeFilter === 'company') ? `filter[companyId]=${companyId}` : ''

    if (token) {
      dispatch(getAllGreetingCards({ token, perPage, page, search, filter, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, templateTypeFilter, debouncedSearchTerm])

  return (
    <div className="row">
      <div className="mb-4 mt-3">
        <Formik
          validationSchema={searchSchema}
          enableReinitialize
          initialValues={{
            search: ''
          }}
          onSubmit={({ search }, actions) => {
            setSearchTerm(search)
            setPage(1)

            actions.setSubmitting(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  onChange={(event) => {
                    const search = event.target.value
                    handleChange(event)
                    setSearchTerm(search)
                    setPage(1)
                  }}
                  maxLength={256}
                  onBlur={handleBlur}
                  value={values.search}
                  className={`form-control ${
                    errors.search &&
                    touched.search &&
                    errors.search
                      ? 'is-invalid'
                      : ''
                  }`}
                  type="text"
                  placeholder="Search greeting cards..."
                  aria-label="Search greeting cards"
                  aria-describedby="greetingCardsSearch"
                  name="search"
                  autoComplete="on"
                />
                <button
                  className="btn btn-outline-dark"
                  id="greetingCardsSearch"
                  type="submit"
                  title="Search"
                  disabled={isSubmitting}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="col">
        <div className="p-0">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4" id="greetingCards">
            {
              isLoadingGreetingCards
                ? (<SkeletonImage repetition={12} />)
                : (greetingCards.length > 0
                    ? (
                        greetingCards.map((greetingCard) => (
                          <div
                            className="col"
                            key={greetingCard.id}
                            onClick={() => {
                              if (hasGreetingCard(greetingCard.jtlfpid)) {
                                selectedCartBundle && removeItem(greetingCard.jtlfpid, selectedCartBundle)
                              } else {
                                const mappedProduct = {
                                  name: greetingCard.articleName,
                                  jfsku: greetingCard.jtlfpid,
                                  merchantSku: greetingCard.articleNumber,
                                  quantity: 1,
                                  isCustomItem: true,
                                  isDeleted: false
                                }
                                selectedCartBundle && addItem(mappedProduct, selectedCartBundle)
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' || event.key === ' ') {
                                if (hasGreetingCard(greetingCard.jtlfpid)) {
                                  selectedCartBundle && removeItem(greetingCard.jtlfpid, selectedCartBundle)
                                } else {
                                  const mappedProduct = {
                                    name: greetingCard.articleName,
                                    jfsku: greetingCard.jtlfpid,
                                    merchantSku: greetingCard.articleNumber,
                                    quantity: 1,
                                    isCustomItem: true,
                                    isDeleted: false
                                  }
                                  selectedCartBundle && addItem(mappedProduct, selectedCartBundle)
                                }
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            aria-label={`Select ${greetingCard.articleName}`}
                          >
                            <div
                              className={`card h-100 template-card ${(hasGreetingCard(greetingCard.jtlfpid)) && 'selected'}`}
                              role="button"
                              >
                              <AnonymousImage src={greetingCard.url} alt={`${greetingCard.articleName} Greeting Card`} />
                              <div className="card-body">
                                <h5 className="card-title text-truncate" title={greetingCard.articleName}>{greetingCard.articleName}</h5>
                                <p className="card-text"></p>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    : (
                      <div className="col">
                        <div className="card h-100">
                          <div className="card-body">
                            <div className="text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-card-image  text-primary" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
                              </svg>
                            </div>
                            <p className="text-primary card-text mt-3">No greeting cards have been added</p>
                          </div>
                        </div>
                      </div>
                      ))
            }
          </div>
          <div className="row mt-4">
            <Pagination
              isLoading={isLoadingGreetingCards}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              module="card-templates"
              perPageOptions={[12, 24, 60, 100]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GreetingCards
