import React from 'react'
import Shimmer from './Shimmer'

const SkeletonElement = ({ type, width = 'w-100' } : { type: 'title' | 'text' | 'avatar' | 'thumbnail' | 'text-small', width?: 'w-25' | 'w-50' | 'w-75' | 'w-100' }) => {
  const classes = `skeleton ${type} ${width}`
  return (
    <div className={classes}>
      <Shimmer />
    </div>
  )
}

export default SkeletonElement
