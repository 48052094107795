import React from 'react'
import { Product } from '../../../types'
import { TrashIcon } from '../../icons/TrashIcon'

interface AdditionalArticleRowProps {
  additionalArticle: Product
  removeAdditionalArticle: Function
  updateAdditionalArticleQuantity: Function
  module: string
}

const AdditionalArticleRow = ({ additionalArticle, removeAdditionalArticle, updateAdditionalArticleQuantity, module }: AdditionalArticleRowProps) => {
  return (
    <tr>
      <td className="col-7">{additionalArticle.name}</td>
      <td className="text-center col-2">
        <span>
          {additionalArticle.stock?.stockLevel || 0}/{Math.max((additionalArticle.stock?.stockLevel || 0) - (additionalArticle.stock?.stockLevelReserved || 0), 0)}
        </span>
      </td>
      <td className="col-2">
        <div className="form-group">
          <input
            type="number"
            min={1}
            max={Math.max((additionalArticle.stock?.stockLevel || 0) - (additionalArticle.stock?.stockLevelReserved || 0), 0) || 1}
            value={additionalArticle.quantity || 1}
            onChange={(e) => {
              const max = Math.max((additionalArticle.stock?.stockLevel || 0) - (additionalArticle.stock?.stockLevelReserved || 0), 0) || 1
              const value = Number(e.target.value)
              const quantity = Math.max(Math.min(value, max), 1)
              updateAdditionalArticleQuantity(additionalArticle, quantity)
            }}
            id={`${additionalArticle.jfsku}-${module}`}
            name="quantity"
            className="form-control"
            aria-label={`Additional Article Quantity ${module}`}
            autoComplete="off"
          />
        </div>
      </td>
      <td className="text-end col-1">
        <div className="btn-group" role="group" aria-label="Item Action Buttons">
          <button
            type="button"
            title="Remove Additional Article"
            className="btn btn-outline-danger btn-round-sm"
            onClick={() => {
              removeAdditionalArticle(additionalArticle)
            }}
          >
            <TrashIcon />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default AdditionalArticleRow
