import React, { useState, useEffect } from 'react'
import { ProductGraduatedPrice } from '../../../types'
import { formatPrice } from '../../../utils/formatPrice'
import { PencilIcon } from '../../icons/PencilIcon'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import * as userRoles from '../../../constants/userRoles'
import { TrashIcon } from '../../icons/TrashIcon'
import SkeletonTableRow from '../../loaders/skeleton/SkeletonTableRow'
import GraduatedPriceEditor from './GraduatedPriceEditor'
import Progress from '../../loaders/Progress'
import { deleteProductGraduatedPriceById } from '../../../store/reducers/api/productGraduatedPriceReducer'
import { dismissModal } from '../../../utils/dismissModal'
import {
  PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE,
  PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE,
  PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE
} from '../../../constants/messages'

interface GraduatedPricesListViewProps {
  isLoading: boolean
  graduatedPrices: ProductGraduatedPrice[]
  productId: string | undefined
}
const GraduatedPricesListView = ({ isLoading, graduatedPrices, productId }: GraduatedPricesListViewProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoadingProductGraduatedPrice = useAppSelector((state) => state.apiProductGraduatedPrice.isLoading)
  const messageProductGraduatedPrice = useAppSelector((state) => state.apiProductGraduatedPrice.message)
  const messageProduct = useAppSelector((state) => state.apiProduct.message)

  const [initialGraduatedPrice, setInitialGraduatedPrice] = useState<Omit<ProductGraduatedPrice, 'createdAt' | 'updatedAt'>>({
    id: '',
    price: 0,
    firstUnit: 0,
    lastUnit: 0
  })
  const [editMode, setEditMode] = useState(false)

  const dispatch = useAppDispatch()

  const token = currentUser?.token
  const role = currentUser?.role || userRoles.USER
  const sortedGraduatedPrices = [...graduatedPrices].sort((a, b) => a.lastUnit - b.lastUnit)

  useEffect(() => {
    const allowedMessages = [PRODUCT_GRADUATED_PRICE_CREATION_MESSAGE, PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE, PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE]

    if ((messageProductGraduatedPrice && allowedMessages.includes(messageProductGraduatedPrice))) {
      dismissModal('productGraduatedPricingModal')
      dismissModal('productGraduatedPricingConfirmationModal')
    }
    if ((messageProduct && allowedMessages.includes(messageProduct))) {
      dismissModal('productGraduatedPricingModal')
    }
  }, [messageProductGraduatedPrice, messageProduct])
  return (
    <div>
      <div className="navbar navbar-expand mb-3">
        <p className="h5"><i className="bi bi-currency-euro me-1"></i>Graduated Prices</p>
        <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm text-nowrap"
            data-bs-toggle="modal"
            data-bs-target="#productGraduatedPricingModal"
            title="Add Graduated Price"
            aria-label="Add Graduated Price"
            onClick={() => {
              setEditMode(false)
              setInitialGraduatedPrice({
                id: '',
                price: 0,
                firstUnit: 0,
                lastUnit: 0
              })
            }}
          >
            <i className="bi bi-plus-circle"></i>
            <span className="d-none d-md-inline-block ms-1">Add Graduated Price</span>
          </button>
        </ul>
      </div>
      {(isLoading || isLoadingProductGraduatedPrice) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
      <div className="table-responsive mt-4">
        <table className="table align-middle">
          <thead>
            <tr>
              <th scope="col">Price</th>
              <th scope="col">First Unit</th>
              <th scope="col">Last Unit</th>
              {userRoles.ADMIN && <th scope="col" className="text-end">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {
              isLoading
                ? (
                    Array.from(Array(4).keys()).map((n: number) => (<SkeletonTableRow key={n} colSpan={userRoles.ADMIN ? 4 : 3} actionQuantity={2} />))
                  )
                : (
                    sortedGraduatedPrices.length
                      ? (
                          sortedGraduatedPrices.map((graduatedPrice) => (
                            <tr key={graduatedPrice.id} className={initialGraduatedPrice.id === graduatedPrice.id ? 'table-primary' : ''}>
                              <td>{formatPrice('EUR', navigator.language).format(graduatedPrice.price)}</td>
                              <td>{graduatedPrice.firstUnit}</td>
                              <td>{graduatedPrice.lastUnit}</td>
                              <td>
                                <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                {
                                  role === userRoles.ADMIN && (
                                    <>
                                      <button
                                        className="btn btn-outline-dark btn-round ms-2"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#productGraduatedPricingModal"
                                        title="Update Product Graduated Price"
                                        onClick={() => {
                                          setEditMode(true)
                                          setInitialGraduatedPrice(graduatedPrice)
                                        }}
                                      >
                                        <PencilIcon/>
                                      </button>
                                      <button
                                        className="btn btn-outline-danger btn-round ms-2"
                                        type="button"
                                        data-bs-toggle="modal"
                                          data-bs-target="#productGraduatedPricingConfirmationModal"
                                        title="Delete Product Graduated Pricing"
                                        onClick={() => {
                                          setInitialGraduatedPrice(graduatedPrice)
                                        }}
                                      >
                                        <TrashIcon />
                                      </button>
                                    </>
                                  )
                                }
                                </div>
                              </td>
                            </tr>
                          ))
                        )
                      : (
                    <tr>
                      <td colSpan={userRoles.ADMIN ? 4 : 3} className="text-center">
                        No graduated prices available yet
                      </td>
                    </tr>
                        )
                  )
            }
          </tbody>
        </table>
      </div>
      {/* Modals */}
      <div className="modal fade" id="productGraduatedPricingModal" tabIndex={-1} aria-labelledby="productGraduatedPricingModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="productGraduatedPricingModalLabel">{`${editMode ? 'Edit' : 'Add'} Graduated Price`}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoadingProductGraduatedPrice || isLoading) ? <Progress /> : <hr className="border border-primary border-1 opacity-50 mt-0"></hr>}
            <div className="modal-body">
              <GraduatedPriceEditor
                initialGraduatedPrice={initialGraduatedPrice}
                productId={productId}
                isLoading={isLoading}
                editMode={editMode}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="productGraduatedPricingConfirmationModal" tabIndex={-1} aria-labelledby="productGraduatedPricingConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="productGraduatedPricingConfirmationModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingProductGraduatedPrice && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to delete the
                <span className="fw-bold">{` '${(initialGraduatedPrice.firstUnit)} - ${initialGraduatedPrice.lastUnit}' `}</span>
                graduated price?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && initialGraduatedPrice.id !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteProductGraduatedPriceById({ productGraduatedPriceId: String(initialGraduatedPrice.id), token, signal }))
                  }
                }}
                disabled={isLoading}
                aria-label="Delete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraduatedPricesListView
