import React from 'react'
import Placeholder from '../../assets/images/placeholder.png'

const ProductCategoryLoader = ({ repetition }: { repetition: number }) => {
  return (
    <>{
      Array.from(Array(repetition).keys()).map((n: number) => (
        <div
          key={n}
          className="col"
        >
          <div className="card square-container">
            <div>
              <img className="square-image text-center" src={Placeholder} alt="Category Preview" />
              <div style={{ position: 'absolute', top: '70%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="small text-capitalize d-flex flex-column align-items-end">
                  <span className="text-bg-light p-1 rounded" style={{ width: 80 }}>
                    <p className="card-text placeholder-glow w-100">
                      <span className="placeholder col-12"></span>
                    </p>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      ))
    }</>
  )
}

export default ProductCategoryLoader
