import React from 'react'

type ConfirmationModalBoxProps = {
  isLoading?: boolean,
  progress?: React.ReactNode,
  modalSubHeader?: React.ReactNode
  modalHeader?: string,
  modalHeaderIcon?: React.ReactNode,
  id?: string,
  ariaLabelledBy?: string
  hidden?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) =>void
  btnClassName?: string
  btnText?: string
  btnIcon?: React.ReactNode
  children?: React.ReactNode
  staticBackdrop?: boolean
}

const ConfirmationModalBox = ({
  isLoading,
  progress,
  modalHeader,
  modalSubHeader,
  modalHeaderIcon,
  id,
  ariaLabelledBy,
  hidden = true,
  onClick,
  btnClassName,
  btnIcon,
  btnText,
  children,
  staticBackdrop = false
}: ConfirmationModalBoxProps) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex={-1}
      aria-labelledby={ariaLabelledBy}
      aria-hidden={hidden}
      data-bs-backdrop={staticBackdrop ? 'static' : ''}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title">
              {modalHeaderIcon} {modalHeader}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {isLoading && progress}
          <div className="modal-body">
            {modalSubHeader}
            {children}
          </div>
          <div className='modal-footer'>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button
              type="submit"
              className={btnClassName}
              disabled={isLoading}
              onClick={onClick}
            >
              {btnIcon} {btnText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModalBox
