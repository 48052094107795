import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import OrderService from '../../services/OrderService'
import { OrdersState } from '../../types'

const initialState: OrdersState = {
  isLoading: false,
  error: null,
  metadata: {
    limit: 10,
    offset: 0,
    total: 0
  },
  orders: [],
  order: null,
  message: null
}

export const getOrders = createAsyncThunk('orders/get', async ({ token, perPage, offset, filter, signal }: { token: string, perPage: number, offset: number, filter: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await OrderService.getAllOrders(token, perPage, offset, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.errors)
  }
})

export const getASingleOrder = createAsyncThunk('orders/get/single', async ({ token, outboundId, signal }: { token: string, outboundId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await OrderService.getASingleOrder(token, outboundId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.errors)
  }
})

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrdersError: (state) => {
      state.error = null
    },
    resetOrders: (state) => {
      state.orders = []
    },
    resetOrdersData: (state) => {
      state.orders = []
      state.error = null
      state.order = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.orders = action.payload.items
        state.metadata = action.payload._page
        state.error = null
      })
      .addCase(getOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getASingleOrder.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getASingleOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.order = action.payload
        state.error = null
      })
      .addCase(getASingleOrder.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetOrdersError, resetOrders, resetOrdersData } = orderSlice.actions

const { reducer } = orderSlice
export default reducer
