import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CampaignShippingDestinationService from '../../../services/api/CampaignShippingDestinationService'
import { ApiCampaignShippingDestinationState } from '../../../types'
import { SHIPPING_DESTINATION_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiCampaignShippingDestinationState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteCampaignShippingDestination = createAsyncThunk('api/campaign-shipping-destination/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CampaignShippingDestinationService.deleteCampaignShippingDestination(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const CampaignShippingDestinationSlice = createSlice({
  name: 'api/campaign-shipping-destination',
  initialState,
  reducers: {
    resetCampaignShippingDestinationError: (state) => {
      state.error = null
    },
    resetCampaignShippingDestinationMessage: (state) => {
      state.message = null
    },
    resetCampaignShippingDestinationData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCampaignShippingDestination.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCampaignShippingDestination.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = SHIPPING_DESTINATION_DELETION_MESSAGE
      })
      .addCase(deleteCampaignShippingDestination.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCampaignShippingDestinationError, resetCampaignShippingDestinationMessage, resetCampaignShippingDestinationData } = CampaignShippingDestinationSlice.actions

const { reducer } = CampaignShippingDestinationSlice
export default reducer
