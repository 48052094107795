import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CompanyInProductAccessControlGroupService from '../../../services/api/CompanyInProductAccessControlGroupService'
import { ApiCompanyInProductAccessControlGroupState } from '../../../types'
import { COMPANY_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiCompanyInProductAccessControlGroupState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteCompanyInProductAccessControlGroup = createAsyncThunk('api/company-product-access-control-group/delete', async ({ token, companyProductAccessControlGroupId, signal }: { token: string, companyProductAccessControlGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyInProductAccessControlGroupService.deleteCompanyInProductAccessControlGroup(token, companyProductAccessControlGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const companyInProductAccessControlGroupSlice = createSlice({
  name: 'api/company-product-access-control-group',
  initialState,
  reducers: {
    resetCompanyInProductAccessControlGroupError: (state) => {
      state.error = null
    },
    resetCompanyInProductAccessControlGroupMessage: (state) => {
      state.message = null
    },
    resetCompanyInProductAccessControlGroupData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCompanyInProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCompanyInProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = COMPANY_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE
      })
      .addCase(deleteCompanyInProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCompanyInProductAccessControlGroupError, resetCompanyInProductAccessControlGroupMessage, resetCompanyInProductAccessControlGroupData } = companyInProductAccessControlGroupSlice.actions

const { reducer } = companyInProductAccessControlGroupSlice
export default reducer
