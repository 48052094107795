import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductMaterialService from '../../../services/api/ProductMaterialService'
import { ApiProductMaterialState } from '../../../types'

const initialState: ApiProductMaterialState = {
  isLoading: false,
  error: null,
  productMaterial: null,
  productMaterials: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getProductMaterials = createAsyncThunk('api/product-materials', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductMaterialService.getProductMaterials(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const productMaterialSlice = createSlice({
  name: 'api/product-material',
  initialState,
  reducers: {
    resetProductMaterialError: (state) => {
      state.error = null
    },
    resetProductMaterialMessage: (state) => {
      state.message = null
    },
    resetProductMaterialData: (state) => {
      state.error = null
      state.productMaterial = null
      state.productMaterials = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductMaterials.pending, (state) => {
        state.productMaterials = []
        state.isLoading = true
      })
      .addCase(getProductMaterials.fulfilled, (state, action) => {
        state.isLoading = false
        state.productMaterials = action.payload.productMaterials
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getProductMaterials.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductMaterialError, resetProductMaterialMessage, resetProductMaterialData } = productMaterialSlice.actions

const { reducer } = productMaterialSlice
export default reducer
