import React from 'react'
import Placeholder from '../../../assets/images/placeholder.png'

const Loader = () => {
  return (
    <div className="row p-4">
      <div className="col-lg-5">
        <span className="text-center d-block mb-4">
          <img src={Placeholder} alt="Placeholder thumbnail" className="img-fluid" style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }} />
        </span>
      </div>
      <div className="col-lg-7">
        <div className="ps-lg-4">
          <p className="placeholder-glow">
            <span className="placeholder col-12 placeholder-lg rounded"></span>
          </p>
          <div className="mb-1">
            <p className="placeholder-glow">
              <span className="placeholder col-8 placeholder-sm rounded"></span>
            </p>
          </div>
          <div className="mt-4">
            <h4>Inventory</h4>
            <div className="row">
              <div className="col-md-4">
                <h6 className="font-14">In Stock / Available:</h6>
                <p className="text-sm lh-150">- / -</p>
              </div>
              <div className="col-md-4">
                <h6 className="font-14">Reserved:</h6>
                <p className="text-sm lh-150">-</p>
              </div>
              <div className="col-md-4">
                <h6 className="font-14">Awaiting Delivery:</h6>
                <p className="text-sm lh-150">-</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
