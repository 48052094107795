import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BUNDLE_DELETION_MESSAGE, PICTURE_POSTED_SUCCESS_MESSAGE } from '../../../constants/messages'
import BundleService from '../../../services/api/BundleService'
import { ApiBundleState, Picture } from '../../../types'

const initialState: ApiBundleState = {
  isLoading: false,
  error: null,
  bundles: [],
  bundle: null,
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getAllBundles = createAsyncThunk('api/bundle/get/all', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await BundleService.getAllBundles(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getBundleById = createAsyncThunk('api/bundle/get', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await BundleService.getBundleById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateBundleById = createAsyncThunk('api/bundle/update', async ({ id, token, bundle, signal }: { id: string, token: string, bundle: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await BundleService.updateBundleById(id, token, bundle, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteBundleById = createAsyncThunk('api/bundle/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await BundleService.deleteBundleById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addPictureToBundle = createAsyncThunk('api/bundle/add/picture', async ({ id, token, picture, signal }: { id: string, token: string, picture: Partial<Picture>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await BundleService.addPictureToBundle(id, token, picture, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const bundleSlice = createSlice({
  name: 'api/bundle',
  initialState,
  reducers: {
    resetBundleError: (state) => {
      state.error = null
    },
    resetBundleMessage: (state) => {
      state.message = null
    },
    resetBundleData: (state) => {
      state.error = null
      state.bundle = null
      state.bundles = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBundles.pending, (state) => {
        state.bundles = []
        state.isLoading = true
        state.message = null
      })
      .addCase(getAllBundles.fulfilled, (state, action) => {
        state.isLoading = false
        state.bundles = action.payload.bundles
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllBundles.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getBundleById.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getBundleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = null
        state.bundle = action.payload.bundle
      })
      .addCase(getBundleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateBundleById.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(updateBundleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = 'Bundle updated successfully'
        state.bundle = action.payload.bundle
      })
      .addCase(updateBundleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteBundleById.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(deleteBundleById.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = BUNDLE_DELETION_MESSAGE
      })
      .addCase(deleteBundleById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addPictureToBundle.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(addPictureToBundle.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = PICTURE_POSTED_SUCCESS_MESSAGE
      })
      .addCase(addPictureToBundle.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetBundleError, resetBundleMessage, resetBundleData } = bundleSlice.actions

const { reducer } = bundleSlice
export default reducer
