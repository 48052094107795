import React, { ChangeEvent, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useAppSelector } from '../../../store/hooks'
import { ApiMetadata, Campaign, Product } from '../../../types'
import Pagination from '../../Pagination'
import useDebounce from '../../../utils/hooks/useDebounce'
import CompanyService from '../../../services/api/CompanyService'
import AdditionalArticleItem from './AdditionalArticleItem'
import AdditionalArticleRow from './AdditionalArticleRow'
import ProductLoader from '../../loaders/ProductLoader'

type AdditionalArticleViewProps = {
  campaign: Campaign
  additionalArticles: Product[]
  addAdditionalArticle: Function
  removeAdditionalArticle: Function
  updateAdditionalArticleQuantity: Function
  isAllowedToReadProducts: boolean
}

const AdditionalArticleViewer = ({ campaign, addAdditionalArticle, removeAdditionalArticle, isAllowedToReadProducts, additionalArticles, updateAdditionalArticleQuantity }: AdditionalArticleViewProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)

  const [products, setProducts] = useState<Product[]>([])
  const [isLoadingCompanyProducts, setIsLoadingCompanyProducts] = useState(false)
  const [metadata, setMetadata] = useState<ApiMetadata>({
    page: 1,
    pageCount: 1,
    perPage: 4,
    total: 0
  })
  const [, setError] = useState(null)
  const [perPage, setPerPage] = useState(4)
  const [page, setPage] = useState(1)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const token = currentUser?.token
  const companyId = campaign.company?.id

  const searchSchema = object({
    search: string()
      .max(256, 'Search Name is too long')
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && isAllowedToReadProducts) {
      setIsLoadingCompanyProducts(true)
      CompanyService.getCompanyProducts(companyId, token, perPage, page, debouncedSearchTerm, signal).then((res: any) => {
        setProducts(res.data.products)
        setMetadata(res.data.meta)
        setIsLoadingCompanyProducts(false)
      }).catch((error: any) => {
        if (error.message !== 'canceled') {
          setError(error)
          setProducts([])
          setIsLoadingCompanyProducts(false)
        }
      })
    }
    return () => {
      controller.abort()
    }
  }, [page, perPage, debouncedSearchTerm, companyId])

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <div className="card p-4">
          <div className="mb-4">
            <div className="">
              <Formik
                validationSchema={searchSchema}
                enableReinitialize
                initialValues={{
                  search: ''
                }}
                onSubmit={({ search }, actions) => {
                  setSearchTerm(search)
                  setPage(1)

                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <input
                        onChange={(event) => {
                          const search = event.target.value
                          handleChange(event)
                          setSearchTerm(search)
                          setPage(1)
                        }}
                        maxLength={256}
                        onBlur={handleBlur}
                        value={values.search}
                        className={`form-control ${
                          errors.search &&
                          touched.search &&
                          errors.search
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder="Search company products..."
                        aria-label="Search company products"
                        aria-describedby="companyProductsSearch"
                        name="search"
                        autoComplete="on"
                      />
                      <button
                        className="btn btn-outline-dark"
                        id="companyProductsSearch"
                        type="submit"
                        title="Search"
                        disabled={isSubmitting}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-4" id="companyProducts">
          {isLoadingCompanyProducts
            ? (<ProductLoader repetition={perPage} />)
            : (products.length > 0
                ? (
                    products.map((product) => (
                      <AdditionalArticleItem
                        key={product.id}
                        product={product}
                        additionalArticles={additionalArticles}
                        addAdditionalArticle={addAdditionalArticle}
                        removeAdditionalArticle={removeAdditionalArticle}
                      />
                    ))
                  )
                : (
                  <div className="col">
                    <p className="text-primary">No products found</p>
                  </div>
                  ))
            }
          </div>
          <div className="row mt-4">
            <Pagination
              isLoading={isLoadingCompanyProducts}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              perPageOptions={[4, 8, 12, 24]}
              module="AdditionalArticleViewer"
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="mb-3">
          <div className="p-0 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Additional Articles</th>
                  <th scope="col">In Stock / Available</th>
                  <th scope="col" className="col-2">Quantity</th>
                  <th scope="col" className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  additionalArticles.length > 0
                    ? (
                        additionalArticles.map(additionalArticle => (
                          <AdditionalArticleRow
                            key={additionalArticle.id}
                            additionalArticle={additionalArticle}
                            removeAdditionalArticle={removeAdditionalArticle}
                            updateAdditionalArticleQuantity={updateAdditionalArticleQuantity}
                            module="AdditionalArticleViewer"
                          />
                        ))
                      )
                    : (
                      <tr>
                        <td colSpan={4}><div className="text-center"><span>No additional articles available yet</span></div></td>
                      </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdditionalArticleViewer
