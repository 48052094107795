import { hexToRGB } from './hexToRGB'

export const reduceOpacity = (hexColor: string, opacityPercentage: number) => {
  // Convert RGB to RGBA
  const rgbToRgba = (rgb: { r: number, g: number, b: number }, alpha: number) => `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`

  // Validate input
  if (!hexColor.startsWith('#') || !/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(hexColor)) {
    throw new Error('Invalid hex color value')
  }

  if (opacityPercentage < 0 || opacityPercentage > 100) {
    throw new Error('Opacity percentage must be between 0 and 100')
  }

  // Calculate alpha value
  const alpha = 1 - opacityPercentage / 100

  // Convert hex to RGBA
  const rgbColor = hexToRGB(hexColor)
  const rgbaColor = rgbToRgba(rgbColor, alpha)

  return rgbaColor
}
