import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductGraduatedPriceService from '../../../services/api/ProductGraduatedPriceService'
import { ApiProductGraduatedPriceState, ProductGraduatedPrice } from '../../../types'
import {
  PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE,
  PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE
} from '../../../constants/messages'

const initialState: ApiProductGraduatedPriceState = {
  isLoading: false,
  error: null,
  message: null
}

export const editProductGraduatedPriceById = createAsyncThunk('api/product-graduated-prices/edit', async ({ token, productGraduatedPriceId, productGraduatedPrice, signal }: { token: string, productGraduatedPriceId: any, productGraduatedPrice: Pick<ProductGraduatedPrice, 'price' | 'firstUnit' | 'lastUnit'>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductGraduatedPriceService.editProductGraduatedPriceById(token, productGraduatedPriceId, productGraduatedPrice, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteProductGraduatedPriceById = createAsyncThunk('api/product-graduated-prices/delete', async ({ token, productGraduatedPriceId, signal }: { token: string, productGraduatedPriceId: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductGraduatedPriceService.deleteProductGraduatedPriceById(token, productGraduatedPriceId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const productGraduatedPriceSlice = createSlice({
  name: 'api/product-graduated-price',
  initialState,
  reducers: {
    resetProductGraduatedPriceError: (state) => {
      state.error = null
    },
    resetProductGraduatedPriceMessage: (state) => {
      state.message = null
    },
    resetProductGraduatedPriceData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProductGraduatedPriceById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editProductGraduatedPriceById.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PRODUCT_GRADUATED_PRICE_UPDATE_MESSAGE
        state.error = null
      })
      .addCase(editProductGraduatedPriceById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(deleteProductGraduatedPriceById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProductGraduatedPriceById.fulfilled, (state) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_GRADUATED_PRICE_DELETION_MESSAGE
      })
      .addCase(deleteProductGraduatedPriceById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
  }
})

export const { resetProductGraduatedPriceError, resetProductGraduatedPriceMessage, resetProductGraduatedPriceData } = productGraduatedPriceSlice.actions

const { reducer } = productGraduatedPriceSlice
export default reducer
