import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import InboundService from '../../services/InboundService'
import { InboundsState } from '../../types'

const initialState: InboundsState = {
  isLoading: false,
  error: null,
  metadata: {
    limit: 10,
    offset: 0,
    total: 0
  },
  inbounds: []
}

export const getAllInbounds = createAsyncThunk('inbounds/get', async ({ token, perPage, offset, filter, signal }: { token: string, perPage: number, offset: number, filter: string, signal: AbortSignal}) => {
  const res = await InboundService.getAllInbounds(token, perPage, offset, filter, signal)
  return res.data
})

const inboundSlice = createSlice({
  name: 'inbounds',
  initialState,
  reducers: {
    resetInboundsError: (state) => {
      state.error = null
    },
    resetInbounds: (state) => {
      state.inbounds = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInbounds.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllInbounds.fulfilled, (state, action) => {
        state.isLoading = false
        state.inbounds = action.payload.items
        state.metadata = action.payload._page
        state.error = null
      })
      .addCase(getAllInbounds.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.error
      })
  }
})

export const { resetInboundsError, resetInbounds } = inboundSlice.actions

const { reducer } = inboundSlice
export default reducer
