import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserService from '../../services/UserService'
import { UsersState } from '../../types'

const initialState: UsersState = {
  isLoading: false,
  error: null,
  user: null
}

export const getCurrentUser = createAsyncThunk('users/get', async ({ token, signal }: { token: string, signal: AbortSignal}) => {
  const res = await UserService.getCurrentUser(token, signal)
  return res.data
})

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.user = action.payload
        state.error = null
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.error
      })
  }
})

export const { resetUserError } = userSlice.actions

const { reducer } = userSlice
export default reducer
