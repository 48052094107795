import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import OrderService from '../../../services/api/OrderService'
import { ApiOrderState } from '../../../types'
import { ORDER_VISIBILITY_CHANGE_SUCCESS_MESSAGE } from '../../../constants/messages'

const initialState: ApiOrderState = {
  isLoading: false,
  error: null,
  order: null,
  orders: [],
  message: null,
  areAllPagesForOrders: false,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  isLoadingOrderVisibility: false
}

type OrdersParams = { token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal}
export const getAllOrders = createAsyncThunk('api/orders/get', async ({ token, perPage, page, search, filter, signal }: OrdersParams, { rejectWithValue }) => {
  try {
    const res = await OrderService.getAllOrders(token, perPage, page, search, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const changeOrderVisibility = createAsyncThunk('api/orders', async ({ token, isVisible, orderId, signal }:{ token: string | undefined, isVisible: boolean, orderId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await OrderService.changeOrderVisibility(token, signal, isVisible, orderId)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
const orderSlice = createSlice({
  name: 'api/orders',
  initialState,
  reducers: {
    resetOrderError: (state) => {
      state.error = null
    },
    resetOrderMessage: (state) => {
      state.message = null
    },
    getAllPagesForOrders: (state, { payload }) => {
      state.areAllPagesForOrders = payload
    },
    resetApiOrderData: (state) => {
      state.error = null
      state.order = null
      state.orders = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrders.pending, (state) => {
        state.orders = []
        state.isLoading = true
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.orders = action.payload.orders
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
      .addCase(changeOrderVisibility.pending, (state) => {
        state.isLoadingOrderVisibility = true
        state.message = null
      })
      .addCase(changeOrderVisibility.fulfilled, (state, action) => {
        state.isLoadingOrderVisibility = false
        state.message = ORDER_VISIBILITY_CHANGE_SUCCESS_MESSAGE

        const updatedOrders = state.orders.map(order =>
          order.id === action.payload.order.id ? { ...order, isVisible: action.meta.arg.isVisible } : order
        )
        state.orders = updatedOrders
      })
      .addCase(changeOrderVisibility.rejected, (state) => {
        state.isLoadingOrderVisibility = false
        state.message = null
      })
  }
})

export const { resetOrderError, resetOrderMessage, resetApiOrderData, getAllPagesForOrders } = orderSlice.actions

const { reducer } = orderSlice
export default reducer
