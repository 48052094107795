import { Campaign } from '../types'

export const calculateCampaignOnboardingProgress = (campaign: Campaign) => {
  const totalProgress = {
    creationValue: 25,
    finalisationValue: campaign.status === 'submitted' ? 25 : 0,
    billingAddressValue: campaign.campaignAddresses?.find(address => address.type === 'billing') ? 25 : 0,
    returnAddressValue: campaign.campaignAddresses?.find(address => address.type === 'return') ? 25 : 0
  }

  const totalPendingTasks = Object.values(totalProgress).filter(value => value !== 25).length
  const totalProgressPercentage = Object.values(totalProgress).reduce((acc, curr) => acc + curr, 0)

  return {
    totalPendingTasks,
    totalProgressPercentage
  }
}
