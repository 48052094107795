import React from 'react'
import { useCSVDownloader } from 'react-papaparse'

type ExportToCSVProps = {
  data: Array<any>
  fileName: string
  isLoading?: boolean
  btnText: string
};
const CSVDownloader = ({ data, fileName, btnText }: ExportToCSVProps) => {
  const { CSVDownloader, Type } = useCSVDownloader()

  return (
    <CSVDownloader
      className="btn btn-primary"
      type={Type.Button}
      filename={fileName}
      bom={true}
      config={{
        delimiter: ',',
        header: true
      }}
      disabled
      data={data}
    >
      {btnText}
    </CSVDownloader>
  )
}

export default CSVDownloader
