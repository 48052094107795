import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Progress from '../../components/loaders/Progress'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Pagination from '../../components/Pagination'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { MaintenanceMode } from '../../types'
import * as userRoles from '../../constants/userRoles'
import {
  MAINTENANCE_MODE_CREATION_SUCCESS_MESSAGE,
  MAINTENANCE_MODE_DELETION_MESSAGE,
  MAINTENANCE_MODE_UPDATE_SUCCESS_MESSAGE
} from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import {
  addMaintenanceMode,
  deleteMaintenanceModeById, editMaintenanceModeById,
  getAllMaintenanceModes, resetMaintenanceModeError,
  resetMaintenanceModeMessage
} from '../../store/reducers/api/maintenanceModeReducer'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import MaintenanceModeEditor from '../../components/MaintenanceModes/MaintenanceModeEditor'

const MaintenanceModes = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiMaintenanceMode.isLoading)
  const message = useAppSelector((state) => state.apiMaintenanceMode.message)
  const maintenanceModes = useAppSelector((state) => state.apiMaintenanceMode.maintenanceModes)
  const metadata = useAppSelector((state) => state.apiMaintenanceMode.metadata)
  const error = useAppSelector((state) => state.apiMaintenanceMode.error)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)

  const [initialMaintenanceMode, setInitialMaintenanceMode] = useState<Partial<MaintenanceMode>>({
    reason: '',
    isActive: true,
    startDate: dayjs().local().toDate(),
    endDate: dayjs().local().toDate()
  })

  const token = currentUser?.token

  const role = currentUser?.role || userRoles.USER

  const dispatch = useAppDispatch()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleMaintenanceModeRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && role === userRoles.ADMIN) {
      dispatch(getAllMaintenanceModes({ token, perPage, page, signal }))
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && role === userRoles.ADMIN) {
      dispatch(getAllMaintenanceModes({ token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((message) === MAINTENANCE_MODE_CREATION_SUCCESS_MESSAGE ||
      (message) === MAINTENANCE_MODE_UPDATE_SUCCESS_MESSAGE ||
      (message) === MAINTENANCE_MODE_DELETION_MESSAGE
    ) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('maintenanceModeModal')
      dismissModal('maintenanceModeEditModal')
      if (token && role === userRoles.ADMIN) {
        dispatch(getAllMaintenanceModes({ token, perPage, page, signal }))
        dispatch(resetMaintenanceModeMessage())
      }
    }
  }, [message])

  useEffect(() => {
    if (error && error?.errors) {
      const payload = {
        title: 'Error',
        message: error?.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetMaintenanceModeError())
    }
  }, [error])

  return (
    <main className="container-fluid px-4 py-4">
      <div className="card">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3">
            <p className="h5"><i className="bi bi-wrench-adjustable-circle me-1"></i>Maintenance Modes</p>
            <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#maintenanceModeModal"
                title="Add Maintenance Mode"
                aria-label="Add Maintenance Mode"
                onClick={() => {
                  setInitialMaintenanceMode({
                    reason: '',
                    startDate: dayjs().local().toDate(),
                    endDate: dayjs().local().toDate(),
                    isActive: true
                  })
                }}
              >
                <i className="bi bi-plus-circle"></i>
                <span className="d-none d-md-inline-block ms-1">Add Maintenance Mode</span>
              </button>
              <button
                type="button"
                title="Refresh"
                aria-label="Refresh"
                className="btn btn-outline-dark ms-2 ms-md-3"
                onClick={() => handleMaintenanceModeRefresh()}
              >
                <i className="fas fa-redo"></i>
              </button>
            </ul>
          </div>
          {(isLoading) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Reason</th>
                  <th scope="col">Active</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col" className="text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  (isLoading)
                    ? (
                        Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={6} actionQuantity={2} />)
                      )
                    : (
                        maintenanceModes.length > 0
                          ? (maintenanceModes.map((maintenanceMode: MaintenanceMode) => (
                            <tr key={maintenanceMode.id} className={initialMaintenanceMode.id === maintenanceMode.id ? 'table-primary' : ''}>
                              <td className="user-select-all" onClick={() => navigator?.clipboard.writeText(String(maintenanceMode.id))}>
                                {(maintenanceMode.id).substring(0, 8)}
                              </td>
                              <td className="text-truncate" style={{ maxWidth: '150px' }} title={maintenanceMode.reason}>
                                {maintenanceMode.reason}
                              </td>
                              <td className="text-center">
                                <span>
                                  {maintenanceMode.isActive ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>}
                                </span>
                              </td>
                              <td className="text-nowrap">{dayjs(maintenanceMode.startDate).format('lll')}</td>
                              <td className="text-nowrap">{dayjs(maintenanceMode.endDate).format('lll')}</td>
                              <td className="text-end">
                                <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                  <button
                                    className="btn btn-outline-dark btn-round me-2"
                                    type="button"
                                    title="Edit Maintenance Mode"
                                    data-bs-toggle="modal"
                                    data-bs-target="#maintenanceModeEditModal"
                                    onClick={() => {
                                      setInitialMaintenanceMode(maintenanceMode)
                                    }}
                                  >
                                    <PencilIcon/>
                                  </button>
                                  <button
                                    className="btn btn-outline-danger btn-round"
                                    type="button"
                                    title="Delete Maintenance Mode"
                                    data-bs-toggle="modal"
                                    data-bs-target="#maintenanceModeConfirmationModal"
                                    onClick={() => {
                                      setInitialMaintenanceMode(maintenanceMode)
                                    }}
                                  >
                                    <TrashIcon/>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            ))
                            )
                          : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No maintenance modes available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoading}
            metadata={{
              limit: metadata.perPage,
              total: metadata.total,
              offset: ((metadata.page - 1) * (metadata.perPage))
            }}
            page={page}
            perPage={perPage}
            handlePageChange={handlePageChange}
            handleShowEntries={handleShowEntries}
          />
        </div>

          <div className="modal fade" id="maintenanceModeModal" tabIndex={-1} aria-labelledby="maintenanceModeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="maintenanceModeModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Add Maintenance Mode'}
                  </h5>
                  <button type="button"
                    onClick={() => dispatch(resetMaintenanceModeError())}
                    className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  >
                  </button>
                </div>
                {(isLoading) && <Progress />}
                <div className="modal-body">
                  <MaintenanceModeEditor
                    id={''}
                    initialMaintenanceMode={initialMaintenanceMode}
                    save={addMaintenanceMode}
                    isEdit={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="maintenanceModeEditModal" tabIndex={-1} aria-labelledby="maintenanceModeEditModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="maintenanceModeEditModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Edit Maintenance Mode'}
                  </h5>
                  <button type="button"
                    onClick={() => dispatch(resetMaintenanceModeError())}
                    className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  >
                  </button>
                </div>
                {isLoading && <Progress />}
                <div className="modal-body">
                  <MaintenanceModeEditor
                    id={String(initialMaintenanceMode.id)}
                    initialMaintenanceMode={initialMaintenanceMode}
                    save={editMaintenanceModeById}
                    isEdit={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="maintenanceModeConfirmationModal" tabIndex={-1} aria-labelledby="maintenanceModeConfirmationModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <h5 className="modal-title text-danger" id="maintenanceModeConfirmationModalLabel">
                    <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                {isLoading && <Progress />}
                <div className="modal-body">
                  <p>
                    Are you sure you want to delete
                    <span className="fw-bold">{` '${(initialMaintenanceMode.id)?.substring(0, 8)}' `}</span>
                    maintenance mode?
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      if (token && initialMaintenanceMode.id !== null) {
                        const controller = new AbortController()
                        const signal = controller.signal
                        dispatch(deleteMaintenanceModeById({ maintenanceModeId: String(initialMaintenanceMode.id), token, signal }))
                      }
                    }}
                    disabled={isLoading}
                    data-bs-dismiss="modal"
                    aria-label="Delete"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </main>
  )
}

export default MaintenanceModes
