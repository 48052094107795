import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductCategoryTagService from '../../../services/api/ProductCategoryTagService'
import { ApiProductCategoryTagState, ProductCategoryTag } from '../../../types'
import {
  PRODUCT_CATEGORY_TAG_DELETION_MESSAGE,
  PRODUCT_CATEGORY_TAG_UPDATE_SUCCESS_MESSAGE
} from '../../../constants/messages'

const initialState: ApiProductCategoryTagState = {
  isLoading: false,
  error: null,
  productCategoryTag: null,
  productCategoryTags: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getProductCategoryTags = createAsyncThunk('api/product-category-tags/get', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search: string }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryTagService.getProductCategoryTags(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const deleteProductCategoryTagById = createAsyncThunk('api/product-category-tags/delete', async ({ token, productCategoryTagId, signal }: { token: string, productCategoryTagId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryTagService.deleteProductCategoryTagById(token, productCategoryTagId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editProductCategoryTagById = createAsyncThunk('api/product-category-tags/edit', async ({ token, productCategoryTagId, productCategoryTag, signal }: { token: string, productCategoryTagId: string, productCategoryTag: Partial<ProductCategoryTag>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductCategoryTagService.editProductCategoryTagById(token, productCategoryTagId, productCategoryTag, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const productCategoryTagSlice = createSlice({
  name: 'api/product-category-tags',
  initialState,
  reducers: {
    resetProductCategoryTagError: (state) => {
      state.error = null
    },
    resetProductCategoryTagMessage: (state) => {
      state.message = null
    },
    resetProductCategoryTagData: (state) => {
      state.error = null
      state.productCategoryTag = null
      state.productCategoryTags = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductCategoryTags.pending, (state) => {
        state.productCategoryTags = []
        state.isLoading = true
      })
      .addCase(getProductCategoryTags.fulfilled, (state, action) => {
        state.isLoading = false
        state.productCategoryTags = action.payload.productCategoryTags
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getProductCategoryTags.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteProductCategoryTagById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProductCategoryTagById.fulfilled, (state) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_CATEGORY_TAG_DELETION_MESSAGE
      })
      .addCase(deleteProductCategoryTagById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
    builder
      .addCase(editProductCategoryTagById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editProductCategoryTagById.fulfilled, (state) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_CATEGORY_TAG_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(editProductCategoryTagById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.message = null
        state.error = action.payload
      })
  }
})

export const { resetProductCategoryTagError: resetProductCategoryError, resetProductCategoryTagMessage, resetProductCategoryTagData } = productCategoryTagSlice.actions

const { reducer } = productCategoryTagSlice
export default reducer
