import React from 'react'
import { Formik } from 'formik'
import { string, object } from 'yup'
import { joinCompanyByInviteCode } from '../../../store/reducers/api/usersReducer'
import { useAppDispatch } from '../../../store/hooks'
import { Role, UserCompany, User } from '../../../types'
import * as userRoles from '../../../constants/userRoles'

interface CompanyAssignmentProps {
  setMode: Function
  setTitle: Function
  company: UserCompany | null | undefined
  userId: string | null
  role: Role
  token: string | null
  setCompanyMode: Function
  editBillingOrDeliveryAddress: boolean
  profile: User | null
}
const CompanyAssignment = ({ setMode, setTitle, company, userId, token, setCompanyMode, role, editBillingOrDeliveryAddress, profile }: CompanyAssignmentProps) => {
  const dispatch = useAppDispatch()

  const disableForAdmin = Boolean(role === userRoles.ADMIN)
  const isOwner = Boolean(company && company.owner.id === profile?.id)
  const allowedRoles = [userRoles.ADMIN, userRoles.COMPANYADMINISTRATOR]
  const isAllowed = () => (isOwner || allowedRoles.includes(profile?.role || userRoles.USER))

  const verificationSchema = object({
    code: string()
      .when([], {
        is: () => !company && !disableForAdmin,
        then: string().required('Code is required'),
        otherwise: string().optional()
      })
  })

  const joinCompany = (code: string) => {
    const controller = new AbortController()
    const signal = controller.signal
    const user = {
      companyInviteCode: code
    }

    if (userId && token) {
      dispatch(joinCompanyByInviteCode({ id: userId, token, user, signal }))
    }
  }

  return (
    <div className="">
      <Formik
        validationSchema={verificationSchema}
        initialValues={{ code: '' }}
        onSubmit={({ code }, actions) => {
          if (!company && !disableForAdmin) {
            joinCompany(code)
          } else {
            if (editBillingOrDeliveryAddress && isAllowed()) {
              setMode('billingOrDeliveryAddress')
            } else {
              setMode('updateProfile')
              setTitle('Profile Information')
            }
          }
        }}
          >
        {({
          touched,
          errors,
          handleChange,
          handleBlur,
          values,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} >
            <div className="modal-body">
              <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      {
                        disableForAdmin && <p className="fw-semibold text-primary">This process is not necessary for the <span className="fw-bold">Administrator</span> role</p>
                      }
                      {
                        company
                          ? <p>You have been assigned to <span className="fw-bold">{company.name}</span>.</p>
                          : <p>If your company does not exist yet, click the <span className="fw-medium">&apos;Create Company&apos;</span> button below to create a new one.</p>
                      }
                      {
                        (isOwner || role === userRoles.COMPANYADMINISTRATOR) &&
                        <p className="text-primary fw-semibold lh-1">Click the &apos;View Company&apos; button to edit addresses</p>
                      }
                      <div className="col-md-6 d-grid d-md-block">
                        <button
                          title="Create a company"
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => {
                            setTitle(`${company ? 'View' : 'Create'} Company`)
                            setCompanyMode('companyCreation')
                          }}
                          disabled={disableForAdmin}
                        >
                          {`${company ? 'View' : 'Create'} Company`}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              {
                company
                  ? <p>You have been assigned to <span className="fw-bold">{company.name}</span>.</p>
                  : <p>If your company exists, please ask the company manager for a code to join your company. The company manager determines your role. <span className="text-primary fw-semibold">{"You will be logged out on successful company assignment and you'll be required to login with your new role."}</span></p>
              }
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label
                      htmlFor="code"
                      className="visually-hidden"
                    >
                      Code
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                      className={`form-control ${
                        errors.code &&
                        touched.code &&
                        errors.code
                          ? 'is-invalid'
                          : ''
                      }`}
                      type="text"
                      id="code"
                      name="code"
                      placeholder={company ? (company.name ?? '') : 'Code'}
                      disabled={Boolean(company) || disableForAdmin}
                      autoComplete="off"
                    />
                    <div
                      id="validationCodeFeedback"
                      className="invalid-feedback"
                    >
                      {errors.code}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setMode('verifyEmail')
                  setTitle('Email Verification')
                }}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                {company || disableForAdmin ? 'Next' : 'Join'}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyAssignment
