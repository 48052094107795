import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAllGreetingCards (token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/greeting-cards?${filter}`, config)
  },
  addGreetingCard (token: string, greetingCard: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/greeting-cards', { greetingCard }, config)
  },
  editGreetingCardById (token: string, greetingCardId: string, greetingCard: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/greeting-cards/${greetingCardId}`, { greetingCard }, config)
  },
  deleteGreetingCardById (token: string, greetingCardId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/greeting-cards/${greetingCardId}`, config)
  }
}
