import React from 'react'
import Placeholder from '../../../assets/images/placeholder.png'
import AnonymousImage from '../../Canvas/AnonymousImage'

const SkeletonImage = ({ repetition }: { repetition: number }) => {
  return (
    <>
      {Array.from(Array(repetition).keys()).map((n: number) => (
        <div key={n}className="col">
          <div className="card h-100 template-card" aria-hidden="true">
            <AnonymousImage src={Placeholder} style={{ objectFit: 'cover' }} alt={'Loading Card Template'} />
            <div className="card-body">
              <h5 className="card-title placeholder-glow">
                <span className="placeholder col-6"></span>
              </h5>
              <p className="card-text placeholder-glow"></p>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default SkeletonImage
