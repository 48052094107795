import React from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { Salutation, User } from '../../types'
import { countries } from '../../utils/countries'
import Progress from '../loaders/Progress'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as userRoles from '../../constants/userRoles'
import { rolesObject } from '../../constants/userRoles'
import {
  updateUserById
} from '../../store/reducers/api/usersReducer'
import { phoneValidationPattern } from '../../constants/regexPatterns'

interface ProfileEditorProps {
  profile: User | null
  isLoading: boolean
  isUpdating: boolean
  title?: string
}

const ProfileEditor = ({ profile, isLoading, isUpdating, title }: ProfileEditorProps) => {
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)

  const role = profile?.role || userRoles.USER
  const userId = profile?.id
  const token = profile?.token

  const dispatch = useAppDispatch()

  const profileSchema = object({
    salutation: string().nullable(),
    firstName: string()
      .required('First Name is required')
      .min(2, 'Enter a valid first name')
      .max(32, 'First Name is too long'),
    lastName: string()
      .required('Last Name is required')
      .min(2, 'Enter a valid last name')
      .max(32, 'Last Name is too long'),
    username: string()
      .nullable()
      .min(2, 'Username is too short')
      .max(32, 'Username is too long')
      .matches(/^\S+$/, 'Enter a valid username'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().oneOf(countries)
  })

  return (
    <div className="border border-gray rounded mb-3">
      {(isLoading || isUpdating) && <Progress />}
      <div className="p-3">
        <Formik
          validationSchema={profileSchema}
          initialValues={{
            salutation: profile?.salutation || '',
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            username: profile?.username || ''
          }}
          onSubmit={(
            { salutation, firstName, lastName, username },
            actions
          ) => {
            const controller = new AbortController()
            const signal = controller.signal
            const user = {
              salutation,
              firstName,
              lastName,
              username: username || null
            }
            if (token && userId) {
              dispatch(
                updateUserById({
                  id: userId,
                  token,
                  user,
                  signal
                })
              )
            }
            actions.setSubmitting(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              {title && <h6 className="mb-4 text-uppercase">
                <i className="bi bi-pencil-square me-1"></i>{' '}
                Update Personal Information
              </h6>}
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="profileSalutation"
                      className="form-label"
                    >
                      Salutation
                    </label>
                    <div className="input-group">
                      <select
                        aria-label="Salutation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.salutation}
                        className={`form-select ${
                          touched.salutation &&
                          errors.salutation
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="profileSalutation"
                        name="salutation"
                        aria-describedby="button-addon2"
                      >
                        <option value="">Select Salutation</option>
                        {salutations.map(
                          (salutation: Salutation, index: number) => (
                            <option key={index}>
                              {salutation.title}
                            </option>
                          )
                        )}
                      </select>
                      {
                        role === userRoles.ADMIN && (
                          <>
                            <button
                              type="button"
                              id="button-addon"
                              className="btn btn-outline-secondary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#salutationEditModal"
                            >
                              <i className="bi bi-pencil"></i>
                            </button>
                            <button
                              type="button"
                              id="button-addon2"
                              className="btn btn-outline-secondary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#salutationModal"
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </>
                        )
                      }
                    </div>
                    <div
                      id="validationSalutationFeedback"
                      className="invalid-feedback"
                    >
                      {errors.salutation}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="profileFirstName"
                      className="form-label"
                    >
                      First Name
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      type="text"
                      className={`form-control ${
                        touched.firstName &&
                        errors.firstName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileFirstName"
                      name="firstName"
                      placeholder="Enter first name"
                    />
                    <div
                      id="validationFirstNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="profileLastName"
                      className="form-label"
                    >
                      Last Name
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      type="text"
                      className={`form-control ${
                        touched.lastName &&
                        errors.lastName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="profileLastName"
                      name="lastName"
                      placeholder="Enter last name"
                    />
                    <div
                      id="validationLastNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.lastName}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="profileEmail"
                      className="form-label"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={profile?.email || ''}
                      id="profileEmail"
                      name="email"
                      placeholder="Email"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="profileRole" className="form-label">
                      Role
                    </label>
                    <input
                      value={rolesObject[profile?.role ?? userRoles.USER] || ''}
                      type="text"
                      className={'form-control'}
                      id="profileRole"
                      name="role"
                      placeholder="Role"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-primary mt-2"
                  disabled={
                    isSubmitting || isUpdating || isLoading
                  }
                >
                  <i className="bi bi-save"></i> Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ProfileEditor
