export const OrderStatus = {
  Acknowledged: { colour: 'confirmed', text: 'Confirmed' },
  Shipped: { colour: 'info', text: 'Shipped' },
  Canceled: { colour: 'danger', text: 'Cancelled' },
  Pending: { colour: 'info', text: 'Pending' },
  Preparation: { colour: 'info', text: 'Preparation' },
  Pickprocess: { colour: 'info', text: 'In Progress' },
  Locked: { colour: 'dark', text: 'Locked' },
  PartiallyShipped: { colour: 'warning', text: 'Partially Shipped' },
  PartiallyCanceled: { colour: 'warning', text: 'Partially Cancelled' }
}

export const OrderStatusDHL: any = {
  'pre-transit': { colour: 'info', text: 'Pre-Transit' },
  transit: { colour: 'info', text: 'Transit' },
  delivered: { colour: 'success', text: 'Delivered' },
  failure: { colour: 'danger', text: 'Failure' },
  unknown: { colour: 'secondary', text: 'Unknown' },
  returned: { colour: 'warning', text: 'Returned' }
}
