import React, { useState, useEffect, ChangeEvent } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Pagination from '../../../components/Pagination'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import * as userRoles from '../../../constants/userRoles'
import { useParams } from 'react-router-dom'
import {
  getProductCategoryById,
  getProductsOfProductCategory,
  getTagsOfProductCategory,
  resetProductCategoryError,
  resetProductCategoryMessage
} from '../../../store/reducers/api/productCategoryReducer'
import useDebounce from '../../../utils/hooks/useDebounce'
import Progress from '../../../components/loaders/Progress'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'
import { TrashIcon } from '../../../components/icons/TrashIcon'
import { ProductCategoryProduct, ProductCategoryTag } from '../../../types'
import ProductCategoryTagEditor from '../../../components/ProductCategories/ProductCategoryTagEditor'
import dayjs from 'dayjs'
import { setToast } from '../../../store/reducers/toastReducer'
import {
  PRODUCT_CATEGORY_PRODUCTS_ADDITION_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_TAG_CREATION_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_TAG_DELETION_MESSAGE,
  PRODUCT_CATEGORY_TAG_UPDATE_SUCCESS_MESSAGE,
  PRODUCT_IN_PRODUCT_CATEGORY_DELETION_MESSAGE,
  PRODUCT_TAGS_UPDATE_MESSAGE
} from '../../../constants/messages'
import { dismissModal } from '../../../utils/dismissModal'
import { deleteProductCategoryTagById, resetProductCategoryTagMessage } from '../../../store/reducers/api/productCategoryTagReducer'
import ProductSelector from '../../../components/ProductCategories/ProductSelector'
import { resetProductMessage } from '../../../store/reducers/api/productReducer'
import ProductTagSelector from '../../../components/ProductCategories/ProductTagSelector'
import { deleteProductInProductCategoryGroup, resetProductInProductCategoryMessage } from '../../../store/reducers/api/productInProductCategoryReducer'
import { PencilIcon } from '../../../components/icons/PencilIcon'
import Placeholder from '../../../assets/images/placeholder.png'

const ProductCategoryDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiProductCategory.isLoading)
  const isLoadingProductCategoryProductCategoryTags = useAppSelector((state) => state.apiProductCategory.isLoadingProductCategoryTags)
  const isLoadingProductCategoryProducts = useAppSelector((state) => state.apiProductCategory.isLoadingProductCategoryProducts)
  const isLoadingProductCategoryTags = useAppSelector((state) => state.apiProductCategoryTag.isLoading)
  const productCategoryTagsMessage = useAppSelector((state) => state.apiProductCategoryTag.message)
  const productCategoryTags = useAppSelector((state) => state.apiProductCategory.productCategoryTags)
  const productCategoryTagsMetadata = useAppSelector((state) => state.apiProductCategory.productCategoryTagsMetadata)
  const productCategoryProducts = useAppSelector((state) => state.apiProductCategory.productCategoryProducts)
  const productCategoryProductsMetadata = useAppSelector((state) => state.apiProductCategory.productCategoryProductsMetadata)
  const message = useAppSelector((state) => state.apiProductCategory.message)
  const productCategory = useAppSelector((state) => state.apiProductCategory.productCategory)
  const error = useAppSelector((state) => state.apiProductCategory.error)
  const isLoadingProduct = useAppSelector((state) => state.apiProduct.isLoading)
  const productMessage = useAppSelector((state) => state.apiProduct.message)
  const isLoadingProductProductCategory = useAppSelector((state) => state.apiProductInProductCategory.isLoading)
  const productProductCategoryMessage = useAppSelector((state) => state.apiProductInProductCategory.message)

  const [searchTermProductCategoryTags, setSearchTermProductCategoryTags] = useState<string>('')
  const debouncedSearchTermProductCategoryTags: string = useDebounce<string>(searchTermProductCategoryTags, 800)
  const [searchTermProducts, setSearchTermProducts] = useState<string>('')
  const debouncedSearchTermProducts: string = useDebounce<string>(searchTermProducts, 800)

  const token = currentUser?.token
  const role = profile?.role

  const dispatch = useAppDispatch()
  const { categoryId: productCategoryId } = useParams()

  const [perPageProductCategoryTags, setPerPageProductCategoryTags] = useState(5)
  const [pageProductCategoryTags, setPageProductCategoryTags] = useState(1)
  const [perPageProducts, setPerPageProducts] = useState(5)
  const [pageProducts, setPageProducts] = useState(1)
  const [initialProductCategoryTag, setInitialProductCategoryTag] = useState<Partial<ProductCategoryTag>>({
    name: ''
  })
  const [initialProductCategoryProduct, setInitialProductCategoryProduct] = useState<Partial<ProductCategoryProduct>>({
    id: ''
  })
  const [productCategoryTagEditMode, setProductCategoryTagEditMode] = useState(false)
  const [productTagSelectorReloadKey, setProductTagSelectorReloadKey] = useState(0)

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const handleProductCategoryTagsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productCategoryId && role === userRoles.ADMIN) {
      dispatch(getTagsOfProductCategory({ token, productCategoryId, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, signal, search: debouncedSearchTermProductCategoryTags }))
    }
  }

  const handleProductsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productCategoryId && role === userRoles.ADMIN) {
      dispatch(getProductsOfProductCategory({ token, productCategoryId, perPage: perPageProducts, page: pageProducts, signal, search: debouncedSearchTermProducts }))
    }
  }

  const handleProductCategoryTagsShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageProductCategoryTags(1)
    setPerPageProductCategoryTags(Number(event.target.value))
  }

  const handleProductCategoryTagsPageChange = (page: number) => {
    setPageProductCategoryTags(page)
  }

  const handleProductsShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageProducts(1)
    setPerPageProducts(Number(event.target.value))
  }

  const handleProductsPageChange = (page: number) => {
    setPageProducts(page)
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productCategoryId) {
      dispatch(getProductCategoryById({ token, productCategoryId, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [productCategoryId])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productCategoryId) {
      dispatch(getTagsOfProductCategory({ token, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
    }

    return () => {
      controller.abort()
    }
  }, [productCategoryId, perPageProductCategoryTags, pageProductCategoryTags, debouncedSearchTermProductCategoryTags])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productCategoryId) {
      dispatch(getProductsOfProductCategory({ token, perPage: perPageProducts, page: pageProducts, productCategoryId, signal, search: debouncedSearchTermProducts }))
    }

    return () => {
      controller.abort()
    }
  }, [productCategoryId, perPageProducts, pageProducts, debouncedSearchTermProducts])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (message === PRODUCT_CATEGORY_TAG_CREATION_SUCCESS_MESSAGE || message === PRODUCT_CATEGORY_PRODUCTS_ADDITION_SUCCESS_MESSAGE) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (message === PRODUCT_CATEGORY_TAG_CREATION_SUCCESS_MESSAGE) {
        setProductTagSelectorReloadKey((prevState) => prevState + 1)
        dismissModal('productCategoryTagEditorModal')
        if (token && productCategoryId && role === userRoles.ADMIN) {
          dispatch(getTagsOfProductCategory({ token, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
          dispatch(resetProductCategoryMessage())
        }
      }
      if (message === PRODUCT_CATEGORY_PRODUCTS_ADDITION_SUCCESS_MESSAGE) {
        if (token && productCategoryId && role === userRoles.ADMIN) {
          dispatch(getProductsOfProductCategory({ token, perPage: perPageProducts, page: pageProducts, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
          dispatch(resetProductCategoryMessage())
        }
      }
    }
    if (productMessage === PRODUCT_TAGS_UPDATE_MESSAGE) {
      const payload = {
        title: 'Success',
        message: productMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (productMessage === PRODUCT_TAGS_UPDATE_MESSAGE) {
        if (token && productCategoryId && role === userRoles.ADMIN) {
          dispatch(getProductsOfProductCategory({ token, perPage: perPageProducts, page: pageProducts, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
          dispatch(resetProductMessage())
        }
      }
    }
    if (productProductCategoryMessage === PRODUCT_IN_PRODUCT_CATEGORY_DELETION_MESSAGE) {
      const payload = {
        title: 'Success',
        message: productProductCategoryMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('categoryProductRemovalModal')
      if (token && productCategoryId && role === userRoles.ADMIN) {
        dispatch(getProductsOfProductCategory({ token, perPage: perPageProducts, page: pageProducts, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
        dispatch(resetProductInProductCategoryMessage())
      }
    }
  }, [message, productMessage, productProductCategoryMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (productCategoryTagsMessage === PRODUCT_CATEGORY_TAG_DELETION_MESSAGE) {
      const payload = {
        title: 'Success',
        message: productCategoryTagsMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('productCategoryTagDeleteModal')
      setProductTagSelectorReloadKey((prevState) => prevState + 1)
      if (token && productCategoryId && role === userRoles.ADMIN) {
        dispatch(getTagsOfProductCategory({ token, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
        dispatch(getProductsOfProductCategory({ token, perPage: perPageProducts, page: pageProducts, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
        dispatch(resetProductCategoryTagMessage())
      }
    }
    if (productCategoryTagsMessage === PRODUCT_CATEGORY_TAG_UPDATE_SUCCESS_MESSAGE) {
      const payload = {
        title: 'Success',
        message: productCategoryTagsMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('productCategoryTagEditorModal')
      setProductTagSelectorReloadKey((prevState) => prevState + 1)
      if (token && productCategoryId && role === userRoles.ADMIN) {
        dispatch(getTagsOfProductCategory({ token, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
        dispatch(getProductsOfProductCategory({ token, perPage: perPageProducts, page: pageProducts, productCategoryId, signal, search: debouncedSearchTermProductCategoryTags }))
        dispatch(resetProductCategoryTagMessage())
      }
    }
  }, [productCategoryTagsMessage])

  useEffect(() => {
    if (error && error?.errors) {
      const payload = {
        title: 'Error',
        message: error?.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetProductCategoryError())
    }
  }, [error])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand d-flex flex-column flex-sm-row">
              {
                isLoading
                  ? (
                    <p className="placeholder-glow w-100 h5">
                      <span className="placeholder col-7 rounded"></span>
                    </p>
                    )
                  : (
                    <p className="w-100 h5" title={`${productCategory?.name || '---'}`}>
                      <i className="bi bi-info-square me-2"></i>{productCategory?.name || '---'}
                    </p>
                    )
              }
            </div>
          </div>
        </div>

        <div className="card mt-4">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
              <p className="h5"><i className="bi bi-tag me-2"></i>Category Tags</p>
              {
                currentUser?.role === userRoles.ADMIN && (
                  <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                    <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                      <Formik
                        validationSchema={searchSchema}
                        enableReinitialize
                        initialValues={{
                          search: ''
                        }}
                        onSubmit={({ search }, actions) => {
                          setSearchTermProductCategoryTags(search)
                          if (pageProductCategoryTags !== 1) {
                            setPageProductCategoryTags(1)
                          }

                          actions.setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="input-group">
                              <input
                                onChange={(event) => {
                                  const search = event.target.value
                                  handleChange(event)
                                  setSearchTermProductCategoryTags(search)
                                  if (pageProductCategoryTags !== 1) {
                                    setPageProductCategoryTags(1)
                                  }
                                }}
                                maxLength={24}
                                onBlur={handleBlur}
                                value={values.search}
                                className={`form-control ${
                                  errors.search && touched.search && errors.search
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                type="text"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="btnNavbarSearch"
                                name="search"
                              />
                              <button
                                className="btn btn-outline-dark"
                                id="btnNavbarSearch"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                      data-bs-toggle="modal"
                      data-bs-target="#productCategoryTagEditorModal"
                      title="Add Category Tag"
                      onClick={() => {
                        setProductCategoryTagEditMode(false)
                        setInitialProductCategoryTag({
                          name: ''
                        })
                      }}
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>Category Tag</span>
                    </button>
                    <button
                      type="button"
                      title="Refresh Category Tags"
                      aria-label="Refresh Category Tags"
                      className="btn btn-outline-dark"
                      onClick={() => handleProductCategoryTagsRefresh()}
                    >
                      <i className="fas fa-redo"></i>
                    </button>
                  </ul>
                )
              }
            </div>
            {isLoadingProductCategoryProductCategoryTags ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap align-middle">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap w-20">Tag Id</th>
                    <th scope="col" className="w-35">Name</th>
                    <th scope="col" className="w-30">Type</th>
                    <th scope="col" className="text-center w-15">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingProductCategoryProductCategoryTags
                      ? (
                          Array.from(Array(perPageProductCategoryTags).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={2} />)
                        )
                      : (
                          productCategoryTags.length > 0
                            ? (
                                productCategoryTags.map((productCategoryTag) => (
                                  <tr key={productCategoryTag.id} className={initialProductCategoryTag?.id === productCategoryTag.id ? 'table-primary' : ''}>
                                    <td>
                                      <span
                                        title="Click To Copy Product Category Tag Id"
                                        onClick={() => navigator.clipboard.writeText(String(productCategoryTag.id))}
                                        className="user-select-all"
                                      >
                                        {(productCategoryTag.id).substring(0, 8)}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        title={'Click To Copy Product Category Tag Name'}
                                        onClick={() => navigator.clipboard.writeText(String(productCategoryTag.name))}
                                        className="user-select-all"
                                      >
                                        {`${productCategoryTag.name}`}
                                      </span>
                                    </td>
                                    <td>
                                      {productCategoryTag.type || ''}
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex flex-row float-end" role="group" aria-label="Product Category Tag Action Buttons">
                                        {
                                          currentUser?.role === userRoles.ADMIN && (
                                            <>
                                              <button
                                                type="button"
                                                title="Edit Product Category Tag"
                                                className="btn btn-outline-dark btn-round me-2"
                                                data-bs-toggle="modal"
                                                data-bs-target="#productCategoryTagEditorModal"
                                                onClick={() => {
                                                  setProductCategoryTagEditMode(true)
                                                  setInitialProductCategoryTag(productCategoryTag)
                                                }}
                                              >
                                                <PencilIcon />
                                              </button>
                                              <button
                                                type="button"
                                                title="Delete Product Category Tag"
                                                className="btn btn-outline-danger btn-round"
                                                data-bs-toggle="modal"
                                                data-bs-target="#productCategoryTagDeleteModal"
                                                onClick={() => {
                                                  setInitialProductCategoryTag(productCategoryTag)
                                                }}
                                              >
                                                <TrashIcon/>
                                              </button>
                                            </>
                                          )
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  No category tags available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoadingProductCategoryProductCategoryTags}
              metadata={{
                limit: productCategoryTagsMetadata.perPage,
                total: productCategoryTagsMetadata.total,
                offset: ((productCategoryTagsMetadata.page - 1) * (productCategoryTagsMetadata.perPage))
              }}
              page={pageProductCategoryTags}
              perPage={perPageProductCategoryTags}
              handlePageChange={handleProductCategoryTagsPageChange}
              handleShowEntries={handleProductCategoryTagsShowEntries}
              module="productCategoryTags"
              perPageOptions={[5, 10, 25]}
            />

          </div>
        </div>

        <div className="card mt-4">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
              <p className="h5"><i className="bi bi-bag me-2"></i>Products</p>
              {
                currentUser?.role === userRoles.ADMIN && (
                  <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                    <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                      <Formik
                        validationSchema={searchSchema}
                        enableReinitialize
                        initialValues={{
                          search: ''
                        }}
                        onSubmit={({ search }, actions) => {
                          setSearchTermProducts(search)
                          if (pageProducts !== 1) {
                            setPageProducts(1)
                          }

                          actions.setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="input-group">
                              <input
                                onChange={(event) => {
                                  const search = event.target.value
                                  handleChange(event)
                                  setSearchTermProducts(search)
                                  if (pageProducts !== 1) {
                                    setPageProducts(1)
                                  }
                                }}
                                maxLength={24}
                                onBlur={handleBlur}
                                value={values.search}
                                className={`form-control ${
                                  errors.search && touched.search && errors.search
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                type="text"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="btnNavbarSearch"
                                name="search"
                              />
                              <button
                                className="btn btn-outline-dark"
                                id="btnNavbarSearch"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                      data-bs-toggle="modal"
                      data-bs-target="#productsAdditionModal"
                      title="Add Product"
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>Product</span>
                    </button>
                    <button
                      type="button"
                      title="Refresh"
                      aria-label="Refresh"
                      className="btn btn-outline-dark"
                      onClick={() => handleProductsRefresh()}
                    >
                      <i className="fas fa-redo"></i>
                    </button>
                  </ul>
                )
              }
            </div>
            {isLoadingProductCategoryProducts ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap align-middle">
                <thead>
                  <tr>
                    <th scope="col" className="w-10">Image</th>
                    <th scope="col" className="w-15">SKU</th>
                    <th scope="col" className="w-35">Name</th>
                    <th scope="col" className="text-nowrap w-30">Product Tags</th>
                    <th scope="col" className="text-center w-10">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoadingProductCategoryProducts
                      ? (
                          Array.from(Array(perPageProducts).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={5} actionQuantity={2} hasThumbnail />)
                        )
                      : (
                          productCategoryProducts.length > 0
                            ? (
                                productCategoryProducts.map((productCategoryProduct) => (
                                    <tr key={productCategoryProduct.id} className={initialProductCategoryProduct?.id === productCategoryProduct.id ? 'table-primary' : ''}>
                                      <td>
                                        {
                                          productCategoryProduct.product.pictures && productCategoryProduct.product.pictures.length > 0
                                            ? (
                                              <img
                                                src={productCategoryProduct.product.pictures[0].publicUrl}
                                                alt="Product thumbnail"
                                                className="product-thumbnail"
                                                onError={(e) => {
                                                  const target = e.target as HTMLImageElement
                                                  target.onerror = null
                                                  target.src = Placeholder
                                                }}
                                              />
                                              )
                                            : (<img src={Placeholder} alt="Placeholder thumbnail" className="product-thumbnail" />)
                                        }
                                      </td>
                                      <td>
                                        <span
                                          title={'Click To Copy SKU Of The Product'}
                                          onClick={() => navigator.clipboard.writeText(String(`${productCategoryProduct.product.merchantSku}`))}
                                          className="user-select-all"
                                        >
                                          {productCategoryProduct.product.merchantSku}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          title={'Click To Copy Name Of The Product'}
                                          onClick={() => navigator.clipboard.writeText(String(`${productCategoryProduct.product.name}`))}
                                          className="user-select-all"
                                        >
                                          {`${productCategoryProduct.product.name}`}
                                        </span>
                                      </td>
                                      <td>
                                        {
                                          productCategoryProduct.product.productTags
                                            .map(tag => (<span key={tag.id} className={`badge rounded-pill ${tag.productCategoryTag?.name ? 'text-bg-secondary' : 'text-bg-danger'} me-1`}>{tag.productCategoryTag?.name || (tag.productCategoryTagId?.substring(0, 8) ?? '-')}</span>))
                                        }
                                      </td>
                                      <td className="text-center">
                                        <div className="d-flex flex-row float-end" role="group" aria-label="Product Action Buttons">
                                          {
                                            currentUser?.role === userRoles.ADMIN && (
                                              <>
                                                <button
                                                  type="button"
                                                  title="Tag Product"
                                                  className="btn btn-outline-dark btn-round me-2"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#productTagAdditionModal"
                                                  onClick={() => {
                                                    setInitialProductCategoryProduct(productCategoryProduct)
                                                  }}
                                                >
                                                  <i className="bi bi-tag"></i>
                                                </button>
                                                <button
                                                  type="button"
                                                  title="Remove Product"
                                                  className="btn btn-outline-danger btn-round"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#categoryProductRemovalModal"
                                                  onClick={() => {
                                                    setInitialProductCategoryProduct(productCategoryProduct)
                                                  }}
                                                >
                                                  <TrashIcon/>
                                                </button>
                                              </>
                                            )
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                ))
                              )
                            : (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  No category products available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoadingProductCategoryProducts}
              metadata={{
                limit: productCategoryProductsMetadata.perPage,
                total: productCategoryProductsMetadata.total,
                offset: ((productCategoryProductsMetadata.page - 1) * (productCategoryProductsMetadata.perPage))
              }}
              page={pageProducts}
              perPage={perPageProducts}
              handlePageChange={handleProductsPageChange}
              handleShowEntries={handleProductsShowEntries}
              module="categoryProducts"
              perPageOptions={[5, 10, 25]}
            />

          </div>
        </div>

        {/* Modals */}
        <div className="modal fade" id="productCategoryTagEditorModal" tabIndex={-1} aria-labelledby="productCategoryTagEditorModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="productCategoryTagEditorModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  {`${productCategoryTagEditMode ? 'Edit' : 'Add'} Product Category Tag`}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {(isLoading || isLoadingProductCategoryTags) && <Progress />}
              <div className="modal-body">
                <ProductCategoryTagEditor
                  id={productCategoryId ?? ''}
                  initialProductCategoryTag={initialProductCategoryTag}
                  isEdit={productCategoryTagEditMode}
                  isLoading={isLoading || isLoadingProductCategoryTags}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="productCategoryTagDeleteModal" tabIndex={-1} aria-labelledby="productCategoryTagDeleteModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title text-danger" id="productCategoryTagDeleteModalLabel">
                  <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {(isLoadingProductCategoryTags) && <Progress />}
              <div className="modal-body">
                <p>
                  Are you sure you want to remove
                  <span className="fw-bold">{` '${initialProductCategoryTag?.name}' `}</span>
                  from {`${productCategory?.name} product category`}?
                </p>
                <p className="small fw-bold text-danger"><i className="bi bi-exclamation-circle me-1"></i>All linked product tags will be deleted.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    if (token && initialProductCategoryTag !== null) {
                      const controller = new AbortController()
                      const signal = controller.signal
                      dispatch(deleteProductCategoryTagById({ productCategoryTagId: String(initialProductCategoryTag.id), token, signal }))
                    }
                  }}
                  disabled={(isLoadingProductCategoryTags)}
                  aria-label="Delete"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="productsAdditionModal" tabIndex={-1} aria-labelledby="productsAdditionModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-capitalize" id="productsAdditionModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  {`Add Products to ${productCategory?.name || 'category'}`}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {isLoadingProductCategoryProducts ? <Progress /> : <hr className="mt-0 border border-primary border-1 opacity-50"></hr>}
              <div className="modal-body">
                <ProductSelector isLoading={isLoadingProductCategoryProducts} productCategoryId={productCategoryId} />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="categoryProductRemovalModal" tabIndex={-1} aria-labelledby="categoryProductRemovalModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title text-danger" id="categoryProductRemovalModalLabel">
                  <i className="bi bi-trash text-danger me-2"></i>Confirm Removal
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {(isLoadingProductProductCategory) && <Progress />}
              <div className="modal-body">
                <p>
                  Are you sure you want to remove
                  <span className="fw-bold">{` '${initialProductCategoryProduct.product?.name}' `}</span>
                  from {`${productCategory?.name} product category`}?
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    if (token && initialProductCategoryProduct !== null) {
                      const controller = new AbortController()
                      const signal = controller.signal
                      dispatch(deleteProductInProductCategoryGroup({ productProductCategoryId: String(initialProductCategoryProduct.id), token, signal }))
                    }
                  }}
                  disabled={(isLoadingProductProductCategory)}
                  aria-label="Remove"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="productTagAdditionModal" tabIndex={-1} aria-labelledby="productTagAdditionModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="productTagAdditionModalLabel">
                  <i className="bi-plus-circle me-1"></i>{' '}
                  Tag Product
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {isLoadingProduct ? <Progress /> : <hr className="mt-0 border border-primary border-1 opacity-50"></hr>}
              <div className="modal-body">
                <ProductTagSelector
                  isLoading={isLoadingProduct}
                  productCategoryProduct={initialProductCategoryProduct}
                  productCategoryId={productCategoryId}
                  reloadKey={productTagSelectorReloadKey}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProductCategoryDetails
