import axios from 'axios'

const baseURL = process.env.REACT_APP_JTL_BACKEND_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api/products`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAProductStockSerialNumbers (token: string, merchantSku: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`${merchantSku}/stock-products`, config)
  }
}
