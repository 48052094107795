export const validateBarcode = (value: string | undefined) => {
  if (value) {
    if (value.length === 8) {
      const checkDigit = parseInt(value.charAt(value.length - 1), 10)
      const calculatedCheckDigit =
      (10 - (
        value
          .slice(0, 7)
          .split('')
          .reverse()
          .reduce(
            (sum, digit, index) =>
              sum + (parseInt(digit, 10) * ((index % 2 === 0) ? 3 : 1)),
            0
          ) % 10
      )) % 10

      return checkDigit === calculatedCheckDigit
    }
    if (value.length === 12 || value.length === 13 || value.length === 14) {
      const checkDigit = parseInt(value.charAt(value.length - 1), 10)
      const calculatedCheckDigit =
      (10 - (
        value
          .slice(0, value.length - 1)
          .split('')
          .reduce(
            (sum, digit, index) =>
              sum + (index % 2 === value.length % 2 ? parseInt(digit, 10) * 3 : parseInt(digit, 10)),
            0
          ) % 10
      )) % 10
      return checkDigit === calculatedCheckDigit
    }
  }
  return true
}
