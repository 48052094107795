import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import EmailTemplateService from '../../../services/api/EmailTemplateService'
import { EMAIL_TEMPLATE_SUCCESS_MESSAGE, EMAIL_TEMPLATE_UPDATE_SUCCESS_MESSAGE } from '../../../constants/messages'
import { ApiEmailTemplateState } from '../../../types'

const initialState: ApiEmailTemplateState = {
  isLoading: false,
  error: null,
  emailTemplate: null,
  emailTemplates: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const createEmailTemplate = createAsyncThunk('api/email-templates/post', async ({ token, emailTemplate, signal }:{token: string, emailTemplate: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const result = await EmailTemplateService.createEmailTemplate(token, signal, emailTemplate)
    return result.data
  } catch (error:any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAllEmailTemplates = createAsyncThunk('api/email-templates/get', async ({ token, perPage, page, signal }:{token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const result = await EmailTemplateService.getAllEmailTemplates(token, perPage, page, signal)
    return result.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateEmailTemplate = createAsyncThunk('api/email-templates/update', async ({ id, token, emailTemplate, signal }:{id: string, token: string, emailTemplate: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const result = await EmailTemplateService.updateEmailTemplate(token, id, emailTemplate, signal)
    return result.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})
const emailTemplateSlice = createSlice({
  name: 'api/email-templates',
  initialState,
  reducers: {
    resetEmailTemplateData: (state) => {
      state.emailTemplates = []
      state.isLoading = false
      state.message = null
    },
    resetEmailTemplateMessage: (state) => {
      state.message = null
    },
    resetEmailTemplateError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmailTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createEmailTemplate.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
      .addCase(createEmailTemplate.fulfilled, (state) => {
        state.isLoading = false
        state.message = EMAIL_TEMPLATE_SUCCESS_MESSAGE
      })
    builder
      .addCase(getAllEmailTemplates.fulfilled, (state, action) => {
        state.isLoading = false
        state.emailTemplates = action.payload.emailTemplates
        state.metadata = action.payload.meta
        state.message = null
      })
      .addCase(getAllEmailTemplates.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllEmailTemplates.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateEmailTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateEmailTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.emailTemplate = action.payload.emailTemplate
        state.message = EMAIL_TEMPLATE_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(updateEmailTemplate.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetEmailTemplateData, resetEmailTemplateMessage, resetEmailTemplateError } = emailTemplateSlice.actions
const { reducer } = emailTemplateSlice
export default reducer
