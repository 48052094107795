import { createSlice } from '@reduxjs/toolkit'
import { OnboardingState } from '../../types'

const initialState: OnboardingState = {
  onboardingProgress: 0,
  totalPendingTasks: 0
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboardingProgress: (state, action) => {
      const { onboardingProgress, totalPendingTasks } = action.payload
      state.onboardingProgress = onboardingProgress
      state.totalPendingTasks = totalPendingTasks
    },
    resetOnboardingProgress: (state) => {
      state.onboardingProgress = 0
      state.totalPendingTasks = 0
    }
  }
})

export const { setOnboardingProgress, resetOnboardingProgress } = onboardingSlice.actions

const { reducer } = onboardingSlice
export default reducer
