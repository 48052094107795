import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string, date } from 'yup'
import dayjs from 'dayjs'
import Select from 'react-select'
import Calendar from 'react-datepicker'
import AsyncSelect from 'react-select/async'
import { countriesObject } from '../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Bundle, Campaign, Product, Recipient, Salutation, orders } from '../../types'
import { getAllSalutations } from '../../store/reducers/api/salutationReducer'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import * as userRoles from '../../constants/userRoles'
import Progress from '../loaders/Progress'
import { createBulkOrders, resetPendingOrders } from '../../store/reducers/api/campaignReducer'
import { openModal } from '../../utils/openModal'
import { generateOriginalCombinedOrderLines } from './utils'
import { getShippingMethod } from '../../constants/shippingMethods'
import { isWeekday } from '../../utils/isWeekday'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'
import { phoneValidationPattern } from '../../constants/regexPatterns'
import { getDateOfDispatchMinimumDate } from '../../utils/getDateOfDispatchMinimumDate'
import { getDateOfDispatchMinimumDays } from '../../utils/getDateOfDispatchMinimumDays'

interface OrderAddressEditorProps {
  activeBundles: Bundle[]
  campaign: Campaign
  setShowDialog: Function
  additionalArticles: Product[]
  setActiveBundles: React.Dispatch<React.SetStateAction<Bundle[]>>
  setAdditionalArticles: React.Dispatch<React.SetStateAction<Product[]>>
  selectedRecipient: Partial<Recipient> | null
  setSelectedRecipient: React.Dispatch<React.SetStateAction<Partial<Recipient> | null>>
  isAllowedToReadRecipients: boolean
  loadCostCenterOptionsDebounced: (...args: any[]) => void
}

const OrderAddressEditor = ({
  activeBundles,
  campaign,
  setShowDialog,
  additionalArticles,
  setActiveBundles,
  setAdditionalArticles,
  selectedRecipient,
  setSelectedRecipient,
  isAllowedToReadRecipients,
  loadCostCenterOptionsDebounced
}: OrderAddressEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const salutation = useAppSelector((state) => state.apiSalutation.salutation)
  const salutationMessage = useAppSelector((state) => state.apiSalutation.message)
  const salutationError = useAppSelector((state) => state.apiSalutation.error)
  const isCreatingOrders = useAppSelector((state) => state.apiCampaign.isCreatingOrders)
  const createdPendingOrders = useAppSelector((state) => state.apiCampaign.pendingOrders)
  const error = useAppSelector((state) => state.apiCampaign.error)

  const token = currentUser?.token
  const role = profile?.role
  const companyId = campaign.company?.id
  const campaignId = campaign.id
  const campaignShippingDestinations = campaign.campaignShippingDestinations.map(destination => ({ value: destination.country, label: destination.country }))
  const countries = countriesObject.map(country => ({ value: country.country, label: country.country }))
  const currentUserAddresses = profile?.addresses ?? []

  const [note, setNote] = useState('')
  const [shippingDate, setShippingDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [hasAgreed, setHasAgreed] = useState<'no' | 'yes'>('no')

  const dispatch = useAppDispatch()

  const orderSchema = object({
    company: string(),
    note: string()
      .max(100, (value) => `Note must be at most ${value.max} characters`)
      .default(''),
    salutation: string(),
    firstName: string()
      .required('First Name is required')
      .min(2, (value) => `First Name must be at least ${value.min} characters`)
      .max(32, (value) => `First Name must be at most ${value.max} characters`),
    lastName: string()
      .required('Last Name is required')
      .min(2, (value) => `Last Name must be at least ${value.min} characters`)
      .max(32, (value) => `Last Name must be at most ${value.max} characters`),
    email: string().email('Enter a valid email').required('Email is required'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    country: string().label('Country')
      .required('Country is required')
      .oneOf(campaignShippingDestinations.length > 0 ? campaignShippingDestinations.map(destination => destination.value) : countries.map(country => country.value)),
    street: string().required('Street and House Number are required'),
    city: string().required('City is required'),
    zip: string().label('Zip Code').required(),
    shippingDate: date().typeError('Shipping Date is required').label('Shipping Date')
      .min(dayjs().startOf('day').add(getDateOfDispatchMinimumDays(), 'days').toDate(), (value) => `Shipping Date field must be later than ${dayjs(value.min).format('YYYY-MM-DD HH:mm')}`),
    costCenter: string().label('Cost Center'),
    startDate: date().label('Start Date').nullable().default(null),
    hasAgreed: string().oneOf(['yes'], 'We want to make sure you understand our policies. Please read and agree to our terms and conditions before continuing.')
  })

  const getShippingId = (campaign: Campaign) => {
    const shippingId = getShippingMethod(campaign)
    return shippingId
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && (salutation || salutationMessage)) {
      dispatch(getAllSalutations({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [salutation, salutationMessage])

  useEffect(() => {
    const length = createdPendingOrders.length
    if (length > 0) {
      const payload = {
        title: 'Success',
        message: `${length} ${length === 1 ? 'order' : 'orders'} created successfully`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      setShowDialog(false)
      dispatch(resetPendingOrders())
      dismissModal('orderAddressEditorModal')
      setActiveBundles([])
      setAdditionalArticles([])
    }
  }, [createdPendingOrders])

  useEffect(() => {
    if (error && error?.errors.message) {
      const payload = {
        title: 'Error',
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [error])

  return (
    <div className="row">
      <div className="col">
        <Formik
          validationSchema={orderSchema}
          enableReinitialize
          initialValues={{
            company: selectedRecipient?.companyName ?? '',
            salutation: selectedRecipient?.salutation ?? '',
            firstName: selectedRecipient?.firstName ?? '',
            lastName: selectedRecipient?.lastName ?? '',
            email: selectedRecipient?.email ?? '',
            phone: selectedRecipient?.phone ?? '',
            street: selectedRecipient?.street ?? '',
            zip: selectedRecipient?.zip ?? '',
            city: selectedRecipient?.city ?? '',
            addressAddition: selectedRecipient?.addressAddition ?? '',
            country: selectedRecipient?.country ?? '',
            note,
            shippingDate,
            costCenter: selectedRecipient?.costCenter ?? '',
            startDate,
            hasAgreed
          }}
          onSubmit={(values, actions) => {
            const controller = new AbortController()
            const signal = controller.signal

            // Separate bundles that are unlocked and not bill of materials and have custom articles into different orders based on bundle quantity
            // ADD LOGIC TO CHECK IF A BUNDLE HAS HAD AN ITEM DELETED THEN SEPARATE IT'S ORDER LINES
            const flattenedActiveBundles = activeBundles.flatMap(activeBundle => {
              const isUnlockedOriginalBundle = activeBundle.specifications?.billOfMaterialsComponents.every(billOfMaterialsComponent => billOfMaterialsComponent.isCustomItem === false && billOfMaterialsComponent.isDeleted === false)
              if ((activeBundle.isBillOfMaterials && activeBundle.isLocked) || isUnlockedOriginalBundle) {
                return activeBundle
              } else {
                return Array.from({ length: (activeBundle.quantity || 1) }, () => ({ ...activeBundle, quantity: 1 }))
              }
            })

            // Separate locked bundles from unlocked bundles
            const unlockedBundles = flattenedActiveBundles.filter(bundle => !bundle.isLocked || !bundle.isBillOfMaterials)

            // Check for unlocked bundles without custom items and those with custom items
            const unlockedCustomBundles = unlockedBundles.filter(bundle => bundle.specifications?.billOfMaterialsComponents.some(billOfMaterialsComponent => billOfMaterialsComponent.isCustomItem === true || billOfMaterialsComponent.isDeleted === true))

            const { mappedOrderLines: orderLineRequests, description } = generateOriginalCombinedOrderLines(activeBundles, additionalArticles)

            const mappedOrders: Array<orders.PendingOrder> = [
              {
                platform: 0,
                language: 0,
                currency: 'EUR',
                orderNo: '0',
                inetorderno: 0,
                shippingId: getShippingId(campaign),
                shipped: dayjs(values.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes').format(),
                deliverydate: dayjs(values.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes').format(),
                note: values.note,
                description: `${(campaign.description || '')} + ${(description || '')}`,
                costCenter: String(values.costCenter),
                paymentType: 0,
                paymentTarget: 0,
                discount: 0,
                orderStatus: 0,
                orderLineRequests,
                shippingAddressRequests: [
                  {
                    salutation: values.salutation,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    title: '',
                    company: values.company,
                    companyAddition: '',
                    street: values.street,
                    addressAddition: values.addressAddition,
                    zipCode: values.zip,
                    place: values.city,
                    phone: values.phone,
                    state: '',
                    country: values.country,
                    iso: '',
                    telephone: '',
                    mobile: '',
                    fax: '',
                    email: values.email,
                    startDate: campaign.includeStartDate ? (values.startDate || null) : null
                  }
                ]
              }
            ]

            // To prevent an order with empty order lines
            let combinedOrders: Array<orders.PendingOrder> = orderLineRequests.length > 0 ? mappedOrders : []

            // Add unlocked bundle with custom items
            const unlockedCustomBundlesPendingOrders: orders.PendingOrder[] = unlockedCustomBundles
              .map(unlockedCustomBundle => (
                {
                  platform: 0,
                  language: 0,
                  currency: 'EUR',
                  orderNo: '0',
                  inetorderno: 0,
                  shippingId: getShippingId(campaign),
                  shipped: dayjs(values.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes').format(),
                  deliverydate: dayjs(values.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes').format(),
                  note: values.note,
                  description: `${(campaign.description || '')} + ${(unlockedCustomBundle.description || '')}`,
                  costCenter: String(values.costCenter),
                  paymentType: 0,
                  paymentTarget: 0,
                  discount: 0,
                  orderStatus: 0,
                  orderLineRequests: unlockedCustomBundle.specifications?.billOfMaterialsComponents
                    .filter(billOfMaterialsComponent => !billOfMaterialsComponent.isDeleted)
                    .map((billOfMaterialsComponent) => (
                      {
                        itemName: billOfMaterialsComponent.name,
                        articleNumber: String(billOfMaterialsComponent.merchantSku),
                        itemNetSale: 0,
                        itemVAT: 0,
                        quantity: Number(billOfMaterialsComponent.quantity || 1),
                        type: 0,
                        discount: 0,
                        netPurchasePrice: 0
                      })) ?? [],
                  shippingAddressRequests: [
                    {
                      salutation: values.salutation,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      title: '',
                      company: values.company,
                      companyAddition: '',
                      street: values.street,
                      addressAddition: values.addressAddition,
                      zipCode: values.zip,
                      place: values.city,
                      phone: values.phone,
                      state: '',
                      country: values.country,
                      iso: '',
                      telephone: '',
                      mobile: '',
                      fax: '',
                      email: values.email,
                      startDate: campaign.includeStartDate ? (values.startDate || null) : null
                    }
                  ]
                }
              ))

            combinedOrders = combinedOrders.concat(unlockedCustomBundlesPendingOrders)

            if (token && campaignId) {
              dispatch(createBulkOrders({ id: campaignId, token, pendingOrders: combinedOrders, signal }))
            }
            actions.setSubmitting(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            handleReset
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-2 d-flex justify-content-between">
                {
                  isAllowedToReadRecipients
                    ? (
                      <button
                        className="btn btn-outline-primary text-nowrap btn-sm"
                        type="button"
                        onClick={() => {
                          openModal('recipientsModal')
                          dismissModal('orderAddressEditorModal')
                        }}
                      >
                        Autofill Address
                      </button>
                      )
                    : (
                      <button
                        className="btn btn-outline-primary text-nowrap btn-sm"
                        type="button"
                        onClick={() => {
                          const foundAddress = currentUserAddresses.find(address => address.type === 'delivery' || address.type === null)
                          if (foundAddress) {
                            setSelectedRecipient({
                              companyName: foundAddress?.companyName ?? '',
                              salutation: '',
                              firstName: profile?.firstName ?? '',
                              lastName: profile?.lastName ?? '',
                              email: foundAddress?.email ?? '',
                              phone: foundAddress?.phone ?? '',
                              country: foundAddress?.country ?? '',
                              city: foundAddress?.city ?? '',
                              street: foundAddress?.street ?? '',
                              zip: foundAddress?.zip ?? '',
                              addressAddition: foundAddress?.addressAddition ?? '',
                              costCenter: foundAddress?.costCenter ?? ''
                            })
                          } else {
                            const payload = {
                              title: 'Info',
                              message: 'Kindly set up your delivery address to use autofill',
                              isVisible: true,
                              timestamp: dayjs().format('LT'),
                              type: 'info'
                            }
                            dispatch(setToast(payload))
                          }
                        }}
                      >
                        Autofill Address
                      </button>
                      )
                }
                <button
                  className="btn btn-outline-danger text-nowrap btn-sm"
                  type="button"
                  onClick={(event) => {
                    setSelectedRecipient(null)
                    handleReset(event)
                    setShippingDate(null)
                    setNote('')
                    setHasAgreed('no')
                  }}
                >
                  Clear Address
                </button>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <div className="mb-3">
                    <label htmlFor="companyName" className="form-label">
                      Company Name
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, companyName: event.target.value }))}
                      onBlur={handleBlur}
                      value={values.company}
                      type="text"
                      className={`form-control ${
                        errors.company &&
                        touched.company
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="companyName"
                      name="company"
                      placeholder="Enter company name"
                      autoComplete="off"
                    />
                    <div
                      id="validationCompanyNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.company || error?.errors?.company}
                    </div>
                  </div>
                </div>
              </div>
              {
                (role === userRoles.ADMIN || campaign.isNoteEnabled) && (
                  <div className="row mb-2">
                    <div className="col">
                      <div className="mb-3">
                        <label htmlFor="note" className="form-label">
                          Order Note
                        </label>
                        <textarea
                          onChange={(event) => {
                            setNote(event.target.value)
                          }}
                          onBlur={handleBlur}
                          value={values.note}
                          className={`form-control ${
                            errors.note &&
                            touched.note
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="note"
                          name="note"
                          placeholder="Enter order note"
                          autoComplete="off"
                        />
                        <div
                          id="validationOrderNoteFeedback"
                          className="invalid-feedback"
                        >
                          {errors.note || error?.errors?.note}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="row mb-2">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="salutation" className="form-label">
                      Salutation
                    </label>
                    <div className="input-group">
                      <select
                        aria-label="Salutation"
                        onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, salutation: event.target.value }))}
                        onBlur={handleBlur}
                        value={values.salutation}
                        className={`form-select ${
                          errors.salutation &&
                          touched.salutation
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="salutation"
                        aria-describedby="button-addon2"
                        autoComplete="honorific-prefix"
                      >
                        <option value="">Select Salutation</option>
                        {salutations.map(
                          (salutation: Salutation, index: number) => (
                            <option key={index}>
                              {salutation.title}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div
                      id="validationSalutationFeedback"
                      className="invalid-feedback"
                    >
                      {errors.salutation ||
                        salutationError?.errors?.salutation}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, firstName: event.target.value }))}
                      onBlur={handleBlur}
                      value={values.firstName}
                      type="text"
                      className={`form-control ${
                        errors.firstName &&
                        touched.firstName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="firstName"
                      placeholder="Enter first name"
                      autoComplete="given-name"
                    />
                    <div
                      id="validationFirstNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.firstName || error?.errors?.firstName}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, lastName: event.target.value }))}
                      onBlur={handleBlur}
                      value={values.lastName}
                      type="text"
                      className={`form-control ${
                        errors.lastName &&
                        touched.lastName
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="lastName"
                      placeholder="Enter last name"
                      autoComplete="family-name"
                    />
                    <div
                      id="validationLastNameFeedback"
                      className="invalid-feedback"
                    >
                      {errors.lastName || error?.errors?.lastName}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderEmail" className="form-label">
                      Email Address
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, email: event.target.value }))}
                      onBlur={handleBlur}
                      type="email"
                      className={`form-control ${
                        errors.email && touched.email
                          ? 'is-invalid'
                          : ''
                      }`}
                      value={values.email}
                      id="orderEmail"
                      name="email"
                      placeholder="Enter Email Address"
                      autoComplete="email"
                    />
                    <div
                      id="validationEmailFeedback"
                      className="invalid-feedback"
                    >
                      {errors.email || error?.errors?.email}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, phone: event.target.value }))}
                      onBlur={handleBlur}
                      value={values.phone}
                      type="text"
                      className={`form-control ${
                        errors.phone && touched.phone
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="phone"
                      placeholder="Enter Phone Number"
                      autoComplete="tel"
                    />
                    <div
                      id="validationPhoneFeedback"
                      className="invalid-feedback"
                    >
                      {errors.phone || error?.errors?.phone}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderStreet" className="form-label">
                      Street and House Number
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, street: event.target.value }))}
                      onBlur={handleBlur}
                      value={values.street}
                      type="text"
                      className={`form-control ${
                        errors.street && touched.street
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="orderStreet"
                      name="street"
                      placeholder="Enter street and House Number"
                      autoComplete="street-address"
                    />
                    <div
                      id="validationOrderStreetFeedback"
                      className="invalid-feedback"
                    >
                      {errors.street || error?.errors?.street}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderZip" className="form-label">
                      Zip
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, zip: event.target.value }))}
                      onBlur={handleBlur}
                      value={values.zip}
                      type="text"
                      className={`form-control ${
                        errors.zip && touched.zip
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="orderZip"
                      name="zip"
                      placeholder="Enter zip"
                      autoComplete="postal-code"
                    />
                    <div
                      id="validationOrderZipFeedback"
                      className="invalid-feedback"
                    >
                      {errors.zip || error?.errors?.zip}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="orderCity" className="form-label">
                      City
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, city: event.target.value }))}
                      onBlur={handleBlur}
                      type="text"
                      className={`form-control ${
                        errors.city && touched.city
                          ? 'is-invalid'
                          : ''
                      }`}
                      value={values.city}
                      id="orderCity"
                      name="city"
                      placeholder="Enter city"
                      autoComplete="address-level2"
                    />
                    <div
                      id="validationOrderCityFeedback"
                      className="invalid-feedback"
                    >
                      {errors.city || error?.errors?.city}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="orderAddressAddition"
                      className="form-label"
                    >
                      Address Addition
                    </label>
                    <input
                      onChange={(event) => setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, addressAddition: event.target.value }))}
                      onBlur={handleBlur}
                      value={values.addressAddition}
                      type="text"
                      className={`form-control ${
                        errors.addressAddition &&
                        touched.addressAddition
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="orderAddressAddition"
                      name="addressAddition"
                      placeholder="Enter Address Addition"
                      autoComplete="on"
                    />
                    <div
                      id="validationOrderAddressAdditionFeedback"
                      className="invalid-feedback"
                    >
                      {errors.addressAddition ||
                        error?.errors?.addressAddition}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <Select
                      className={`${
                        touched.country &&
                        errors.country
                          ? 'is-invalid'
                          : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: (errors.country && touched.country) ? '#dc3545' : provided.borderColor,
                          '&:hover': {
                            boxShadow: (errors.country && touched.country) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                            borderColor: (errors.country && touched.country) ? '#dc3545' : '#86b7fe'
                          }
                        })
                      }}
                      isClearable
                      inputId="country"
                      name="country"
                      aria-label="Country"
                      options={campaignShippingDestinations.length > 0 ? campaignShippingDestinations : countries}
                      onChange={(selectedOption) => {
                        const selectedCountry = selectedOption?.value ?? ''
                        setFieldValue('country', selectedCountry)
                        setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, country: selectedCountry }))
                      }}
                      onBlur={handleBlur}
                      value={countries.find((country) => country.value === values.country)}
                    />
                    <div
                      id="validationOrderCountryFeedback"
                      className="invalid-feedback"
                    >
                      {errors.country || error?.errors?.country}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="shippingDate" className="form-label">
                      Shipping Date
                    </label>
                    <div
                      className={`${
                        errors.shippingDate &&
                        touched.shippingDate
                          ? 'is-invalid'
                          : ''
                      }`}
                    >
                      <Calendar
                        id="shippingDate"
                        name="shippingDate"
                        onChange={(date: Date) => {
                          setShippingDate(date)
                        }}
                        selected={values.shippingDate}
                        className={`form-control ${
                          errors.shippingDate &&
                          touched.shippingDate
                            ? 'is-invalid'
                            : ''
                        }`}
                        minDate={getDateOfDispatchMinimumDate()}
                        dateFormat={'dd/MM/yyyy'}
                        autoComplete={'off'}
                        filterDate={isWeekday}
                        placeholderText="Select a weekday"
                        calendarStartDay={DaysOfWeek.Monday}
                      />
                    </div>
                    <div
                      id="validationShippingDateFeedback"
                      className="invalid-feedback"
                    >
                      {errors.shippingDate || error?.errors?.shippingDate}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="costCenter" className="form-label">
                      Cost Center
                    </label>
                    <AsyncSelect
                      inputId="costCenter"
                      cacheOptions
                      loadOptions={loadCostCenterOptionsDebounced}
                      defaultOptions
                      onChange={(selectedOption: any) => {
                        setFieldValue('costCenter', selectedOption.value)
                        setSelectedRecipient(selectedRecipient => ({ ...selectedRecipient, costCenter: selectedOption.value }))
                      }}
                      key={companyId}
                      placeholder="Select Cost Center"
                      className={`${
                        ((errors.costCenter) && touched.costCenter)
                          ? 'is-invalid'
                          : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: (errors.costCenter && touched.costCenter) ? '#dc3545' : provided.borderColor,
                          '&:hover': {
                            boxShadow: (errors.costCenter && touched.costCenter) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                            borderColor: (errors.costCenter && touched.costCenter) ? '#dc3545' : '#86b7fe'
                          }
                        })
                      }}
                      value={{ value: values.costCenter, label: values.costCenter }}
                    />
                    <div
                      id="validationCostCenterFeedback"
                      className="invalid-feedback"
                    >
                      {errors.costCenter}
                    </div>
                  </div>
                </div>
                {
                  campaign.includeStartDate && (
                    <div className="col-md-6">
                      <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">
                      Start Date
                    </label>
                    <div
                      className={`${
                        errors.startDate &&
                        touched.startDate
                          ? 'is-invalid'
                          : ''
                      }`}
                    >
                      <Calendar
                        id="startDate"
                        name="startDate"
                        onChange={(date: Date) => {
                          setStartDate(date)
                        }}
                        selected={values.startDate}
                        className={`form-control ${
                          errors.startDate &&
                          touched.startDate
                            ? 'is-invalid'
                            : ''
                        }`}
                        dateFormat={'dd/MM/yyyy'}
                        autoComplete={'off'}
                        placeholderText="Select start date"
                        calendarStartDay={DaysOfWeek.Monday}
                      />
                    </div>
                    <div
                      id="validationStartDateFeedback"
                      className="invalid-feedback"
                    >
                      {errors.startDate || error?.errors?.startDate}
                    </div>
                  </div>
                    </div>
                  )
                }
              </div>

              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <p className="mt-2">
                      By creating an order, you agree to our <button className="btn btn-link text-primary m-0 p-0" data-bs-toggle="modal" type="button" data-bs-target="#termsModal">terms and conditions</button>.
                    </p>
                    <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAgreed"
                        id="inlineRadioYes"
                        onChange={() => setHasAgreed('yes')}
                        value="yes"
                        autoComplete="off"
                        checked={values.hasAgreed === 'yes'}
                      />
                      <label className="form-check-label" htmlFor="inlineRadioYes">Yes</label>
                    </div>
                    <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                      <input
                        className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                        type="radio"
                        name="hasAgreed"
                        id="inlineRadioNo"
                        onChange={() => setHasAgreed('no')}
                        value="no"
                        autoComplete="off"
                        checked={values.hasAgreed === 'no'}
                      />
                      <label className="form-check-label" htmlFor="inlineRadioNo">No</label>
                    </div>
                    <div
                      id="validationHasAgreedFeedback"
                      className="invalid-feedback"
                    >
                      {errors.hasAgreed}
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-top">
              {(isCreatingOrders) && <Progress marginBottom={false} />}
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-secondary mt-2 me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#cartModal"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary mt-2"
                    disabled={isCreatingOrders || isSubmitting}
                  >
                    {isCreatingOrders ? 'Creating' : 'Create Order'}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default OrderAddressEditor
