import React from 'react'
import { object, string, number } from 'yup'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ProductCategory } from '../../types'

dayjs.extend(utc)

type ProductCategoryEditorProps = { id: string, initialProductCategory: Partial<ProductCategory>, save: Function, isEdit: boolean }

const ProductCategoryEditor = ({ id, initialProductCategory, save }: ProductCategoryEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const productCategorySchema = object({
    name: string().label('Name').required().max(64),
    description: string().label('Description').max(255).nullable(),
    sortIndex: number().label('Sort Index').min(0)
  })

  const saveProductCategory = (id: string, productCategory: Partial<ProductCategory>, signal: AbortSignal) => {
    dispatch(save({ productCategoryId: id, token, productCategory, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={productCategorySchema}
        enableReinitialize
        initialValues={{
          ...initialProductCategory
        }}
        onSubmit={(
          { name, description, sortIndex },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal

          const productCategory = {
            name,
            description,
            sortIndex
          }

          if (token) {
            saveProductCategory(id, productCategory, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="sortIndex" className="form-label">
                    Sort Index
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    className={`form-control ${
                      touched.sortIndex &&
                      errors.sortIndex
                        ? 'is-invalid'
                        : ''
                    }`}
                    min={0}
                    value={values.sortIndex}
                    id="sortIndex"
                    name="sortIndex"
                    placeholder="Enter Sort Index"
                    autoComplete="off"
                  />
                  <div
                    id="validationSortIndexFeedback"
                    className="invalid-feedback"
                  >
                    {errors.sortIndex}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="productCategoryName"
                    className="form-label"
                  >
                    Name
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      (touched.name &&
                      errors.name)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="productCategoryName"
                    name="name"
                    placeholder=""
                    maxLength={64}
                    autoComplete="off"
                  />
                  <div
                    id="validationProductCategoryNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="productCategoryDescription"
                    className="form-label"
                  >
                    Description
                  </label>
                  <textarea
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description ?? ''}
                    className={`form-control ${
                      (touched.description &&
                      errors.description)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="productCategoryDescription"
                    name="description"
                    placeholder=""
                    maxLength={255}
                    autoComplete="off"
                  />
                  <div
                    id="validationProductCategoryDescriptionFeedback"
                    className="invalid-feedback"
                  >
                    {errors.description}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ProductCategoryEditor
