import React, { CSSProperties, useState } from 'react'

interface AnonymousImageProps {
  src: string
  alt: string
  style?: CSSProperties
}

const AnonymousImage = ({ src, alt, style }: AnonymousImageProps) => {
  const [, setIsLoaded] = useState(false)
  const [, setError] = useState('')

  const handleImageLoad = () => {
    setIsLoaded(true)
  }

  const handleImageError = () => {
    setError('Error loading image')
  }

  return (
    <img
      src={src}
      className="card-img-top"
      alt={alt}
      crossOrigin="anonymous"
      onLoad={handleImageLoad}
      onError={handleImageError}
      style={style}
    />
  )
}

export default AnonymousImage
