import React from 'react'
import Placeholder from '../../assets/images/placeholder.png'

const ChildProductLoader = ({ repetition }: { repetition: number }) => {
  return (
    <>
      {Array.from(Array(repetition).keys()).map((n: number) => (
        <div className="col rounded-5" key={n}>
          <div className="card h-100">
            <img
              src={Placeholder}
              alt="Child Product Loader"
              className="img-fluid rounded-5"
              style={{
                objectFit: 'cover'
              }}
              onError={(e) => {
                const target = e.target as HTMLImageElement
                target.onerror = null
                target.src = Placeholder
              }}
            />
          </div>
        </div>
      ))}
    </>
  )
}

export default ChildProductLoader
