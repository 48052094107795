import confetti from 'canvas-confetti'

export const fireConfetti = () => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 },
    ticks: 500,
    disableForReducedMotion: true
  })
}
