import { Role, User, UserCompany } from '../types'
import * as userRoles from '../constants/userRoles'

export const companyAddressProgress = (company: UserCompany | null | undefined, isBillingAndDeliveryAddress: boolean) => {
  if (company && company.addresses && company.addresses.length > 0) {
    const foundBillingAndDeliveryAddress = company.addresses?.find(address => address.type === 'billingAndDelivery')
    const foundBillingAddress = company.addresses?.find(address => address.type === 'billing')
    const foundDeliveryAddress = company.addresses?.find(address => address.type === 'delivery')
    if (isBillingAndDeliveryAddress) {
      if (foundBillingAndDeliveryAddress) {
        return 25
      }
    } else {
      if (foundBillingAddress && foundDeliveryAddress) {
        return 25
      }
      if (foundBillingAddress || foundDeliveryAddress) {
        return 10
      }
    }
    return 0
  }
  return 0
}

const companyValue = (role: Role, editBillingOrDeliveryAddress: boolean, company: UserCompany | null | undefined, isBillingAndDeliveryAddress: boolean) => {
  if (role === userRoles.ADMIN) {
    return 25
  }
  if (role === userRoles.COMPANYADMINISTRATOR) {
    if (editBillingOrDeliveryAddress) {
      return companyAddressProgress(company, isBillingAndDeliveryAddress)
    }
    return 25
  } else {
    return company ? 25 : 0
  }
}

export const calculateProgress = (profile: User, editBillingOrDeliveryAddress: boolean, isBillingAndDeliveryAddress: boolean) => {
  const { isVerified, firstName, addresses = [], role, company } = profile
  const totalProgress = {
    loginValue: 25,
    verificationValue: isVerified ? 25 : 0,
    companyValue: companyValue(role, editBillingOrDeliveryAddress, company, isBillingAndDeliveryAddress),
    profileValue: (firstName && addresses.length > 0) ? 25 : 0
  }

  const totalPendingTasks = Object.values(totalProgress).filter(value => value !== 25).length
  const totalProgressPercentage = Object.values(totalProgress).reduce((acc, curr) => acc + curr, 0)

  return {
    totalPendingTasks,
    totalProgressPercentage
  }
}
