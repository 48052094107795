import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PictureService from '../../../services/api/PictureService'
import { ApiPictureState, Nullable } from '../../../types'
import { PICTURE_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiPictureState = {
  isLoading: false,
  error: null,
  message: null,
  cards: [],
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    nextPage: 'CgZjYXJkcy8=',
    total: 0
  }
}

export const deletePictureById = createAsyncThunk('api/pictures/delete', async ({ token, pictureId, signal }: { token: string, pictureId: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await PictureService.deletePictureById(token, pictureId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAllCards = createAsyncThunk('api/pictures/cards', async ({ token, perPage, pageToken, filter, signal }: { token: string, perPage: number, pageToken: Nullable<string>, filter: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await PictureService.getAllCards(token, perPage, pageToken, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const pictureSlice = createSlice({
  name: 'api/picture',
  initialState,
  reducers: {
    resetPictureError: (state) => {
      state.error = null
    },
    resetPictureMessage: (state) => {
      state.message = null
    },
    resetPictureData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePictureById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePictureById.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PICTURE_DELETION_MESSAGE
      })
      .addCase(deletePictureById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAllCards.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllCards.fulfilled, (state, action) => {
        state.isLoading = false
        state.cards = action.payload.cards
        state.metadata = action.payload.meta
      })
      .addCase(getAllCards.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetPictureError, resetPictureMessage, resetPictureData } = pictureSlice.actions

const { reducer } = pictureSlice
export default reducer
