import React from 'react'
import Progress from '../loaders/Progress'
import { cancelFulfillmentOrders } from '../../store/reducers/api/dotnet/fulfillmentOrderReducer'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const CancelMultipleOrders = ({ outboundIds }: { outboundIds: Array<string>}) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isCancelling = useAppSelector((state) => state.dotnetApiFulfillmentOrders.isLoading)

  const token = currentUser?.token
  const dispatch = useAppDispatch()

  return (
    <div className="modal fade" id={'cancelMultipleConfirmationModal'} tabIndex={-1} aria-labelledby={'cancelMultipleConfirmationModalLabel'} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={'cancelMultipleConfirmationModalLabel'}>Cancel Order</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          {isCancelling && <Progress />}
          <div className="modal-body text-start">
            {`Are you sure you want to cancel the selected ${outboundIds.length} ${outboundIds.length === 1 ? 'order' : 'orders'}?`}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button"
              disabled={isCancelling}
              className="btn btn-danger"
              onClick={() => {
                if (token) {
                  const controller = new AbortController()
                  const signal = controller.signal

                  dispatch(cancelFulfillmentOrders({ token, outboundIds, signal }))
                }
              }}
            >
              Cancel {outboundIds.length === 1 ? 'Order' : 'Orders'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancelMultipleOrders
