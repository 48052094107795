import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import HubspotService from '../../../../services/api/dotnet/HubspotService'
import { DotnetApiHubspotState } from '../../../../types'

const initialState: DotnetApiHubspotState = {
  isLoading: false,
  error: null,
  hubspotCategory: null,
  hubspotCategories: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getHubspotCategories = createAsyncThunk('dotnet/hubspotCategories/get', async ({ token, perPage, offset, signal }: { token: string, perPage: number, offset: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await HubspotService.getHubspotCategories(token, perPage, offset, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

const hubspotSlice = createSlice({
  name: 'dotnet/hubspot',
  initialState,
  reducers: {
    resetHubspotError: (state) => {
      state.error = null
    },
    resetHubspotMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHubspotCategories.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getHubspotCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.hubspotCategories = action.payload.pageCollection
        state.metadata = {
          page: (action.payload.pageIndex / action.payload.pageSize) + 1,
          pageCount: Math.ceil(action.payload.itemsCount / action.payload.pageSize),
          perPage: action.payload.pageSize,
          total: action.payload.itemsCount
        }
      })
      .addCase(getHubspotCategories.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetHubspotError, resetHubspotMessage } = hubspotSlice.actions

const { reducer } = hubspotSlice
export default reducer
