import CompanyUserGroupService from '../services/api/CompanyUserGroupService'
import { CompanyUserGroup } from '../types'

const mapCompanyUserGroups = (companyUserGroups: Array<CompanyUserGroup>) => {
  return companyUserGroups.map(companyUserGroup => ({
    value: companyUserGroup.id,
    label: `${companyUserGroup.name}`
  }))
}

export const loadCompanyUserGroupOptions = async (page: number, perPage: number, token: string, inputValue: string) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token) {
    try {
      const res = await CompanyUserGroupService.getCompanyUserGroups(token, perPage, page, signal, inputValue)
      return mapCompanyUserGroups(res.data.companyUserGroups)
    } catch (error) {
      return mapCompanyUserGroups([])
    }
  }

  return mapCompanyUserGroups([])
}
