import { initializeApp } from 'firebase/app'
import {
  getStorage, ref, deleteObject, uploadBytesResumable,
  getDownloadURL, getMetadata, uploadBytes
} from 'firebase/storage'
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics'
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth'
import { FirebaseEventParams } from '../types'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

export const storageFirebase = getStorage(firebaseApp)

export const deleteObjectFirebase = deleteObject

export const uploadBytesResumableFirebase = uploadBytesResumable

export const uploadBytesFirebase = uploadBytes

export const getDownloadURLFirebase = getDownloadURL

export const getMetadataFirebase = getMetadata

export const refFirebase = ref

export const analyticsFirebase = isSupported().then(yes => yes ? getAnalytics(firebaseApp) : null).catch(() => {})

export const logEventFirebase = (eventName: 'page_view', eventParams?: FirebaseEventParams | undefined) => isSupported()
  .then(yes => {
    if (yes) {
      logEvent(getAnalytics(firebaseApp), eventName, eventParams)
    }
  }).catch(() => {})

if (process.env.NODE_ENV !== 'test') {
  const auth = getAuth()
  signInAnonymously(auth).then(() => {
    // Signed in successfully
  }).catch(() => {
    // An error occured
  })

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
    } else {
      // user logged out
    }
  })
}
