import CustomerService from '../services/api/dotnet/CustomerService'

export const loadCustomerOptions = async (page: number, perPage: number, token: string, inputValue: string) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token) {
    try {
      const res = await CustomerService.getCustomers(token, perPage, page, signal, inputValue)
      return res.data.customers
    } catch (error) {
      return []
    }
  } else {
    return []
  }
}
