import { Bundle, Product, orders } from '../../types'

const generateMappedOriginalOrderLines = (lockedBundles: Bundle[], unlockedOriginalBundles: Bundle[], additionalArticles: Product[]) => {
  let orderLines: orders.OrderLineRequest[] = []
  let description: string = ''
  if (lockedBundles.length > 0) {
    const mappedLockedBundlesOrderLines = lockedBundles.map(lockedBundle => ({
      itemName: lockedBundle.name,
      articleNumber: String(lockedBundle.merchantSku),
      itemNetSale: 0,
      itemVAT: 0,
      quantity: lockedBundle.quantity || 1,
      type: 0,
      discount: 0,
      netPurchasePrice: 0
    }))
    orderLines = orderLines.concat(mappedLockedBundlesOrderLines)
    lockedBundles.forEach((lockedBundle) => {
      description += `${lockedBundle.name}: ${lockedBundle.description || 'No description'}; `
    })
  }

  if (unlockedOriginalBundles.length > 0) {
    const mappedUnLockedOriginalBundlesOrderLines: orders.OrderLineRequest[] = unlockedOriginalBundles
      .map(unlockedOriginalBundle => ({
        itemName: unlockedOriginalBundle.name,
        articleNumber: String(unlockedOriginalBundle.merchantSku),
        itemNetSale: 0,
        itemVAT: 0,
        quantity: unlockedOriginalBundle.quantity || 1,
        type: 0,
        discount: 0,
        netPurchasePrice: 0
      }))
    orderLines = orderLines.concat(mappedUnLockedOriginalBundlesOrderLines)
    unlockedOriginalBundles.forEach((unlockedOriginalBundle) => {
      description += `${unlockedOriginalBundle.name}: ${unlockedOriginalBundle.description || 'No description'}; `
    })
  }

  if (additionalArticles.length > 0) {
    const mappedAdditionalArticles: orders.OrderLineRequest[] = additionalArticles
      .map(additionalArticle => ({
        itemName: additionalArticle.name,
        articleNumber: String(additionalArticle.merchantSku),
        itemNetSale: 0,
        itemVAT: 0,
        quantity: additionalArticle.quantity || 1,
        type: 0,
        discount: 0,
        netPurchasePrice: 0
      }))
    orderLines = orderLines.concat(mappedAdditionalArticles)
  }

  return { orderLines, description }
}

export const generateOriginalCombinedOrderLines = (activeBundles: Bundle[], additionalArticles: Product[]) => {
  // Separate bundles that are unlocked and not bill of materials and have custom articles or deleted items into different orders based on bundle quantity
  // ADD LOGIC TO CHECK IF A BUNDLE HAS HAD AN ITEM DELETED THEN SEPARATE IT'S ORDER LINES
  const flattenedActiveBundles = activeBundles.flatMap(activeBundle => {
    const isUnlockedOriginalBundle = activeBundle.specifications?.billOfMaterialsComponents.every(billOfMaterialsComponent => billOfMaterialsComponent.isCustomItem === false && billOfMaterialsComponent.isDeleted === false)
    if ((activeBundle.isBillOfMaterials && activeBundle.isLocked) || isUnlockedOriginalBundle) {
      return activeBundle
    } else {
      return Array.from({ length: (activeBundle.quantity || 1) }, () => ({ ...activeBundle, quantity: 1 }))
    }
  })

  // Separate locked bundles from unlocked bundles
  const lockedBundles = flattenedActiveBundles.filter(bundle => bundle.isLocked && bundle.isBillOfMaterials)
  const unlockedBundles = flattenedActiveBundles.filter(bundle => !bundle.isLocked || !bundle.isBillOfMaterials)

  // Check for unlocked bundles without custom items and without deleted items
  const unlockedOriginalBundles = unlockedBundles.filter(bundle => bundle.specifications?.billOfMaterialsComponents.every(billOfMaterialsComponent => billOfMaterialsComponent.isCustomItem === false && billOfMaterialsComponent.isDeleted === false))

  let mappedOrderLines: orders.OrderLineRequest[] = []

  const mappedOriginalOrderLines = generateMappedOriginalOrderLines(lockedBundles, unlockedOriginalBundles, additionalArticles)
  mappedOrderLines = mappedOrderLines.concat(mappedOriginalOrderLines.orderLines)

  return { mappedOrderLines, description: mappedOriginalOrderLines.description }
}
