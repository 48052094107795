import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string, number } from 'yup'
import dayjs from 'dayjs'
import Select from 'react-select'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { getMyProfile, resetProfileError } from '../../store/reducers/api/profileReducer'
import {
  resetUserData,
  resetUserMessage,
  sendVerifyCode,
  updateUserAddress,
  updateUserPasswordById,
  verifyEmail
} from '../../store/reducers/api/usersReducer'
import {
  logoutCurrentUser,
  setCurrentUser
} from '../../store/reducers/api/authReducer'
import { countriesObject } from '../../utils/countries'
import Progress from '../../components/loaders/Progress'
import {
  ADDRESS_UPDATE_SUCCESS_MESSAGE,
  EMAIL_VERIFICATION_CONFIRMATION,
  PASSWORD_CHANGE_CONFIRMATION,
  USER_UPDATE_SUCCESS_MESSAGE,
  VERIFICATION_CODE_CONFIRMATION
} from '../../constants/messages'
import { resetToast, setToast } from '../../store/reducers/toastReducer'
import {
  getCompanyById
} from '../../store/reducers/api/companyReducer'
import * as userRoles from '../../constants/userRoles'
import {
  addSalutation, deleteASalutationById,
  editASalutationById, getAllSalutations
} from '../../store/reducers/api/salutationReducer'
import SalutationEditor from '../../components/Salutations/SalutationEditor'
import hasPermission from '../../utils/checkPermissions'
import * as appModules from '../../constants/appModules'
import ProfileEditor from '../../components/editors/ProfileEditor'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { phoneValidationPattern } from '../../constants/regexPatterns'

dayjs.extend(localizedFormat)

const Profile = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.profile.isLoading)
  const updatedUser = useAppSelector((state) => state.apiUsers.user)
  const isUpdating = useAppSelector((state) => state.apiUsers.isLoading)

  const error = useAppSelector((state) => state.profile.error)
  const userError = useAppSelector((state) => state.apiUsers.error)
  const userMessage = useAppSelector((state) => state.apiUsers.message)
  const userAddress = useAppSelector((state) => state.apiUsers.address)
  const address = useAppSelector((state) => state.apiCompany.address)

  const salutations = useAppSelector((state) => state.apiSalutation.salutations)
  const salutation = useAppSelector((state) => state.apiSalutation.salutation)
  const salutationMessage = useAppSelector((state) => state.apiSalutation.message)
  const isLoadingSalutations = useAppSelector((state) => state.apiSalutation.isLoading)

  const [selectedSalutation, setSelectedSalutation] = useState({
    id: '',
    title: ''
  })

  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []
  const personalAddress = profile?.addresses && profile?.addresses.find((address) => address.type === 'delivery' || address.type === null)

  const userId = currentUser?.id
  const token = currentUser?.token
  const companyId = currentUser?.company?.id
  const companyOwnerId = currentUser?.company?.owner?.id
  const role = currentUser?.role || userRoles.USER
  const countries = countriesObject.map(country => ({ value: country.country, label: country.country }))

  const isOwner = companyOwnerId && userId === companyOwnerId

  const dispatch = useAppDispatch()

  const verificationSchema = object({
    otp: number()
      .typeError('OTP must be a number')
      .positive('OTP must be a positive number')
      .integer('OTP must be an integer')
      .required('OTP is required')
  })

  const addressSchema = object({
    country: string().required('Country is required').oneOf(countries.map(country => country.value)),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street name and House Number are required')
      .max(64, 'Street name and House Number are too long'),
    zip: string()
      .required('Zip is required')
      .max(16, 'Zip is too long'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    addressAddition: string()
      .nullable()
      .max(255, 'Address Addition is too long')
  })

  const passwordSchema = object({
    currentPassword: string()
      .required('Current Password is required'),
    password: string()
      .required('New Password is required')
      .min(6, 'New Password must be at least 6 characters')
  })

  const verify = (email: string, otp: number) => {
    const controller = new AbortController()
    const signal = controller.signal
    const user = {
      email,
      otp
    }
    dispatch(resetUserData())
    if (userId && token) {
      dispatch(verifyEmail({ id: userId, token, user, signal }))
    }
  }

  useEffect(() => {
    if (userMessage && userMessage === EMAIL_VERIFICATION_CONFIRMATION) {
      dispatch(logoutCurrentUser())
    }

    if (userMessage && userMessage === PASSWORD_CHANGE_CONFIRMATION) {
      const payload = {
        title: 'Success',
        message: PASSWORD_CHANGE_CONFIRMATION,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))

      const timer = setTimeout(() => {
        dispatch(resetUserData())
        dispatch(resetProfileError())
        dispatch(resetToast())
        dispatch(logoutCurrentUser())
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }

    if (userMessage && userMessage === VERIFICATION_CODE_CONFIRMATION) {
      const payload = {
        title: 'OTP request successful',
        message: userMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
    }

    if (userMessage && (userMessage === (USER_UPDATE_SUCCESS_MESSAGE) || userMessage === ADDRESS_UPDATE_SUCCESS_MESSAGE)) {
      const payload = {
        title: 'Success',
        message: userMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetUserMessage())
    }
  }, [userMessage])

  useEffect(() => {
    if (userMessage !== PASSWORD_CHANGE_CONFIRMATION && (error && error.message === 'token invalid')) {
      const payload = {
        title: 'Session Expired',
        message: 'Refreshing your session',
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    if (userError && userError.errors) {
      const payload = {
        title: 'Error',
        message: `Kindly try again, ${userError.errors.message}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [userError])

  useEffect(() => {
    if (userError && userError?.user && userError?.user?.message) {
      const payload = {
        title: 'Error',
        message: `Kindly try again, ${userError?.user?.message}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [userError])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (currentUser?.token) {
      const { token } = currentUser
      dispatch(getMyProfile({ token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (updatedUser && token) {
      dispatch(setCurrentUser({ ...updatedUser, token }))
      dispatch(getMyProfile({ token, signal }))
    }

    if (currentUser && userAddress) {
      dispatch(setCurrentUser({ ...currentUser, address: userAddress }))
    }

    return () => {
      controller.abort()
    }
  }, [updatedUser, userAddress])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && companyId && (isOwner || hasPermission(appModules.COMPANIES, role, companyAccessPermissions, defaultAccessPermissions))) {
      dispatch(getCompanyById({ id: companyId, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [address])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [salutation, salutationMessage])

  return (
    <div className="container-fluid px-4 py-4">
      <div className="card">
        <div className="card-body">
          <div>
            <div id="aboutme">
              {!currentUser?.isVerified && (
                <div className="border border-primary rounded mb-3">
                  {isLoading && <Progress />}
                  <div className="p-2 mb-3">
                    <h6 className="mb-3 ps-2 mt-2 text-uppercase">
                      <i className="bi bi-info-circle me-1"></i> Pending
                      Actions
                    </h6>
                    <div className="border border-gray rounded p-2 m-2">
                      <p className="ps-2 mt-2">
                        Request a verification OTP to verify your email.{' '}
                        <br />
                        <span className="text-muted small">
                          You will be redirected to log in again once your
                          email is verified
                        </span>
                      </p>
                      <div className="card-body">
                        <Formik
                          validationSchema={verificationSchema}
                          initialValues={{ otp: '' }}
                          onSubmit={({ otp }, actions) => {
                            verify(
                              String(currentUser?.email),
                              parseInt(otp, 10)
                            )
                          }}
                        >
                          {({
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            values,
                            handleSubmit
                          }) => (
                            <div className="row">
                              <form
                                className="row gx-2"
                                onSubmit={handleSubmit}
                              >
                                <div className="col-auto mb-3">
                                  <label
                                    htmlFor="otp"
                                    className="visually-hidden"
                                  >
                                    OTP
                                  </label>
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.otp}
                                    className={`form-control ${
                                      errors.otp &&
                                      touched.otp &&
                                      errors.otp
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    type="text"
                                    id="otp"
                                    name="otp"
                                    placeholder="OTP"
                                  />
                                  <div
                                    id="validationOtpFeedback"
                                    className="invalid-feedback"
                                  >
                                    {errors.otp}
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <button
                                    disabled={isLoading}
                                    type="submit"
                                    className="btn btn-primary mb-3"
                                  >
                                    Verify Email
                                  </button>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    disabled={isLoading}
                                    onClick={() => {
                                      const controller =
                                        new AbortController()
                                      const signal = controller.signal
                                      const user = {
                                        email: currentUser?.email
                                      }
                                      if (token && userId) {
                                        dispatch(
                                          sendVerifyCode({
                                            id: userId,
                                            token,
                                            user,
                                            signal
                                          })
                                        )
                                      }
                                    }}
                                  >
                                    Request Verification OTP
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div id="edit-profile">
              <ProfileEditor
                profile={currentUser}
                isLoading={isLoading}
                isUpdating={isUpdating}
                title='Update Personal Information'
              />
              <div className="border border-gray rounded mb-3">
                {(isLoading || isUpdating) && <Progress />}
                <div className="p-3">
                  <Formik
                    validationSchema={addressSchema}
                    enableReinitialize
                    initialValues={{
                      country: personalAddress?.country || '',
                      city: personalAddress?.city || '',
                      street: personalAddress?.street || '',
                      zip: personalAddress?.zip || '',
                      phone: personalAddress?.phone || '',
                      addressAddition: personalAddress?.addressAddition || ''
                    }}
                    onSubmit={(
                      { country, city, street, zip, phone, addressAddition },
                      actions
                    ) => {
                      const controller = new AbortController()
                      const signal = controller.signal
                      const address = {
                        country,
                        city,
                        street,
                        zip,
                        phone,
                        addressAddition,
                        affiliation: 'personal'
                      }
                      if (token && userId) {
                        dispatch(
                          updateUserAddress({
                            id: userId,
                            token,
                            address,
                            signal
                          })
                        )
                      }
                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <h6 className="mb-4 text-uppercase">
                          <i className="bi bi-pencil-square me-1"></i>{' '}
                          Update Personal Address
                        </h6>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="profileStreet"
                                className="form-label"
                              >
                                Street and House Number
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.street}
                                type="text"
                                className={`form-control ${
                                  errors.street &&
                                  touched.street &&
                                  errors.street
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="profileStreet"
                                name="street"
                                placeholder="Enter street and House Number"
                              />
                              <div
                                id="validationProfileStreetFeedback"
                                className="invalid-feedback"
                              >
                                {errors.street || error?.errors?.street}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="profileZip" className="form-label">
                                Zip
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.zip}
                                type="text"
                                className={`form-control ${
                                  errors.zip && touched.zip && errors.zip
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="profileZip"
                                name="zip"
                                placeholder="Enter zip"
                              />
                              <div
                                id="validationProfileZipFeedback"
                                className="invalid-feedback"
                              >
                                {errors.zip || error?.errors?.zip}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="profileCity" className="form-label">
                                City
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                className={`form-control ${
                                  errors.city &&
                                  touched.city &&
                                  errors.city
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={values.city}
                                id="profileCity"
                                name="city"
                                placeholder="Enter city"
                              />
                              <div
                                id="validationProfileCityFeedback"
                                className="invalid-feedback"
                              >
                                {errors.city ||
                                  error?.errors?.city}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="profileAddressAddition"
                                className="form-label"
                              >
                                Address Addition
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.addressAddition}
                                type="text"
                                className={`form-control ${
                                  errors.addressAddition &&
                                  touched.addressAddition &&
                                  errors.addressAddition
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="profileAddressAddition"
                                name="addressAddition"
                                placeholder="Enter Address Addition"
                              />
                              <div
                                id="validationProfileAddressAdditionFeedback"
                                className="invalid-feedback"
                              >
                                {errors.addressAddition ||
                                  error?.errors?.addressAddition}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="profilePhone" className="form-label">
                                Phone
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                type="text"
                                className={`form-control ${
                                  errors.phone &&
                                  touched.phone &&
                                  errors.phone
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="profilePhone"
                                name="phone"
                                placeholder="Enter Phone Number"
                              />
                              <div
                                id="validationProfilePhoneFeedback"
                                className="invalid-feedback"
                              >
                                {errors.phone || error?.errors?.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="country"
                                className="form-label"
                              >
                                Country
                              </label>
                                <Select
                                  className={`${
                                    touched.country &&
                                    errors.country
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      borderColor: (errors.country && touched.country) ? '#dc3545' : provided.borderColor,
                                      '&:hover': {
                                        borderColor: (errors.country && touched.country) ? '#dc3545' : provided.borderColor
                                      }
                                    })
                                  }}
                                  isClearable
                                  inputId="country"
                                  name="country"
                                  aria-label="Country"
                                  options={countries}
                                  getOptionLabel={(country) => `${country.value}`}
                                  getOptionValue={(country) => String(country.value)}
                                  onChange={(selectedOption) => {
                                    const selectedCountry = selectedOption?.value ?? ''
                                    setFieldValue('country', selectedCountry)
                                  }}
                                  onBlur={handleBlur}
                                  value={countries.find((country) => country.value === values.country)}
                                />
                              <div
                                id="validationProfileCountryFeedback"
                                className="invalid-feedback"
                              >
                                {errors.country || error?.errors?.country}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary mt-2"
                            disabled={
                              isSubmitting || isUpdating || isLoading
                            }
                          >
                            <i className="bi bi-save"></i> Save
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="border border-gray rounded mb-3">
                {(isLoading || isUpdating) && <Progress />}
                <div className="p-3">
                  <Formik
                    validationSchema={passwordSchema}
                    enableReinitialize
                    initialValues={{
                      currentPassword: '',
                      password: ''
                    }}
                    onSubmit={(
                      { currentPassword, password },
                      actions
                    ) => {
                      const controller = new AbortController()
                      const signal = controller.signal
                      const user = {
                        currentPassword,
                        password
                      }
                      if (token && userId) {
                        dispatch(
                          updateUserPasswordById({
                            id: userId,
                            token,
                            user,
                            signal
                          })
                        )
                      }
                      actions.setSubmitting(false)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <h6 className="mb-4 text-uppercase">
                          <i className="bi bi-pencil-square me-1"></i>{' '}
                          Update Password
                        </h6>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="currentPassword"
                                className="form-label"
                              >
                                Current Password
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.currentPassword}
                                type="password"
                                className={`form-control ${
                                  errors.currentPassword &&
                                  touched.currentPassword &&
                                  errors.currentPassword
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="currentPassword"
                                name="currentPassword"
                                placeholder="Enter Current Password"
                              />
                              <div
                                id="validationCurrentPasswordFeedback"
                                className="invalid-feedback"
                              >
                                {errors.currentPassword || error?.errors?.currentPassword}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="newPassword"
                                className="form-label"
                              >
                                New Password
                              </label>
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                type="password"
                                className={`form-control ${
                                  errors.password &&
                                  touched.password &&
                                  errors.password
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="newPassword"
                                name="password"
                                placeholder="Enter New Password"
                              />
                              <div
                                id="validationNewPasswordFeedback"
                                className="invalid-feedback"
                              >
                                {errors.password || error?.errors?.password}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary mt-2"
                            disabled={
                              isSubmitting || isUpdating || isLoading
                            }
                          >
                            <i className="bi bi-save"></i> Update
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="modal fade" id="salutationModal" tabIndex={-1} role="dialog" aria-labelledby="salutationModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="salutationModalLabel">Add Salutation</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingSalutations && <Progress />}
                    <div className="modal-body">
                      <SalutationEditor id='' title='' save={addSalutation} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="salutationEditModal" tabIndex={-1} role="dialog" aria-labelledby="salutationEditModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="salutationEditModalLabel">Edit Salutation</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingSalutations && <Progress />}
                    <div className="modal-body">
                      <ul className="list-group">
                        {
                          salutations.map((salutation) => (
                            <li key={salutation.id} className="list-group-item d-flex justify-content-between align-items-start">
                              <div className="ms-2 me-auto">
                                <div className="fw-bold">{salutation.title}</div>
                              </div>
                              <div className="d-flex flex-row" role="group" aria-label="Salutation Actions">
                                <button
                                  type="button"
                                  id="button-addon"
                                  className="btn btn-outline-secondary btn-round btn-sm me-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#salutationEditorModal"
                                  onClick={() => {
                                    setSelectedSalutation(salutation)
                                  }}
                                >
                                  <PencilIcon />
                                </button>
                                <button
                                  type="button"
                                  id="button-addon"
                                  className="btn btn-outline-danger btn-round btn-sm"
                                  onClick={() => {
                                    if (token) {
                                      const controller = new AbortController()
                                      const signal = controller.signal
                                      dispatch(deleteASalutationById({ token, salutationId: salutation.id, signal }))
                                    }
                                  }}
                                >
                                  <TrashIcon />
                                </button>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="salutationEditorModal" tabIndex={-1} aria-labelledby="salutationEditorModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="salutationEditorModalLabel">{`Edit '${selectedSalutation.title}'`}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {isLoadingSalutations && <Progress />}
                    <div className="modal-body">
                      <SalutationEditor
                        id={selectedSalutation.id}
                        title={selectedSalutation.title}
                        save={editASalutationById}
                        mode="edit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
