import React from 'react'
import { StockProduct } from '../../../types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const SerialNumber = ({ stockProduct }: { stockProduct: StockProduct }) => {
  const { deliveryNotePosition: { deliveryNote: { order } } } = stockProduct
  const name = `${order.orderAddress.firstName} ${order.orderAddress.lastName}`
  const date = order.expectedDeliveryDate ? dayjs.utc(order.expectedDeliveryDate).local().format('LL') : '---'

  return (
    <tr>
      <td>{order.orderNumber}</td>
      <td>{order.orderAddress.company || name}</td>
      <td>{stockProduct.serialNumber}</td>
      <td>{date}</td>
    </tr>
  )
}

export default SerialNumber
