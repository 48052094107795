import React, { ChangeEvent, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import dayjs from 'dayjs'
import { ShoppingCartAddress } from '../../types'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import Pagination from '../Pagination'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getUserAddresses, resetUserMessage } from '../../store/reducers/api/usersReducer'
import useDebounce from '../../utils/hooks/useDebounce'
import { ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE } from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'

interface ShoppingDeliveryAddressPickerProps {
  isLoading: boolean
  setSelectedAddress: React.Dispatch<React.SetStateAction<Partial<ShoppingCartAddress> | null>>
  selectedAddress: Partial<ShoppingCartAddress> | null
  isAllowedToWriteDeliveryAddresses: boolean
  isOneAddress: boolean
  selectedMultipleAddresses: string[] | null,
  setSelectedMultipleAddresses: React.Dispatch<React.SetStateAction<string[] | null>>
}
const ShoppingDeliveryAddressPicker = ({
  isOneAddress,
  isLoading,
  setSelectedAddress,
  selectedAddress,
  isAllowedToWriteDeliveryAddresses,
  selectedMultipleAddresses,
  setSelectedMultipleAddresses
} : ShoppingDeliveryAddressPickerProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const deliveryAddresses = useAppSelector((state) => state.apiUsers.addresses)
  const address = useAppSelector((state) => state.apiUsers.address)
  const metadata = useAppSelector((state) => state.apiUsers.addressesMetadata)
  const message = useAppSelector((state) => state.apiUsers.message)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)

  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const token = currentUser?.token
  const userId = currentUser?.id

  const dispatch = useAppDispatch()

  const searchSchema = object({
    search: string()
      .max(256, 'Search Name is too long')
  })

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && userId) {
      const search = debouncedSearchTerm
      const filter = 'filter[type]=delivery,billingAndDelivery'
      dispatch(getUserAddresses({ id: userId, token, perPage, page, signal, search, filter }))
    }

    return () => {
      controller.abort()
    }
  }, [page, perPage, debouncedSearchTerm])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const allowedMessages = [ADDRESS_ADDITION_SUCCESS_MESSAGE, ADDRESS_UPDATE_SUCCESS_MESSAGE]

    if (token && userId && (allowedMessages.includes(message) && address?.type === 'delivery')) {
      const search = debouncedSearchTerm
      const filter = 'filter[type]=delivery,billingAndDelivery'
      dispatch(getUserAddresses({ id: userId, token, perPage, page, signal, search, filter }))
      dispatch(resetUserMessage())
      dismissModal('addressEditorModal')
      openModal('shoppingDeliveryAddressPickerModal')
    }
  }, [message])

  return (
    <div className="row">
      <div className="col">
        <div className="p-0">
          <div>
            <div className="mb-4 mt-2">
              <div className="">
                <Formik
                  validationSchema={searchSchema}
                  enableReinitialize
                  initialValues={{
                    search: searchTerm
                  }}
                  onSubmit={({ search }, actions) => {
                    setSearchTerm(search)
                    handlePageChange(1)

                    actions.setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="input-group">
                        <input
                          onChange={(event) => {
                            const search = event.target.value
                            handleChange(event)
                            setSearchTerm(search)
                            handlePageChange(1)
                          }}
                          maxLength={256}
                          onBlur={handleBlur}
                          value={values.search}
                          className={`form-control ${
                            errors.search &&
                            touched.search &&
                            errors.search
                              ? 'is-invalid'
                              : ''
                          }`}
                          type="text"
                          placeholder="Search delivery addresses..."
                          aria-label="Search delivery addresses"
                          aria-describedby="deliveryAddressesSearch"
                          name="search"
                          autoComplete="on"
                        />
                        <button
                          className="btn btn-outline-dark"
                          id="deliveryAddressesSearch"
                          type="submit"
                          title="Search"
                          disabled={isSubmitting}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
              {isAllowedToWriteDeliveryAddresses && <div className="col">
                <div className="card h-100 bundle-card" role="button">
                  <div
                    onClick={() => {
                      openModal('addressEditorModal')
                      dismissModal('shoppingDeliveryAddressPickerModal')
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        openModal('addressEditorModal')
                        dismissModal('shoppingDeliveryAddressPickerModal')
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label="Add New Address"
                    className="h-100 d-flex align-items-center"
                  >
                    <div className="card-body py-5">
                      <div className="text-center py-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>
                      </div>
                      <h6 className="card-title text-center text-primary mt-3">
                        Add New Address
                      </h6>
                    </div>
                  </div>
                </div>
              </div>}
              {
                deliveryAddresses.map((address) => (
                  <div
                    className="col"
                    key={address.id}
                  >
                    <div className={`card h-100 template-card ${isOneAddress ? (selectedAddress?.id === address.id ? 'selected' : '') : (selectedMultipleAddresses?.includes(address.id) ? 'selected' : '')}`} role="button">
                      <div
                        onClick={() => {
                          if (shoppingCartAddresses.find(shoppingCartAddress => shoppingCartAddress.id === address.id)) {
                            const toastPayload = {
                              title: 'Info',
                              message: 'Delivery address already selected',
                              isVisible: true,
                              timestamp: dayjs().format('LT'),
                              type: 'info',
                              delay: 2000
                            }
                            dispatch(setToast(toastPayload))
                          } else {
                            if (isOneAddress) {
                              setSelectedAddress(address)
                            } else {
                              setSelectedMultipleAddresses((prev) => {
                                if (!prev?.includes(address.id)) {
                                  return [...(prev || []), address.id]
                                }
                                return prev.filter(id => id !== address.id)
                              })
                            }
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ' ') {
                            if (shoppingCartAddresses.find(shoppingCartAddress => shoppingCartAddress.id === address.id)) {
                              const toastPayload = {
                                title: 'Info',
                                message: 'Delivery address already selected',
                                isVisible: true,
                                timestamp: dayjs().format('LT'),
                                type: 'info',
                                delay: 2000
                              }
                              dispatch(setToast(toastPayload))
                            } else {
                              if (isOneAddress) {
                                setSelectedAddress(address)
                              } else {
                                setSelectedMultipleAddresses((prev) => {
                                  if (!prev?.includes(address.id)) {
                                    return [...(prev || []), address.id]
                                  }
                                  return prev.filter(id => id !== address.id)
                                })
                              }
                            }
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label={`Select ${address.companyName ?? address.firstName ?? 'Address'}`}
                        className="h-100"
                      >
                        {
                          shoppingCartAddresses.find(shoppingCartAddress => shoppingCartAddress.id === address.id) && (
                            <div className="position-relative" title="Already selected address">
                              <span className="badge rounded-pill text-bg-success position-absolute top-0 end-0 m-1">
                                <i className="bi bi-check-circle-fill"></i>
                              </span>
                            </div>
                          )
                        }
                        <div className="card-body">
                          <h5 className="card-title">{address.companyName ?? '---'}</h5>
                          <p className="card-text lh-1">{`${address.firstName ?? '--'} ${address.lastName ?? '--'}`}</p>
                          <p className="card-text lh-1">{`${address.street ?? '--'} ${address.zip ?? '--'}`}</p>
                          <p className="card-text lh-1">{`${address.city ?? '--'} ${address.country}`}</p>
                          <p className="card-text lh-1">Cost Center: {address.costCenter ?? 'Not Set'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="row mt-4">
              <Pagination
                isLoading={isLoading}
                metadata={{
                  limit: metadata.perPage,
                  total: metadata.total,
                  offset: ((metadata.page - 1) * (metadata.perPage))
                }}
                page={page}
                perPage={perPage}
                handlePageChange={handlePageChange}
                handleShowEntries={handleShowEntries}
                perPageOptions={[5, 10, 25, 50]}
                module="ShoppingDeliveryAddressPicker"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingDeliveryAddressPicker
