import { Theme } from '../types'
import { reduceOpacity } from './reduceColorHexOpacity'

export const configureTheme = (theme: Theme | null) => {
  if (theme) {
    document.documentElement.style.setProperty('--ed-background-color', theme.backgroundColor)
    document.documentElement.style.setProperty('--ed-accent-color', theme.accentColor)
    document.documentElement.style.setProperty('--ed-foreground-color', theme.foregroundColor)
    document.documentElement.style.setProperty('--ed-primary-color', theme.primaryColor)
    document.documentElement.style.setProperty('--ed-secondary-color', theme.secondaryColor)
    if (theme.primaryColor) {
      const lowOpacityColor = reduceOpacity(theme.primaryColor, 50)
      document.documentElement.style.setProperty('--ed-primary-reduce-opacity', lowOpacityColor)
    }
    if (theme.foregroundColor) {
      const lowOpacityColor = reduceOpacity(theme.foregroundColor, 50)
      document.documentElement.style.setProperty('--ed-foreground-reduce-opacity', lowOpacityColor)
    }
    if (theme.backgroundColor) {
      const lowOpacityColor = reduceOpacity(theme.backgroundColor, 50)
      document.documentElement.style.setProperty('--ed-background-reduce-opacity', lowOpacityColor)
    }
  } else {
    const defaultTheme = {
      backgroundColor: '#212529',
      foregroundColor: '#d3d4d4',
      primaryColor: '#ff5129',
      secondaryColor: '#2a314a',
      accentColor: '#ffffff'
    }

    document.documentElement.style.setProperty('--ed-background-color', defaultTheme.backgroundColor)
    document.documentElement.style.setProperty('--ed-accent-color', defaultTheme.accentColor)
    document.documentElement.style.setProperty('--ed-foreground-color', defaultTheme.foregroundColor)
    document.documentElement.style.setProperty('--ed-primary-color', defaultTheme.primaryColor)
    document.documentElement.style.setProperty('--ed-secondary-color', defaultTheme.secondaryColor)
    if (defaultTheme.primaryColor) {
      const lowOpacityColor = reduceOpacity(defaultTheme.primaryColor, 50)
      document.documentElement.style.setProperty('--ed-primary-reduce-opacity', lowOpacityColor)
    }
    if (defaultTheme.foregroundColor) {
      const lowOpacityColor = reduceOpacity(defaultTheme.foregroundColor, 50)
      document.documentElement.style.setProperty('--ed-foreground-reduce-opacity', lowOpacityColor)
    }
    if (defaultTheme.backgroundColor) {
      const lowOpacityColor = reduceOpacity(defaultTheme.backgroundColor, 50)
      document.documentElement.style.setProperty('--ed-background-reduce-opacity', lowOpacityColor)
    }
  }
}
