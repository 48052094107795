import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import ShoppingCart from './ShoppingCart'
import ShoppingShippingDetails from './ShoppingShippingDetails'
import { dismissModal } from '../../utils/dismissModal'
import { openModal } from '../../utils/openModal'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import ShoppingDeliveryAddressPicker from './ShoppingDeliveryAddressPicker'
import { Address, ShoppingCartAddress, ShoppingCartBundle, ShoppingCartProduct, SplitProducts } from '../../types'
import { resetShoppingAddresses, resetShoppingCart, setShoppingAddresses, setShoppingCart, setSplitProduct } from '../../store/reducers/shoppingCartReducer'
import ShoppingDeliveryAddressEditor from './ShoppingDeliveryAddressEditor'
import ShoppingDetailsAssignArticles from './ShoppingDetailsAssignArticles'
import AddressEditor from './AddressEditor'
import { updateUserAddress } from '../../store/reducers/api/usersReducer'
import Progress from '../loaders/Progress'
import { setToast } from '../../store/reducers/toastReducer'
import ShoppingPayment from './ShoppingPayment'
import Terms from '../Terms'
import BundleConfiguratorProductPicker from './BundleConfigurator/BundleConfiguratorProductPicker'
import {
  allowedFillingMaterialTagNames,
  allowedGreetingCardsTagNames,
  allowedPackagingTagNames,
  allowedTissuePaperTagNames
} from '../../utils/allowedBundleTags'
import ShoppingOrderConfirmation from './ShoppingOrderConfirmation'
import { TrashIcon } from '../icons/TrashIcon'
import { generateSpliteId } from '../../utils/idGenerator'
import { getMaximumProductOrderQuantity } from '../../utils/getMaximumProductOrderQuantity'
import { getMinimumProductOrderQuantity } from '../../utils/getMinimumProductOrderQuantity'
import { addToPreMadeBox, removeFromPreMadeBox } from '../../store/reducers/PreMadeBoxReducer'

interface ShoppingModalsProps {
  setSelectedAddress: React.Dispatch<React.SetStateAction<Partial<ShoppingCartAddress> | null>>
  selectedAddress: Partial<ShoppingCartAddress> | null
  isAllowedToWriteDeliveryAddresses: boolean
  loadCostCenterOptionsDebounced: any,
  selectedMultipleAddresses: string[] | null,
  setSelectedMultipleAddresses: React.Dispatch<React.SetStateAction<string[] | null>>
}
const ShoppingModals = ({
  setSelectedAddress,
  selectedAddress,
  isAllowedToWriteDeliveryAddresses,
  loadCostCenterOptionsDebounced,
  selectedMultipleAddresses,
  setSelectedMultipleAddresses
}: ShoppingModalsProps) => {
  const profile = useAppSelector((state) => state.profile.profile)
  const splitProduct = useAppSelector((state) => state.shoppingCart.splitProduct)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartLeftOverProducts = useAppSelector((state) => state.shoppingCart.leftOverProducts)
  const deliveryAddresses = useAppSelector((state) => state.apiUsers.addresses)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const isBulkOrder = useAppSelector((state) => state.shoppingCart.isBulkOrder)
  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || 1 }))
  const isLoadingUserAddress = useAppSelector((state) => state.apiUsers.isLoading)
  const legalTexts = useAppSelector((state) => state.apiCompany.legalTexts)

  const [selectedAssignmentAddress, setSelectedAssignmentAddress] = useState<ShoppingCartAddress | null>(null)
  const [shippingMode, setShippingMode] = useState<'inventory' | 'delivery' | null>('delivery')
  const [areBundlesValid, setAreBundlesValid] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<Partial<Address> | null>(null)
  const [isOneAddress, setIsOneAddress] = useState<boolean>(true)
  const [splitedProducts, setSplitedProducts] = useState<SplitProducts | null>(null)
  const [assignedItems, setAssignedItems] = useState<String[] | null>(null)
  const billingOrbillingAndDeliveryAddress = profile?.addresses?.find(address => address.type === 'billing' || address.type === 'billingAndDelivery')

  const dispatch = useAppDispatch()

  const maximumQuantity = 1000

  const handleConfirmAndPay = () => {
    const allAdressesAreFild = shoppingCartAddresses.every(address => address.assignedLeftOverProductIds.length > 0)
    if (!allAdressesAreFild && !isOneAddress) {
      dispatch(setToast({
        title: 'delivery destination Required',
        message: 'Please fill in all delivery destinations.',
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger',
        delay: 3000
      }))
    } else if (!isOneAddress && assignedItems?.length !== shoppingCartProducts.length && !isOneAddress) {
      dispatch(setToast({
        title: 'Not all items assigned',
        message: 'Please add remaining items to a delivery destination.',
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger',
        delay: 3000
      }))
    } else {
      openModal('shoppingPaymentModal')
      dismissModal('shoppingShippingDetailsModal')
    }
  }

  const validateShoppingCartBundles = (shoppingCartBundles: ShoppingCartBundle[]): boolean => {
    if (shoppingCartBundles.length === 0) return false

    const categoryMap = [
      { tags: allowedPackagingTagNames },
      { tags: allowedFillingMaterialTagNames },
      { tags: allowedGreetingCardsTagNames },
      { tags: allowedTissuePaperTagNames }
    ]
    return shoppingCartBundles.every(bundle => {
      let hasPackaging = false
      let hasFillingMaterial = false
      let hasBundleContent = false

      bundle.products.forEach(product => {
        const productTags = product.productTags.map(tag => tag.productCategoryTag.name.toLowerCase())

        const category = categoryMap.find(cat => productTags.some(tag => cat.tags.includes(tag)))

        if (category) {
          if (category.tags === allowedPackagingTagNames) {
            hasPackaging = true
          } else if (category.tags === allowedFillingMaterialTagNames) {
            hasFillingMaterial = true
          }
        } else {
          hasBundleContent = true
        }
      })

      return hasPackaging && hasFillingMaterial && hasBundleContent
    })
  }

  useEffect(() => {
    setAreBundlesValid(validateShoppingCartBundles(shoppingCartBundles))
  }, [shoppingCartBundles])

  useEffect(() => {
    setSelectedBillingAddress(billingOrbillingAndDeliveryAddress as ShoppingCartAddress | null)
  }, [billingOrbillingAndDeliveryAddress])

  const calculateTotalQuantityInBundles = ({ splitProduct }:{splitProduct:ShoppingCartProduct | null}): number => {
    return shoppingCartBundles.reduce((total, bundle) => {
      const bundleProduct = bundle.products.find(p => (splitProduct?.spliteId && p.spliteId) ? p.spliteId === splitProduct.spliteId : p.id === splitProduct?.id)
      return total + (bundleProduct ? bundleProduct.quantity * bundle.quantity : 0)
    }, 0)
  }
  return (
    <>
      <div className="modal fade" id="shoppingCartModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingCartModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingCartModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingCart />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (shoppingCartProducts.length > 0) {
                    if (!isBulkOrder && !areBundlesValid) {
                      const toastPayload = {
                        title: 'Warning',
                        message: 'Please ensure the bundles are complete.',
                        isVisible: true,
                        timestamp: dayjs().format('LT'),
                        type: 'warning',
                        delay: 2000
                      }
                      dispatch(setToast(toastPayload))
                      return
                    }
                    dismissModal('shoppingCartModal')
                    openModal('shoppingShippingDetailsModal')
                  } else {
                    const toastPayload = {
                      title: 'Warning',
                      message: 'Please ensure your cart is not empty.',
                      isVisible: true,
                      timestamp: dayjs().format('LT'),
                      type: 'warning',
                      delay: 2000
                    }
                    dispatch(setToast(toastPayload))
                  }
                }}
                disabled={shoppingCartProducts.length === 0 || (!isBulkOrder && !areBundlesValid)}
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingShippingDetailsModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingShippingDetailsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingShippingDetailsModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingShippingDetails
                setIsOneAddress = { setIsOneAddress }
                setSelectedAddress={setSelectedAddress}
                isOneAddress = { isOneAddress }
                setSelectedAssignmentAddress={setSelectedAssignmentAddress}
                shippingMode={shippingMode}
                setShippingMode={setShippingMode}
                setEditMode={setEditMode}
                setAssignedItems={setAssignedItems}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingShippingDetailsModal')
                  openModal('shoppingCartModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary d-flex justify-content-between align-items-center"
                onClick={() => handleConfirmAndPay()}
                disabled={shoppingCartAddresses.length === 0}
              >
                <span>Confirm and Pay</span>
                <i className="bi bi-arrow-right ms-2 text-white"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDeliveryAddressPickerModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDeliveryAddressPickerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDeliveryAddressPickerModalLabel"><i className="bi bi-truck me-1"></i>Shipping Details - Select delivery address</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingUserAddress ? <Progress marginBottom={false} /> : <hr className="border border-1 opacity-50 m-0"></hr>}
            <div className="modal-body">
              <ShoppingDeliveryAddressPicker
                selectedMultipleAddresses = {selectedMultipleAddresses}
                setSelectedMultipleAddresses = {setSelectedMultipleAddresses}
                isOneAddress = {isOneAddress}
                isLoading={isLoadingUserAddress}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                isAllowedToWriteDeliveryAddresses={isAllowedToWriteDeliveryAddresses}
              />
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  if (selectedAddress) {
                    setSelectedAddress(null)
                    const updatedAddresses = shoppingCartAddresses.filter(address => address.id !== selectedAddress.id)
                    dispatch(setShoppingAddresses({ addresses: updatedAddresses }))
                  }
                }}
                disabled={!selectedAddress}
              >
                Reset Selection
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingShippingDetailsModal')
                    if (selectedAddress) {
                      const updatedAddresses = shoppingCartAddresses.filter(address => address.id !== selectedAddress.id)
                      const payload = {
                        addresses: updatedAddresses
                      }
                      dispatch(setShoppingAddresses(payload))
                      setSelectedAddress(null)
                    }
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    dismissModal('shoppingDeliveryAddressPickerModal')
                    openModal('shoppingShippingDetailsModal')
                    if (isOneAddress && selectedAddress?.id) {
                      const updatedAddress: ShoppingCartAddress = {
                        ...selectedAddress,
                        id: selectedAddress.id,
                        shippingDate: '',
                        assignedBundleIds: shoppingCartBundles.map(bundle => bundle.id),
                        assignedLeftOverProductIds: shoppingCartLeftOverProducts.map(product => product.id),
                        vat: null,
                        type: 'delivery',
                        country: selectedAddress.country ?? '',
                        companyName: selectedAddress.companyName ?? '',
                        salutation: selectedAddress.salutation ?? '',
                        firstName: selectedAddress.firstName ?? '',
                        lastName: selectedAddress.lastName ?? '',
                        email: selectedAddress.email ?? '',
                        phone: selectedAddress.phone ?? '',
                        street: selectedAddress.street ?? '',
                        zip: selectedAddress.zip ?? '',
                        city: selectedAddress.city ?? '',
                        addressAddition: selectedAddress.addressAddition ?? '',
                        note: '',
                        costCenter: selectedAddress.costCenter ?? ''
                      }
                      const updatedAddresses = [updatedAddress]
                      const payload = { addresses: updatedAddresses }
                      if (isOneAddress) {
                        dispatch(resetShoppingAddresses())
                      }
                      dispatch(setShoppingAddresses(payload))
                    } else if (!isOneAddress) {
                      const updatedShippingAddresses = [
                        ...shoppingCartAddresses
                      ]
                      selectedMultipleAddresses?.forEach(addressId => {
                        const address = deliveryAddresses.find(deliveryAddress => deliveryAddress.id === addressId)
                        if (address) {
                          const updatedAddress: ShoppingCartAddress = {
                            ...address,
                            type: 'delivery',
                            shippingDate: '',
                            assignedBundleIds: [],
                            assignedLeftOverProductIds: [],
                            vat: null,
                            note: ''
                          }
                          updatedShippingAddresses.push(updatedAddress)
                        }
                      })
                      dispatch(setShoppingAddresses({ addresses: updatedShippingAddresses }))
                      setSelectedMultipleAddresses([])
                    }
                  }}
                  disabled={((selectedMultipleAddresses ?? []).length <= 0) && !selectedAddress}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDeliveryAddressEditorModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDeliveryAddressEditorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDeliveryAddressEditorModalLabel"><i className="bi bi-cart me-1"></i>Shipping Details - Add delivery address</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ShoppingDeliveryAddressEditor
                isOneAddress = { isOneAddress }
                shippingAddress={selectedAddress}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                setSelectedDeliveryAddress={setSelectedAddress}
                setSelectedAddress={setSelectedAddress}
                editMode={editMode}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingDetailsAssignArticlesModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingDetailsAssignArticlesModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingDetailsAssignArticlesModalLabel"><i className="bi bi-cart me-1"></i>Shopping Details - Assign Articles</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {
                selectedAssignmentAddress
                  ? (<ShoppingDetailsAssignArticles selectedAssignmentAddress={selectedAssignmentAddress} setSelectedAssignmentAddress={setSelectedAssignmentAddress} />)
                  : (<p>No assignment address selected</p>)
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dismissModal('shoppingDetailsAssignArticlesModal')
                  openModal('shoppingShippingDetailsModal')
                }}
              >
                Assign Articles
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="addressEditorModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="addressEditorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addressEditorModalLabel"><i className="bi bi-cart me-1"></i>Shopping Details - Delivery Address Editor</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoadingUserAddress ? <Progress marginBottom={false} /> : <hr className="border border-1 opacity-50 m-0"></hr>}
            <div className="modal-body">
              <AddressEditor
                initialAddress={selectedAddress}
                id={profile?.id || ''}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                save={updateUserAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingPaymentModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingPaymentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingPaymentModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <hr className="border border-1 opacity-50 m-0"></hr>
            <div className="modal-body">
              <ShoppingPayment
                billingAddress={selectedBillingAddress}
                loadCostCenterOptionsDebounced={loadCostCenterOptionsDebounced}
                shippingMode={shippingMode}
                setSelectedBillingAddress={setSelectedBillingAddress}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="termsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="termsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-3" id="termsModalLabel">Terms and Conditions</h1>
              <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#shoppingPaymentModal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Terms terms={legalTexts.find(legalText => legalText.type === 'terms')?.template} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#shoppingPaymentModal">Understood</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="bundleConfiguratorProductPickerModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="bundleConfiguratorProductPickerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="bundleConfiguratorProductPickerModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <BundleConfiguratorProductPicker />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dismissModal('bundleConfiguratorProductPickerModal')
                  openModal('shoppingCartModal')
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="shoppingOrderConfirmationModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="shoppingOrderConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="shoppingOrderConfirmationModalLabel"><i className="bi bi-cart me-1"></i>Shopping Cart</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => {
                  dispatch(resetShoppingCart())
                  dispatch(resetShoppingAddresses())
                }}
              ></button>
            </div>
            <div className="modal-body">
              <ShoppingOrderConfirmation selectedBillingAddress={selectedBillingAddress} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="splitAmountModal" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="splitAmountModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '577px' }}>
          <div className="modal-content" style={{ height: '400px', width: '577px' }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5 d-flex align-items-center" id="splitAmountModalLabel">
                <i className="bi bi-pencil-fill me-2"></i> Split Amount
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => {
                  openModal('shoppingShippingDetailsModal')
                  dismissModal('splitAmountModal')
                  setSplitedProducts(null)
                }}
              >
              </button>
            </div>
            <div className="modal-body"
              style={{
                display: 'grid',
                placeItems: '',
                height: '100%',
                padding: '0'
              }}>
              <div className='w-100'>

                <div className='d-flex justify-content-between align-items-center bg-light w-100 p-3 border-bottom border-1' style={{ height: '50px' }}>
                  <h6 className='fw-bold'>{splitProduct?.name}</h6>
                  <div className="d-flex justify-content-center align-items-center gap-3" style={{ color: '#808080' }}>
                    remaining Amount
                    <input
                      type="number"
                      min={Math.max(calculateTotalQuantityInBundles({ splitProduct }), getMinimumProductOrderQuantity(splitProduct?.minimumOrderQuantity || 0, splitProduct?.graduatedPrices || []), calculateTotalQuantityInBundles({ splitProduct }))}
                      max={(splitProduct?.isExceedStockEnabled ? getMaximumProductOrderQuantity(maximumQuantity, splitProduct.graduatedPrices) : Math.max((splitProduct?.stock?.stockLevel || 0) - (splitProduct?.stock?.stockLevelReserved || 0), 0) || 1)}
                      value={splitProduct?.quantity}
                      onChange={(e) => {
                        const max = Math.max((splitProduct?.stock?.stockLevel || 0) - (splitProduct?.stock?.stockLevelReserved || 0), 0) || 1
                        let min = getMinimumProductOrderQuantity(splitProduct?.minimumOrderQuantity || 1, splitProduct?.graduatedPrices || [])

                        const totalShoppingCartProductInBundlesQuantity = calculateTotalQuantityInBundles({ splitProduct })

                        min = Math.max(min, totalShoppingCartProductInBundlesQuantity)
                        const value = Number(e.target.value)
                        const quantity = splitProduct?.isExceedStockEnabled ? Math.max(Math.min(value, getMaximumProductOrderQuantity(maximumQuantity, splitProduct?.graduatedPrices || [])), min) : Math.max(Math.min(value, max), min)
                        const foundProductInCartIndex = shoppingCartProductsWithQuantity.findIndex(cartProduct => (splitProduct?.spliteId && cartProduct.spliteId) ? cartProduct.spliteId === splitProduct.spliteId : cartProduct.id === splitProduct?.id)

                        if (foundProductInCartIndex !== -1) {
                          const updatedProductsInCart = [...shoppingCartProductsWithQuantity]
                          updatedProductsInCart[foundProductInCartIndex] = {
                            ...updatedProductsInCart[foundProductInCartIndex],
                            quantity
                          }
                          const payload = {
                            products: updatedProductsInCart
                          }
                          const updatedSplitProduct = { ...splitProduct, quantity }
                          dispatch(setSplitProduct(updatedSplitProduct))
                          dispatch(setShoppingCart(payload))
                        }
                      }}
                      name="quantity"
                      className="form-control"
                      aria-label={'Cart Item Quantity'}
                      autoComplete="off"
                      disabled={false}
                      style={{ width: '4rem' }}
                    />
                  </div>

                  <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                    <button type="button"
                      className='btn btn-primary d-flex justify-content-center align-items-center '
                      style={{
                        width: '31px',
                        height: '31px',
                        padding: 0
                      }}
                      onClick={() => {
                        if (splitProduct && splitProduct.quantity > 0) {
                          const updatedProduct = { ...splitProduct, quantity: splitProduct.quantity - 1 }
                          dispatch(setSplitProduct(updatedProduct))
                          setSplitedProducts((prev) => {
                            const currentProducts = prev ? [...prev.products] : []
                            const newLocalId = prev ? (prev.lastId || 0) + 1 : 1
                            const newProduct = {
                              ...splitProduct,
                              quantity: 1,
                              isSplited: true,
                              spliteId: generateSpliteId()
                            }
                            return {
                              products: [...currentProducts, newProduct],
                              lastId: newLocalId
                            } as SplitProducts
                          })
                        }
                      }}
                    >
                      <i className="bi bi-plus fs-2"></i>
                    </button>
                  </div>
                </div>
                <div style={{ maxHeight: '200px', overflowY: 'auto', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                  <table className="table p-3">
                    <thead className='p-3'>
                      <tr className='p-3'>
                        <th scope="col">Product</th>
                        <th scope="col">Split Quantity</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {splitedProducts && splitedProducts.products.length > 0 && (
                        splitedProducts.products.map((product) => (
                          <tr key={product.spliteId || product.id }>
                            <td>{product.name}</td>
                            <td>
                              <input
                                type="number"
                                value={product.quantity}
                                onChange={(e) => {
                                  const newQuantity = Number(e.target.value)
                                  const previousQuantity = product.quantity

                                  if (splitProduct) {
                                    const availableQuantity = splitProduct.quantity + previousQuantity

                                    if (newQuantity <= availableQuantity && newQuantity >= 1) {
                                      const updatedQuantity = availableQuantity - newQuantity
                                      dispatch(setSplitProduct({ ...splitProduct, quantity: updatedQuantity }))

                                      setSplitedProducts((prev) => {
                                        if (prev) {
                                          const updatedProducts = prev.products.map(p =>
                                            p.spliteId === product.spliteId ? { ...p, quantity: newQuantity } : p
                                          )
                                          return { ...prev, products: updatedProducts } as SplitProducts
                                        }
                                        return null
                                      })
                                    }
                                  }
                                }}
                                min={1}
                                max={splitProduct ? splitProduct?.quantity + product.quantity : 0}
                                className="form-control"
                                style={{ width: '80px' }}
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-round-sm"
                                onClick={() => {
                                  if (splitProduct) {
                                    const updatedProduct = { ...splitProduct, quantity: splitProduct.quantity + product.quantity }
                                    dispatch(setSplitProduct(updatedProduct))
                                  }
                                  setSplitedProducts((prev) => {
                                    if (prev) {
                                      const filteredProducts = prev.products.filter(p => p.spliteId !== product.spliteId)
                                      return {
                                        ...prev,
                                        products: filteredProducts
                                      } as SplitProducts
                                    }
                                    return null
                                  })
                                }}
                              >
                                <TrashIcon />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between align-items-center w-100 p-3'>
              <div>{' '}</div>
              <button type="button" className='btn btn-primary d-flex justify-content-center align-items-center'
                onClick={(
                  () => {
                    if (splitProduct?.specifications && splitProduct?.specifications.isBillOfMaterials) {
                      dispatch(removeFromPreMadeBox(splitProduct?.id))
                    }
                    if (splitedProducts && splitProduct && splitProduct?.quantity <= 0) {
                      const updatedProducts = shoppingCartProducts.filter((product) => splitProduct.spliteId ? splitProduct.id !== product.spliteId : splitProduct.id !== product.id)
                      const updatedShoppingProducts = [...updatedProducts, ...splitedProducts.products]
                      dispatch(setShoppingCart({ products: updatedShoppingProducts }))
                    } else if (splitedProducts && splitProduct && splitProduct?.quantity > 0) {
                      const updatedProducts = shoppingCartProducts.filter((product) => splitProduct.spliteId ? splitProduct.id !== product.spliteId : splitProduct.id !== product.id)
                      const updatedSplitProduct = { ...splitProduct, isSplited: true, spliteId: generateSpliteId() }
                      const updatedShoppingProducts = [...updatedProducts, ...splitedProducts.products, updatedSplitProduct]
                      updatedShoppingProducts.forEach((product) => {
                        if (product.specifications && product.specifications.isBillOfMaterials) {
                          dispatch(addToPreMadeBox(product.spliteId))
                        }
                      })
                      dispatch(setShoppingCart({ products: updatedShoppingProducts }))
                    }
                    setSplitedProducts(null)
                    dismissModal('splitAmountModal')
                    openModal('shoppingShippingDetailsModal')
                  }
                )}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ShoppingModals
