import React, { useState } from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import Navbar from '../components/layout/Navbar'
import SideNav from '../components/layout/SideNav'
import Toast from '../components/Toast'
import { useAppSelector } from '../store/hooks'
import ServiceWorkerToast from '../components/ServiceWorkerToast'
import MaintenanceModeAlert from '../components/MaintenanceModeAlert'
import CSVExporter from '../components/CSVExporter'
import LandingModal from '../components/Onboarding/LandingModal'
import WelcomeModal from '../components/Onboarding/WelcomeModal'

const PrivateRoutes = ({ isAllowed = true, redirectPath = '/', children }: { isAllowed?: boolean, redirectPath?: string, children?: any }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const onboardingProgress = useAppSelector((state) => state.onboarding.onboardingProgress)
  const totalPendingTasks = useAppSelector((state) => state.onboarding.totalPendingTasks)
  const firstName = currentUser?.firstName
  const lastName = currentUser?.lastName

  const [onboardingStartCount, setOnboardingStartCount] = useState(0)

  const location = useLocation()
  const closeSideNav = () => {
    const sidebarElement = document.body.querySelector('#sidebarToggle')
    if (sidebarElement) {
      document.body.classList.remove('sb-sidenav-toggled')
    }
  }

  return currentUser
    ? (
      <div className="sb-nav-fixed">
        <Navbar />
        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <SideNav />
          </div>
          <div id="layoutSidenav_content" onClick={closeSideNav}>
            <main>
              {onboardingProgress !== 100 && (
                <div className="container-fluid px-4 pt-4">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#welcomeModal"
                  >
                    <i className="bi bi-person me-1"></i>
                    <span className="fw-semibold">Get Started</span>
                    <span className="badge rounded-pill bg-danger ms-1">
                      {totalPendingTasks}
                    </span>
                  </button>
                </div>
              )}
              <CSVExporter/>
              <Toast />
              <MaintenanceModeAlert />
              <ServiceWorkerToast />
              {
                !isAllowed && (<Navigate to={redirectPath} replace state={{ from: location }} />)
              }
              {
                children || <Outlet />
              }
            </main>
          </div>
        </div>
        {/* Modals */}
        <WelcomeModal
          firstName={firstName}
          lastName={lastName}
          onboardingStartCount={onboardingStartCount}
          setOnboardingStartCount={setOnboardingStartCount}
        />
        <LandingModal onboardingStartCount={onboardingStartCount} />
      </div>
      )
    : (
      <Navigate to="/login" replace state={{ from: location }} />
      )
}

export default PrivateRoutes
