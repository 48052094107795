export const formatPrice = (currency: string = 'EUR', locale: string = 'de-De') => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

export const formatPriceNoSymbol = (
  locale: string = 'de-De'
) => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format
}

export const formatPriceWithSymbolAtEnd = (value: number) => {
  const formattedPrice = new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
  return `${formattedPrice} €`
}
