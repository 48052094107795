import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserInProductAccessControlGroupService from '../../../services/api/UserInProductAccessControlGroupService'
import { ApiUserInProductAccessControlGroupState } from '../../../types'
import { USER_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiUserInProductAccessControlGroupState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteUserInProductAccessControlGroup = createAsyncThunk('api/user-product-access-control-group/delete', async ({ token, userProductAccessControlGroupId, signal }: { token: string, userProductAccessControlGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserInProductAccessControlGroupService.deleteUserInProductAccessControlGroup(token, userProductAccessControlGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const userInProductAccessControlGroupSlice = createSlice({
  name: 'api/user-product-access-control-group',
  initialState,
  reducers: {
    resetUserInProductAccessControlGroupError: (state) => {
      state.error = null
    },
    resetUserInProductAccessControlGroupMessage: (state) => {
      state.message = null
    },
    resetUserInProductAccessControlGroupData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUserInProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteUserInProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = USER_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE
      })
      .addCase(deleteUserInProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetUserInProductAccessControlGroupError, resetUserInProductAccessControlGroupMessage, resetUserInProductAccessControlGroupData } = userInProductAccessControlGroupSlice.actions

const { reducer } = userInProductAccessControlGroupSlice
export default reducer
