import React from 'react'
import { ColorType } from '../../types'

const Progress = ({ backgroundColor = 'primary', marginTop = false, marginBottom = true }: { backgroundColor?: ColorType, marginTop?: boolean, marginBottom?: boolean }) => {
  return (
    <div className={`progress-bar-custom ${!marginTop && 'mt-0'} ${!marginBottom && 'mb-0'}`}>
      <div className={`progress-bar-custom-value bg-${backgroundColor}`}></div>
    </div>
  )
}

export default Progress
