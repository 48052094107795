import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductAccessControlGroupService from '../../../services/api/ProductAccessControlGroupService'
import { ApiProductAccessControlGroupState, CompanyProductAccessControlGroup, CompanyUserGroupProductAccessControlGroup, ProductAccessControlGroupEdit, ProductCategoryTagProductAccessControlGroup, UserProductAccessControlGroup } from '../../../types'
import {
  PRODUCT_ACCESS_CONTROL_GROUP_COMPANIES_ADDITION_SUCCESS_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_COMPANY_USER_GROUPS_ADDITION_SUCCESS_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_CREATION_SUCCESS_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_UPDATE_SUCCESS_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_PRODUCT_CATEGORY_TAGS_ADDITION_SUCCESS_MESSAGE
} from '../../../constants/messages'

const initialState: ApiProductAccessControlGroupState = {
  isLoading: false,
  isLoadingProductAccessControlGroupProductCategoryTags: false,
  isLoadingProductAccessControlGroupUsers: false,
  isLoadingProductAccessControlGroupCompanies: false,
  isLoadingProductAccessControlGroupCompanyUserGroups: false,
  error: null,
  productAccessControlGroup: null,
  productAccessControlGroups: [],
  productAccessControlGroupProductCategoryTags: [],
  productAccessControlGroupUsers: [],
  productAccessControlGroupCompanies: [],
  productAccessControlGroupCompanyUserGroups: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  metadataProductAccessControlGroupProductCategoryTags: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  metadataProductAccessControlGroupUsers: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  metadataProductAccessControlGroupCompanies: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  metadataProductAccessControlGroupCompanyUserGroups: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getProductAccessControlGroups = createAsyncThunk('api/product-access-control-groups/get', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.getProductAccessControlGroups(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const getProductAccessControlGroupById = createAsyncThunk('api/product-access-control-group/get', async ({ token, productAccessControlGroupId, signal }: { token: string, productAccessControlGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.getProductAccessControlGroupById(token, productAccessControlGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/add', async ({ token, productAccessControlGroup, signal }: { token: string, productAccessControlGroup: ProductAccessControlGroupEdit, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.addProductAccessControlGroup(token, productAccessControlGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editProductAccessControlGroupById = createAsyncThunk('api/product-access-control-group/edit', async ({ token, productAccessControlGroupId, productAccessControlGroup, signal }: { token: string, productAccessControlGroupId: string, productAccessControlGroup: ProductAccessControlGroupEdit, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.editProductAccessControlGroupById(token, productAccessControlGroupId, productAccessControlGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteProductAccessControlGroupById = createAsyncThunk('api/product-access-control-group/delete', async ({ token, productAccessControlGroupId, signal }: { token: string, productAccessControlGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.deleteProductAccessControlGroupById(token, productAccessControlGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addProductCategoryTagsToProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/product-category-tags/add', async ({ token, productAccessControlGroupId, productCategoryTagProductAccessControlGroup, signal }: { token: string, productAccessControlGroupId: string, productCategoryTagProductAccessControlGroup: ProductCategoryTagProductAccessControlGroup, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.addProductCategoryTagsToProductAccessControlGroup(token, productAccessControlGroupId, productCategoryTagProductAccessControlGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getProductCategoryTagsOfProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/product-category-tags/get', async ({ token, productAccessControlGroupId, perPage, page, signal, search }: { token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.getProductCategoryTagsOfProductAccessControlGroup(token, productAccessControlGroupId, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addUsersToProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/users/add', async ({ token, productAccessControlGroupId, userProductAccessControlGroup, signal }: { token: string, productAccessControlGroupId: string, userProductAccessControlGroup: UserProductAccessControlGroup, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.addUsersToProductAccessControlGroup(token, productAccessControlGroupId, userProductAccessControlGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getUsersOfProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/users/get', async ({ token, productAccessControlGroupId, perPage, page, signal, search }: { token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.getUsersOfProductAccessControlGroup(token, productAccessControlGroupId, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompaniesToProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/companies/add', async ({ token, productAccessControlGroupId, companyProductAccessControlGroup, signal }: { token: string, productAccessControlGroupId: string, companyProductAccessControlGroup: CompanyProductAccessControlGroup, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.addCompaniesToProductAccessControlGroup(token, productAccessControlGroupId, companyProductAccessControlGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompaniesOfProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/companies/get', async ({ token, productAccessControlGroupId, perPage, page, signal, search }: { token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.getCompaniesOfProductAccessControlGroup(token, productAccessControlGroupId, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCompanyUserGroupsToProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/company-user-groups/add', async ({ token, productAccessControlGroupId, companyUserGroupProductAccessControlGroup, signal }: { token: string, productAccessControlGroupId: string, companyUserGroupProductAccessControlGroup: CompanyUserGroupProductAccessControlGroup, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.addCompanyUserGroupsToProductAccessControlGroup(token, productAccessControlGroupId, companyUserGroupProductAccessControlGroup, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCompanyUserGroupsOfProductAccessControlGroup = createAsyncThunk('api/product-access-control-group/company-user-groups/get', async ({ token, productAccessControlGroupId, perPage, page, signal, search }: { token: string, productAccessControlGroupId: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductAccessControlGroupService.getCompanyUserGroupsOfProductAccessControlGroup(token, productAccessControlGroupId, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const productAccessControlGroupSlice = createSlice({
  name: 'api/product-access-control-group',
  initialState,
  reducers: {
    resetProductAccessControlGroupError: (state) => {
      state.error = null
    },
    resetProductAccessControlGroupMessage: (state) => {
      state.message = null
    },
    resetProductAccessControlGroupData: (state) => {
      state.error = null
      state.productAccessControlGroup = null
      state.productAccessControlGroups = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductAccessControlGroups.pending, (state) => {
        state.productAccessControlGroups = []
        state.isLoading = true
      })
      .addCase(getProductAccessControlGroups.fulfilled, (state, action) => {
        state.isLoading = false
        state.productAccessControlGroups = action.payload.productAccessControlGroups
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getProductAccessControlGroups.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getProductAccessControlGroupById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProductAccessControlGroupById.fulfilled, (state, action) => {
        state.isLoading = false
        state.productAccessControlGroup = action.payload.productAccessControlGroup
        state.error = null
        state.message = null
      })
      .addCase(getProductAccessControlGroupById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.productAccessControlGroup = action.payload.productAccessControlGroup
        state.error = null
        state.message = PRODUCT_ACCESS_CONTROL_GROUP_CREATION_SUCCESS_MESSAGE
      })
      .addCase(addProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editProductAccessControlGroupById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editProductAccessControlGroupById.fulfilled, (state, action) => {
        state.isLoading = false
        state.productAccessControlGroup = action.payload.productAccessControlGroup
        state.error = null
        state.message = PRODUCT_ACCESS_CONTROL_GROUP_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(editProductAccessControlGroupById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteProductAccessControlGroupById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteProductAccessControlGroupById.fulfilled, (state, action) => {
        state.isLoading = false
        state.productAccessControlGroup = null
        state.error = null
        state.message = PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE
      })
      .addCase(deleteProductAccessControlGroupById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addProductCategoryTagsToProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addProductCategoryTagsToProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_ACCESS_CONTROL_GROUP_PRODUCT_CATEGORY_TAGS_ADDITION_SUCCESS_MESSAGE
      })
      .addCase(addProductCategoryTagsToProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getProductCategoryTagsOfProductAccessControlGroup.pending, (state) => {
        state.isLoadingProductAccessControlGroupProductCategoryTags = true
        state.productAccessControlGroupProductCategoryTags = []
      })
      .addCase(getProductCategoryTagsOfProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoadingProductAccessControlGroupProductCategoryTags = false
        state.error = null
        state.message = null
        state.metadataProductAccessControlGroupProductCategoryTags = action.payload.meta
        state.productAccessControlGroupProductCategoryTags = action.payload.productAccessControlGroupProductCategoryTags
      })
      .addCase(getProductCategoryTagsOfProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductAccessControlGroupProductCategoryTags = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addUsersToProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addUsersToProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE
      })
      .addCase(addUsersToProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getUsersOfProductAccessControlGroup.pending, (state) => {
        state.isLoadingProductAccessControlGroupUsers = true
        state.productAccessControlGroupUsers = []
      })
      .addCase(getUsersOfProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoadingProductAccessControlGroupUsers = false
        state.error = null
        state.message = null
        state.metadataProductAccessControlGroupUsers = action.payload.meta
        state.productAccessControlGroupUsers = action.payload.productAccessControlGroupUsers
      })
      .addCase(getUsersOfProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductAccessControlGroupUsers = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompaniesToProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCompaniesToProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_ACCESS_CONTROL_GROUP_COMPANIES_ADDITION_SUCCESS_MESSAGE
      })
      .addCase(addCompaniesToProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompaniesOfProductAccessControlGroup.pending, (state) => {
        state.isLoadingProductAccessControlGroupCompanies = true
        state.productAccessControlGroupCompanies = []
      })
      .addCase(getCompaniesOfProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoadingProductAccessControlGroupCompanies = false
        state.error = null
        state.message = null
        state.metadataProductAccessControlGroupCompanies = action.payload.meta
        state.productAccessControlGroupCompanies = action.payload.productAccessControlGroupCompanies
      })
      .addCase(getCompaniesOfProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductAccessControlGroupCompanies = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCompanyUserGroupsToProductAccessControlGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCompanyUserGroupsToProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = PRODUCT_ACCESS_CONTROL_GROUP_COMPANY_USER_GROUPS_ADDITION_SUCCESS_MESSAGE
      })
      .addCase(addCompanyUserGroupsToProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCompanyUserGroupsOfProductAccessControlGroup.pending, (state) => {
        state.isLoadingProductAccessControlGroupCompanyUserGroups = true
        state.productAccessControlGroupCompanyUserGroups = []
      })
      .addCase(getCompanyUserGroupsOfProductAccessControlGroup.fulfilled, (state, action) => {
        state.isLoadingProductAccessControlGroupCompanyUserGroups = false
        state.error = null
        state.message = null
        state.metadataProductAccessControlGroupCompanyUserGroups = action.payload.meta
        state.productAccessControlGroupCompanyUserGroups = action.payload.productAccessControlGroupCompanyUserGroups
      })
      .addCase(getCompanyUserGroupsOfProductAccessControlGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingProductAccessControlGroupCompanyUserGroups = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductAccessControlGroupError, resetProductAccessControlGroupMessage, resetProductAccessControlGroupData } = productAccessControlGroupSlice.actions

const { reducer } = productAccessControlGroupSlice
export default reducer
