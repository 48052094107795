import React from 'react'

interface LogInToAccountProps {
  setMode: Function
  setTitle: Function
}
const LogInToAccount = ({ setMode, setTitle }: LogInToAccountProps) => {
  return (
    <>
      <div className="modal-body">
        <div className="text-center py-4">
          <h4 className="mb-3">Welcome!</h4>
          <p className="text-muted">
            You have successfully logged in. <br />
            Let&apos;s get started with setting up your account. <br />
            Follow the steps to complete your onboarding process.
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="submit" className="btn btn-primary" onClick={() => {
          setMode('verifyEmail')
          setTitle('Email Verification')
        }}>
          Next
        </button>
      </div>
    </>
  )
}

export default LogInToAccount
