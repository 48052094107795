import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/auth`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  loginUser (user: any, signal: AbortSignal) {
    return apiClient.post('/login', { user }, { signal })
  },
  logoutUser (token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch('logout', { signal })
  },
  signUpUser (user: any, companyId: string | null, signal: AbortSignal) {
    const config = {
      params: {
        companyId
      },
      signal
    }
    return apiClient.post('/signup', { user }, config)
  },
  forgotPassword (user: any, signal: AbortSignal) {
    return apiClient.post('/forgot-password', { user }, { signal })
  },
  resetPassword (user: any, token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch('/reset-password', { user }, { signal })
  },
  getAuthToken (token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/token', { signal })
  },
  refreshAuthToken (auth: { token: string }, token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/token/refresh', { auth }, { signal })
  }
}
