import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { getCompaniesOfProductAccessControlGroup, getCompanyUserGroupsOfProductAccessControlGroup, getProductAccessControlGroupById, getProductCategoryTagsOfProductAccessControlGroup, getUsersOfProductAccessControlGroup } from '../../../../store/reducers/api/productAccessControlGroupReducer'
import * as userRoles from '../../../../constants/userRoles'
import Progress from '../../../../components/loaders/Progress'
import { ProductAccessControlGroupCompany, ProductAccessControlGroupCompanyUserGroup, ProductAccessControlGroupProductCategoryTag, ProductAccessControlGroupUser } from '../../../../types'
import { TrashIcon } from '../../../../components/icons/TrashIcon'
import Pagination from '../../../../components/Pagination'
import ProductCategoryTagsSelector from '../../../../components/AccessControls/Products/ProductCategoryTagsSelector'
import {
  PRODUCT_ACCESS_CONTROL_GROUP_PRODUCT_CATEGORY_TAGS_ADDITION_SUCCESS_MESSAGE,
  COMPANY_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_COMPANIES_ADDITION_SUCCESS_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE,
  USER_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE,
  PRODUCT_ACCESS_CONTROL_GROUP_COMPANY_USER_GROUPS_ADDITION_SUCCESS_MESSAGE,
  COMPANY_USER_GROUP_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE,
  PRODUCT_CATEGORY_TAG_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE
} from '../../../../constants/messages'
import { setToast } from '../../../../store/reducers/toastReducer'
import { dismissModal } from '../../../../utils/dismissModal'
import { deleteUserInProductAccessControlGroup, resetUserInProductAccessControlGroupMessage } from '../../../../store/reducers/api/userInProductAccessControlGroupReducer'
import UserSelector from '../../../../components/AccessControls/Products/UserSelector'
import CompanySelector from '../../../../components/AccessControls/Products/CompanySelector'
import { deleteCompanyInProductAccessControlGroup, resetCompanyInProductAccessControlGroupMessage } from '../../../../store/reducers/api/companyInProductAccessControlGroupReducer'
import CompanyUserGroupSelector from '../../../../components/AccessControls/CompanyUserGroups/CompanyUserGroupSelector'
import { deleteCompanyUserGroupInProductAccessControlGroup, resetCompanyUserGroupInProductAccessControlGroupMessage } from '../../../../store/reducers/api/companyUserGroupInProductAccessControlGroupReducer'
import { deleteProductCategoryTagInProductAccessControlGroup, resetProductCategoryTagInProductAccessControlGroupMessage } from '../../../../store/reducers/api/productCategoryTagInProductAccessControlGroupReducer'
import SkeletonTableRow from '../../../../components/loaders/skeleton/SkeletonTableRow'
import useDebounce from '../../../../utils/hooks/useDebounce'

const ProductAccessControlGroupDetails = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiProductAccessControlGroup.isLoading)
  const productAccessControlGroup = useAppSelector((state) => state.apiProductAccessControlGroup.productAccessControlGroup)
  const message = useAppSelector((state) => state.apiProductAccessControlGroup.message)
  const isLoadingProductAccessControlGroupProductCategoryTags = useAppSelector((state) => state.apiProductAccessControlGroup.isLoadingProductAccessControlGroupProductCategoryTags)
  const metadataProductAccessControlGroupProductCategoryTags = useAppSelector((state) => state.apiProductAccessControlGroup.metadataProductAccessControlGroupProductCategoryTags)
  const productAccessControlGroupProductCategoryTags = useAppSelector((state) => state.apiProductAccessControlGroup.productAccessControlGroupProductCategoryTags)
  const isLoadingProductAccessControlGroupUsers = useAppSelector((state) => state.apiProductAccessControlGroup.isLoadingProductAccessControlGroupUsers)
  const metadataProductAccessControlGroupUsers = useAppSelector((state) => state.apiProductAccessControlGroup.metadataProductAccessControlGroupUsers)
  const productAccessControlGroupUsers = useAppSelector((state) => state.apiProductAccessControlGroup.productAccessControlGroupUsers)
  const isLoadingProductAccessControlGroupCompanies = useAppSelector((state) => state.apiProductAccessControlGroup.isLoadingProductAccessControlGroupCompanies)
  const metadataProductAccessControlGroupCompanies = useAppSelector((state) => state.apiProductAccessControlGroup.metadataProductAccessControlGroupCompanies)
  const productAccessControlGroupCompanies = useAppSelector((state) => state.apiProductAccessControlGroup.productAccessControlGroupCompanies)
  const isLoadingProductAccessControlGroupCompanyUserGroups = useAppSelector((state) => state.apiProductAccessControlGroup.isLoadingProductAccessControlGroupCompanyUserGroups)
  const metadataProductAccessControlGroupCompanyUserGroups = useAppSelector((state) => state.apiProductAccessControlGroup.metadataProductAccessControlGroupCompanyUserGroups)
  const productAccessControlGroupCompanyUserGroups = useAppSelector((state) => state.apiProductAccessControlGroup.productAccessControlGroupCompanyUserGroups)
  const isLoadingProductCategoryTagInProductAccessControlGroup = useAppSelector((state) => state.apiProductCategoryTagInProductAccessControlGroup.isLoading)
  const productCategoryTagInProductAccessControlGroupMessage = useAppSelector((state) => state.apiProductCategoryTagInProductAccessControlGroup.message)
  const isLoadingUserInProductAccessControlGroup = useAppSelector((state) => state.apiUserInProductAccessControlGroup.isLoading)
  const userInProductAccessControlGroupMessage = useAppSelector((state) => state.apiUserInProductAccessControlGroup.message)
  const companyInProductAccessControlGroupMessage = useAppSelector((state) => state.apiCompanyInProductAccessControlGroup.message)
  const isLoadingCompanyInProductAccessControlGroup = useAppSelector((state) => state.apiCompanyInProductAccessControlGroup.isLoading)
  const companyUserGroupsInProductAccessControlGroupMessage = useAppSelector((state) => state.apiCompanyUserGroupInProductAccessControlGroup.message)
  const isLoadingCompanyUserGroupInProductAccessControlGroup = useAppSelector((state) => state.apiCompanyUserGroupInProductAccessControlGroup.isLoading)

  const [selectedProductAccessControlGroupProductCategoryTag, setSelectedProductAccessControlGroupProductCategoryTag] = useState<ProductAccessControlGroupProductCategoryTag | null>(null)
  const [perPageProductCategoryTags, setPerPageProductCategoryTags] = useState(5)
  const [pageProductCategoryTags, setPageProductCategoryTags] = useState(1)
  const [selectedProductAccessControlGroupUser, setSelectedProductAccessControlGroupUser] = useState<ProductAccessControlGroupUser | null>(null)
  const [perPageUsers, setPerPageUsers] = useState(5)
  const [pageUsers, setPageUsers] = useState(1)
  const [selectedProductAccessControlGroupCompany, setSelectedProductAccessControlGroupCompany] = useState<ProductAccessControlGroupCompany | null>(null)
  const [perPageCompanies, setPerPageCompanies] = useState(5)
  const [pageCompanies, setPageCompanies] = useState(1)
  const [selectedProductAccessControlGroupCompanyUserGroup, setSelectedProductAccessControlGroupCompanyUserGroup] = useState<ProductAccessControlGroupCompanyUserGroup | null>(null)
  const [perPageCompanyUserGroups, setPerPageCompanyUserGroups] = useState(5)
  const [pageCompanyUserGroups, setPageCompanyUserGroups] = useState(1)
  const [searchTermProductCategoryTags, setSearchTermProductCategoryTags] = useState<string>('')
  const debouncedSearchTermProductCategoryTags: string = useDebounce<string>(searchTermProductCategoryTags, 800)
  const [searchTermUsers, setSearchTermUsers] = useState<string>('')
  const debouncedSearchTermUsers: string = useDebounce<string>(searchTermUsers, 800)
  const [searchTermCompanies, setSearchTermCompanies] = useState<string>('')
  const debouncedSearchTermCompanies: string = useDebounce<string>(searchTermCompanies, 800)
  const [searchTermCompanyUserGroups, setSearchTermCompanyUserGroups] = useState<string>('')
  const debouncedSearchTermCompanyUserGroups: string = useDebounce<string>(searchTermCompanyUserGroups, 800)

  const token = currentUser?.token
  const role = profile?.role || userRoles.USER

  const { productAccessControlGroupId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const handleProductAccessControlGroupProductCategoryTagsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getProductCategoryTagsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, signal, search: debouncedSearchTermProductCategoryTags }))
    }
  }

  const handleProductAccessControlGroupUsersRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getUsersOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTermUsers }))
    }
  }

  const handleProductAccessControlGroupCompaniesRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getCompaniesOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanies, page: pageCompanies, signal, search: debouncedSearchTermCompanies }))
    }
  }

  const handleProductAccessControlGroupCompanyUserGroupsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getCompanyUserGroupsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanyUserGroups, page: pageCompanyUserGroups, signal, search: debouncedSearchTermCompanyUserGroups }))
    }
  }

  const handleProductCategoryTagsShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageProductCategoryTags(1)
    setPerPageProductCategoryTags(Number(event.target.value))
  }

  const handleProductCategoryTagsPageChange = (page: number) => {
    setPageProductCategoryTags(page)
  }

  const handleUsersShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageUsers(1)
    setPerPageUsers(Number(event.target.value))
  }

  const handleUsersPageChange = (page: number) => {
    setPageUsers(page)
  }

  const handleCompanyShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageCompanies(1)
    setPerPageCompanies(Number(event.target.value))
  }

  const handleCompanyPageChange = (page: number) => {
    setPageCompanies(page)
  }

  const handleCompanyUserGroupShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPageCompanyUserGroups(1)
    setPerPageCompanyUserGroups(Number(event.target.value))
  }

  const handleCompanyUserGroupPageChange = (page: number) => {
    setPageCompanyUserGroups(page)
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getProductAccessControlGroupById({ productAccessControlGroupId, token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getProductCategoryTagsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, signal, search: debouncedSearchTermProductCategoryTags }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageProductCategoryTags, pageProductCategoryTags, debouncedSearchTermProductCategoryTags])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getUsersOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTermUsers }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageUsers, pageUsers, debouncedSearchTermUsers])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getCompaniesOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanies, page: pageCompanies, signal, search: debouncedSearchTermCompanies }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageCompanies, pageCompanies, debouncedSearchTermCompanies])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
      dispatch(getCompanyUserGroupsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanies, page: pageCompanies, signal, search: debouncedSearchTermCompanyUserGroups }))
    }

    return () => {
      controller.abort()
    }
  }, [perPageCompanyUserGroups, pageCompanyUserGroups, debouncedSearchTermCompanyUserGroups])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [
      PRODUCT_ACCESS_CONTROL_GROUP_PRODUCT_CATEGORY_TAGS_ADDITION_SUCCESS_MESSAGE,
      PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE,
      PRODUCT_ACCESS_CONTROL_GROUP_COMPANIES_ADDITION_SUCCESS_MESSAGE,
      PRODUCT_ACCESS_CONTROL_GROUP_COMPANY_USER_GROUPS_ADDITION_SUCCESS_MESSAGE
    ]

    if (token && message && allowedMessages.includes(message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (productAccessControlGroupId && role === userRoles.ADMIN) {
        if (message === PRODUCT_ACCESS_CONTROL_GROUP_PRODUCT_CATEGORY_TAGS_ADDITION_SUCCESS_MESSAGE) {
          dispatch(getProductCategoryTagsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, signal, search: debouncedSearchTermProductCategoryTags }))
          dismissModal('productCategoryTagAdditionModal')
        }
        if (message === PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE) {
          dispatch(getUsersOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTermUsers }))
          dismissModal('usersAdditionModal')
        }
        if (message === PRODUCT_ACCESS_CONTROL_GROUP_COMPANIES_ADDITION_SUCCESS_MESSAGE) {
          dispatch(getCompaniesOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanies, page: pageCompanies, signal, search: debouncedSearchTermCompanies }))
          dismissModal('companiesAdditionModal')
        }
        if (message === PRODUCT_ACCESS_CONTROL_GROUP_COMPANY_USER_GROUPS_ADDITION_SUCCESS_MESSAGE) {
          dispatch(getCompanyUserGroupsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanies, page: pageCompanies, signal, search: debouncedSearchTermCompanyUserGroups }))
          dismissModal('companyUserGroupAdditionModal')
        }
      }
    }
    return () => {
      controller.abort()
    }
  }, [message])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [PRODUCT_CATEGORY_TAG_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE]
    if (token && productCategoryTagInProductAccessControlGroupMessage && allowedMessages.includes(productCategoryTagInProductAccessControlGroupMessage)) {
      const payload = {
        title: 'Success',
        message: productCategoryTagInProductAccessControlGroupMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
        dispatch(getProductCategoryTagsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageProductCategoryTags, page: pageProductCategoryTags, signal, search: debouncedSearchTermProductCategoryTags }))
        dispatch(resetProductCategoryTagInProductAccessControlGroupMessage())
      }
      dismissModal('productCategoryTagRemovalModal')
    }
  }, [productCategoryTagInProductAccessControlGroupMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [USER_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE]
    if (token && userInProductAccessControlGroupMessage && allowedMessages.includes(userInProductAccessControlGroupMessage)) {
      const payload = {
        title: 'Success',
        message: userInProductAccessControlGroupMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
        dispatch(getUsersOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageUsers, page: pageUsers, signal, search: debouncedSearchTermUsers }))
        dispatch(resetUserInProductAccessControlGroupMessage())
      }
      dismissModal('userRemovalModal')
    }
  }, [userInProductAccessControlGroupMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [COMPANY_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE]
    if (token && companyInProductAccessControlGroupMessage && allowedMessages.includes(companyInProductAccessControlGroupMessage)) {
      const payload = {
        title: 'Success',
        message: companyInProductAccessControlGroupMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
        dispatch(getCompaniesOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanies, page: pageCompanies, signal, search: debouncedSearchTermCompanies }))
        dispatch(resetCompanyInProductAccessControlGroupMessage())
      }
      dismissModal('companyRemovalModal')
    }
  }, [companyInProductAccessControlGroupMessage])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const allowedMessages = [COMPANY_USER_GROUP_PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE]
    if (token && companyUserGroupsInProductAccessControlGroupMessage && allowedMessages.includes(companyUserGroupsInProductAccessControlGroupMessage)) {
      const payload = {
        title: 'Success',
        message: companyUserGroupsInProductAccessControlGroupMessage,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      if (token && productAccessControlGroupId && role === userRoles.ADMIN) {
        dispatch(getCompanyUserGroupsOfProductAccessControlGroup({ token, productAccessControlGroupId, perPage: perPageCompanies, page: pageCompanies, signal, search: debouncedSearchTermCompanyUserGroups }))
        dispatch(resetCompanyUserGroupInProductAccessControlGroupMessage())
      }
      dismissModal('companyUserGroupRemovalModal')
    }
  }, [companyUserGroupsInProductAccessControlGroupMessage])

  return (
    <main>
      <div className="card">
        <div className="m-4">
          <div className="navbar navbar-expand d-flex flex-column flex-sm-row">
            {
              isLoading
                ? (
                  <p className="placeholder-glow w-100 h5">
                    <span className="placeholder col-7 rounded"></span>
                  </p>
                  )
                : (
                  <p className="w-100 h5" title={`${productAccessControlGroup?.name || '---'}`}>
                    <i className="bi bi-info-square me-2"></i>{productAccessControlGroup?.name || '---'}
                  </p>
                  )
            }
            {
              isLoading
                ? (
                  <p className="placeholder-glow w-100 h5">
                    <span className="placeholder col-7 rounded"></span>
                  </p>
                  )
                : (
                  <p className="w-100 h5" title={`${productAccessControlGroup?.company?.name || 'All'}`}>
                    <i className="bi bi-building me-2"></i>{productAccessControlGroup?.company?.name || 'All'}
                  </p>
                  )
            }
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
            <p className="h5 me-2"><i className="bi bi-tag me-2"></i>Product Category Tags</p>
            {
              currentUser?.role === userRoles.ADMIN && (
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                    <Formik
                      validationSchema={searchSchema}
                      enableReinitialize
                      initialValues={{
                        search: ''
                      }}
                      onSubmit={({ search }, actions) => {
                        setSearchTermProductCategoryTags(search)
                        if (pageProductCategoryTags !== 1) {
                          setPageProductCategoryTags(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <input
                              onChange={(event) => {
                                const search = event.target.value
                                handleChange(event)
                                setSearchTermProductCategoryTags(search)
                                if (pageProductCategoryTags !== 1) {
                                  setPageProductCategoryTags(1)
                                  navigate('')
                                }
                              }}
                              maxLength={24}
                              onBlur={handleBlur}
                              value={values.search}
                              className={`form-control ${
                                errors.search && touched.search && errors.search
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="btnNavbarSearch"
                              name="search"
                            />
                            <button
                              className="btn btn-outline-dark"
                              id="btnNavbarSearch"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#productCategoryTagAdditionModal"
                    title="Add Product Category Tag"
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>Product Category Tag</span>
                  </button>
                  <button
                    type="button"
                    title="Refresh Product Category Tags"
                    aria-label="Refresh Product Category Tags"
                    className="btn btn-outline-dark"
                    onClick={() => handleProductAccessControlGroupProductCategoryTagsRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              )
            }
          </div>
          {isLoadingProductAccessControlGroupProductCategoryTags ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col">Tag Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Category</th>
                  <th scope="col" className="text-center">
                    <div className="float-end">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoadingProductAccessControlGroupProductCategoryTags
                    ? (
                        Array.from(Array(perPageProductCategoryTags).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={5} actionQuantity={1} />)
                      )
                    : (
                        productAccessControlGroupProductCategoryTags.length > 0
                          ? (
                              productAccessControlGroupProductCategoryTags.map((productAccessControlGroupProductCategoryTag) => (
                                <tr key={productAccessControlGroupProductCategoryTag.id} className={selectedProductAccessControlGroupProductCategoryTag?.id === productAccessControlGroupProductCategoryTag.id ? 'table-primary' : ''}>
                                  <td>
                                    <span
                                      title="Click To Copy Product Category Tag Id"
                                      onClick={() => navigator.clipboard.writeText(String(productAccessControlGroupProductCategoryTag.id))}
                                      className="user-select-all"
                                    >
                                      {(productAccessControlGroupProductCategoryTag.id).substring(0, 8)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      title={'Click To Copy Product Category Tag Name'}
                                      onClick={() => navigator.clipboard.writeText(String(productAccessControlGroupProductCategoryTag.productCategoryTag.name))}
                                      className="user-select-all"
                                    >
                                      {`${productAccessControlGroupProductCategoryTag.productCategoryTag.name}`}
                                    </span>
                                  </td>
                                  <td>
                                    {productAccessControlGroupProductCategoryTag.productCategoryTag.type || ''}
                                  </td>
                                  <td>{productAccessControlGroupProductCategoryTag.productCategoryTag.productCategory?.name ?? '---'}</td>
                                  <td className="text-center">
                                    <div className="d-flex flex-row float-end" role="group" aria-label="Product Category Tag Action Buttons">
                                      {
                                        currentUser?.role === userRoles.ADMIN && (
                                          <button
                                            type="button"
                                            title="Remove Product Category Tag"
                                            className="btn btn-outline-danger btn-round"
                                            data-bs-toggle="modal"
                                            data-bs-target="#productCategoryTagRemovalModal"
                                            onClick={() => {
                                              setSelectedProductAccessControlGroupProductCategoryTag(productAccessControlGroupProductCategoryTag)
                                            }}
                                          >
                                            <TrashIcon/>
                                          </button>
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )
                          : (
                            <tr>
                              <td colSpan={5} className="text-center">
                                No product category tags available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoadingProductAccessControlGroupProductCategoryTags}
            metadata={{
              limit: metadataProductAccessControlGroupProductCategoryTags.perPage,
              total: metadataProductAccessControlGroupProductCategoryTags.total,
              offset: ((metadataProductAccessControlGroupProductCategoryTags.page - 1) * (metadataProductAccessControlGroupProductCategoryTags.perPage))
            }}
            page={pageProductCategoryTags}
            perPage={perPageProductCategoryTags}
            handlePageChange={handleProductCategoryTagsPageChange}
            handleShowEntries={handleProductCategoryTagsShowEntries}
            module="productAccessControlGroupProductCategoryTags"
            perPageOptions={[5, 10, 25]}
          />

        </div>
      </div>

      <div className="card mt-4">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
            <p className="h5"><i className="bi bi-people me-2"></i>Users</p>
            {
              currentUser?.role === userRoles.ADMIN && (
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                    <Formik
                      validationSchema={searchSchema}
                      enableReinitialize
                      initialValues={{
                        search: ''
                      }}
                      onSubmit={({ search }, actions) => {
                        setSearchTermUsers(search)
                        if (pageUsers !== 1) {
                          setPageUsers(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <input
                              onChange={(event) => {
                                const search = event.target.value
                                handleChange(event)
                                setSearchTermUsers(search)
                                if (pageUsers !== 1) {
                                  setPageUsers(1)
                                  navigate('')
                                }
                              }}
                              maxLength={24}
                              onBlur={handleBlur}
                              value={values.search}
                              className={`form-control ${
                                errors.search && touched.search && errors.search
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="btnNavbarSearch"
                              name="search"
                            />
                            <button
                              className="btn btn-outline-dark"
                              id="btnNavbarSearch"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#usersAdditionModal"
                    title="Add User"
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>User</span>
                  </button>
                  <button
                    type="button"
                    title="Refresh"
                    aria-label="Refresh"
                    className="btn btn-outline-dark"
                    onClick={() => handleProductAccessControlGroupUsersRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              )
            }
          </div>
          {isLoadingProductAccessControlGroupUsers ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col">User Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col" className="text-center">
                    <div className="float-end">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoadingProductAccessControlGroupUsers
                    ? (
                        Array.from(Array(perPageProductCategoryTags).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={1} />)
                      )
                    : (
                        productAccessControlGroupUsers.length > 0
                          ? (
                              productAccessControlGroupUsers.map((productAccessControlGroupUser) => (
                                  <tr key={productAccessControlGroupUser.id} className={selectedProductAccessControlGroupUser?.id === productAccessControlGroupUser.id ? 'table-primary' : ''}>
                                    <td>
                                      <span
                                        title="Click To Copy User Id"
                                        onClick={() => navigator.clipboard.writeText(String(productAccessControlGroupUser.id))}
                                        className="user-select-all"
                                      >
                                        {(productAccessControlGroupUser.id).substring(0, 8)}
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        title={'Click To Copy Name Of The User'}
                                        onClick={() => navigator.clipboard.writeText(String(`${productAccessControlGroupUser.user.firstName} ${productAccessControlGroupUser.user.lastName}`))}
                                        className="user-select-all"
                                      >
                                        {`${productAccessControlGroupUser.user.firstName} ${productAccessControlGroupUser.user.lastName}`}
                                      </span>
                                    </td>
                                    <td>
                                      {productAccessControlGroupUser.user.email || ''}
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex flex-row float-end" role="group" aria-label="User Action Buttons">
                                        {
                                          currentUser?.role === userRoles.ADMIN && (
                                            <button
                                              type="button"
                                              title="Remove User"
                                              className="btn btn-outline-danger btn-round"
                                              data-bs-toggle="modal"
                                              data-bs-target="#userRemovalModal"
                                              onClick={() => {
                                                setSelectedProductAccessControlGroupUser(productAccessControlGroupUser)
                                              }}
                                            >
                                              <TrashIcon/>
                                            </button>
                                          )
                                        }
                                      </div>
                                    </td>
                                  </tr>
                              ))
                            )
                          : (
                            <tr>
                              <td colSpan={4} className="text-center">
                                No users available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoadingProductAccessControlGroupUsers}
            metadata={{
              limit: metadataProductAccessControlGroupUsers.perPage,
              total: metadataProductAccessControlGroupUsers.total,
              offset: ((metadataProductAccessControlGroupUsers.page - 1) * (metadataProductAccessControlGroupUsers.perPage))
            }}
            page={pageUsers}
            perPage={perPageUsers}
            handlePageChange={handleUsersPageChange}
            handleShowEntries={handleUsersShowEntries}
            module="productAccessControlGroupUsers"
            perPageOptions={[5, 10, 25]}
          />

        </div>
      </div>

      <div className="card mt-4">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
            <p className="h5"><i className="bi bi-building me-2"></i>Companies</p>
            {
              currentUser?.role === userRoles.ADMIN && (
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                    <Formik
                      validationSchema={searchSchema}
                      enableReinitialize
                      initialValues={{
                        search: ''
                      }}
                      onSubmit={({ search }, actions) => {
                        setSearchTermCompanies(search)
                        if (pageCompanies !== 1) {
                          setPageCompanies(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <input
                              onChange={(event) => {
                                const search = event.target.value
                                handleChange(event)
                                setSearchTermCompanies(search)
                                if (pageCompanies !== 1) {
                                  setPageCompanies(1)
                                  navigate('')
                                }
                              }}
                              maxLength={24}
                              onBlur={handleBlur}
                              value={values.search}
                              className={`form-control ${
                                errors.search && touched.search && errors.search
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="btnNavbarSearch"
                              name="search"
                            />
                            <button
                              className="btn btn-outline-dark"
                              id="btnNavbarSearch"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#companiesAdditionModal"
                    title="Add Company"
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>Company</span>
                  </button>
                  <button
                    type="button"
                    title="Refresh"
                    aria-label="Refresh"
                    className="btn btn-outline-dark"
                    onClick={() => handleProductAccessControlGroupCompaniesRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              )
            }
          </div>
          {isLoadingProductAccessControlGroupCompanies ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">Company Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Domain</th>
                  <th scope="col">Email</th>
                  <th scope="col" className="text-center">
                    <div className="float-end">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoadingProductAccessControlGroupCompanies
                    ? (
                        Array.from(Array(perPageProductCategoryTags).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={5} actionQuantity={1} />)
                      )
                    : (
                        productAccessControlGroupCompanies.length > 0
                          ? (
                              productAccessControlGroupCompanies.map((productAccessControlGroupCompany) => (
                                <tr key={productAccessControlGroupCompany.id} className={selectedProductAccessControlGroupCompany?.id === productAccessControlGroupCompany.id ? 'table-primary' : ''}>
                                  <td>
                                    <span
                                      title="Click To Copy Company Id"
                                      onClick={() => navigator.clipboard.writeText(String(productAccessControlGroupCompany.id))}
                                      className="user-select-all"
                                    >
                                      {(productAccessControlGroupCompany.id).substring(0, 8)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      title={'Click To Copy Company Name'}
                                      onClick={() => navigator.clipboard.writeText(String(productAccessControlGroupCompany.company.name))}
                                      className="user-select-all"
                                    >
                                      {productAccessControlGroupCompany.company.name}
                                    </span>
                                  </td>
                                  <td>{productAccessControlGroupCompany.company.domain}</td>
                                  <td>
                                    {productAccessControlGroupCompany.company.email || ''}
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex flex-row float-end" role="group" aria-label="Company Action Buttons">
                                      {
                                        currentUser?.role === userRoles.ADMIN && (
                                          <button
                                            type="button"
                                            title="Remove Company"
                                            className="btn btn-outline-danger btn-round"
                                            data-bs-toggle="modal"
                                            data-bs-target="#companyRemovalModal"
                                            onClick={() => {
                                              setSelectedProductAccessControlGroupCompany(productAccessControlGroupCompany)
                                            }}
                                          >
                                            <TrashIcon/>
                                          </button>
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )
                          : (
                            <tr>
                              <td colSpan={5} className="text-center">
                                No companies available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoadingProductAccessControlGroupCompanies}
            metadata={{
              limit: metadataProductAccessControlGroupCompanies.perPage,
              total: metadataProductAccessControlGroupCompanies.total,
              offset: ((metadataProductAccessControlGroupCompanies.page - 1) * (metadataProductAccessControlGroupCompanies.perPage))
            }}
            page={pageCompanies}
            perPage={perPageCompanies}
            handlePageChange={handleCompanyPageChange}
            handleShowEntries={handleCompanyShowEntries}
            module="productAccessControlGroupCompanies"
            perPageOptions={[5, 10, 25]}
          />

        </div>
      </div>

      <div className="card mt-4">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3 d-flex flex-column flex-sm-row align-items-start">
            <p className="h5"><i className="bi bi-person-rolodex me-2"></i>Company User Groups</p>
            {
              currentUser?.role === userRoles.ADMIN && (
                <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                  <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                    <Formik
                      validationSchema={searchSchema}
                      enableReinitialize
                      initialValues={{
                        search: ''
                      }}
                      onSubmit={({ search }, actions) => {
                        setSearchTermCompanyUserGroups(search)
                        if (pageCompanyUserGroups !== 1) {
                          setPageCompanyUserGroups(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group">
                            <input
                              onChange={(event) => {
                                const search = event.target.value
                                handleChange(event)
                                setSearchTermCompanyUserGroups(search)
                                if (pageCompanyUserGroups !== 1) {
                                  setPageCompanyUserGroups(1)
                                  navigate('')
                                }
                              }}
                              maxLength={24}
                              onBlur={handleBlur}
                              value={values.search}
                              className={`form-control ${
                                errors.search && touched.search && errors.search
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              type="text"
                              placeholder="Search..."
                              aria-label="Search"
                              aria-describedby="btnNavbarSearch"
                              name="search"
                            />
                            <button
                              className="btn btn-outline-dark"
                              id="btnNavbarSearch"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <i className="fas fa-search"></i>
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm me-2 text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#companyUserGroupAdditionModal"
                    title="Add Company User Group"
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-1"><span className="d-none d-md-inline-block me-1">Add</span>Company User Group</span>
                  </button>
                  <button
                    type="button"
                    title="Refresh"
                    aria-label="Refresh"
                    className="btn btn-outline-dark"
                    onClick={() => handleProductAccessControlGroupCompanyUserGroupsRefresh()}
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                </ul>
              )
            }
          </div>
          {isLoadingProductAccessControlGroupCompanyUserGroups ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col" className="text-nowrap">Group Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Company</th>
                  <th scope="col" className="text-center">
                    <div className="float-end">
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoadingProductAccessControlGroupCompanyUserGroups
                    ? (
                        Array.from(Array(perPageProductCategoryTags).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={1} />)
                      )
                    : (
                        productAccessControlGroupCompanyUserGroups.length > 0
                          ? (
                              productAccessControlGroupCompanyUserGroups.map((productAccessControlGroupCompanyUserGroup) => (
                                <tr key={productAccessControlGroupCompanyUserGroup.id} className={selectedProductAccessControlGroupCompanyUserGroup?.id === productAccessControlGroupCompanyUserGroup.id ? 'table-primary' : ''}>
                                  <td>
                                    <span
                                      title="Click To Copy Company User Group Id"
                                      onClick={() => navigator.clipboard.writeText(String(productAccessControlGroupCompanyUserGroup.id))}
                                      className="user-select-all"
                                    >
                                      {(productAccessControlGroupCompanyUserGroup.id).substring(0, 8)}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      title={'Click To Copy Company User Group Name'}
                                      onClick={() => navigator.clipboard.writeText(String(productAccessControlGroupCompanyUserGroup.companyUserGroup.name))}
                                      className="user-select-all"
                                    >
                                      {productAccessControlGroupCompanyUserGroup.companyUserGroup.name}
                                    </span>
                                  </td>
                                  <td><span className="fw-bold">{`${productAccessControlGroupCompanyUserGroup.companyUserGroup.company.name}`}</span> <span className="small">{`(${productAccessControlGroupCompanyUserGroup.companyUserGroup.company.domain || ''})`}</span></td>
                                  <td className="text-center">
                                    <div className="d-flex flex-row float-end" role="group" aria-label="Company User Group Action Buttons">
                                      {
                                        currentUser?.role === userRoles.ADMIN && (
                                          <button
                                            type="button"
                                            title="Remove Company User Group"
                                            className="btn btn-outline-danger btn-round"
                                            data-bs-toggle="modal"
                                            data-bs-target="#companyUserGroupRemovalModal"
                                            onClick={() => {
                                              setSelectedProductAccessControlGroupCompanyUserGroup(productAccessControlGroupCompanyUserGroup)
                                            }}
                                          >
                                            <TrashIcon/>
                                          </button>
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )
                          : (
                            <tr>
                              <td colSpan={4} className="text-center">
                                No company user groups available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoadingProductAccessControlGroupCompanyUserGroups}
            metadata={{
              limit: metadataProductAccessControlGroupCompanyUserGroups.perPage,
              total: metadataProductAccessControlGroupCompanyUserGroups.total,
              offset: ((metadataProductAccessControlGroupCompanyUserGroups.page - 1) * (metadataProductAccessControlGroupCompanyUserGroups.perPage))
            }}
            page={pageCompanyUserGroups}
            perPage={perPageCompanyUserGroups}
            handlePageChange={handleCompanyUserGroupPageChange}
            handleShowEntries={handleCompanyUserGroupShowEntries}
            module="productAccessControlGroupCompanyUserGroups"
            perPageOptions={[5, 10, 25]}
          />

        </div>
      </div>

      {/* Modals */}
      <div className="modal fade" id="productCategoryTagAdditionModal" tabIndex={-1} aria-labelledby="productCategoryTagAdditionModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="productCategoryTagAdditionModalLabel">
                <i className="bi-plus-circle me-1"></i>{' '}
                Add Product Category Tags
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <ProductCategoryTagsSelector isLoading={isLoading} productAccessControlGroupId={productAccessControlGroupId} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="productCategoryTagRemovalModal" tabIndex={-1} aria-labelledby="productCategoryTagRemovalModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="productCategoryTagRemovalModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Removal
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoading || isLoadingProductCategoryTagInProductAccessControlGroup) && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to remove
                <span className="fw-bold">{` '${selectedProductAccessControlGroupProductCategoryTag?.productCategoryTag.name}' `}</span>
                from the product access control group?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && selectedProductAccessControlGroupProductCategoryTag !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteProductCategoryTagInProductAccessControlGroup({ productCategoryTagProductAccessControlGroupId: String(selectedProductAccessControlGroupProductCategoryTag.id), token, signal }))
                  }
                }}
                disabled={(isLoadingProductCategoryTagInProductAccessControlGroup)}
                aria-label="Remove"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="usersAdditionModal" tabIndex={-1} aria-labelledby="usersAdditionModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="usersAdditionModalLabel">
                <i className="bi-plus-circle me-1"></i>{' '}
                Add Users
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <UserSelector isLoading={isLoading} productAccessControlGroupId={productAccessControlGroupId} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="userRemovalModal" tabIndex={-1} aria-labelledby="userRemovalModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="userRemovalModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Removal
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoading || isLoadingUserInProductAccessControlGroup) && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to remove
                <span className="fw-bold">{` '${selectedProductAccessControlGroupUser?.user.firstName} ${selectedProductAccessControlGroupUser?.user.lastName} <${selectedProductAccessControlGroupUser?.user.email}>' `}</span>
                from the product access control group?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && selectedProductAccessControlGroupUser !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteUserInProductAccessControlGroup({ userProductAccessControlGroupId: String(selectedProductAccessControlGroupUser.id), token, signal }))
                  }
                }}
                disabled={(isLoadingUserInProductAccessControlGroup)}
                aria-label="Remove"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="companiesAdditionModal" tabIndex={-1} aria-labelledby="companiesAdditionModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companiesAdditionModalLabel">
                <i className="bi-plus-circle me-1"></i>{' '}
                Add Companies
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <CompanySelector isLoading={isLoading} productAccessControlGroupId={productAccessControlGroupId} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="companyRemovalModal" tabIndex={-1} aria-labelledby="companyRemovalModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="companyRemovalModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Removal
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoading || isLoadingCompanyInProductAccessControlGroup) && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to remove
                <span className="fw-bold">{` '${selectedProductAccessControlGroupCompany?.company.name} <${selectedProductAccessControlGroupCompany?.company.domain}>' `}</span>
                from the product access control group?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && selectedProductAccessControlGroupCompany !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteCompanyInProductAccessControlGroup({ companyProductAccessControlGroupId: String(selectedProductAccessControlGroupCompany.id), token, signal }))
                  }
                }}
                disabled={(isLoadingCompanyInProductAccessControlGroup)}
                aria-label="Remove"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="companyUserGroupAdditionModal" tabIndex={-1} aria-labelledby="companyUserGroupAddModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companyUserGroupAddModalLabel">
                <i className="bi-plus-circle me-1"></i>{' '}
                Add Company User Groups
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <CompanyUserGroupSelector isLoading={isLoading} productAccessControlGroupId={productAccessControlGroupId} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="companyUserGroupRemovalModal" tabIndex={-1} aria-labelledby="companyUserGroupRemovalModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="companyUserGroupRemovalModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Removal
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoading || isLoadingCompanyUserGroupInProductAccessControlGroup) && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to remove
                <span className="fw-bold">{` '${selectedProductAccessControlGroupCompanyUserGroup?.companyUserGroup.name}' `}</span>
                from the product access control group?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && selectedProductAccessControlGroupCompanyUserGroup !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteCompanyUserGroupInProductAccessControlGroup({ companyUserGroupProductAccessControlGroupId: String(selectedProductAccessControlGroupCompanyUserGroup.id), token, signal }))
                  }
                }}
                disabled={(isLoadingCompanyUserGroupInProductAccessControlGroup)}
                aria-label="Remove"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ProductAccessControlGroupDetails
