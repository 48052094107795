import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MaintenanceMode } from '../types'
import { useAppSelector } from '../store/hooks'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

const MaintenanceModeAlert = () => {
  const maintenanceModes = useAppSelector((state) => state.apiMaintenanceMode.maintenanceModes)
  const [maintenanceMode, setMaintenanceMode] = useState<MaintenanceMode | undefined>(undefined)
  const [maintenanceModeMessage, setMaintenanceModeMessage] = useState('')

  const calculateMaintenanceStatus = (mode: MaintenanceMode) => {
    const startDate = dayjs(mode.startDate)
    const endDate = dayjs(mode.endDate)
    const now = dayjs()
    const isWithin48Hours = startDate.diff(now, 'hours') <= 48
    const hasStarted = startDate.isBefore(now) || startDate.isSame(now)
    const hasEnded = endDate.isBefore(now)
    const isActive = mode.isActive

    return { isWithin48Hours, hasStarted, startDate, endDate, now, hasEnded, isActive }
  }

  const updateMaintenanceModeMessage = (mode: MaintenanceMode) => {
    const { hasStarted, isWithin48Hours, startDate, endDate, hasEnded, isActive } = calculateMaintenanceStatus(mode)

    if (hasStarted && isActive) {
      setMaintenanceModeMessage(`Service maintenance ongoing and will end ${dayjs().to(endDate)}: ${mode.reason} (downtime: ${startDate.format('DD.MM.YYYY h:mm A')} - ${endDate.format('DD.MM.YYYY h:mm A')})`)
    }
    if (!hasStarted && isWithin48Hours && isActive) {
      setMaintenanceModeMessage(`Upcoming maintenance ${dayjs().to(startDate)}: ${mode.reason} (downtime: ${startDate.format('DD.MM.YYYY h:mm A')} - ${endDate.format('DD.MM.YYYY h:mm A')})`)
    }
    if (hasEnded || !isActive) {
      setMaintenanceMode(undefined)
    }
  }

  const getMaintenanceMode = () => {
    const nextMaintenanceMode = maintenanceModes.find(maintenanceMode => {
      const { hasStarted, isWithin48Hours, endDate, now } = calculateMaintenanceStatus(maintenanceMode)

      return maintenanceMode.isActive && ((hasStarted || isWithin48Hours) && endDate.isAfter(now))
    })
    if (nextMaintenanceMode) {
      setMaintenanceMode(nextMaintenanceMode)
      updateMaintenanceModeMessage(nextMaintenanceMode)
    } else {
      setMaintenanceMode(undefined)
    }
  }

  useEffect(() => {
    getMaintenanceMode()
    const intervalId = setInterval(() => {
      getMaintenanceMode()
      if (!maintenanceMode) {
        clearInterval(intervalId)
      }
    }, 60000)

    return () => clearInterval(intervalId)
  }, [maintenanceModes, maintenanceMode])

  return (
    <div className={`alert alert-warning alert-dismissible mx-4 mt-4 mb-0 ${maintenanceMode ? '' : 'd-none'}`} role="alert">
      {
        maintenanceMode
          ? (
            <span className="fw-semibold">{maintenanceModeMessage}</span>
            )
          : ('')
      }
    </div>
  )
}

export default MaintenanceModeAlert
