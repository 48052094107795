import axios from 'axios'
import { ProductGraduatedPrice } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  editProductGraduatedPriceById (token: string, productGraduatedPriceId: string, productGraduatedPrice: Pick<ProductGraduatedPrice, 'price' | 'firstUnit' | 'lastUnit'>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/product-graduated-prices/${productGraduatedPriceId}`, { productGraduatedPrice }, config)
  },
  deleteProductGraduatedPriceById (token: string, productGraduatedPriceId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/product-graduated-prices/${productGraduatedPriceId}`, config)
  }
}
