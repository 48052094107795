import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PendingOrderQueueService from '../../../services/api/PendingOrderQueueService'
import { ApiPendingOrderState, DuplicatePostedOrder } from '../../../types'
import { ORDER_CREATION_SUCCESS_MESSAGE, ORDER_DUPLICATE_MESSAGE } from '../../../constants/messages'

const initialState: ApiPendingOrderState = {
  isLoading: false,
  error: null,
  pendingOrder: null,
  pendingOrders: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

type DuplicatePendingOrdersParams = { token: string, postedOrders: DuplicatePostedOrder[], signal: AbortSignal}
export const duplicateOrders = createAsyncThunk('api/pending-orders-queue/duplicate', async ({ token, postedOrders, signal }: DuplicatePendingOrdersParams, { rejectWithValue }) => {
  try {
    const res = await PendingOrderQueueService.duplicateOrders(token, postedOrders, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

type PendingOrdersParams = { token: string, pendingOrders: any, signal: AbortSignal }
export const createCatalogueOrders = createAsyncThunk('api/pending-orders/post', async ({ token, pendingOrders, signal }: PendingOrdersParams, { rejectWithValue }) => {
  try {
    const res = await PendingOrderQueueService.createCatalogueOrders(token, pendingOrders, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const pendingOrderQueueSlice = createSlice({
  name: 'api/pending-orders-queue',
  initialState,
  reducers: {
    resetPendingOrderError: (state) => {
      state.error = null
    },
    resetPendingOrderMessage: (state) => {
      state.message = null
    },
    resetApiPendingOrderData: (state) => {
      state.error = null
      state.pendingOrder = null
      state.pendingOrders = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(duplicateOrders.pending, (state) => {
        state.pendingOrders = []
        state.isLoading = true
      })
      .addCase(duplicateOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.pendingOrders = action.payload.pendingOrders
        state.error = null
        state.message = ORDER_DUPLICATE_MESSAGE
      })
      .addCase(duplicateOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createCatalogueOrders.pending, (state) => {
        state.pendingOrders = []
        state.isLoading = true
      })
      .addCase(createCatalogueOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.pendingOrders = action.payload.pendingOrders
        state.error = null
        state.message = ORDER_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createCatalogueOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetPendingOrderError, resetPendingOrderMessage, resetApiPendingOrderData } = pendingOrderQueueSlice.actions

const { reducer } = pendingOrderQueueSlice
export default reducer
