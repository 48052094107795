import { ProductGraduatedPrice } from '../types'

export const calculateGraduatedPrice = (graduatedPrices: ProductGraduatedPrice[], quantity: number, netRetailPrice: number) => {
  if (graduatedPrices.length > 0) {
    const sortedGraduatedPrices = [...graduatedPrices].sort((a, b) => a.lastUnit - b.lastUnit)
    const foundPrice = sortedGraduatedPrices.find(graduatedPrice => quantity >= graduatedPrice.firstUnit && quantity <= graduatedPrice.lastUnit)
    if (foundPrice) {
      return foundPrice.price
    }
  }
  return netRetailPrice
}
