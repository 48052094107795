import React, { useState } from 'react'
import { Order } from '../../../types'
import SkeletonTableRow from '../../loaders/skeleton/SkeletonTableRow'
import TableRow from './TableRow'

const TableBody = ({
  orders,
  isLoading,
  type,
  colSpan,
  selectedOutboundIds,
  setSelectedOutboundIds
}: {
  orders: Array<Order>
  isLoading: boolean
  type: string
  colSpan: number
  selectedOutboundIds: Array<string>
  setSelectedOutboundIds: Function
}) => {
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null)

  return (
    <tbody>
      {isLoading
        ? (
            Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={colSpan} actionQuantity={2} />)
          )
        : orders.length > 0
          ? (
              orders.map((order, index) => (
                <TableRow
                  key={index}
                  type={type}
                  order={order}
                  index={index}
                  colSpan={colSpan}
                  selectedOutboundIds={selectedOutboundIds}
                  setSelectedOutboundIds={setSelectedOutboundIds}
                  selectedOrderId={selectedOrderId}
                  setSelectedOrderId={setSelectedOrderId}
                />
              ))
            )
          : (
            <tr>
              <td colSpan={colSpan} className="text-center">
                No orders available yet
              </td>
            </tr>
            )
      }
    </tbody>
  )
}

export default TableBody
