import React from 'react'
import { object, bool } from 'yup'
import { Formik } from 'formik'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const CompanyDomainStatusEditor = ({ companyId, initialCompany, save }: { companyId: string, initialCompany: { isDomainVerified: boolean }, save: any }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isUpdating = useAppSelector((state) => state.apiCompany.isLoading)

  const token = currentUser?.token

  const dispatch = useAppDispatch()
  const companySchema = object({
    isDomainVerified: bool()
      .required('Domain Verification Status is required')
  })

  const saveCompany = (companyId: string, company: any, signal: AbortSignal) => {
    dispatch(save({ companyId, token, company, signal }))
  }

  return (
    <div>
      <Formik
        validationSchema={companySchema}
        enableReinitialize
        initialValues={{
          ...initialCompany
        }}
        onSubmit={(
          { isDomainVerified },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal
          const company = {
            isDomainVerified: (String(isDomainVerified) === 'true')
          }
          if (token && companyId) {
            saveCompany(companyId, company, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="isDomainVerified"
                    className="form-label"
                  >
                    Company Domain Verification Status
                  </label>
                  <select
                    aria-label="Domain Verification Status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={String(values.isDomainVerified)}
                    className={`form-select ${
                      errors.isDomainVerified &&
                      touched.isDomainVerified &&
                      errors.isDomainVerified
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="isDomainVerified"
                    name="isDomainVerified"
                  >
                    <option value="">Select Domain Verification Status</option>
                    <option value={'true'}>True</option>
                    <option value={'false'}>False</option>
                  </select>
                  <div
                    id="validationUserRoleFeedback"
                    className="invalid-feedback"
                  >
                    {errors.isDomainVerified}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isUpdating
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyDomainStatusEditor
