import axios from 'axios'
import { AccessPermission } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAllAccessPermissions (token: string, perPage: number, page: number, filter: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    let url = '/access-permissions'
    if (filter) {
      url += `?filter[companyId]=${filter}`
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(url, config)
  },
  getDefaultAccessPermissions (token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/access-permissions/default', config)
  },
  addAccessPermission (token: string, accessPermission: Partial<AccessPermission>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/access-permissions', { accessPermission }, config)
  },
  editAccessPermissionById (token: string, accessPermissionId: string, accessPermission: Partial<AccessPermission>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/access-permissions/${accessPermissionId}`, { accessPermission }, config)
  },
  deleteAccessPermissionById (token: string, accessPermissionId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/access-permissions/${accessPermissionId}`, config)
  }
}
