import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Bundle, stocks } from '../../../types'
import BundleService from '../../../services/api/BundleService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setToast } from '../../../store/reducers/toastReducer'

const placeholderThumbnail = 'https://storage.googleapis.com/endeavor-b285f.appspot.com/placeholders/vorgefertigete_Boxen.jpg'

interface BundleSelectorItemProps {
  bundle: Bundle
  activeBundles: Bundle[]
  addBundle: (bundle: Bundle) => void
  removeBundle: (bundle: Bundle) => void
  isExceedStockEnabled: boolean
}
const BundleSelectorItem = ({ bundle, activeBundles, addBundle, removeBundle, isExceedStockEnabled }: BundleSelectorItemProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const [stock, setStock] = useState<stocks.Stock | null>(null)
  const [, setIsLoadingStock] = useState(false)

  const dispatch = useAppDispatch()

  const getStock = async (
    bundleId: string,
    signal: AbortSignal
  ) => {
    try {
      setIsLoadingStock(true)
      const { data: { stock } }: { data: { statusCode: number, success: boolean, stock: stocks.Stock } } =
        await BundleService.getBundleStock(String(currentUser?.token), bundleId, signal)
      setStock(stock)
      setIsLoadingStock(false)
    } catch (error: any) {
      if (error.message !== 'canceled') {
        setIsLoadingStock(false)
      }
    }
  }

  const handleSelection = () => {
    const availableStock = (stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0)
    if (isExceedStockEnabled || availableStock > 0 || !bundle.isBillOfMaterials) {
      if (!(activeBundles.map(activeBundle => activeBundle.id).includes(bundle.id))) {
        addBundle(bundle)
      } else {
        removeBundle(bundle)
      }
    } else {
      const payload = {
        title: 'Out of Stock',
        message: `There is no available stock for ${bundle.name}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'warning'
      }
      dispatch(setToast(payload))
    }
  }

  useEffect(() => {
    if (!isExceedStockEnabled) {
      const controller = new AbortController()
      const signal = controller.signal
      getStock(bundle.id, signal)

      return () => {
        controller.abort()
      }
    }
  }, [])
  return (
    <div
      className="col position-relative"
      key={bundle.id}
    >
      <div className={`card h-100 bundle-card ${(activeBundles.map(activeBundle => activeBundle.id).includes(bundle.id)) && 'selected'}`} role="button">
        <div
          onClick={() => {
            handleSelection()
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              handleSelection()
            }
          }}
          role="button"
          tabIndex={0}
          aria-label={`Select ${bundle.name}`}
          className="h-100"
        >
          <img
            src={bundle.pictures.length > 0 ? bundle.pictures[0].url : placeholderThumbnail}
            className="card-img-top" alt="Bundle"
            style={{
              maxHeight: 300
            }}
          />
          <div className="card-body">
            <h5 className={`card-title text-truncate ${(isExceedStockEnabled || (stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0) > 0 || !bundle.isBillOfMaterials) ? '' : 'text-muted'}`} title={bundle.name}>
              {bundle.name}
            </h5>
            <p className={`card-text small ${(isExceedStockEnabled || (stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0) > 0 || !bundle.isBillOfMaterials) ? 'text-success' : 'text-danger'}`}>
              {(isExceedStockEnabled || (stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0) > 0 || !bundle.isBillOfMaterials) ? '' : <span className="small fw-normal text-nowrap position-absolute top-0 start-0 m-1"><span className="badge rounded-pill text-bg-danger">Out of Stock</span></span>}
            </p>
          </div>
        </div>
        <div className="dropdown-center border-top">
          <button
            className="accordion-button rounded-bottom-5"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-expanded="false"
          >
            <span className="accordion-button-text me-1"></span>bundle contents
          </button>
          <ul className="dropdown-menu py-0">
            {
              bundle.specifications?.billOfMaterialsComponents.map(billOfMaterialsComponent => (
                <li key={billOfMaterialsComponent.jfsku} className="dropdown-item list-group-item bg-dark text-white">{billOfMaterialsComponent.name}</li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BundleSelectorItem
