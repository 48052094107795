import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import Calendar from 'react-datepicker'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { formatPriceWithSymbolAtEnd } from '../../utils/formatPrice'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'
import { TrashIcon } from '../icons/TrashIcon'
import { ShoppingCartAddress, ShoppingCartProduct } from '../../types'
import { resetShoppingAddresses, setShoppingAddresses } from '../../store/reducers/shoppingCartReducer'
import { calculateGraduatedPrice } from '../../utils/calculateGraduatedPrice'
import { germanyTaxRate } from '../../constants/taxRates'
import { calculateShippingCost } from '../../utils/calculateShippingCost'
import ShoppingCartManager from './ShoppingCartManager'
import { getDateOfDispatchMinimumDate } from '../../utils/getDateOfDispatchMinimumDate'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'
import { isWeekday } from '../../utils/isWeekday'
import { PencilIcon } from '../icons/PencilIcon'
interface ShoppingShippingDetailsProps {
  setSelectedAssignmentAddress: React.Dispatch<React.SetStateAction<ShoppingCartAddress | null>>
  shippingMode: 'inventory' | 'delivery' | null
  setShippingMode: React.Dispatch<React.SetStateAction<'inventory' | 'delivery' | null>>
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  setIsOneAddress: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<Partial<ShoppingCartAddress> | null>>
  isOneAddress: boolean
  setAssignedItems: React.Dispatch<React.SetStateAction<String[] | null>>
}
const ShoppingShippingDetails = ({
  setSelectedAddress,
  setSelectedAssignmentAddress,
  shippingMode,
  setShippingMode,
  setEditMode,
  setIsOneAddress,
  isOneAddress,
  setAssignedItems
}: ShoppingShippingDetailsProps) => {
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartAddresses = useAppSelector((state) => state.shoppingCart.addresses)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const dispatch = useAppDispatch()
  const [assignedItemIds, setAssignedItemIds] = useState<string[] | []>([])

  const [selectedShoppingCartAddress, setSelectedShoppingCartAddress] = useState<ShoppingCartAddress>({} as ShoppingCartAddress)
  const [shoppingCartAddressesLength, setShoppingCartAddressesLength] = useState<number>(0)
  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || 1 }))

  const calculateTotalPrices = (cartProducts: ShoppingCartProduct[], shippingAddresses: ShoppingCartAddress[]) => {
    const updatedShippingAddresses = shippingAddresses.filter(address =>
      !isOneAddress ? address.assignedLeftOverProductIds.length > 0 : true
    )
    const subtotal = cartProducts.reduce((accumulator, currentValue) => accumulator + (calculateGraduatedPrice(currentValue.graduatedPrices, currentValue.quantity, currentValue.netRetailPrice.amount) * currentValue.quantity), 0)
    const shippingTotal = updatedShippingAddresses.reduce((accumulator, address) => {
      const totalBundleQuantity = address.assignedBundleIds.reduce((total, bundleId) => {
        const bundle = shoppingCartBundles.find(b => b.id === bundleId)
        return total + (bundle?.quantity || 1)
      }, 0)
      const shippingCost = calculateShippingCost(address.country || 'Germany', totalBundleQuantity)
      return accumulator + (shippingCost || 0)
    }, 0)
    const taxTotal = (subtotal + shippingTotal) * germanyTaxRate
    const total = subtotal + shippingTotal + taxTotal
    return {
      subtotal,
      taxTotal,
      shippingTotal,
      total
    }
  }
  const handleDragStart = (e: { dataTransfer: { setData: (arg0: string, arg1: any) => void } }, product: { id: any, spliteId: any}) => {
    e.dataTransfer.setData('productId', product.spliteId || product.id)
  }

  useEffect(() => {
    setAssignedItemIds([
      ...shoppingCartAddresses.flatMap((cur) => [
        ...cur.assignedBundleIds,
        ...cur.assignedLeftOverProductIds
      ])
    ])
    setAssignedItems([
      ...shoppingCartAddresses.flatMap((cur) => [
        ...cur.assignedBundleIds,
        ...cur.assignedLeftOverProductIds
      ])
    ])
    setShoppingCartAddressesLength(shoppingCartAddresses.length)
  }, [shoppingCartAddresses])

  useEffect(() => {
    if (shoppingCartAddresses.length > 0) {
      setSelectedShoppingCartAddress(shoppingCartAddresses[0])
    }
  }, [shoppingCartAddressesLength])
  useEffect(() => {
    if (isOneAddress && shoppingCartAddresses.length > 1) {
      dispatch(resetShoppingAddresses())
    }
  }, [isOneAddress])
  return (
    <div>
      <div className="row justify-content-center m-0">
        <div className="col-lg-7 col-md-10 col-sm-11">
          <div className="horizontal-steps mt-4 mb-4 pb-5" id="tooltip-container">
            <div className="horizontal-steps-content">
              <div className="step-item">
                <span data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-title="">
                  Your Cart
                </span>
              </div>
              <div className="step-item current">
                <span data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-title="">
                  Shipping Details
                </span>
              </div>
              <div className="step-item">
                <span>Payment</span>
              </div>
            </div>
            <div className="process-line" style={{ width: '50%' }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <h6 className="fw-bold p-0 m-0">Shipping</h6>
          <p className="mx-0">Do you want to ship to one or multiple addresses?</p>
          <div className="d-flex gap-4 my-3">
          <button
            type="button"
            className={`btn w-100 ${isOneAddress ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => {
              setIsOneAddress(true)
              dispatch(resetShoppingAddresses())
            }}>
              One Address
            </button>
            <button type="button" className={`btn w-100 ${isOneAddress ? 'btn-outline-primary' : 'btn-primary'}`} onClick={() => setIsOneAddress(false)}>
              Multiple Addresses
            </button>
          </div>
          <div className="rounded">
            <div className="row mb-4">
              <div className="col">
                <h6 className="fw-bold">1. Choose a shipping location</h6>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="inventoryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setShippingMode('inventory')
                      }
                    }}
                    checked={shippingMode === 'inventory'}
                    disabled
                  />
                  <label className="form-check-label" htmlFor="inventoryRadio">
                    Ship articles to inventory
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="deliveryRadio"
                    onChange={(event) => {
                      const value = event.target.value
                      if (value === 'on') {
                        setShippingMode('delivery')
                      }
                    }}
                    checked={shippingMode === 'delivery'}
                  />
                  <label className="form-check-label" htmlFor="deliveryRadio">
                    Ship articles to delivery address
                  </label>
                </div>
              </div>
            </div>
            {shippingMode === 'delivery' && (
              <div className="row">
                <div className="col">
                  <h6 className="fw-bold">2. Select delivery address</h6>
                  <p className="small mb-0">Select an address from the address book in order to assign articles.</p>
                    <button
                      type="button"
                      className="btn btn-outline-primary mt-3"
                      onClick={() => {
                        setEditMode(false)
                        openModal('shoppingDeliveryAddressPickerModal')
                        dismissModal('shoppingShippingDetailsModal')
                      }}>
                    <i className="bi bi-plus-circle me-1"></i>{isOneAddress && shoppingCartAddresses.length === 1 ? 'Change Delivery Address' : 'Add Delivery Address'}
                    </button>
                  <div className="my-4">
                    {isOneAddress && shoppingCartAddresses.length > 0 && (
                      <div className="card h-100 selected border-primary">
                        <div className="card-body d-flex justify-content-between">
                          <div className="col w-100">
                            <h5 className="card-title">{shoppingCartAddresses[0].companyName ?? '---'}</h5>
                            <p className="card-text lh-1">{`${shoppingCartAddresses[0].firstName ?? '--'} ${shoppingCartAddresses[0].lastName ?? '--'}`}</p>
                            <p className="card-text lh-1">{`${shoppingCartAddresses[0].street ?? '--'} ${shoppingCartAddresses[0].zip ?? '--'}`}</p>
                            <p className="card-text lh-1">{`${shoppingCartAddresses[0].city ?? '--'} ${shoppingCartAddresses[0].country}`}</p>
                            <p className="card-text lh-1">Cost Center: {shoppingCartAddresses[0].costCenter ?? 'Not Set'}</p>
                          </div>
                          <div className="w-40 d-flex flex-column align-items-end justify-content-between">
                            <div className="d-flex gap-2">
                              <button
                                className="btn btn-round"
                                type="button"
                                onClick={() => {
                                  setEditMode(true)
                                  setSelectedAddress(shoppingCartAddresses[0])
                                  openModal('shoppingDeliveryAddressEditorModal')
                                  dismissModal('shoppingShippingDetailsModal')
                                }}>
                                <PencilIcon />
                              </button>
                              <button
                                type="button"
                                title={`Remove ${shoppingCartAddresses[0].companyName}`} className="btn btn-outline-danger btn-round"
                                onClick={() => {
                                  const foundShoppingAddressIndex = shoppingCartAddresses.findIndex(cartAddress => cartAddress.id === shoppingCartAddresses[0].id)
                                  if (foundShoppingAddressIndex !== -1) {
                                    const updatedAddressesInCart = shoppingCartAddresses.filter(cartAddress => cartAddress.id !== shoppingCartAddresses[0].id)
                                    const payload = { addresses: updatedAddressesInCart }; dispatch(setShoppingAddresses(payload))
                                    setSelectedAssignmentAddress(null)
                                  }
                                }}>
                                <TrashIcon />
                              </button>
                            </div>
                            <div className="col-md-6 w-75">
                              <div className=" w-50 d-flex flex-column  w-100 align-items-end">
                                <label htmlFor="shippingDate" className="form-label text-muted">
                                  Shipping Date
                                </label>
                                <div className=''>
                                  <Calendar
                                    id="shippingDate"
                                    name="shippingDate"
                                    onChange={(date: Date) => {
                                      const updatedAddresses = shoppingCartAddresses.map((address) => address.id === shoppingCartAddresses[0].id ? { ...address, shippingDate: dayjs(date).format() } : address)
                                      dispatch(setShoppingAddresses({ addresses: updatedAddresses }))
                                    }}
                                    selected={shoppingCartAddresses[0].shippingDate ? new Date(shoppingCartAddresses[0].shippingDate) : null}
                                    className="form-control"
                                    minDate={getDateOfDispatchMinimumDate()}
                                    dateFormat={'dd/MM/yyyy'}
                                    autoComplete={'off'}
                                    filterDate={isWeekday}
                                    calendarStartDay={DaysOfWeek.Monday}
                                    placeholderText="Select Shipping Date"
                                    />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {!isOneAddress && (
                    <div className="accordion-custom order-cart my-4" id="accordionFlushShippingDetails" style={{ maxHeight: shoppingCartAddresses.length > 2 ? '200px' : 'auto', overflowY: shoppingCartAddresses.length > 2 ? 'scroll' : 'visible', scrollbarWidth: 'thin', msOverflowStyle: 'auto', width: '100%' }}>
                      {shoppingCartAddresses.length > 0 && shoppingCartAddresses.map((shoppingCartAddress, index) => (
                        <div key={`shopping-shipping-details-${shoppingCartAddress.id}`} className="border rounded mb-4 px-1 p-1 w-100">
                          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                          <div className="w-100 mx-3 d-flex flex-column justify-content-between">
                            <p className="fs-6 text-muted fw-normal mb-1">
                            <span>{`Shipping Destination ${index + 1}`}</span>
                            </p>
                            <h6 className="fw-bold">{`${shoppingCartAddress.country}, ${shoppingCartAddress.city}`}</h6>
                          </div>
                          <div className="w-100">
                            <div className="col-md-6 w-100">
                            <div className="mb-3 w-100">
                              <label htmlFor="shippingDate" className="form-label text-muted">
                              Shipping Date
                              </label>
                              <div>
                              <Calendar
                                id="shippingDate"
                                name="shippingDate"
                                onChange={(date: Date) => {
                                  const updatedAddresses = shoppingCartAddresses.map((address) => address.id === shoppingCartAddress.id ? { ...address, shippingDate: dayjs(date).format() } : address)
                                  dispatch(setShoppingAddresses({ addresses: updatedAddresses }))
                                }}
                                selected={shoppingCartAddress.shippingDate ? new Date(shoppingCartAddress.shippingDate) : null}
                                className="form-control"
                                minDate={getDateOfDispatchMinimumDate()}
                                dateFormat={'dd/MM/yyyy'}
                                autoComplete={'off'}
                                filterDate={isWeekday}
                                calendarStartDay={DaysOfWeek.Monday}
                                placeholderText="Select Shipping Date" />
                              </div>
                            </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end align-items-center w-md-50 mt-2 mt-md-0 px-3">
                            <button
                              className="btn-round bg-white me-2"
                              type="button"
                              onClick={() => {
                                setEditMode(true)
                                setSelectedAddress(shoppingCartAddress)
                                openModal('shoppingDeliveryAddressEditorModal')
                                dismissModal('shoppingShippingDetailsModal')
                              }}
                              >
                            <i className="bi bi-pencil"></i>
                            </button>
                            <button
                              className="btn btn-outline-danger btn-round"
                              type="button"
                              onClick={() => {
                                const updatedAddressesInCart = shoppingCartAddresses.filter(cartAddress => cartAddress.id !== shoppingCartAddress.id)
                                const payload = { addresses: updatedAddressesInCart }
                                dispatch(setShoppingAddresses(payload))
                                setSelectedAssignmentAddress(null)
                              }}
                              >
                            <TrashIcon />
                            </button>
                          </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-lg-6 mb-4">
          <div className="border rounded p-3 ms-auto">
            <div className="row">
              <div className="d-flex justify-content-between align-items-center mt-2">
                <h6 className="fw-bold">Summary</h6>
              </div>
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <h6 className="fs-6 text-muted">Total price items</h6>
                <p className="fw-bold">{formatPriceWithSymbolAtEnd(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).subtotal) || '-'}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="fs-6 text-muted">Packing</h6>
                <p className="fw-bold">-</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="fs-6 text-muted">Tax</h6>
                <p className="fw-bold">{formatPriceWithSymbolAtEnd(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).taxTotal) || '-'}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="fs-6 text-muted">Shipping fee</h6>
                <p className="fw-bold">{formatPriceWithSymbolAtEnd(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).shippingTotal)}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="fs-6 text-muted">Storage</h6>
                <p className="fw-bold">-</p>
              </div>
            </div>
            <div className="fw-bold row border-top gx-3">
              <div className="d-flex justify-content-between align-items-center my-2">
                <h6 className="fw-bold">Total Price</h6>
                <p className="fw-bold">{formatPriceWithSymbolAtEnd(calculateTotalPrices(shoppingCartProductsWithQuantity, shoppingCartAddresses).total)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isOneAddress && (
        <ShoppingCartManager
          assignedItemIds={assignedItemIds}
          handleDragStart={handleDragStart}
          isOneAddress={isOneAddress}
          setSelectedShoppingCartAddress={setSelectedShoppingCartAddress}
          selectedShoppingCartAddress={selectedShoppingCartAddress}
        />
      )}
    </div>
  )
}

export default ShoppingShippingDetails
