const getRGB = (c:any) => {
  return parseInt(c, 16) || c
}

const getsRGB = (c:any) => {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

const getLuminance = (hexColor:string) => {
  return (
    0.2126 * getsRGB(hexColor.substring(1, 3)) +
    0.7152 * getsRGB(hexColor.substring(3, 5)) +
    0.0722 * getsRGB(hexColor.substring(5))
  )
}

function getContrast (f:any, b:any) {
  const L1 = getLuminance(f)
  const L2 = getLuminance(b)
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

export const getForegroundColor = (bgColor:string) => {
  const whiteContrast = getContrast(bgColor, '#ffffff')
  const blackContrast = getContrast(bgColor, '#000000')

  return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}
