import React from 'react'
import SkeletonElement from '../../../components/loaders/skeleton/SkeletonElement'

const Loader = ({ repetition } : { repetition: number }) => {
  return (
    <div className="border border-gray rounded px-2 py-1">
      <table className="table table-borderless mb-0">
        <tbody>
          {
            Array.from(Array(repetition).keys()).map((n: number) => (
              <tr key={n}>
                <td className="text-muted"><SkeletonElement type="text-small" /></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default Loader
