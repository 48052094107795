import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CostCenterService from '../../../services/api/CostCenterService'
import { ApiCostCenterState } from '../../../types'
import { COST_CENTER_DELETION_MESSAGE, COST_CENTER_UPDATE_SUCCESS_MESSAGE } from '../../../constants/messages'

const initialState: ApiCostCenterState = {
  isLoading: false,
  error: null,
  costCenter: null,
  costCenters: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const editACostCenterById = createAsyncThunk('api/cost-centers/edit', async ({ id, token, costCenter, signal }: { id: any, token: string, costCenter: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CostCenterService.updateCostCenterById(id, token, costCenter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteACostCenterById = createAsyncThunk('api/cost-centers/delete', async ({ id, token, signal }: { id: any, token: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CostCenterService.deleteCostCenterById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const costCenterSlice = createSlice({
  name: 'api/cost-center',
  initialState,
  reducers: {
    resetCostCenterError: (state) => {
      state.error = null
    },
    resetCostCenterMessage: (state) => {
      state.message = null
    },
    resetCostCenterData: (state) => {
      state.error = null
      state.costCenter = null
      state.costCenters = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editACostCenterById.pending, (state) => {
        state.costCenters = []
        state.isLoading = true
      })
      .addCase(editACostCenterById.fulfilled, (state, action) => {
        state.isLoading = false
        state.costCenter = action.payload.costCenter
        state.message = COST_CENTER_UPDATE_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(editACostCenterById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteACostCenterById.pending, (state) => {
        state.costCenters = []
        state.isLoading = true
      })
      .addCase(deleteACostCenterById.fulfilled, (state, action) => {
        state.isLoading = false
        state.costCenter = null
        state.error = null
        state.message = COST_CENTER_DELETION_MESSAGE
      })
      .addCase(deleteACostCenterById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCostCenterError, resetCostCenterMessage, resetCostCenterData } = costCenterSlice.actions

const { reducer } = costCenterSlice
export default reducer
