import React from 'react'
import { BillOfMaterialsComponent, Bundle, Product, stocks } from '../../types'
import { containsObject } from '../../utils/containsObject'
import Placeholder from '../../assets/images/placeholder.png'
import { useAppDispatch } from '../../store/hooks'
import dayjs from 'dayjs'
import { setToast } from '../../store/reducers/toastReducer'

type ItemCardProps = {
  product: Product
  bundle: Bundle
  addItem: (item: BillOfMaterialsComponent, bundle: Bundle) => void
  deleteItem: (jfsku: string, bundle: Bundle) => void
  setEditCustomBundleItem: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product | null>>
  setSelectedProductStock: React.Dispatch<React.SetStateAction<stocks.Stock | null>>
}

const ItemCard = ({ product, bundle, addItem, deleteItem, setEditCustomBundleItem, setSelectedProduct, setSelectedProductStock }: ItemCardProps) => {
  const dispatch = useAppDispatch()

  const handleSelection = () => {
    const mappedProduct = {
      name: product.name,
      jfsku: product.jfsku,
      merchantSku: product.merchantSku,
      quantity: 1,
      isCustomItem: true,
      isDeleted: false
    }
    const availableStock = Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0)
    if (availableStock > 0) {
      if (!containsObject(mappedProduct, bundle?.specifications?.billOfMaterialsComponents || [])) {
        addItem(mappedProduct, bundle)
      } else {
        deleteItem(product.jfsku, bundle)
      }
    } else {
      const payload = {
        title: 'Out of Stock',
        message: `There is no available stock for ${product.name}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'warning'
      }
      dispatch(setToast(payload))
    }
  }

  return (
    <div
      className="col"
      key={product.jfsku}
    >
      <div
        onClick={() => handleSelection()}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleSelection()
          }
        }}
        role="button"
        tabIndex={0}
        aria-label={`Select ${product.name}`}
        className={`card h-100 product-card ${containsObject({ name: product.name, jfsku: product.jfsku, merchantSku: product.merchantSku }, bundle?.specifications?.billOfMaterialsComponents.map((billOfMaterialsComponent) => ({ name: billOfMaterialsComponent.name, jfsku: billOfMaterialsComponent.jfsku, merchantSku: billOfMaterialsComponent.merchantSku })) || []) ? 'selected' : ''}`}
      >
        {
          product && product.pictures && product?.pictures.length > 0
            ? (
              <img
                src={product.pictures[0].publicUrl}
                className="card-img-top"
                alt={product.name}
              />
              )
            : (<img src={Placeholder} alt="Placeholder thumbnail" style={{ objectFit: 'cover' }} className="card-img-top" />)
        }
        <div
          className="h-100 d-flex align-content-end flex-wrap"
        >
          <div className="position-relative d-none" title="Availability Date">
            <span className="badge rounded-pill text-bg-secondary position-absolute bottom-0 end-0 m-1">
              <i className="bi bi-clock"></i> 0 days
            </span>
          </div>
          <div className="card-body">
            <h6 className={`card-title ${(product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0) > 0 ? '' : 'text-muted'}`}>
              {product.name}
            </h6>
          </div>
        </div>
        <ul className="list-group list-group-flush m-1">
          <li className={`list-group-item d-flex small justify-content-between align-items-center ${(product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0) > 0 ? '' : 'text-muted'}`}>
            <span><span className="fw-bold">In Stock:</span> {product.stock?.stockLevel || 0}</span> - <span><span className="fw-bold">Available:</span> {Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0)}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default React.memo(ItemCard)
