import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductServiceJTL from '../../../../services/api/dotnet/ProductService'
import { DotnetApiProductState } from '../../../../types'

const initialState: DotnetApiProductState = {
  isLoading: false,
  error: null,
  product: null,
  stockProducts: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  stockProductsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 10,
    total: 0
  }
}

export const getAProductStockSerialNumbers = createAsyncThunk('dotnet/api/products/stock/serial-numbers', async ({ token, merchantSku, perPage, page, signal }: { token: string, merchantSku: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ProductServiceJTL.getAProductStockSerialNumbers(token, merchantSku, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const productSlice = createSlice({
  name: 'dotnet/api/products',
  initialState,
  reducers: {
    resetProductError: (state) => {
      state.error = null
    },
    resetProductMessage: (state) => {
      state.message = null
    },
    resetProductData: (state) => {
      state.error = null
      state.product = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAProductStockSerialNumbers.pending, (state) => {
        state.stockProducts = []
        state.isLoading = true
      })
      .addCase(getAProductStockSerialNumbers.fulfilled, (state, action) => {
        state.isLoading = false
        state.stockProducts = action.payload.stockProducts
        state.stockProductsMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getAProductStockSerialNumbers.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductError, resetProductMessage, resetProductData } = productSlice.actions

const { reducer } = productSlice
export default reducer
