import axios from 'axios'
import { CompanyUserGroupEdit, UserCompanyUserGroup } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getCompanyUserGroups (token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/company-user-groups', config)
  },
  getCompanyUserGroupById (token: string, companyUserGroupId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/company-user-groups/${companyUserGroupId}`, config)
  },
  addCompanyUserGroup (token: string, companyUserGroup: CompanyUserGroupEdit, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/company-user-groups', { companyUserGroup }, config)
  },
  editCompanyUserGroupById (token: string, companyUserGroupId: string, companyUserGroup: CompanyUserGroupEdit, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/company-user-groups/${companyUserGroupId}`, { companyUserGroup }, config)
  },
  deleteCompanyUserGroupById (token: string, companyUserGroupId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/company-user-groups/${companyUserGroupId}`, config)
  },
  addUsersToCompanyUserGroup (token: string, companyUserGroupId: string, userCompanyUserGroup: UserCompanyUserGroup, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/company-user-groups/${companyUserGroupId}/users`, { userCompanyUserGroup }, config)
  },
  getUsersOfACompanyUserGroup (token: string, perPage: number, page: number, companyUserGroupId: string, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/company-user-groups/${companyUserGroupId}/users`, config)
  }
}
