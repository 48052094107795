import axios from 'axios'

const baseURL = process.env.REACT_APP_DOTNET_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/v1/hubspot`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getHubspotCategories (token: string, perPage: number, offset: number, signal: AbortSignal) {
    const config = {
      params: {
        pageSize: perPage,
        offset
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/hubspotCategories', config)
  }
}
