import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAllShippingMethods (token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/shipping-methods', config)
  },
  getAllJtlShippingMethods (token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/shipping-methods-jtl', config)
  },
  addShippingMethod (token: string, shippingMethod: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/shipping-methods', { shippingMethod }, config)
  },
  editShippingMethodById (token: string, shippingMethodId: string, shippingMethod: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/shipping-methods/${shippingMethodId}`, { shippingMethod }, config)
  },
  deleteShippingMethodById (token: string, shippingMethodId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/shipping-methods/${shippingMethodId}`, config)
  }
}
