import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAllSalutations (token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/salutations', config)
  },
  addSalutation (token: string, salutation: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/salutations', { salutation }, config)
  },
  editSalutationById (token: string, salutationId: string, salutation: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/salutations/${salutationId}`, { salutation }, config)
  },
  deleteSalutationById (token: string, salutationId: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/salutations/${salutationId}`, config)
  }
}
