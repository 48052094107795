import axios from 'axios'

const baseURL = process.env.REACT_APP_JTL_BACKEND_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api/fulfillment-orders`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  cancelFulfillmentOrders (token: string, outboundIds: Array<string>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.patch('cancel', { outboundIds }, config)
  }
}
