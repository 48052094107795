import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'

const AccessControls = () => {
  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <ul className="nav nav-pills mb-4">
          <li className="nav-item">
            <NavLink className="nav-link" to="/access-controls/product-access-control-groups">Product Access Control Groups</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/access-controls/company-user-groups">Company User Groups</NavLink>
          </li>
        </ul>
        <Outlet />
      </div>
    </main>
  )
}

export default AccessControls
