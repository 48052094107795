import React, { ChangeEvent, useEffect, useState } from 'react'
import _ from 'lodash'
import { Tab } from 'bootstrap'
import dayjs from 'dayjs'
import { CanvasTemplate } from '../../types'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Pagination from '../Pagination'
import SkeletonImage from '../loaders/skeleton/SkeletonImage'
import { setToast } from '../../store/reducers/toastReducer'
import * as userRoles from '../../constants/userRoles'

const useTemplateSelector = () => {
  const profile = useAppSelector((state) => state.profile.profile)
  const templates = useAppSelector((state) => state.apiPicture.cards)
  const isLoadingCards = useAppSelector((state) => state.apiPicture.isLoading)
  const metadata = useAppSelector((state) => state.apiPicture.metadata)
  const campaign = useAppSelector((state) => state.apiCampaign.campaign)
  const isLoadingCampaign = useAppSelector((state) => state.apiCampaign.isLoading)
  const [activeTemplate, setActiveTemplate] = useState<CanvasTemplate | null>(null)
  const [originalActiveTemplate, setOriginalActiveTemplate] = useState<CanvasTemplate | null>(null)
  const [activeFront, setActiveFront] = useState<string | null>(null)
  const [nextPressed, setNextPressed] = useState(false)
  const [goToDraftsPressed, setGoToDraftsPressed] = useState(false)
  const [image, setImage] = useState<HTMLImageElement | undefined>()
  const [perPageTemplateSelector, setPerPageTemplateSelector] = useState(12)
  const [pageTemplateSelector, setPageTemplateSelector] = useState(1)
  const [nextPageToken, setNextPageToken] = useState<string>('CgZjYXJkcy8=')
  const [previousNextPageToken, setPreviousNextPageToken] = useState('CgZjYXJkcy8=')
  const [previousPageTemplateSelector, setPreviousPageTemplateSelector] = useState(1)
  const [templateTypeFilter, setTemplateTypeFilter] = useState<'general' | 'company'>('company')

  const dispatch = useAppDispatch()

  const role = profile?.role || userRoles.USER

  const compare = (a: string | null, b: string | null) => {
    return _.isEqual(a, b)
  }

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    const nextPageTokenOption = templateTypeFilter === 'general' ? 'Cg5jYXJkcy9nZW5lcmFsLw==' : 'CgZjYXJkcy8='
    setPageTemplateSelector(1)
    setNextPageToken(nextPageTokenOption)
    setPerPageTemplateSelector(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPreviousNextPageToken(nextPageToken)
    setPreviousPageTemplateSelector(pageTemplateSelector)

    setPageTemplateSelector(page)
    const nextPageTokenOption = templateTypeFilter === 'general' ? 'Cg5jYXJkcy9nZW5lcmFsLw==' : 'CgZjYXJkcy8='
    if (page > previousPageTemplateSelector) {
      if (page === 1) {
        localStorage.setItem(`nextPageTemplateSelector-${templateTypeFilter}-${page}`, nextPageTokenOption)
      } else {
        metadata.nextPage && localStorage.setItem(`nextPageTemplateSelector-${templateTypeFilter}-${page}`, metadata.nextPage)
      }
    }
    setNextPageToken(localStorage.getItem(`nextPageTemplateSelector-${templateTypeFilter}-${page}`) || nextPageTokenOption)
  }

  useEffect(() => {
    if (activeFront) {
      const img = new Image()
      // img.crossOrigin = 'anonymous'
      img.src = activeFront
      img.onload = () => {
        // Load image to get width and height dimensions
        setImage(img)
      }
      img.onerror = (event, source, lineno, colno, error) => {
        setImage(undefined)
      }
    }
  }, [activeFront])

  useEffect(() => {
    handlePageChange(1)
  }, [templateTypeFilter])

  return {
    activeTemplate,
    setActiveTemplate,
    originalActiveTemplate,
    activeFront,
    setActiveFront,
    nextPressed,
    setNextPressed,
    goToDraftsPressed,
    setGoToDraftsPressed,
    perPageTemplateSelector,
    nextPageToken,
    setNextPageToken,
    previousNextPageToken,
    previousPageTemplateSelector,
    templateTypeFilter,
    setTemplateTypeFilter,
    image,
    render: (
      <div className="row">
        <div className="col">
          <div className="p-0">
            {
              isLoadingCampaign
                ? (<p className="text-muted">Choose one of the curated templates. Or continue where you left off from the <span className="fw-bold">Drafts</span> section.</p>)
                : (
                    (role === userRoles.ADMIN || campaign?.cardSetting?.isFrontSelectable)
                      ? (<p className="text-muted">Choose one of the curated templates. Or continue where you left off from the <span className="fw-bold">Drafts</span> section.</p>)
                      : (<p>Template selection has been disabled.</p>)
                  )
            }
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4" id="templates">
              {
                isLoadingCards
                  ? (
                    <SkeletonImage repetition={12} />
                    )
                  : (templates.length > 0
                      ? (
                          templates.map((template) => (
                            <div
                              className="col"
                              key={template.id}
                              onClick={() => {
                                if (role === userRoles.ADMIN || campaign?.cardSetting?.isFrontSelectable) {
                                  setActiveTemplate(template)
                                  setOriginalActiveTemplate(template)
                                  setActiveFront(template.url)
                                } else {
                                  const payload = {
                                    title: 'Info',
                                    message: 'Template selection has been disabled',
                                    isVisible: true,
                                    timestamp: dayjs().format('LT'),
                                    type: 'info'
                                  }
                                  dispatch(setToast(payload))
                                }
                              }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter' || event.key === ' ') {
                                  if (role === userRoles.ADMIN || campaign?.cardSetting?.isFrontSelectable) {
                                    setActiveTemplate(template)
                                    setOriginalActiveTemplate(template)
                                    setActiveFront(template.url)
                                  } else {
                                    const payload = {
                                      title: 'Info',
                                      message: 'Template selection has been disabled',
                                      isVisible: true,
                                      timestamp: dayjs().format('LT'),
                                      type: 'info'
                                    }
                                    dispatch(setToast(payload))
                                  }
                                }
                              }}
                              role="button"
                              tabIndex={0}
                              aria-label={`Select ${template.name}`}
                            >
                              <div className={`card overflow-hidden h-100 template-card ${compare(activeTemplate?.url || null, template.url) && 'selected'}`} role="button">
                                <img src={template.thumbnailUrl} style={{ objectFit: 'cover' }} alt={`${template.name} Card Template`} />
                                <div className="card-body">
                                  <h5 className="card-title text-truncate" title={template.name}>{template.name}</h5>
                                  <p className="card-text"></p>
                                </div>
                              </div>
                            </div>
                          ))
                        )
                      : (
                        <div className="col">
                          <div className="border rounded d-flex justify-content-center p-4 text-center">
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="currentColor" className="bi bi-card-image text-secondary" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
                              </svg>
                              <p>No templates have been added</p>
                            </div>
                          </div>
                        </div>
                        ))
              }
            </div>
            <div className="row mt-4">
              <Pagination
                isLoading={isLoadingCards}
                metadata={{
                  limit: metadata.perPage,
                  total: metadata.total,
                  offset: ((pageTemplateSelector - 1) * (metadata.perPage))
                }}
                page={pageTemplateSelector}
                perPage={perPageTemplateSelector}
                handlePageChange={handlePageChange}
                handleShowEntries={handleShowEntries}
                module="card-templates"
                perPageOptions={[12, 24, 60, 100]}
              />
            </div>
            <div className="row mt-2">
              {
              (role === userRoles.ADMIN || campaign?.cardSetting?.isFrontSelectable)
                ? (!activeTemplate && (
                  <div className="col">
                    <p className="text-primary fw-semibold">Select a new template to get started or continue where you left off from the Drafts section.</p>
                  </div>)
                  )
                : (
                  <div className="col">
                    <p className="text-primary fw-semibold">Template selection has been disabled.</p>
                  </div>
                  )
              }
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => {
                    setGoToDraftsPressed(true)
                    const triggerEl = document.querySelector('#canvasTab button[data-bs-target="#drafts"]')
                    if (triggerEl) {
                      const tab = new Tab(triggerEl)
                      tab.show()
                    }
                  }}
                >
                  Go to Drafts
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setNextPressed(true)
                    const triggerEl = document.querySelector('#canvasTab button[data-bs-target="#message-editor"]')
                    if (triggerEl) {
                      const tab = new Tab(triggerEl)
                      tab.show()
                    }
                  }}
                  disabled={activeTemplate === null}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default useTemplateSelector
