import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Progress from '../../../components/loaders/Progress'
import Pagination from '../../../components/Pagination'
import { TrashIcon } from '../../../components/icons/TrashIcon'
import SkeletonTableRow from '../../../components/loaders/skeleton/SkeletonTableRow'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { PencilIcon } from '../../../components/icons/PencilIcon'
import * as userRoles from '../../../constants/userRoles'
import { ProductAccessControlGroup, Permission } from '../../../types'
import { READWRITE } from '../../../constants/permissions'
import {
  addProductAccessControlGroup,
  deleteProductAccessControlGroupById,
  editProductAccessControlGroupById,
  getProductAccessControlGroups
} from '../../../store/reducers/api/productAccessControlGroupReducer'
import ProductAccessControlGroupEditor from '../../../components/AccessControls/Products/ProductAccessControlGroupEditor'
import { getCompanies } from '../../../store/reducers/api/companyReducer'
import { dismissModal } from '../../../utils/dismissModal'
import { setToast } from '../../../store/reducers/toastReducer'
import { PRODUCT_ACCESS_CONTROL_GROUP_CREATION_SUCCESS_MESSAGE, PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE, PRODUCT_ACCESS_CONTROL_GROUP_UPDATE_SUCCESS_MESSAGE } from '../../../constants/messages'
import useDebounce from '../../../utils/hooks/useDebounce'

const ProductAccessControlGroups = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiProductAccessControlGroup.isLoading)
  const productAccessControlGroups = useAppSelector((state) => state.apiProductAccessControlGroup.productAccessControlGroups)
  const metadata = useAppSelector((state) => state.apiProductAccessControlGroup.metadata)
  const message = useAppSelector((state) => state.apiProductAccessControlGroup.message)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [perPageCompanies] = useState(100)
  const [pageCompanies] = useState(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const [initialProductAccessControlGroup, setInitialProductAccessControlGroup] = useState<Partial<ProductAccessControlGroup>>({
    name: '',
    description: '',
    company: null
  })

  const token = currentUser?.token
  const role = profile?.role || userRoles.USER

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const handleProductAccessControlGroupsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      if (role === userRoles.ADMIN) {
        dispatch(getProductAccessControlGroups({ token, perPage, page, signal }))
      }
    }
  }

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const isAllowed = (permission: Permission) => {
    return role === userRoles.ADMIN
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      if (role === userRoles.ADMIN) {
        dispatch(getProductAccessControlGroups({ token, perPage, page, signal, search: debouncedSearchTerm }))
      }
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTerm])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && role === userRoles.ADMIN) {
      token && dispatch(getCompanies({ token, perPage: perPageCompanies, page: pageCompanies, signal }))
    }
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [PRODUCT_ACCESS_CONTROL_GROUP_CREATION_SUCCESS_MESSAGE, PRODUCT_ACCESS_CONTROL_GROUP_UPDATE_SUCCESS_MESSAGE, PRODUCT_ACCESS_CONTROL_GROUP_DELETION_MESSAGE]
    if (token && message && allowedMessages.includes(message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(getProductAccessControlGroups({ token, perPage, page, signal }))
      dismissModal('productAccessControlGroupModal')
    }
    return () => {
      controller.abort()
    }
  }, [message])

  return (
    <div className="card">
      <div className="m-4">
        <div className="navbar navbar-expand mb-3">
          <p className="h5"><i className="bi bi-collection me-2"></i>Product Access Control Groups</p>
          <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
            <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
              <Formik
                validationSchema={searchSchema}
                enableReinitialize
                initialValues={{
                  search: ''
                }}
                onSubmit={({ search }, actions) => {
                  setSearchTerm(search)
                  if (page !== 1) {
                    setPage(1)
                    navigate('')
                  }

                  actions.setSubmitting(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <input
                        onChange={(event) => {
                          const search = event.target.value
                          handleChange(event)
                          setSearchTerm(search)
                          if (page !== 1) {
                            setPage(1)
                            navigate('')
                          }
                        }}
                        maxLength={24}
                        onBlur={handleBlur}
                        value={values.search}
                        className={`form-control ${
                          errors.search && touched.search && errors.search
                            ? 'is-invalid'
                            : ''
                        }`}
                        type="text"
                        placeholder="Search..."
                        aria-label="Search"
                        aria-describedby="btnNavbarSearch"
                        name="search"
                      />
                      <button
                        className="btn btn-outline-dark"
                        id="btnNavbarSearch"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            {
              isAllowed(READWRITE) && (<button
                type="button"
                className="btn btn-outline-primary btn-sm text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#productAccessControlGroupModal"
                title="Add Access Control Group"
                aria-label="Add Access Control Group"
                onClick={() => {
                  setInitialProductAccessControlGroup({
                    name: '',
                    description: '',
                    company: null
                  })
                }}
              >
                <i className="bi bi-plus-circle"></i>
                <span className="d-none d-md-inline-block ms-1">Add Access Control Group</span>
              </button>)
            }
            <button
              type="button"
              title="Refresh"
              aria-label="Refresh"
              className="btn btn-outline-dark ms-2 ms-md-3"
              onClick={() => handleProductAccessControlGroupsRefresh()}
            >
              <i className="fas fa-redo"></i>
            </button>
          </ul>
        </div>
        {(isLoading) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
        <div className="table-responsive">
          <table className="table table-hover table-centered table-nowrap align-middle">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Name</th>
                <th scope="col" className="text-nowrap">Date Created</th>
                {isAllowed(READWRITE) && <th scope="col" className="text-end">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {
                (isLoading)
                  ? (
                      Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={2} />)
                    )
                  : (
                      productAccessControlGroups.length > 0
                        ? (productAccessControlGroups.map((productAccessControlGroup: ProductAccessControlGroup) => (
                          <tr key={productAccessControlGroup.id} className={initialProductAccessControlGroup.id === productAccessControlGroup.id ? 'table-primary' : ''}>
                            <td>
                              {productAccessControlGroup.company ? <span className="fw-semibold">Company Access Control Group <span className="small">({productAccessControlGroup.company?.name})</span></span> : <span className="fw-semibold">{'General Access Control Group'}</span>}
                            </td>
                            <td>
                              <Link title="View Product Access Control Group" className="text-decoration-none" to={`/access-controls/product-access-control-groups/${productAccessControlGroup.id}`}>
                                {productAccessControlGroup.name}
                              </Link>
                            </td>
                            <td className="text-nowrap">{dayjs(productAccessControlGroup.createdAt).format('ll')}</td>
                            {isAllowed(READWRITE) &&
                            <td className="text-end">
                              <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                <button
                                  className="btn btn-outline-dark btn-round me-2"
                                  type="button"
                                  title="Edit Product Access Control Group"
                                  data-bs-toggle="modal"
                                  data-bs-target="#productAccessControlGroupEditModal"
                                  onClick={() => {
                                    setInitialProductAccessControlGroup(productAccessControlGroup)
                                  }}
                                >
                                  <PencilIcon/>
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-round"
                                  type="button"
                                  title="Delete Product Access Control Group"
                                  data-bs-toggle="modal"
                                  data-bs-target="#productAccessControlGroupConfirmationModal"
                                  onClick={() => {
                                    setInitialProductAccessControlGroup(productAccessControlGroup)
                                  }}
                                >
                                  <TrashIcon/>
                                </button>
                              </div>
                            </td>}
                          </tr>
                          ))
                          )
                        : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No product access control groups available yet
                            </td>
                          </tr>
                          )
                    )
              }
            </tbody>
          </table>
        </div>
        <Pagination
          isLoading={isLoading}
          metadata={{
            limit: metadata.perPage,
            total: metadata.total,
            offset: ((metadata.page - 1) * (metadata.perPage))
          }}
          page={page}
          perPage={perPage}
          handlePageChange={handlePageChange}
          handleShowEntries={handleShowEntries}
          isTrackingPage
        />
      </div>

      <div className="modal fade" id="productAccessControlGroupModal" tabIndex={-1} aria-labelledby="productAccessControlGroupModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="productAccessControlGroupModalLabel">
                <i className={'bi bi-plus-circle me-1'}></i>{' '}
                {'Add Product Access Control Group'}
              </h5>
              <button type="button"
                onClick={() => {}}
                className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              >
              </button>
            </div>
            {(isLoading) && <Progress />}
            <div className="modal-body">
              <ProductAccessControlGroupEditor
                id=''
                initialProductAccessControlGroup={initialProductAccessControlGroup}
                save={addProductAccessControlGroup}
                isEdit={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="productAccessControlGroupEditModal" tabIndex={-1} aria-labelledby="productAccessControlGroupEditModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="productAccessControlGroupEditModalLabel">
                <i className={'bi bi-plus-circle me-1'}></i>{' '}
                {'Edit Product Access Control Group'}
              </h5>
              <button type="button"
                onClick={() => {}}
                className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              >
              </button>
            </div>
            {(isLoading) && <Progress />}
            <div className="modal-body">
              <ProductAccessControlGroupEditor
                id={String(initialProductAccessControlGroup.id)}
                initialProductAccessControlGroup={initialProductAccessControlGroup}
                save={editProductAccessControlGroupById}
                isEdit={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="productAccessControlGroupConfirmationModal" tabIndex={-1} aria-labelledby="productAccessControlGroupConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title text-danger" id="productAccessControlGroupConfirmationModalLabel">
                <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {(isLoading) && <Progress />}
            <div className="modal-body">
              <p>
                Are you sure you want to delete
                <span className="fw-bold">{` '${initialProductAccessControlGroup.name}' `}</span>
                access control group?
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  if (token && initialProductAccessControlGroup.id !== null) {
                    const controller = new AbortController()
                    const signal = controller.signal
                    dispatch(deleteProductAccessControlGroupById({ productAccessControlGroupId: String(initialProductAccessControlGroup.id), token, signal }))
                  }
                }}
                disabled={(isLoading)}
                data-bs-dismiss="modal"
                aria-label="Delete"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductAccessControlGroups
