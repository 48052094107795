import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CampaignAddressService from '../../../services/api/CampaignAddressService'
import { ApiCampaignOrderLimitState } from '../../../types'
import { CAMPAIGN_ADDRESS_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiCampaignOrderLimitState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteCampaignAddress = createAsyncThunk('api/campaign-address/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CampaignAddressService.deleteCampaignAddress(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const CampaignAddressSlice = createSlice({
  name: 'api/campaign-address',
  initialState,
  reducers: {
    resetCampaignAddressError: (state) => {
      state.error = null
    },
    resetCampaignAddressMessage: (state) => {
      state.message = null
    },
    resetCampaignAddressData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCampaignAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCampaignAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = CAMPAIGN_ADDRESS_DELETION_MESSAGE
      })
      .addCase(deleteCampaignAddress.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCampaignAddressError, resetCampaignAddressMessage, resetCampaignAddressData } = CampaignAddressSlice.actions

const { reducer } = CampaignAddressSlice
export default reducer
