import React, { ChangeEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import BundleConfiguratorItemLoader from './BundleConfiguratorItemLoader'
import Pagination from '../../Pagination'
import Placeholder from '../../../assets/images/placeholder.png'
import { getAllBundleConfiguratorProducts } from '../../../store/reducers/api/productReducer'
import useDebounce from '../../../utils/hooks/useDebounce'
import { getProductCategories } from '../../../store/reducers/api/productCategoryReducer'
import { getMinimumProductOrderQuantity } from '../../../utils/getMinimumProductOrderQuantity'
import { setShoppingCart, setShoppingCartBundles } from '../../../store/reducers/shoppingCartReducer'
import dayjs from 'dayjs'
import { setToast } from '../../../store/reducers/toastReducer'
import { Product } from '../../../types'

const BundleConfiguratorProductPicker = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiProduct.isLoadingBundleConfiguratorProducts)
  const products = useAppSelector((state) => state.apiProduct.bundleConfiguratorProducts)
  const metadata = useAppSelector((state) => state.apiProduct.bundleConfiguratorProductsMetadata)
  const bundleConfiguratorMode = useAppSelector((state) => state.shoppingCart.bundleConfiguratorMode)
  const shoppingCartProducts = useAppSelector((state) => state.shoppingCart.products)
  const shoppingCartBundles = useAppSelector((state) => state.shoppingCart.bundles)
  const isLoadingProductCategories = useAppSelector((state) => state.apiProductCategory.isLoading)
  const productCategories = useAppSelector((state) => state.apiProductCategory.productCategories)

  const [perPage, setPerPage] = useState(8)
  const [page, setPage] = useState(1)
  const [searchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)
  const [orderBy] = useState<string | undefined>('')

  const shoppingCartProductsWithQuantity = shoppingCartProducts.map(shoppingCartProduct => ({ ...shoppingCartProduct, quantity: shoppingCartProduct.quantity || getMinimumProductOrderQuantity((shoppingCartProduct.minimumOrderQuantity ?? 1), shoppingCartProduct.graduatedPrices) }))

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const addToProductCart = (product: Product) => {
    const availableStock = Math.max((product.stock?.stockLevel || 0) - (product.stock?.stockLevelReserved || 0), 0)
    if (product.isExceedStockEnabled || availableStock > 0) {
      const foundProductInCart = shoppingCartProductsWithQuantity.find(shoppingCartProduct => shoppingCartProduct.id === product.id)
      if (foundProductInCart === undefined) {
        const bundleQuantity = shoppingCartBundles.reduce((total, bundle) => total + bundle.quantity, 0)
        const quantity = getMinimumProductOrderQuantity(Math.max(bundleQuantity, 1), product.graduatedPrices)
        const updatedProducts = [...shoppingCartProductsWithQuantity, { ...product, quantity }]
        const payload = {
          products: updatedProducts
        }
        dispatch(setShoppingCart(payload))
        const toastPayload = {
          title: 'Info',
          message: `${product.name} added to your bundle`,
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'info',
          delay: 2000
        }
        dispatch(setToast(toastPayload))
      }
    } else {
      const toastPayload = {
        title: 'Out of Stock',
        message: `There is no available stock for ${product.name}`,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'warning',
        delay: 2000
      }
      dispatch(setToast(toastPayload))
    }
  }

  const addToBundleCart = (product: Product) => {
    const updatedShoppingCartBundles = shoppingCartBundles.map((shoppingCartBundle) => ({
      ...shoppingCartBundle,
      products: [...shoppingCartBundle.products, { ...product, quantity: 1 }],
      quantity: shoppingCartBundle.quantity
    }))

    const payload = {
      bundles: updatedShoppingCartBundles
    }

    dispatch(setShoppingCartBundles(payload))
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const page = 1
    const perPage = 10
    if (token && bundleConfiguratorMode && bundleConfiguratorMode.category) {
      dispatch(getProductCategories({ token, perPage, page, signal, search: bundleConfiguratorMode.category }))
    }

    return () => {
      controller.abort()
    }
  }, [bundleConfiguratorMode])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const productCategoryTags = productCategories.flatMap(productCategory => productCategory.productCategoryTags)
    const productCategoryTagIds = productCategoryTags
      .filter(productCategoryTag => bundleConfiguratorMode?.tags.includes(productCategoryTag.name))
      .map(productCategoryTag => productCategoryTag.id)

    if (token && productCategoryTagIds.length > 0) {
      const filter = `filter[tags]=${productCategoryTagIds}`
      dispatch(getAllBundleConfiguratorProducts({ token, perPage, page, search: debouncedSearchTerm, signal, orderBy, filter }))
    }

    return () => {
      controller.abort()
    }
  }, [page, perPage, productCategories])

  return (
    <main>
      <div className="row">
        <div className="col-12 mt-2">
          {
            (isLoadingProductCategories || isLoading)
              ? (
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 gy-4">
                  <BundleConfiguratorItemLoader repetition={perPage} />
                </div>
                )
              : products.length > 0
                ? <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 gy-4">
                  {
                    (
                      products.map((product) => (
                        <div className="col" key={`${product.id}-bundle-configurator-product-picker`}>
                          <div
                            aria-label={product.name}
                            className={`card h-100 product-card ${shoppingCartProducts.some(cartProduct => cartProduct.id === product.id) ? 'selected' : ''}`}
                          >
                            <img
                              src={product.pictures && product.pictures?.length > 0 ? product.pictures[0].publicUrl : Placeholder}
                              alt="Product thumbnail"
                              className={`card-img-top ${product.pictures && product.pictures?.length > 0 ? 'object-fit-contain object-fit-md-contain' : 'object-fit-cover'}`}
                              role="button"
                              style={{
                                height: 300
                              }}
                              tabIndex={0}
                              onClick={() => {
                                addToProductCart(product)
                                addToBundleCart(product)
                              }}
                              onError={(e) => {
                                const target = e.target as HTMLImageElement
                                target.onerror = null
                                target.src = Placeholder
                              }}
                            />
                            <div
                              className="h-100 d-flex align-content-end flex-wrap"
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                addToProductCart(product)
                                addToBundleCart(product)
                              }}
                            >
                              <div className="card-body p-2">
                                <h6 className="card-title ">{product.name}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )
                  }
                </div>
                : (
                  <div className="h-100 d-flex justify-content-center align-items-center">
                    {<p>No products available yet</p>}
                  </div>
                  )
          }
        </div>
      </div>
      <div className="row mt-4">
        <Pagination
          isLoading={isLoadingProductCategories || isLoading}
          metadata={{
            limit: metadata.perPage,
            total: metadata.total,
            offset: ((metadata.page - 1) * (metadata.perPage))
          }}
          page={page}
          perPage={perPage}
          handlePageChange={handlePageChange}
          handleShowEntries={handleShowEntries}
          perPageOptions={[8, 16]}
          module="BundleConfiguratorProductPicker"
        />
      </div>
    </main>
  )
}

export default BundleConfiguratorProductPicker
