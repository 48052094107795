import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Select from 'react-select'
import Progress from '../../components/loaders/Progress'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Pagination from '../../components/Pagination'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { AccessPermission, Permission } from '../../types'
import * as userRoles from '../../constants/userRoles'
import {
  addCompanyAccessPermission,
  getCompanies,
  getCompanyAccessPermissions, resetCompanyError, resetCompanyMessage
} from '../../store/reducers/api/companyReducer'
import AccessPermissionEditor from '../../components/AccessPermissions/AccessPermissionEditor'
import {
  ACCESS_PERMISSION_CREATION_SUCCESS_MESSAGE, ACCESS_PERMISSION_DELETION_MESSAGE,
  ACCESS_PERMISSION_UPDATE_SUCCESS_MESSAGE
} from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import {
  addAccessPermission,
  deleteAccessPermissionById, editAccessPermissionById,
  getAllAccessPermissions, getDefaultAccessPermissions, resetAccessPermissionError,
  resetAccessPermissionMessage
} from '../../store/reducers/api/accessPermissionReducer'
import * as appModules from '../../constants/appModules'
import hasPermission from '../../utils/checkPermissions'
import { READ, READWRITE, permissionsObject } from '../../constants/permissions'
import { modulesObject } from '../../constants/appModules'
import { getMyProfile } from '../../store/reducers/api/profileReducer'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { useNavigate } from 'react-router-dom'

const Permissions = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoading = useAppSelector((state) => state.apiAccessPermission.isLoading)
  const message = useAppSelector((state) => state.apiAccessPermission.message)
  const companies = useAppSelector((state) => state.apiCompany.companies)
  const isLoadingCompany = useAppSelector((state) => state.apiCompany.isLoading)
  const messageCompany = useAppSelector((state) => state.apiCompany.message)
  const accessPermissions = currentUser?.role === userRoles.ADMIN
    ? useAppSelector((state) => state.apiAccessPermission.accessPermissions)
    : useAppSelector((state) => state.apiCompany.accessPermissions)
  const metadata = currentUser?.role === userRoles.ADMIN
    ? useAppSelector((state) => state.apiAccessPermission.metadata)
    : useAppSelector((state) => state.apiCompany.accessPermissionMetadata)
  const error = useAppSelector((state) => state.apiAccessPermission.error)
  const errorCompany = useAppSelector((state) => state.apiCompany.error)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [perPageCompanies] = useState(100)
  const [pageCompanies] = useState(1)

  const [initialAccessPermission, setInitialAccessPermission] = useState<Partial<AccessPermission>>({
    name: '',
    module: '',
    permission: '',
    role: '',
    isEnabled: true,
    company: null
  })

  const [filter, setFilter] = useState<string>('')

  const token = currentUser?.token
  const userId = currentUser?.id

  const companyId = currentUser?.company?.id
  const role = currentUser?.role || userRoles.USER
  const companyAccessPermissions = profile?.company?.accessPermissions || []
  const defaultAccessPermissions = profile?.company?.defaultAccessPermissions || []
  const companyOwnerId = currentUser?.company?.owner?.id
  const isOwner = companyOwnerId && userId === companyOwnerId

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const isAllowed = (permission: Permission) => {
    return isOwner || hasPermission(appModules.ACCESSPERMISSIONS, role, companyAccessPermissions, defaultAccessPermissions, permission)
  }

  const handlePermissionsRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      if (role === userRoles.ADMIN) {
        dispatch(getAllAccessPermissions({ token, perPage, page, filter, signal }))
      } else {
        companyId && isAllowed(READ) && dispatch(getCompanyAccessPermissions({ id: companyId, token, perPage, page, signal }))
      }
    }
  }

  const filterSchema = object({
    filter: string().uuid()
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      if (role === userRoles.ADMIN) {
        dispatch(getAllAccessPermissions({ token, perPage, page, filter, signal }))
      } else {
        companyId && isAllowed(READ) && dispatch(getCompanyAccessPermissions({ id: companyId, token, perPage, page, signal }))
      }
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, filter])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if ((message || messageCompany) === ACCESS_PERMISSION_CREATION_SUCCESS_MESSAGE ||
      (message || messageCompany) === ACCESS_PERMISSION_UPDATE_SUCCESS_MESSAGE ||
      (message || messageCompany) === ACCESS_PERMISSION_DELETION_MESSAGE
    ) {
      const payload = {
        title: 'Success',
        message: message || messageCompany,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetAccessPermissionMessage())
      dispatch(resetCompanyMessage())
      dismissModal('accessPermissionModal')
      dismissModal('accessPermissionEditModal')
      if (token) {
        if (role === userRoles.ADMIN) {
          dispatch(getAllAccessPermissions({ token, perPage, page, filter, signal }))
        } else {
          companyId && isAllowed(READ) && dispatch(getCompanyAccessPermissions({ id: companyId, token, perPage, page, signal }))
        }
        dispatch(getMyProfile({ token, signal }))
      }
    }
  }, [message, messageCompany])

  useEffect(() => {
    if ((error && error?.statusCode === 422) || (errorCompany && errorCompany?.statusCode === 422)) {
      if ((error && error?.errors) || (errorCompany && errorCompany?.errors)) {
        const hasModule = (error?.errors?.details[0]?.module || errorCompany?.errors?.details[0]?.module)
        const message = error?.errors.message || errorCompany?.errors.message
        const payload = {
          title: hasModule ? 'Info' : 'Error',
          message: hasModule ? "Set 'Override default role permission' to 'Yes' to override the default permission of a Campaign Manager for this module" : message,
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: hasModule ? 'info' : 'danger'
        }
        dispatch(setToast(payload))
        dispatch(resetAccessPermissionError())
        dispatch(resetCompanyError())
      }
    } else {
      if ((error && error?.errors) || (errorCompany && errorCompany?.errors)) {
        const payload = {
          title: 'Error',
          message: error?.errors.message || errorCompany?.errors.message,
          isVisible: true,
          timestamp: dayjs().format('LT'),
          type: 'danger'
        }
        dispatch(setToast(payload))
        dispatch(resetAccessPermissionError())
        dispatch(resetCompanyError())
      }
    }
  }, [error, errorCompany])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && hasPermission(appModules.ACCESSPERMISSIONS, role, companyAccessPermissions, defaultAccessPermissions, READ)) {
      token && dispatch(getDefaultAccessPermissions({ token, signal }))
    }
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && role === userRoles.ADMIN) {
      token && dispatch(getCompanies({ token, perPage: perPageCompanies, page: pageCompanies, signal }))
    }
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-shield-lock-fill me-1"></i>Access Permissions</p>
              <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                {
                  role === userRoles.ADMIN && (
                    <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                    <Formik
                      validationSchema={filterSchema}
                      enableReinitialize
                      initialValues={{
                        companyId: ''
                      }}
                      onSubmit={({ companyId }, actions) => {
                        setFilter(companyId)
                        if (page !== 1) {
                          setPage(1)
                          navigate('')
                        }

                        actions.setSubmitting(false)
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleBlur,
                        handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Select
                            className={`${
                              errors.companyId &&
                              touched.companyId &&
                              errors.companyId
                                ? 'is-invalid'
                                : ''
                            }`}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor,
                                '&:hover': {
                                  borderColor: (errors.companyId && touched.companyId) ? '#dc3545' : provided.borderColor
                                },
                                minWidth: 180,
                                '@media (min-width: 992px)': {
                                  minWidth: 200
                                },
                                '@media (min-width: 1200px)': {
                                  minWidth: 250
                                }
                              })
                            }}
                            isClearable
                            inputId="companyId"
                            name="companyId"
                            aria-label="Company"
                            options={companies}
                            getOptionLabel={(company) => `${company.name}`}
                            getOptionValue={(company) => String(company.id)}
                            onChange={(selectedOption) => {
                              const selectedCompanyId = selectedOption?.id ?? ''
                              setFieldValue('companyId', selectedCompanyId)
                              setFilter(selectedCompanyId)
                              if (page !== 1) {
                                setPage(1)
                                navigate('')
                              }
                            }}
                            onBlur={handleBlur}
                            isLoading={isLoadingCompany}
                          />
                        </form>
                      )}
                    </Formik>
                  </div>
                  )
                }
                {
                  isAllowed(READWRITE) && (<button
                    type="button"
                    className="btn btn-outline-primary btn-sm text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#accessPermissionModal"
                    title="Add Access Permission"
                    aria-label="Add Access Permission"
                    onClick={() => {
                      setInitialAccessPermission({
                        name: '',
                        module: '',
                        role: '',
                        permission: '',
                        isEnabled: true,
                        company: {
                          id: '',
                          customerId: 0,
                          name: '',
                          email: '',
                          phone: '',
                          vat: '',
                          domain: ''
                        }
                      })
                    }}
                  >
                    <i className="bi bi-plus-circle"></i>
                    <span className="d-none d-md-inline-block ms-1">Add Access Permission</span>
                  </button>)
                }
                <button
                  type="button"
                  title="Refresh"
                  aria-label="Refresh"
                  className="btn btn-outline-dark ms-2 ms-md-3"
                  onClick={() => handlePermissionsRefresh()}
                >
                  <i className="fas fa-redo"></i>
                </button>
              </ul>
            </div>
            {(isLoading || isLoadingCompany) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Name</th>
                    <th scope="col">Role</th>
                    <th scope="col">Permission</th>
                    <th scope="col">Module</th>
                    <th scope="col">Enabled</th>
                    <th scope="col" className="text-nowrap">Date Created</th>
                    {isAllowed(READWRITE) && <th scope="col" className="text-end">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    (isLoading || isLoadingCompany)
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={(isAllowed(READWRITE) ? 8 : 7)} actionQuantity={isAllowed(READWRITE) ? 2 : 0} />)
                        )
                      : (
                          accessPermissions.length > 0
                            ? (accessPermissions.map((accessPermission: AccessPermission) => (
                              <tr key={accessPermission.id} className={initialAccessPermission.id === accessPermission.id ? 'table-primary' : ''}>
                                <td>
                                  {accessPermission.company ? <span className="fw-semibold">Company Role Permission <span className="small">({accessPermission.company?.name})</span></span> : <span className="fw-semibold">{'Default Role Permission'}</span>}
                                </td>
                                <td>{accessPermission.name}</td>
                                <td>{userRoles.rolesObject[accessPermission.role]}</td>
                                <td>{permissionsObject[accessPermission.permission]}</td>
                                <td>{modulesObject[accessPermission.module]}</td>
                                <td className="text-center">
                                  <span>
                                    {accessPermission.isEnabled ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>}
                                  </span>
                                </td>
                                <td className="text-nowrap">{dayjs(accessPermission.createdAt).format('ll')}</td>
                                {isAllowed(READWRITE) &&
                                <td className="text-end">
                                  <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                    <button
                                      className="btn btn-outline-dark btn-round me-2"
                                      type="button"
                                      title="Edit Access Permission"
                                      data-bs-toggle="modal"
                                      data-bs-target="#accessPermissionEditModal"
                                      onClick={() => {
                                        setInitialAccessPermission(accessPermission)
                                      }}
                                      disabled={accessPermission.company === null}
                                    >
                                      <PencilIcon/>
                                    </button>
                                    <button
                                      className="btn btn-outline-danger btn-round"
                                      type="button"
                                      title="Delete Access Permission"
                                      data-bs-toggle="modal"
                                      data-bs-target="#accessPermissionConfirmationModal"
                                      onClick={() => {
                                        setInitialAccessPermission(accessPermission)
                                      }}
                                      disabled={accessPermission.company === null}
                                    >
                                      <TrashIcon/>
                                    </button>
                                  </div>
                                </td>}
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={(isAllowed(READWRITE) ? 8 : 7)} className="text-center">
                                  No access permissions available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading || isLoadingCompany}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              isTrackingPage
            />
          </div>

          <div className="modal fade" id="accessPermissionModal" tabIndex={-1} aria-labelledby="accessPermissionModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="accessPermissionModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Add Access Permission'}
                  </h5>
                  <button type="button"
                    onClick={() => dispatch(resetAccessPermissionError())}
                    className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  >
                  </button>
                </div>
                {(isLoading || isLoadingCompany) && <Progress />}
                <div className="modal-body">
                  <AccessPermissionEditor
                    id={currentUser?.role === userRoles.ADMIN ? '' : String(companyId)}
                    initialAccessPermission={initialAccessPermission}
                    save={role === userRoles.ADMIN ? addAccessPermission : addCompanyAccessPermission}
                    isEdit={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="accessPermissionEditModal" tabIndex={-1} aria-labelledby="accessPermissionEditModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="accessPermissionEditModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Edit Access Permission'}
                  </h5>
                  <button type="button"
                    onClick={() => dispatch(resetAccessPermissionError())}
                    className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  >
                  </button>
                </div>
                {(isLoading || isLoadingCompany) && <Progress />}
                <div className="modal-body">
                  <AccessPermissionEditor
                    id={String(initialAccessPermission.id)}
                    initialAccessPermission={initialAccessPermission}
                    save={editAccessPermissionById}
                    isEdit={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="accessPermissionConfirmationModal" tabIndex={-1} aria-labelledby="accessPermissionConfirmationModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <h5 className="modal-title text-danger" id="accessPermissionConfirmationModalLabel">
                    <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                {(isLoading || isLoadingCompany) && <Progress />}
                <div className="modal-body">
                  <p>
                    Are you sure you want to delete
                    <span className="fw-bold">{` '${initialAccessPermission.name}' `}</span>
                    access permission?
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      if (token && initialAccessPermission.id !== null) {
                        const controller = new AbortController()
                        const signal = controller.signal
                        dispatch(deleteAccessPermissionById({ accessPermissionId: String(initialAccessPermission.id), token, signal }))
                      }
                    }}
                    disabled={(isLoading || isLoadingCompany)}
                    data-bs-dismiss="modal"
                    aria-label="Delete"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Permissions
