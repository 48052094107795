import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import EmailTemplateTypeService from '../../../services/api/EmailTemplateTypeService'
import { ApiEmailTemplateTypeState } from '../../../types'

const initialState: ApiEmailTemplateTypeState = {
  isLoading: false,
  error: null,
  emailTemplateType: null,
  emailTemplateTypes: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getEmailTemplateTypes = createAsyncThunk('api/email-template-types/get', async ({ token, page, perPage, signal }:{token: string, page: number, perPage: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await EmailTemplateTypeService.getEmailTemplateTypes(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const emailTemplateTypeSlice = createSlice({
  name: 'api/email-template-types',
  initialState,
  reducers: {
    resetEmailTemplateTypeError: (state) => {
      state.error = null
    },
    resetEmailTemplateTypeMessage: (state) => {
      state.message = null
    },
    resetEmailTemplateTypeData: (state) => {
      state.error = null
      state.emailTemplateType = null
      state.emailTemplateTypes = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmailTemplateTypes.pending, (state) => {
        state.emailTemplateTypes = []
        state.isLoading = true
      })
      .addCase(getEmailTemplateTypes.fulfilled, (state, action) => {
        state.isLoading = false
        state.emailTemplateTypes = action.payload.emailTemplateTypes
        state.error = null
      })
      .addCase(getEmailTemplateTypes.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetEmailTemplateTypeError, resetEmailTemplateTypeMessage, resetEmailTemplateTypeData } = emailTemplateTypeSlice.actions

const { reducer } = emailTemplateTypeSlice
export default reducer
