import React from 'react'
import { object, string } from 'yup'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ProductCategoryTag } from '../../types'
import { addTagToProductCategory } from '../../store/reducers/api/productCategoryReducer'
import { editProductCategoryTagById } from '../../store/reducers/api/productCategoryTagReducer'

dayjs.extend(utc)

type ProductCategoryTagEditorProps = {
  id: string, initialProductCategoryTag: Partial<ProductCategoryTag>
  isEdit: boolean
  isLoading: boolean
}

const ProductCategoryTagEditor = ({ id, initialProductCategoryTag, isEdit, isLoading }: ProductCategoryTagEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const productCategorySchema = object({
    name: string().label('Name').required().max(64),
    type: string().label('Type').oneOf(['category', 'security']).default('category')
  })

  const saveProductCategoryTag = (token: string, id: string, productCategoryTag: Partial<ProductCategoryTag>, signal: AbortSignal) => {
    if (isEdit) {
      if (initialProductCategoryTag && initialProductCategoryTag.id) {
        const data = {
          name: productCategoryTag.name,
          type: productCategoryTag.type
        }
        dispatch(editProductCategoryTagById({ productCategoryTagId: initialProductCategoryTag.id, token, productCategoryTag: data, signal }))
      }
    } else {
      dispatch(addTagToProductCategory({ productCategoryId: id, token, productCategoryTag, signal }))
    }
  }

  return (
    <div>
      <Formik
        validationSchema={productCategorySchema}
        enableReinitialize
        initialValues={{
          ...initialProductCategoryTag
        }}
        onSubmit={(
          { name, type },
          actions
        ) => {
          const controller = new AbortController()
          const signal = controller.signal

          const productCategoryTag = {
            name,
            type
          }

          if (token) {
            saveProductCategoryTag(token, id, productCategoryTag, signal)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="productCategoryTagName"
                    className="form-label"
                  >
                    Name
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    className={`form-control ${
                      (touched.name &&
                      errors.name)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="productCategoryTagName"
                    name="name"
                    placeholder=""
                    maxLength={64}
                    autoComplete="off"
                  />
                  <div
                    id="validationProductCategoryTagNameFeedback"
                    className="invalid-feedback"
                  >
                    {errors.name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label
                    htmlFor="productCategoryTagType"
                    className="form-label"
                  >
                    Type
                  </label>
                  <select
                    aria-label="Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type || ''}
                    className={`form-select ${
                      touched.type &&
                      errors.type
                        ? 'is-invalid'
                        : ''
                    }`}
                    id="productCategoryTagType"
                    name="type"
                  >
                    <option value={''}>Select Type</option>
                    <option value="category">category</option>
                    <option value="security">security</option>
                  </select>
                  <div
                    id="validationProductCategoryTagTypeFeedback"
                    className="invalid-feedback"
                  >
                    {errors.type}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={
                  isSubmitting || isLoading
                }
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ProductCategoryTagEditor
