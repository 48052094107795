import dayjs from 'dayjs'
import { DaysOfWeek } from '../enums/daysOfTheWeek'

export const getDateOfDispatchMinimumDays = () => {
  let daysAdded = 0
  let businessDaysAdded = 0
  let currentDay = dayjs()

  while (businessDaysAdded < 3) {
    currentDay = currentDay.add(1, 'day')
    daysAdded++

    // Only count it as a business day if it's not Saturday (6) or Sunday (0)
    if (currentDay.day() !== DaysOfWeek.Saturday && currentDay.day() !== DaysOfWeek.Sunday) {
      businessDaysAdded++
    }
  }

  return daysAdded
}
