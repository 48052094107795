import React, { useEffect, useState } from 'react'
import { FieldArray, Formik } from 'formik'
import { object, string, date, array } from 'yup'
import dayjs from 'dayjs'
import Calendar from 'react-datepicker'
import { countries, countriesObject } from '../../utils/countries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { PendingOrder, PendingOrderShippingAddress, Salutation } from '../../types'
import { getMyProfile } from '../../store/reducers/api/profileReducer'
import {
  getAllSalutations
} from '../../store/reducers/api/salutationReducer'
import Progress from '../loaders/Progress'
import { updatePendingOrderById } from '../../store/reducers/api/dotnet/pendingOrderReducer'
import PendingOrderLinesViewer from '../PendingOrderLines/PendingOrderLinesViewer'
import { isWeekday } from '../../utils/isWeekday'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'
import { phoneValidationPattern } from '../../constants/regexPatterns'
import { getDateOfDispatchMinimumDate } from '../../utils/getDateOfDispatchMinimumDate'
import { getDateOfDispatchMinimumDays } from '../../utils/getDateOfDispatchMinimumDays'

interface PendingOrderEditorProps {
  pendingOrder: Partial<PendingOrder>
  shippingAddresses: Partial<Array<PendingOrderShippingAddress>>
}

const PendingOrderEditor = ({ pendingOrder, shippingAddresses }: PendingOrderEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const salutations = useAppSelector(
    (state) => state.apiSalutation.salutations
  )
  const salutation = useAppSelector((state) => state.apiSalutation.salutation)
  const salutationMessage = useAppSelector(
    (state) => state.apiSalutation.message
  )
  const salutationError = useAppSelector((state) => state.apiSalutation.error)
  const error = useAppSelector((state) => state.dotnetApiPendingOrder.error)
  const isUpdatingOrder = useAppSelector((state) => state.dotnetApiPendingOrder.isLoading)

  const [isEditDisabled, setIsEditDisabled] = useState(true)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const getCountry = (value: string | undefined) => {
    if (value) {
      const countryValue = (value).toUpperCase()
      return countriesObject
        .find(item => item.alpha2Code === countryValue ||
          item.alpha3Code === countryValue ||
          item.country.toUpperCase() === countryValue ||
          item.countryGerman.toUpperCase() === countryValue)?.country
    }
    return undefined
  }

  const orderSchema = object({
    shippingAddresses: array().of(object({
      company: string().nullable(),
      salutation: string().nullable(),
      firstName: string()
        .min(2, (value) => `First Name must be at least ${value.min} characters`)
        .max(32, (value) => `First Name must be at most ${value.max} characters`)
        .when(['company'], {
          is: (company: string) => !company,
          then: string().required('First Name is required'),
          otherwise: string()
        }),
      lastName: string()
        .min(2, (value) => `Last Name must be at least ${value.min} characters`)
        .max(32, (value) => `Last Name must be at most ${value.max} characters`)
        .when(['company'], {
          is: (company: string) => !company,
          then: string().required('Last Name is required'),
          otherwise: string()
        }),
      email: string().email('Enter a valid email').required('Email is required'),
      phone: string()
        .nullable()
        .matches(phoneValidationPattern, 'Enter a valid phone number'),
      country: string().required('Country is required')
        .oneOf(countries)
        .oneOf(countriesObject.map(country => country.alpha2Code))
        .oneOf(countriesObject.map(country => country.alpha3Code))
        .oneOf(countriesObject.map(country => country.countryGerman)),
      street: string().required('Street and House Number are required'),
      city: string().required('City is required'),
      zip: string().label('Zip Code').required()
    })).min(1),
    shippingDate: date().label('Shipping Date').required()
      .typeError('Shipping Date must be a valid date')
      .min(dayjs().startOf('day').add(getDateOfDispatchMinimumDays(), 'days').toDate(), (value) => `Shipping Date field must be later than ${dayjs(value.min).format('YYYY-MM-DD HH:mm')}`),
    hasAgreed: string().oneOf(['yes'], 'We want to make sure you understand our policies. Please read and agree to our terms and conditions before continuing.')
  })

  const getError = (errors: any, fieldName: string) => {
    return errors.map((value: any) => value && value[fieldName])
  }
  const getTouched = (touched: any, fieldName: string) => {
    return touched.map((value: any) => value && value[fieldName])
  }

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (currentUser?.token) {
      const { token } = currentUser
      dispatch(getMyProfile({ token, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getAllSalutations({ token, perPage: 20, page: 1, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [salutation, salutationMessage])

  useEffect(() => {
    setIsEditDisabled(Boolean(pendingOrder.isPosted || pendingOrder.isQueued))
  }, [pendingOrder])

  return (
    <>
      <div className="row mb-3 border rounded p-1 mx-0 position-relative">
        <div className="col my-2">
          {pendingOrder.note && <span className="position-absolute top-0 start-0 translate-middle p-1 bg-danger border border-light rounded-circle">
            <span className="visually-hidden">Has a note</span>
          </span>}
          <h5 className="text-primary">Note</h5>
          <span className="user-select-all p" onClick={() => navigator.clipboard.writeText(String(pendingOrder.note))}>
            {pendingOrder.note || 'No note available'}
          </span>
        </div>
      </div>
      <div className="row mb-3 border rounded p-1 mx-0">
        <div className="col mt-2">
          <h5 className="text-primary">Items</h5>
          <PendingOrderLinesViewer pendingOrderLines={pendingOrder.pendingOrderLines ?? []} />
        </div>
      </div>
      <div className="row border rounded p-1 mx-0">
        <div className="col my-2">
          <Formik
            validationSchema={orderSchema}
            key={pendingOrder.id}
            enableReinitialize
            initialValues={{
              shippingAddresses: shippingAddresses.map(shippingAddress => (
                {
                  id: shippingAddress?.id,
                  company: shippingAddress?.company || '',
                  salutation: shippingAddress?.salutation || '',
                  firstName: shippingAddress?.firstName || '',
                  lastName: shippingAddress?.lastName || '',
                  email: shippingAddress?.email || '',
                  phone: shippingAddress?.phone || '',
                  street: shippingAddress?.street || '',
                  zip: shippingAddress?.zipCode || '',
                  city: shippingAddress?.place || '',
                  addressAddition: shippingAddress?.addressAddition || '',
                  country: shippingAddress?.country || ''
                }
              )),
              shippingDate: dayjs.utc(pendingOrder.deliveryDate).local().toDate(),
              hasAgreed: 'no'
            }}
            onSubmit={(values, actions) => {
              const controller = new AbortController()
              const signal = controller.signal

              const pendingOrderUpdated = {
                deliveryDate: dayjs(values.shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes').format(),
                shippingAddresses: values.shippingAddresses.map(shippingAddress => ({
                  id: shippingAddress.id,
                  salutation: shippingAddress.salutation,
                  firstName: shippingAddress.firstName,
                  lastName: shippingAddress.lastName,
                  company: shippingAddress.company,
                  street: shippingAddress.street,
                  addressAddition: shippingAddress.addressAddition,
                  zipCode: shippingAddress.zip,
                  place: shippingAddress.city,
                  phone: shippingAddress.phone,
                  country: shippingAddress.country,
                  email: shippingAddress.email
                }))
              }

              if (token && pendingOrder.id) {
                dispatch(updatePendingOrderById({ id: pendingOrder.id, token, pendingOrder: pendingOrderUpdated, signal }))
              }
              actions.setSubmitting(false)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <FieldArray
                  name='shippingAddresses'
                  render={arrayHelpers => (
                    <>
                    {
                      values.shippingAddresses.map((shippingAddress, index) => (
                        <div key={shippingAddress.id}>
                          <h5 className="text-primary">Shipping Address {index + 1}</h5>
                          <div className="row mb-2">
                            <div className="col">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.company`} className="form-label">
                                  Company Name
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={shippingAddress.company}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'company')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'company')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.company`}
                                  name={`shippingAddresses.${index}.company`}
                                  placeholder="Enter company name"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationCompanyNameFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'company')) || error?.errors?.company}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.salutation`} className="form-label">
                                  Salutation
                                </label>
                                <div className="input-group">
                                  <select
                                    aria-label="Salutation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={shippingAddress.salutation}
                                    className={`form-select ${
                                      (errors.shippingAddresses && getError(errors.shippingAddresses, 'salutation')[index]) &&
                                      (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'salutation')[index])
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    id={`shippingAddresses.${index}.salutation`}
                                    autoComplete="on"
                                    disabled={isEditDisabled}
                                  >
                                    <option value="">Select Salutation</option>
                                    {salutations.map(
                                      (salutation: Salutation, index: number) => (
                                        <option key={index}>
                                          {salutation.title}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div
                                  id={`validationSalutationFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'salutation')) ||
                                    salutationError?.errors?.salutation}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.firstName`} className="form-label">
                                  First Name
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={shippingAddress.firstName}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'firstName')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'firstName')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.firstName`}
                                  placeholder="Enter first name"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationFirstNameFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'firstName')) || error?.errors?.firstName}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.lastName`} className="form-label">
                                  Last Name
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={shippingAddress.lastName}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'lastName')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'lastName')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.lastName`}
                                  placeholder="Enter last name"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationLastNameFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'lastName')) || error?.errors?.lastName}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.email`} className="form-label">
                                  Email Address
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="email"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'email')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'email')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  value={shippingAddress.email}
                                  id={`shippingAddresses.${index}.email`}
                                  name={`shippingAddresses.${index}.email`}
                                  placeholder="Enter Email Address"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationEmailFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'email')) || error?.errors?.email}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.phone`} className="form-label">
                                  Phone
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={shippingAddress.phone}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'phone')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'phone')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.phone`}
                                  placeholder="Enter Phone Number"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationPhoneFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'phone')) || error?.errors?.phone}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.street`} className="form-label">
                                  Street and House Number
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={shippingAddress.street}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'street')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'street')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.street`}
                                  name={`shippingAddresses.${index}.street`}
                                  placeholder="Enter street and House Number"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationOrderStreetFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'street')) || error?.errors?.street}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.zip`} className="form-label">
                                  Zip
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={shippingAddress.zip}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'zip')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'zip')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.zip`}
                                  name={`shippingAddresses.${index}.zip`}
                                  placeholder="Enter zip"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationOrderZipFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'zip')) || error?.errors?.zip}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.city`} className="form-label">
                                  City
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'city')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'city')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  value={shippingAddress.city}
                                  id={`shippingAddresses.${index}.city`}
                                  name={`shippingAddresses.${index}.city`}
                                  placeholder="Enter city"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationOrderCityFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'city')) || error?.errors?.city}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor={`shippingAddresses.${index}.country`} className="form-label">
                                  Country
                                </label>
                                <select
                                  aria-label="Country"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={getCountry(shippingAddress.country)}
                                  className={`form-select ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'country')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'country')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.country`}
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country: string, index: number) => (
                                    <option key={index}>{country}</option>
                                  ))}
                                </select>
                                <div
                                  id={`validationOrderCountryFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'country')) || error?.errors?.country}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="mb-3">
                                <label
                                  htmlFor={`shippingAddresses.${index}.addressAddition`}
                                  className="form-label"
                                >
                                  Address Addition
                                </label>
                                <input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={shippingAddress.addressAddition}
                                  type="text"
                                  className={`form-control ${
                                    (errors.shippingAddresses && getError(errors.shippingAddresses, 'addressAddition')[index]) &&
                                    (touched.shippingAddresses && getTouched(touched.shippingAddresses, 'addressAddition')[index])
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  id={`shippingAddresses.${index}.addressAddition`}
                                  name={`shippingAddresses.${index}.addressAddition`}
                                  placeholder="Enter Address Addition"
                                  autoComplete="on"
                                  disabled={isEditDisabled}
                                />
                                <div
                                  id={`validationOrderAddressAdditionFeedback${index}`}
                                  className="invalid-feedback"
                                >
                                  {(errors.shippingAddresses && getError(errors.shippingAddresses, 'addressAddition')) ||
                                    error?.errors?.addressAddition}
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      ))
                    }
                    </>
                  )}
                />

                <hr />

                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="shippingDate" className="form-label">
                        Shipping Date
                      </label>
                      <div
                        className={`${
                          errors.shippingDate &&
                          touched.shippingDate
                            ? 'is-invalid'
                            : ''
                        }`}
                      >
                      <Calendar
                        id="shippingDate"
                        name="shippingDate"
                        onChange={(date: Date) => {
                          setFieldValue('shippingDate', date)
                        }}
                        selected={values.shippingDate}
                        className={`form-control ${
                          errors.shippingDate &&
                          touched.shippingDate
                            ? 'is-invalid'
                            : ''
                        }`}
                        minDate={getDateOfDispatchMinimumDate()}
                        dateFormat={'dd/MM/yyyy'}
                        autoComplete={'off'}
                        filterDate={isWeekday}
                        calendarStartDay={DaysOfWeek.Monday}
                        placeholderText="Select a weekday"
                        disabled={isEditDisabled}
                      />
                      </div>
                      <div
                        id="validationShippingDateFeedback"
                        className="invalid-feedback"
                      >
                        {errors.shippingDate || error?.errors?.shippingDate}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <p className="mt-2">
                        By updating this order, you agree to our <button className="btn btn-link text-primary m-0 p-0" data-bs-toggle="modal" type="button" data-bs-target="#termsModal">terms and conditions</button>.
                      </p>
                      <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                        <input
                          className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                          type="radio"
                          name="hasAgreed"
                          id="inlineRadioYes"
                          onChange={handleChange}
                          value="yes"
                          autoComplete="off"
                          disabled={isEditDisabled}
                          checked={values.hasAgreed === 'yes'}
                        />
                        <label className="form-check-label" htmlFor="inlineRadioYes">Yes</label>
                      </div>
                      <div className={`form-check form-check-inline ${errors.hasAgreed ? 'is-invalid' : ''}`}>
                        <input
                          className={`form-check-input ${errors.hasAgreed ? 'is-invalid' : ''}`}
                          type="radio"
                          name="hasAgreed"
                          id="inlineRadioNo"
                          onChange={handleChange}
                          value="no"
                          autoComplete="off"
                          disabled={isEditDisabled}
                          checked={values.hasAgreed === 'no'}
                        />
                        <label className="form-check-label" htmlFor="inlineRadioNo">No</label>
                      </div>
                      <div
                        id="validationHasAgreedFeedback"
                        className="invalid-feedback"
                      >
                        {errors.hasAgreed}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-top">
                {(isUpdatingOrder) && <Progress marginBottom={false} />}
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-secondary mt-2 me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                      disabled={isUpdatingOrder || isSubmitting || isEditDisabled}
                    >
                      {isUpdatingOrder ? 'Updating' : 'Update Order'}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default PendingOrderEditor
