import dayjs from 'dayjs'
import { Order, shipping } from '../types'

export const getShippedDate = (order: Order): string => {
  const shipmentData = order.shipments
  if (order.status === 'Shipped') {
    if (shipmentData.length > 0) {
      const shippedKeywords = ['onward transport', "Shipment arrived in the recipient's region", 'letter center']
      const allEvents = shipmentData.flatMap((data) => data.data[0].events)
      let foundEvents: shipping.Event[] = []
      const foundEventsWithCenterOfOrigin = allEvents.filter((event) => event.statusCode === 'transit' && event.description?.includes('center of origin'))
      const foundEventsWithKeywords = allEvents.filter((event) => event.statusCode === 'transit' && shippedKeywords.some(keyword => event.description?.includes(keyword)))

      if (foundEventsWithCenterOfOrigin.length > 0) {
        foundEvents = foundEventsWithCenterOfOrigin
      } else {
        foundEvents = foundEventsWithKeywords
      }

      if (foundEvents.length > 0) {
        const shippedDates = foundEvents.map(foundEvent => dayjs(foundEvent.timestamp))
        const latestDate = dayjs.max(shippedDates)

        return dayjs(latestDate).format('DD/MM/YYYY')
      }
    }
  }
  return ''
}

export const getDeliveryDate = (order: Order): string => {
  const shipmentData = order.shipments
  if (order.status === 'Shipped') {
    if (shipmentData.length > 0) {
      const allEvents = shipmentData.flatMap((data) => data.data[0].events)
      const foundEvents = allEvents.filter((event) => event.statusCode === 'delivered')

      if (foundEvents.length > 0) {
        const shippedDates = foundEvents.map(foundEvent => dayjs(foundEvent.timestamp))
        const latestDate = dayjs.max(shippedDates)

        return dayjs(latestDate).format('DD/MM/YYYY')
      }
    }
  }
  return ''
}
