import React from 'react'
import Placeholder from '../../assets/images/placeholder.png'

const SimilarProductLoader = ({ repetition }: { repetition: number }) => {
  return (
    <>
      {Array.from(Array(repetition).keys()).map((n: number) => (
        <div className="col rounded" key={n}>
          <img
            src={Placeholder}
            alt="Similar Product Loader"
            className="img-fluid rounded border h-100"
            style={{
              objectFit: 'cover',
              maxHeight: 180
            }}
            role="button"
            title="Loader"
          />
        </div>
      ))}
    </>
  )
}

export default SimilarProductLoader
