import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserInCompanyUserGroupService from '../../../services/api/UserInCompanyUserGroupService'
import { ApiUserInCompanyUserGroupState } from '../../../types'
import { USER_COMPANY_USER_GROUP_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiUserInCompanyUserGroupState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteUserInCompanyUserGroup = createAsyncThunk('api/user-company-user-group/delete', async ({ token, userCompanyUserGroupId, signal }: { token: string, userCompanyUserGroupId: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await UserInCompanyUserGroupService.deleteUserInCompanyUserGroup(token, userCompanyUserGroupId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const userInCompanyUserGroupSlice = createSlice({
  name: 'api/user-company-user-group',
  initialState,
  reducers: {
    resetUserInCompanyUserGroupError: (state) => {
      state.error = null
    },
    resetUserInCompanyUserGroupMessage: (state) => {
      state.message = null
    },
    resetUserInCompanyUserGroupData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUserInCompanyUserGroup.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteUserInCompanyUserGroup.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = USER_COMPANY_USER_GROUP_DELETION_MESSAGE
      })
      .addCase(deleteUserInCompanyUserGroup.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetUserInCompanyUserGroupError, resetUserInCompanyUserGroupMessage, resetUserInCompanyUserGroupData } = userInCompanyUserGroupSlice.actions

const { reducer } = userInCompanyUserGroupSlice
export default reducer
