import React, { useEffect } from 'react'
import CompanyAssignment from './steps/CompanyAssignment'
import { User, CompanyMode, Role } from '../../types'
import CompanyCreation from './steps/CompanyCreation'

interface AssignOrCreateCompanyProps {
  setMode: Function
  setTitle: Function
  userId: string | null
  role: Role
  token: string | null
  profile: User | null
  companyMode: CompanyMode
  setCompanyMode: Function
  editBillingOrDeliveryAddress: boolean
  setEditBillingOrDeliveryAddress: Function
}
const AssignOrCreateCompany = ({
  setMode,
  setTitle,
  userId,
  role,
  token,
  profile,
  companyMode,
  setCompanyMode,
  editBillingOrDeliveryAddress,
  setEditBillingOrDeliveryAddress
}: AssignOrCreateCompanyProps) => {
  useEffect(() => {
    if (companyMode === 'companyAssignment') {
      setTitle('Assign to Company')
    } else {
      setTitle(`${profile?.company ? 'View' : 'Create'} Company`)
    }
  }, [companyMode])

  if (companyMode === 'companyCreation') {
    return <CompanyCreation setMode={setMode} setTitle={setTitle} setCompanyMode={setCompanyMode} company={profile?.company} setEditBillingOrDeliveryAddress={setEditBillingOrDeliveryAddress} editBillingOrDeliveryAddress={editBillingOrDeliveryAddress} token={token} profile={profile} />
  }
  return (
    <CompanyAssignment setMode={setMode} setTitle={setTitle} company={profile?.company} userId={userId} role={role} token={token} setCompanyMode={setCompanyMode} editBillingOrDeliveryAddress={editBillingOrDeliveryAddress} profile={profile} />
  )
}

export default AssignOrCreateCompany
