import React, { useState } from 'react'
import { Formik } from 'formik'
import { object, date } from 'yup'
import dayjs from 'dayjs'
import Calendar from 'react-datepicker'
import { Order } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { duplicateOrders } from '../../../store/reducers/api/pendingOrderQueueReducer'
import { isWeekday } from '../../../utils/isWeekday'
import { DaysOfWeek } from '../../../enums/daysOfTheWeek'
import { getDateOfDispatchMinimumDate } from '../../../utils/getDateOfDispatchMinimumDate'
import { getDateOfDispatchMinimumDays } from '../../../utils/getDateOfDispatchMinimumDays'

interface PendingOrderDuplicateEditorProps {
  isLoading: boolean
  order: Order
}
const PendingOrderDuplicateEditor = ({ isLoading, order } : PendingOrderDuplicateEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const [shippingDate, setShippingDate] = useState<Date | null>(null)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const pendingOrderDuplicateSchema = object({
    shippingDate: date()
      .typeError('Shipping Date is required')
      .label('Shipping Date')
      .min(dayjs().startOf('day').add(getDateOfDispatchMinimumDays(), 'days').toDate(), (value) => `Shipping Date field must be later than ${dayjs(value.min).format('YYYY-MM-DD HH:mm')}`)
  })
  return (
    <div>
      <Formik
        validationSchema={pendingOrderDuplicateSchema}
        enableReinitialize
        initialValues={{
          shippingDate
        }}
        onSubmit={({ shippingDate }, actions) => {
          const postedOrderId = order.attributes.find(item => item.key === 'order_id' || item.key === 'FFN-Externe-Nummer')
          if (token && postedOrderId) {
            const controller = new AbortController()
            const signal = controller.signal
            const postedOrders = [{
              orderId: postedOrderId.value,
              shipped: dayjs(shippingDate).set('hour', dayjs().hour()).set('minute', dayjs().minute()).add(15, 'minutes').utc().toDate()
            }]
            dispatch(duplicateOrders({ token, postedOrders, signal }))
          }

          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="shippingDate" className="form-label">
                    Shipping Date
                  </label>
                  <div
                    className={`${
                      errors.shippingDate &&
                      touched.shippingDate
                        ? 'is-invalid'
                        : ''
                    }`}
                  >
                    <Calendar
                      id="shippingDate"
                      name="shippingDate"
                      onChange={(date: Date) => {
                        setShippingDate(date)
                      }}
                      selected={values.shippingDate}
                      className={`form-control ${
                        errors.shippingDate &&
                        touched.shippingDate
                          ? 'is-invalid'
                          : ''
                      }`}
                      minDate={(getDateOfDispatchMinimumDate())}
                      dateFormat={'dd/MM/yyyy'}
                      autoComplete={'off'}
                      filterDate={isWeekday}
                      calendarStartDay={DaysOfWeek.Monday}
                      placeholderText="Select a weekday"
                    />
                  </div>
                  <div
                    id="validationShippingDateFeedback"
                    className="invalid-feedback"
                  >
                    {errors.shippingDate}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">Close</button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading || isSubmitting}
              >
                Duplicate Order
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default PendingOrderDuplicateEditor
