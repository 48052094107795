import axios from 'axios'

const baseURL = process.env.REACT_APP_DOTNET_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/v1/order`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  createOrder (token: string, order: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post('/createOrder', order, config)
  }
}
