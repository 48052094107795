import React, { ChangeEvent } from 'react'
import Pagination from '../Pagination'
import { ApiMetadata, Recipient } from '../../types'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { openModal } from '../../utils/openModal'
import { dismissModal } from '../../utils/dismissModal'

interface AddressPickerProps {
  recipientAddresses: Recipient[]
  metadata: ApiMetadata
  isLoading: boolean
  handleShowEntries: (event: ChangeEvent<HTMLSelectElement>) => void
  handlePageChange: (page: number) => void
  page: number
  perPage: number
  setSelectedRecipient: React.Dispatch<React.SetStateAction<Partial<Recipient> | null>>
  selectedRecipient: Partial<Recipient> | null
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  setIsAddingNewRecipient: React.Dispatch<React.SetStateAction<boolean>>
  isAllowedToWriteRecipients: boolean
}
const AddressPicker = ({
  recipientAddresses, metadata, isLoading,
  handlePageChange, handleShowEntries,
  page, perPage,
  setSelectedRecipient, selectedRecipient,
  setSearchTerm, searchTerm,
  setIsAddingNewRecipient, isAllowedToWriteRecipients
} : AddressPickerProps) => {
  const searchSchema = object({
    search: string()
      .max(256, 'Search Name is too long')
  })

  return (
    <div className="row">
      <div className="col">
        <div className="p-0">
          <div>
            <div className="mb-4 mt-2">
              <div className="">
                <Formik
                  validationSchema={searchSchema}
                  enableReinitialize
                  initialValues={{
                    search: searchTerm
                  }}
                  onSubmit={({ search }, actions) => {
                    setSearchTerm(search)
                    handlePageChange(1)

                    actions.setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="input-group">
                        <input
                          onChange={(event) => {
                            const search = event.target.value
                            handleChange(event)
                            setSearchTerm(search)
                            handlePageChange(1)
                          }}
                          maxLength={256}
                          onBlur={handleBlur}
                          value={values.search}
                          className={`form-control ${
                            errors.search &&
                            touched.search &&
                            errors.search
                              ? 'is-invalid'
                              : ''
                          }`}
                          type="text"
                          placeholder="Search campaign recipients..."
                          aria-label="Search campaign recipients"
                          aria-describedby="campaignRecipientsSearch"
                          name="search"
                          autoComplete="on"
                        />
                        <button
                          className="btn btn-outline-dark"
                          id="campaignRecipientsSearch"
                          type="submit"
                          title="Search"
                          disabled={isSubmitting}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
              {isAllowedToWriteRecipients && <div className="col">
                <div className="card h-100 bundle-card" role="button">
                  <div
                    onClick={() => {
                      openModal('recipientAddressModal')
                      dismissModal('recipientsModal')
                      setIsAddingNewRecipient(true)
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        openModal('recipientAddressModal')
                        dismissModal('recipientsModal')
                        setIsAddingNewRecipient(true)
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label="Add New Recipient"
                    className="h-100 d-flex align-items-center"
                  >
                    <div className="card-body py-5">
                      <div className="text-center py-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>
                      </div>
                      <h6 className="card-title text-center text-primary mt-3">
                        Add New Recipient
                      </h6>
                    </div>
                  </div>
                </div>
              </div>}
              {
                recipientAddresses.map((recipient) => (
                  <div
                    className="col"
                    key={recipient.id}
                  >
                    <div className={`card h-100 template-card ${(selectedRecipient?.id === recipient.id) && 'selected'}`} role="button">
                      <div
                        onClick={() => {
                          setSelectedRecipient(recipient)
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' || event.key === ' ') {
                            setSelectedRecipient(recipient)
                          }
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label={`Select ${recipient.companyName ?? recipient.firstName ?? 'Address'}`}
                        className="h-100"
                      >
                        <div className="card-body">
                          <h5 className="card-title">{recipient.companyName ?? '---'}</h5>
                          <p className="card-text lh-1">{`${recipient.firstName ?? '--'} ${recipient.lastName ?? '--'}`}</p>
                          <p className="card-text lh-1">{`${recipient.street ?? '--'} ${recipient.zip ?? '--'}`}</p>
                          <p className="card-text lh-1">{`${recipient.city ?? '--'} ${recipient.country}`}</p>
                          <p className="card-text lh-1">Cost Center: {recipient.costCenter ?? 'Not Set'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="row mt-4">
              <Pagination
                isLoading={isLoading}
                metadata={{
                  limit: metadata.perPage,
                  total: metadata.total,
                  offset: ((metadata.page - 1) * (metadata.perPage))
                }}
                page={page}
                perPage={perPage}
                handlePageChange={handlePageChange}
                handleShowEntries={handleShowEntries}
                perPageOptions={[5, 10, 25, 50]}
                module="AddressPicker"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressPicker
