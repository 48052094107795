import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CARD_TEMPATE_DELETION_MESSAGE } from '../../../constants/messages'
import CardTemplateService from '../../../services/api/CardTemplateService'
import { CardTemplateState } from '../../../types'

const initialState: CardTemplateState = {
  isLoading: false,
  error: null,
  cardTemplate: null,
  message: null
}

export const deleteCardTemplate = createAsyncThunk('api/card-template/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal}) => {
  const res = await CardTemplateService.deleteCardTemplate(id, token, signal)
  return res.data
})

const recipientSlice = createSlice({
  name: 'api/recipient',
  initialState,
  reducers: {
    resetCardTemplateError: (state) => {
      state.error = null
    },
    resetCardTemplateMessage: (state) => {
      state.message = null
    },
    resetCardTemplateData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCardTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCardTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = CARD_TEMPATE_DELETION_MESSAGE
      })
      .addCase(deleteCardTemplate.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCardTemplateError, resetCardTemplateMessage, resetCardTemplateData } = recipientSlice.actions

const { reducer } = recipientSlice
export default reducer
