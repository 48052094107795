import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  BUNDLE_CREATION_SUCCESS_MESSAGE, CAMPAIGN_DELETION_MESSAGE,
  CAMPAIGN_UPDATE_SUCCESS_MESSAGE, RECIPIENT_ADDITION_SUCCESS_MESSAGE,
  CARD_TEMPLATE_CREATION_SUCCESS_MESSAGE,
  CARD_SETTING_CREATION_SUCCESS_MESSAGE,
  SHIPPING_DESTINATION_UPDATE_SUCCESS_MESSAGE,
  CAMPAIGN_ORDER_LIMIT_CREATION_SUCCESS_MESSAGE,
  CAMPAIGN_ADDRESS_CREATION_SUCCESS_MESSAGE,
  CARD_TEMPLATE_UPDATE_SUCCESS_MESSAGE
} from '../../../constants/messages'
import CampaignService from '../../../services/api/CampaignService'
import {
  Address, ApiCampaignState, CampaignOrderLimit, CardSetting, CardTemplate
} from '../../../types'
import * as statusCodes from '../../../constants/statusCodes'

const initialState: ApiCampaignState = {
  isLoading: false,
  isLoadingRecipients: false,
  isLoadingBundles: false,
  isCreatingOrders: false,
  isLoadingOrderLimits: false,
  isLoadingShippingDestinations: false,
  isLoadingCardTemplates: false,
  error: null,
  campaigns: [],
  campaignsMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  campaign: null,
  recipient: null,
  recipients: [],
  bundle: null,
  bundles: [],
  bundlesMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  message: null,
  shippingDestinationsMessage: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  campaignOrders: [],
  campaignOrdersMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  pendingOrders: [],
  cardTemplates: [],
  cardTemplatesMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  campaignAddresses: [],
  areAllPagesForCampaignOrders: false,
  areAllPagesForCampaignOrdersForBundle: false,
  campaignOrdersExportCampaignId: null,
  campaignOrdersExportJFSKU: null
}

export const getAllCampaigns = createAsyncThunk('api/campaign/get/all', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.getAllCampaigns(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addCampaignRecipient = createAsyncThunk('api/campaign/post/recipient', async ({ id, token, recipient, signal }: { id: string, token: string, recipient: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.addCampaignRecipient(id, token, recipient, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCampaignRecipients = createAsyncThunk('api/campaign/get/recipients', async ({ id, token, perPage, page, signal, search }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.getCampaignRecipients(id, token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCampaignById = createAsyncThunk('api/campaign/get', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.getCampaignById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const updateCampaignById = createAsyncThunk('api/campaign/update', async ({ id, token, campaign, signal }: { id: string, token: string, campaign: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.updateCampaignById(id, token, campaign, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteCampaignById = createAsyncThunk('api/campaign/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.deleteCampaignById(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createCampaignBundle = createAsyncThunk('api/campaign/post/bundle', async ({ id, token, bundle, signal }: { id: string, token: string, bundle: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.createCampaignBundle(id, token, bundle, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCampaignBundles = createAsyncThunk('api/campaign/get/bundles', async ({ id, token, perPage, page, signal, search }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.getCampaignBundles(id, token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCampaignOrders = createAsyncThunk('api/campaign/get/orders', async ({ id, token, perPage, page, search, filter, signal }: { id: string, token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.getCampaignOrders(id, token, perPage, page, search, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCampaignOrdersForBundle = createAsyncThunk('api/campaign/get/orders/bundle', async ({ id, jfsku, token, perPage, page, search, filter, signal }: { id: string, jfsku:string, token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.getCampaignOrdersForBundle(id, jfsku, token, perPage, page, search, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

type PendingOrdersParams = { id: string, token: string, pendingOrders: any, signal: AbortSignal }
export const createBulkOrders = createAsyncThunk('api/campaign/pending-orders/post', async ({ id, token, pendingOrders, signal }: PendingOrdersParams, { rejectWithValue }) => {
  try {
    const res = await CampaignService.createBulkOrders(id, token, pendingOrders, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createCampaignCardTemplate = createAsyncThunk('api/campaign/post/card-template', async ({ id, token, cardTemplate, signal }: { id: string, token: string, cardTemplate: Partial<CardTemplate>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.createCampaignCardTemplate(id, token, cardTemplate, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getCampaignCardTemplates = createAsyncThunk('api/campaign/get/card-templates', async ({ id, token, perPage, page, signal }: { id: string, token: string, perPage: number, page: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.getCampaignCardTemplates(id, token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createCampaignCardSetting = createAsyncThunk('api/campaign/post/card-setting', async ({ id, token, cardSetting, signal }: { id: string, token: string, cardSetting: Partial<CardSetting>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.createCampaignCardSetting(id, token, cardSetting, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createCampaignShippingDestination = createAsyncThunk('api/campaign/post/shipping-destination', async ({ id, token, campaignShippingDestinations, signal }: { id: string, token: string, campaignShippingDestinations: string[], signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.createCampaignShippingDestination(id, token, campaignShippingDestinations, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createCampaignOrderLimit = createAsyncThunk('api/campaign/post/order-limit', async ({ id, token, campaignOrderLimit, signal }: { id: string, token: string, campaignOrderLimit: Partial<CampaignOrderLimit>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.createCampaignOrderLimit(id, token, campaignOrderLimit, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const createCampaignAddress = createAsyncThunk('api/campaign/post/address', async ({ id, token, campaignAddresses, signal }: { id: string, token: string, campaignAddresses: Array<Partial<Address>>, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CampaignService.createCampaignAddress(id, token, campaignAddresses, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const campaignSlice = createSlice({
  name: 'api/campaign',
  initialState,
  reducers: {
    resetCampaignError: (state) => {
      state.error = null
    },
    resetCampaignMessage: (state) => {
      state.message = null
    },
    resetCampaignMessageShippingDestinations: (state) => {
      state.shippingDestinationsMessage = null
    },
    resetCampaignOrders: (state) => {
      state.campaignOrders = []
      state.campaignOrdersMetadata = {
        page: 1,
        pageCount: 1,
        perPage: 20,
        total: 0
      }
    },
    resetCampaignData: (state) => {
      state.error = null
      state.campaign = null
      state.campaigns = []
      state.recipient = null
      state.recipients = []
      state.bundle = null
      state.bundles = []
      state.message = null
      state.campaignOrders = []
      state.cardTemplates = []
      state.shippingDestinationsMessage = null
    },
    resetPendingOrders: (state) => {
      state.pendingOrders = []
    },
    getAllPagesForCampaignOrders: (state, { payload }) => {
      state.areAllPagesForCampaignOrders = payload
    },
    getAllPagesForCampaignOrdersForBundle: (state, { payload }) => {
      state.areAllPagesForCampaignOrdersForBundle = payload
    },
    setCampaignOrdersExportMetadata: (state, { payload }) => {
      state.campaignOrdersExportCampaignId = payload.campaignId
      state.campaignOrdersExportJFSKU = payload.jfsku
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCampaigns.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload.campaigns
        state.campaignsMetadata = action.payload.meta
        state.isLoading = false
        state.error = null
        state.message = null
      })
      .addCase(getAllCampaigns.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addCampaignRecipient.pending, (state) => {
        state.isLoadingRecipients = true
      })
      .addCase(addCampaignRecipient.fulfilled, (state, action) => {
        state.isLoadingRecipients = false
        state.recipient = action.payload.recipient
        state.message = RECIPIENT_ADDITION_SUCCESS_MESSAGE
        state.error = null
      })
      .addCase(addCampaignRecipient.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingRecipients = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCampaignRecipients.pending, (state) => {
        state.isLoadingRecipients = true
      })
      .addCase(getCampaignRecipients.fulfilled, (state, action) => {
        state.isLoadingRecipients = false
        state.recipients = action.payload.recipients
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getCampaignRecipients.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingRecipients = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCampaignById.pending, (state) => {
        state.isLoading = true
        state.campaign = null
      })
      .addCase(getCampaignById.fulfilled, (state, action) => {
        state.campaign = action.payload.campaign
        state.isLoading = false
        state.error = null
        state.message = null
      })
      .addCase(getCampaignById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updateCampaignById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCampaignById.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = CAMPAIGN_UPDATE_SUCCESS_MESSAGE
        state.campaign = action.payload.campaign
      })
      .addCase(updateCampaignById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteCampaignById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCampaignById.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = CAMPAIGN_DELETION_MESSAGE
      })
      .addCase(deleteCampaignById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createCampaignBundle.pending, (state) => {
        state.isLoadingBundles = true
        state.message = null
      })
      .addCase(createCampaignBundle.fulfilled, (state, action) => {
        state.isLoadingBundles = false
        state.bundle = action.payload.bundle
        state.error = null
        state.message = BUNDLE_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createCampaignBundle.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingBundles = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCampaignBundles.pending, (state) => {
        state.isLoadingBundles = true
      })
      .addCase(getCampaignBundles.fulfilled, (state, action) => {
        state.isLoadingBundles = false
        state.bundles = action.payload.bundles
        state.bundlesMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCampaignBundles.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingBundles = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCampaignOrders.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCampaignOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.campaignOrders = action.payload.orders
        state.campaignOrdersMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCampaignOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCampaignOrdersForBundle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCampaignOrdersForBundle.fulfilled, (state, action) => {
        state.isLoading = false
        state.campaignOrders = action.payload.orders
        state.campaignOrdersMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCampaignOrdersForBundle.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createBulkOrders.pending, (state) => {
        state.pendingOrders = []
        state.isCreatingOrders = true
      })
      .addCase(createBulkOrders.fulfilled, (state, action) => {
        state.isCreatingOrders = false
        state.pendingOrders = action.payload.pendingOrders
        state.error = null
      })
      .addCase(createBulkOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isCreatingOrders = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createCampaignCardTemplate.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(createCampaignCardTemplate.fulfilled, (state, action) => {
        const statusCode = action.payload.statusCode
        state.isLoading = false
        state.error = null
        state.message = statusCode === statusCodes.CREATED ? CARD_TEMPLATE_CREATION_SUCCESS_MESSAGE : CARD_TEMPLATE_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(createCampaignCardTemplate.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getCampaignCardTemplates.pending, (state) => {
        state.isLoadingCardTemplates = true
      })
      .addCase(getCampaignCardTemplates.fulfilled, (state, action) => {
        state.isLoadingCardTemplates = false
        state.cardTemplates = action.payload.cardTemplates
        state.cardTemplatesMetadata = action.payload.meta
        state.error = null
      })
      .addCase(getCampaignCardTemplates.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingCardTemplates = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createCampaignCardSetting.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(createCampaignCardSetting.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = CARD_SETTING_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createCampaignCardSetting.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createCampaignShippingDestination.pending, (state) => {
        state.isLoadingShippingDestinations = true
        state.message = null
      })
      .addCase(createCampaignShippingDestination.fulfilled, (state, action) => {
        state.isLoadingShippingDestinations = false
        state.error = null
        state.shippingDestinationsMessage = SHIPPING_DESTINATION_UPDATE_SUCCESS_MESSAGE
      })
      .addCase(createCampaignShippingDestination.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingShippingDestinations = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createCampaignOrderLimit.pending, (state) => {
        state.isLoadingOrderLimits = true
        state.message = null
      })
      .addCase(createCampaignOrderLimit.fulfilled, (state, action) => {
        state.isLoadingOrderLimits = false
        state.error = null
        state.message = CAMPAIGN_ORDER_LIMIT_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createCampaignOrderLimit.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoadingOrderLimits = false
        }
        state.error = action.payload
      })
    builder
      .addCase(createCampaignAddress.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(createCampaignAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = CAMPAIGN_ADDRESS_CREATION_SUCCESS_MESSAGE
      })
      .addCase(createCampaignAddress.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const {
  resetCampaignError, resetCampaignMessage, resetCampaignData, resetCampaignOrders,
  resetPendingOrders, resetCampaignMessageShippingDestinations,
  getAllPagesForCampaignOrders, getAllPagesForCampaignOrdersForBundle, setCampaignOrdersExportMetadata
} = campaignSlice.actions

const { reducer } = campaignSlice
export default reducer
