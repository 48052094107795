import { ShippingMethod } from '../types'

export const customShippingMethods: Array<ShippingMethod> = [
  {
    shippingMethodId: 'NDZ20AEJBTEC9QCH',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:24:44.766+00:00',
      updatedAt: '2022-07-27T11:24:44.766+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AKV8JQPK1UR',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:24:44.766+00:00',
      updatedAt: '2022-07-27T11:24:44.766+00:00',
      state: 'New'
    }
  }
]
