import React from 'react'
import Placeholder from '../../../assets/images/placeholder.png'

const BundleConfiguratorItemLoader = ({ repetition }: { repetition: number }) => {
  return (
    <>
      {Array.from(Array(repetition).keys()).map((n: number) => (
        <div className="col" key={n}>
          <div role="button"
            tabIndex={0}
            aria-label="Product Loader"
            className="card h-100 product-card"
          >
            <img src={Placeholder} alt="Placeholder thumbnail" className="card-img-top" style={{ objectFit: 'cover', height: 300 }}/>
            <div className="h-100 d-flex align-content-end flex-wrap">
              <div className="card-body p-2">
                <h6 className="card-title placeholder-glow">
                  <span className="placeholder col-10"></span>
                </h6>
                <h6 className="card-title placeholder-glow">
                  <span className="placeholder col-6"></span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default BundleConfiguratorItemLoader
