import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

type DropZopProps = {
  handleUpload: (input: File) => void
  validWidth?: number
  validHeight?: number
  className?: React.CSSProperties
  uploadProgress: number
  uploadError: string | null
}

const DropZone = ({
  handleUpload,
  uploadProgress,
  uploadError
}: DropZopProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleUpload(acceptedFiles[0])
    },
    [handleUpload]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg'] },
    onDrop
  })
  return (
    <div
      className={`w-100 d-flex border rounded text-center ${uploadError ? 'border-danger' : isDragActive ? 'border-primary' : ''}`}
    >
      <div className="container-fluid gx-0 cursor-pointer">
        <div {...getRootProps({ className: 'dropzone' })} className="pt-3">
          <input {...getInputProps()} />
          <i className="bi bi-file-earmark-image h2"></i>
          <p className="small lh-1 px-2">
            Drop your logo here or <span className="text-primary fw-bold">browse</span>
          </p>
          <div>
            <p
              className={`px-2 small ${uploadError ? 'text-danger' : ''}`}
            >
              {uploadError || 'Recommended image should be 48x48 or 120x20 | Below 5MB | JPEG | PNG'}
            </p>
            <div
              className="progress"
              style={{
                height: '0.2em'
              }}
            >
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${(uploadProgress).toFixed(0)}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropZone
