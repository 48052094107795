import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Progress from '../../components/loaders/Progress'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ProductCategory } from '../../types'
import Pagination from '../../components/Pagination'
import * as userRoles from '../../constants/userRoles'
import {
  addProductCategory,
  deleteProductCategoryById,
  editProductCategoryById,
  getProductCategories,
  resetProductCategoryError,
  resetProductCategoryMessage,
  updateProductCategoriesSortOrder
} from '../../store/reducers/api/productCategoryReducer'
import ProductCategoryEditor from '../../components/ProductCategories/ProductCategoryEditor'
import {
  PRODUCT_CATEGORY_CREATION_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_DELETION_MESSAGE,
  PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE
} from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import useDebounce from '../../utils/hooks/useDebounce'

const ProductCategories = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiProductCategory.isLoading)
  const isLoadingProductCategorySortOrder = useAppSelector((state) => state.apiProductCategory.isLoadingProductCategorySortOrder)
  const message = useAppSelector((state) => state.apiProductCategory.message)
  const productCategories = useAppSelector((state) => state.apiProductCategory.productCategories)
  const metadata = useAppSelector((state) => state.apiProductCategory.metadata)
  const error = useAppSelector((state) => state.apiProductCategory.error)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [searchTermProductCategories, setSearchTermProductCategories] = useState<string>('')
  const debouncedSearchTermProductCategories: string = useDebounce<string>(searchTermProductCategories, 800)

  const [initialProductCategory, setInitialProductCategory] = useState<Partial<ProductCategory>>({
    name: '',
    description: '',
    sortIndex: 0
  })

  const [localProductCategories, setLocalProductCategories] = useState<ProductCategory[]>([])

  const token = currentUser?.token

  const role = currentUser?.role || userRoles.USER

  const dispatch = useAppDispatch()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleProductCategoryRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && role === userRoles.ADMIN) {
      dispatch(getProductCategories({ token, perPage, page, signal, search: debouncedSearchTermProductCategories }))
    }
  }

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && role === userRoles.ADMIN) {
      dispatch(getProductCategories({ token, perPage, page, signal, search: debouncedSearchTermProductCategories }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTermProductCategories])

  useEffect(() => {
    setLocalProductCategories(productCategories)
  }, [productCategories])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [
      PRODUCT_CATEGORY_CREATION_SUCCESS_MESSAGE,
      PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE,
      PRODUCT_CATEGORY_DELETION_MESSAGE,
      PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE
    ]
    if (message && allowedMessages.includes(message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('productCategoryModal')
      dismissModal('productCategoryEditModal')
      if (token && role === userRoles.ADMIN) {
        const silent = message === PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE
        dispatch(getProductCategories({ token, perPage, page, signal, silent }))
        dispatch(resetProductCategoryMessage())
      }
    }
  }, [message])

  useEffect(() => {
    if (error && error?.errors) {
      const payload = {
        title: 'Error',
        message: error?.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetProductCategoryError())
    }
  }, [error])

  return (
    <main className="container-fluid px-4 py-4">
      <div className="card">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3">
            <p className="h5"><i className="bi bi-tags me-1"></i>Product Categories</p>
            <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                <Formik
                  validationSchema={searchSchema}
                  enableReinitialize
                  initialValues={{
                    search: ''
                  }}
                  onSubmit={({ search }, actions) => {
                    setSearchTermProductCategories(search)
                    if (page !== 1) {
                      setPage(1)
                    }

                    actions.setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="input-group">
                        <input
                          onChange={(event) => {
                            const search = event.target.value
                            handleChange(event)
                            setSearchTermProductCategories(search)
                            if (page !== 1) {
                              setPage(1)
                            }
                          }}
                          maxLength={24}
                          onBlur={handleBlur}
                          value={values.search}
                          className={`form-control ${
                            errors.search && touched.search && errors.search
                              ? 'is-invalid'
                              : ''
                          }`}
                          type="text"
                          placeholder="Search..."
                          aria-label="Search"
                          aria-describedby="btnNavbarSearch"
                          name="search"
                        />
                        <button
                          className="btn btn-outline-dark"
                          id="btnNavbarSearch"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#productCategoryModal"
                title="Add Product Category"
                aria-label="Add Product Category"
                onClick={() => {
                  setInitialProductCategory({
                    name: '',
                    description: '',
                    sortIndex: 0
                  })
                }}
              >
                <i className="bi bi-plus-circle"></i>
                <span className="d-none d-md-inline-block ms-1">Add Category</span>
              </button>
              <button
                type="button"
                title="Refresh"
                aria-label="Refresh"
                className="btn btn-outline-dark ms-2"
                onClick={() => handleProductCategoryRefresh()}
              >
                <i className="fas fa-redo"></i>
              </button>
            </ul>
          </div>
          {(isLoading || isLoadingProductCategorySortOrder) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col" className="w-20">ID</th>
                  <th scope="col" className="w-30">Name</th>
                  <th scope="col" className="w-35">Description</th>
                  <th scope="col" className="text-end w-15">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  (isLoading)
                    ? (
                        Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={4} actionQuantity={2} />)
                      )
                    : (
                        localProductCategories.length > 0
                          ? (localProductCategories.map((category: ProductCategory) => (
                            <tr
                              draggable="true"
                              key={category.id}
                              className={initialProductCategory.id === category.id ? 'table-primary' : ''}
                              onDragStart={(e) => {
                                e.dataTransfer.setData('text/plain', category.id)
                              }}
                              onDragOver={(e) => {
                                e.preventDefault()
                              }}
                              onDrop={(e) => {
                                e.preventDefault()
                                const draggedId = e.dataTransfer.getData('text')
                                const draggedIndex = localProductCategories.findIndex(cat => cat.id === draggedId)
                                const targetIndex = localProductCategories.findIndex(cat => cat.id === category.id)
                                if (draggedIndex !== targetIndex) {
                                  const newOrder = [...localProductCategories]
                                  const [reorderedItem] = newOrder.splice(draggedIndex, 1)
                                  newOrder.splice(targetIndex, 0, reorderedItem)

                                  // Update local state immediately
                                  setLocalProductCategories(newOrder)

                                  // Update server state
                                  if (token) {
                                    const controller = new AbortController()
                                    const signal = controller.signal
                                    const updatedCategories = newOrder.map((category, index) => ({
                                      productCategoryId: category.id,
                                      sortIndex: (page - 1) * perPage + index
                                    }))
                                    dispatch(updateProductCategoriesSortOrder({ token, productCategories: updatedCategories, signal }))
                                  }
                                }
                              }}
                            >
                              <td className="user-select-all" onClick={() => navigator?.clipboard.writeText(String(category.id))}>
                                {(category.id).substring(0, 8)}
                              </td>
                              <td>
                                <Link className="text-decoration-none" to={`/product-categories/${category.id}`}>
                                  {category.name}
                                </Link>
                              </td>
                              <td className="text-truncate" style={{ maxWidth: '150px' }} title="Category Description">
                                {category.description || '---'}
                              </td>
                              <td className="text-end">
                                <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                  <button
                                    className="btn btn-outline-dark btn-round me-2"
                                    type="button"
                                    title="Edit Product Category"
                                    data-bs-toggle="modal"
                                    data-bs-target="#productCategoryEditModal"
                                    onClick={() => {
                                      setInitialProductCategory(category)
                                    }}
                                    disabled={isLoadingProductCategorySortOrder}
                                  >
                                    <PencilIcon/>
                                  </button>
                                  <button
                                    className="btn btn-outline-danger btn-round"
                                    type="button"
                                    title="Delete Product Category"
                                    data-bs-toggle="modal"
                                    data-bs-target="#productCategoryConfirmationModal"
                                    onClick={() => {
                                      setInitialProductCategory(category)
                                    }}
                                    disabled={isLoadingProductCategorySortOrder}
                                  >
                                    <TrashIcon/>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            ))
                            )
                          : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No product categories available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoading}
            metadata={{
              limit: metadata.perPage,
              total: metadata.total,
              offset: ((metadata.page - 1) * (metadata.perPage))
            }}
            page={page}
            perPage={perPage}
            handlePageChange={handlePageChange}
            handleShowEntries={handleShowEntries}
            isTrackingPage
          />
        </div>

          <div className="modal fade" id="productCategoryModal" tabIndex={-1} aria-labelledby="productCategoryModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="productCategoryModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Add Product Category'}
                  </h5>
                  <button type="button"
                    onClick={() => dispatch(resetProductCategoryError())}
                    className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  >
                  </button>
                </div>
                {(isLoading) && <Progress />}
                <div className="modal-body">
                  <ProductCategoryEditor
                    id={''}
                    initialProductCategory={initialProductCategory}
                    save={addProductCategory}
                    isEdit={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="productCategoryEditModal" tabIndex={-1} aria-labelledby="productCategoryEditModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="productCategoryEditModalLabel">
                    <i className={'bi bi-plus-circle me-1'}></i>{' '}
                    {'Edit Product Category'}
                  </h5>
                  <button type="button"
                    onClick={() => dispatch(resetProductCategoryError())}
                    className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  >
                  </button>
                </div>
                {isLoading && <Progress />}
                <div className="modal-body">
                  <ProductCategoryEditor
                    id={String(initialProductCategory.id)}
                    initialProductCategory={initialProductCategory}
                    save={editProductCategoryById}
                    isEdit={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="productCategoryConfirmationModal" tabIndex={-1} aria-labelledby="productCategoryConfirmationModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <h5 className="modal-title text-danger" id="productCategoryConfirmationModalLabel">
                    <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                {isLoading && <Progress />}
                <div className="modal-body">
                  <p>
                    Are you sure you want to delete the
                    <span className="fw-bold">{` '${(initialProductCategory.name)}' `}</span>
                    product category?
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      if (token && initialProductCategory.id !== null) {
                        const controller = new AbortController()
                        const signal = controller.signal
                        dispatch(deleteProductCategoryById({ productCategoryId: String(initialProductCategory.id), token, signal }))
                      }
                    }}
                    disabled={isLoading}
                    data-bs-dismiss="modal"
                    aria-label="Delete"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </main>
  )
}

export default ProductCategories
