import React from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const SalutationEditor = ({ id, title, save, mode = 'none' }: { id: string, title: string, save: Function, mode?: 'edit' | 'add' | 'none' }) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiSalutation.isLoading)
  const error = useAppSelector((state) => state.apiSalutation.error)

  const token = currentUser?.token

  const dispatch = useAppDispatch()

  const salutationSchema = object({
    title: string()
      .required('Title is required')
      .max(8, 'Title name is too long')
  })
  return (
    <div>
      <Formik
        validationSchema={salutationSchema}
        enableReinitialize
        initialValues={{
          title
        }}
        onSubmit={({ title }, actions) => {
          const controller = new AbortController()
          const signal = controller.signal
          const salutation = {
            title
          }
          if (token) {
            dispatch(
              save({
                salutationId: id,
                token,
                salutation,
                signal
              })
            )
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="mb-3">
                  <label htmlFor={`title${mode}`} className="form-label">
                    Title
                  </label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    type="text"
                    className={`form-control ${
                      errors.title && touched.title && errors.title
                        ? 'is-invalid'
                        : ''
                    }`}
                    id={`title${mode}`}
                    name="title"
                    placeholder="Enter title"
                  />
                  <div
                    className="invalid-feedback"
                  >
                    {errors.title || error?.errors?.title}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end">
              <button
                className="btn btn-secondary mt-2 me-2"
                data-bs-target="#salutationEditModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                type="button"
                disabled={isLoading || isSubmitting}
              >
                <i className="bi bi-list"></i> View All
              </button>
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={isLoading || isSubmitting}
              >
                <i className="bi bi-save"></i> Save
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default SalutationEditor
