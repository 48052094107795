import React, { useState, useEffect } from 'react'
import { Bundle, Campaign, stocks } from '../../../types'
import BillOfMaterialsItem from '../../ItemListView/BillOfMaterialsItem'
import { useAppSelector } from '../../../store/hooks'
import BundleService from '../../../services/api/BundleService'
import { Tooltip } from '../../Tooltip'

interface SelectedBundleItemProps {
  selectedBundle: Bundle
  showBillOfMaterialsStock: boolean
  updateBundle: Function
  setSelectedCartBundle: Function
  campaign: Campaign
  deleteItem: (jfsku: string, bundle: Bundle) => void
  setItemDeletedStatus: (activeBundle: Bundle, jfsku: string, isDeleted: boolean) => void
  updateItemQuantity: (activeBundle: Bundle, jfsku: string, quantity: number) => void
  isExceedStockEnabled: boolean
}

const SelectedBundleItem = ({
  selectedBundle,
  showBillOfMaterialsStock,
  updateBundle,
  setSelectedCartBundle,
  campaign,
  deleteItem,
  setItemDeletedStatus,
  updateItemQuantity,
  isExceedStockEnabled
}: SelectedBundleItemProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const [stock, setStock] = useState<stocks.Stock | null>(null)
  const [isLoadingStock, setIsLoadingStock] = useState(false)
  const maximumQuantity = 1000

  const getStock = async (
    bundleId: string,
    signal: AbortSignal
  ) => {
    try {
      setIsLoadingStock(true)
      const { data: { stock } }: { data: { statusCode: number, success: boolean, stock: stocks.Stock } } =
        await BundleService.getBundleStock(String(currentUser?.token), bundleId, signal)
      setStock(stock)
      setIsLoadingStock(false)
    } catch (error: any) {
      if (error.message !== 'canceled') {
        setIsLoadingStock(false)
      }
    }
  }

  useEffect(() => {
    if (!isExceedStockEnabled) {
      const controller = new AbortController()
      const signal = controller.signal
      getStock(selectedBundle.id, signal)

      return () => {
        controller.abort()
      }
    }
  }, [])

  return (
    <div key={selectedBundle.id} className="accordion-item">
      <h2 className="accordion-header">
        <div className="row m-0">
          <div className="col-md-6 col-xl-8 p-0">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${selectedBundle.jfsku}`} aria-expanded="false" aria-controls={`collapse-${selectedBundle.jfsku}`}>
              {selectedBundle.name} <span className="badge bg-primary ms-2">{selectedBundle.quantity} {selectedBundle.quantity === 1 ? 'Box' : 'Boxes'}</span>
            </button>
          </div>
          <div className="col">
            <div className="d-flex justify-content-md-end">
              <div className="input-group m-2">
                {!isExceedStockEnabled && <span className="input-group-text" id="basic-addon">
                  {
                    isLoadingStock
                      ? (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                        )
                      : (
                        <Tooltip text="In stock / Available" placement="bottom">
                          <span>
                            {stock?.stockLevel || 0}/{(stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0)}
                          </span>
                        </Tooltip>
                        )
                  }
                </span>}
                <input
                  type="number"
                  min={1}
                  value={selectedBundle.quantity || 1}
                  onChange={(e) => {
                    const max = (stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0) || 1
                    const value = Number(e.target.value)
                    const quantity = isExceedStockEnabled ? Math.max(Math.min(value, maximumQuantity), 1) : Math.max(Math.min(value, max), 1)
                    updateBundle(selectedBundle, quantity)
                  }}
                  name="quantity"
                  className="form-control"
                  aria-label="Bundle Quantity"
                  autoComplete="off"
                  aria-describedby="basic-addon"
                  disabled={isLoadingStock}
                />
              </div>
              <button
                className="btn btn-outline-primary text-nowrap btn-sm m-2"
                type="button"
                data-bs-toggle="modal" data-bs-target="#greetingCardModal"
                onClick={() => {
                  setSelectedCartBundle(selectedBundle)
                }}
                disabled={campaign.cardSetting?.isEnabled !== true || selectedBundle.isLocked}
              >
                <i className="bi bi-plus-circle me-1"></i>Greeting Card
              </button>
            </div>
          </div>
        </div>
      </h2>
      <div id={`collapse-${selectedBundle.jfsku}`} className="accordion-collapse collapse">
        <div className="accordion-body">
          <div className="p-0 table-responsive">
            <table className="table">
              {selectedBundle.isLocked && <caption className="small text-primary"><span className="ms-1" title="Locked">{selectedBundle.isLocked && <i className="bi bi-lock"></i>}</span> Selected items are not editable as this bundle is locked.</caption>}
              <thead>
                <tr>
                  <th scope="col">Selected Items <span className="badge bg-primary">{(selectedBundle?.specifications?.billOfMaterialsComponents.filter(item => !item.isDeleted) ?? []).length}</span></th>
                  {showBillOfMaterialsStock && <th scope="col">In Stock / Available</th>}
                  <th scope="col" className="col-2">Quantity</th>
                  <th scope="col" className="col-1 text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  selectedBundle.specifications?.billOfMaterialsComponents.map(item => (
                    <BillOfMaterialsItem
                      key={`${item.jfsku}-cart`}
                      bundle={selectedBundle}
                      item={item}
                      found={item}
                      deleteItem={deleteItem}
                      setItemDeletedStatus={setItemDeletedStatus}
                      updateItemQuantity={updateItemQuantity}
                      showStock={showBillOfMaterialsStock}
                    />
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectedBundleItem
