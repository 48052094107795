import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import LegalTextService from '../../../services/api/LegalTextService'
import { ApiLegalTextState } from '../../../types'

const initialState: ApiLegalTextState = {
  isLoading: false,
  error: null,
  legalText: null,
  legalTexts: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

type LegalTextParams = { perPage: number, page: number, filter: string, signal: AbortSignal}
export const getAllLegalTexts = createAsyncThunk('api/legal-texts/get', async ({ perPage, page, filter, signal }: LegalTextParams, { rejectWithValue }) => {
  try {
    const res = await LegalTextService.getAllLegalTexts(perPage, page, filter, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const legalTextSlice = createSlice({
  name: 'api/legal-texts',
  initialState,
  reducers: {
    resetLegalTextError: (state) => {
      state.error = null
    },
    resetLegalTextMessage: (state) => {
      state.message = null
    },
    resetLegalTextData: (state) => {
      state.error = null
      state.legalText = null
      state.legalTexts = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLegalTexts.pending, (state) => {
        state.legalTexts = []
        state.isLoading = true
      })
      .addCase(getAllLegalTexts.fulfilled, (state, action) => {
        state.isLoading = false
        state.legalTexts = action.payload.legalTexts
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllLegalTexts.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetLegalTextError, resetLegalTextMessage, resetLegalTextData } = legalTextSlice.actions

const { reducer } = legalTextSlice
export default reducer
