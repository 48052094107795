import React from 'react'
import SkeletonElement from './SkeletonElement'
import Placeholder from '../../../assets/images/placeholder.png'

interface SkeletonTableRowProps {
  colSpan: number
  actionQuantity?: number
  width?: 'w-25' | 'w-50' | 'w-75' | 'w-100'
  hasThumbnail?: boolean
}
const SkeletonTableRow = ({ colSpan, actionQuantity = 1, width = 'w-100', hasThumbnail = false }: SkeletonTableRowProps) => {
  return (
    <tr>
      {
        hasThumbnail && (
          <td>
            <img src={Placeholder} alt="Placeholder thumbnail" className="product-thumbnail" />
          </td>
        )
      }
      {Array.from(Array(actionQuantity > 0 ? colSpan - (hasThumbnail ? 2 : 1) : (hasThumbnail ? colSpan - 1 : colSpan)).keys()).map((n: number) => (
        <td key={n}>
          <div className="skeleton-wrapper">
            <SkeletonElement type="text" width={width} />
          </div>
        </td>
      ))}
        {actionQuantity > 0 && <td className="text-end">
          <div
            className="d-flex justify-content-end"
            role="group"
            aria-label="Actions"
            >
              {Array.from(Array(actionQuantity).keys()).map((n: number) => (
                <button
                  key={`button-${n}`}
                  className="btn btn-outline-dark btn-round ms-2"
                  type="button"
                  disabled
                >
                </button>
              ))}
          </div>
        </td>}
    </tr>
  )
}

export default SkeletonTableRow
