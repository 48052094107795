import { Campaign, ShippingMethod } from '../types'

export const shippingMethods: Array<ShippingMethod> = [
  {
    shippingMethodId: 'NDZ20A8EPPGEGQVN',
    fulfillerId: 'NDZ2',
    name: 'BIG LITTLE THINGS DPD',
    carrierCode: 'DPD',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2021-05-17T11:53:35.859+00:00',
      updatedAt: '2021-05-31T11:31:20.055+00:00',
      state: 'Modified'
    }
  },
  {
    shippingMethodId: 'X4MB0AAJ4HVLBGGY',
    fulfillerId: 'X4MB',
    name: 'DHL Paket',
    carrierName: 'DHL',
    shippingType: 'Standard',
    trackingUrlSchema: 'https://dhl.de/sendungsverfolgung?tracking=#TrackingId#',
    cutoffTime: '14:00:00',
    note: 'Standard DHL Versand',
    modificationInfo: {
      createdAt: '2021-11-04T12:16:28.193+00:00',
      updatedAt: '2021-11-04T12:16:28.193+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AG4HTCWN6XW',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things Einzelversand',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-04-21T06:44:40.236+00:00',
      updatedAt: '2022-04-21T06:44:40.236+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AAFC64A2SER',
    fulfillerId: 'NDZ2',
    name: 'Big Little things Dropshipping',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-06-07T09:12:37.109+00:00',
      updatedAt: '2022-06-07T09:12:37.109+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20ACNZSMFBC9K',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things Transportversicherung',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-06T04:01:11.315+00:00',
      updatedAt: '2022-07-06T04:01:11.315+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AMNHLZJQXJ9',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things - Spedition',
    carrierName: 'Spedition',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:13:16.964+00:00',
      updatedAt: '2022-07-27T11:13:16.964+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20ASQ8CCNCH8R',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things - Transportversicherung (Dropship)',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:14:47.924+00:00',
      updatedAt: '2022-07-27T11:14:47.924+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20ACS7TDCN5XB',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 1 kg',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:15:35.061+00:00',
      updatedAt: '2022-07-27T11:15:35.061+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20A3EUTJT2AF2',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 10 kg',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:16:14.893+00:00',
      updatedAt: '2022-07-27T11:16:14.893+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AH69CE77DMM',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 20 kg',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:17:15.355+00:00',
      updatedAt: '2022-07-27T11:17:15.355+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20A7VNA2G1YAR',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 3 kg',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:18:05.201+00:00',
      updatedAt: '2022-07-27T11:18:05.201+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AA848VXHZ9T',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 31,5 kg',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:18:48.750+00:00',
      updatedAt: '2022-07-27T11:18:48.750+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AFGQSS98VSB',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 5 kg',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:19:26.578+00:00',
      updatedAt: '2022-07-27T11:19:26.578+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AFS26LNFZNJ',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 1 kg - International',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:20:06.596+00:00',
      updatedAt: '2022-07-27T11:20:06.596+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AHXTW9WBFGJ',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 10 kg - International',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:20:46.232+00:00',
      updatedAt: '2022-07-27T11:20:46.232+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20ANQJVKAD5GW',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 20 kg - International',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:21:37.261+00:00',
      updatedAt: '2022-07-27T11:21:37.261+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AKFZ2WS6S71',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 3 kg - International',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:22:14.196+00:00',
      updatedAt: '2022-07-27T11:22:14.196+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20APWRTTBTNNC',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 31,5 kg - International',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:23:21.478+00:00',
      updatedAt: '2022-07-27T11:23:21.478+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20AR3VV9BYSWN',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL 5 kg - International',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:24:04.973+00:00',
      updatedAt: '2022-07-27T11:24:04.973+00:00',
      state: 'New'
    }
  },
  {
    shippingMethodId: 'NDZ20A4251N9NRN1',
    fulfillerId: 'NDZ2',
    name: 'Big Little Things DHL - Warenpost',
    carrierCode: 'DHL',
    shippingType: 'Standard',
    modificationInfo: {
      createdAt: '2022-07-27T11:24:44.766+00:00',
      updatedAt: '2022-07-27T11:24:44.766+00:00',
      state: 'New'
    }
  }
]

export const getShippingMethod = (campaign: Campaign) => {
  const shippingId = campaign.shippingMethodType
    ? campaign.shippingMethodType
    : 0
  return shippingId
}
