import React from 'react'
import Calendar from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DaysOfWeek } from '../../enums/daysOfTheWeek'

type DatePickerProps = {
  setDate: Function
  date: Date | null | undefined
  className?: string
  minDate?: Date
  maxDate?: Date
  id?: string
  autoComplete?: string
  showTimeSelect?: boolean
  dateFormat?: string
  timeIntervals?: number
  calendarStartDay?: DaysOfWeek
  filterDate? (date: Date): boolean
  placeholderText?: string
  disabled?: boolean
}

const DatePicker = ({
  setDate,
  date,
  minDate,
  maxDate,
  className,
  id = 'date',
  autoComplete = 'off',
  dateFormat = 'dd/MM/yyyy',
  showTimeSelect = false,
  timeIntervals = 30,
  calendarStartDay = DaysOfWeek.Monday,
  filterDate = () => true,
  placeholderText = '',
  disabled = false
}: DatePickerProps) => {
  return (
    <Calendar
      id={id}
      onChange={(date: Date) => setDate(date)}
      selected={date}
      className={`form-control ${className}`}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={dateFormat}
      autoComplete={autoComplete}
      showTimeSelect={showTimeSelect}
      timeIntervals={timeIntervals}
      calendarStartDay={calendarStartDay}
      filterDate={filterDate}
      placeholderText={placeholderText}
      disabled={disabled}
    />
  )
}

export default DatePicker
