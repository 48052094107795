import React, { useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { MultiValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { object, string, array } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { addUsersToProductAccessControlGroup } from '../../../store/reducers/api/productAccessControlGroupReducer'
import { debounce } from '../../../utils/debounce'
import { loadUserOptions } from '../../../utils/loadUserOptions'
import * as userRoles from '../../../constants/userRoles'
import { PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE } from '../../../constants/messages'
import { UserProductAccessControlGroup } from '../../../types'

interface UserSelectorProps {
  isLoading: boolean
  productAccessControlGroupId: string | undefined
}
const UserSelector = ({ isLoading, productAccessControlGroupId }: UserSelectorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const message = useAppSelector((state) => state.apiProductAccessControlGroup.message)

  const token = currentUser?.token
  const role = profile?.role || userRoles.USER

  const dispatch = useAppDispatch()

  const loadUserOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: { value: string, label: string }[]) => void) => {
      loadUserOptions(1, 20, String(token), inputValue)
        .then(options => callback(options))
    }, 800),
    []
  )

  const productAccessControlGroupUsersSchema = object({
    userIds: array()
      .of(string())
      .required('Users are required')
      .min(1, 'Select at least one user')
  })

  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      userIds: []
    },
    onSubmit: (
      {
        userIds
      },
      actions
    ) => {
      const controller = new AbortController()
      const signal = controller.signal

      const userProductAccessControlGroup: UserProductAccessControlGroup = {
        userIds
      }

      if (token && (productAccessControlGroupId)) {
        dispatch(addUsersToProductAccessControlGroup({ token, productAccessControlGroupId, userProductAccessControlGroup, signal }))
      }
      actions.setSubmitting(false)
    },
    validationSchema: productAccessControlGroupUsersSchema,
    enableReinitialize: true
  })

  useEffect(() => {
    const allowedMessages = [PRODUCT_ACCESS_CONTROL_GROUP_USERS_ADDITION_SUCCESS_MESSAGE]
    if (message && allowedMessages.includes(message)) {
      resetForm()
      setFieldValue('userIds', [])
    }
  }, [message])

  return (
    <form onSubmit={handleSubmit}>
      {role === userRoles.ADMIN && (<div className="row">
        <div className="col">
          <div className="mb-3">
            <label
              htmlFor="userIds"
              className="form-label"
            >
              Users
            </label>
            <AsyncSelect
              onBlur={handleBlur}
              inputId='userIds'
              className={`${
                touched.userIds &&
                errors.userIds
                  ? 'is-invalid'
                  : ''
              }`}
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={loadUserOptionsDebounced}
              onChange={(selectedOption: MultiValue<{ label: string, value: string }>) => {
                const selectedUserIds = selectedOption ? selectedOption.map(option => option.value) : []
                setFieldValue('userIds', selectedUserIds)
              }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: (errors.userIds && touched.userIds) ? '#dc3545' : provided.borderColor,
                  '&:hover': {
                    boxShadow: (errors.userIds && touched.userIds) ? '0 0 0 0.25rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5))',
                    borderColor: (errors.userIds && touched.userIds) ? '#dc3545' : '#86b7fe'
                  }
                })
              }}
            />
            <div
              id="validationUserIdsFeedback"
              className="invalid-feedback"
            >
              {errors.userIds}
            </div>
          </div>
        </div>
      </div>)}

      <div className="text-end">
        <button
          type="submit"
          className="btn btn-primary mt-2"
          disabled={
            isSubmitting || isLoading
          }
        >
          <i className="bi bi-save"></i> Save
        </button>
      </div>
    </form>
  )
}

export default UserSelector
