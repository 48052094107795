import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CompanyService from '../../../../services/api/dotnet/CompanyService'
import { DotnetApiCompanyState } from '../../../../types'

const initialState: DotnetApiCompanyState = {
  isLoading: false,
  error: null,
  taxRates: [],
  massUnits: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getCompanyTaxRates = createAsyncThunk('dotnet/company/tax-rates/get', async ({ token, perPage, offset, signal }: { token: string, perPage: number, offset: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyTaxRates(token, perPage, offset, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

export const getMassUnits = createAsyncThunk('dotnet/company/mass-units/get', async ({ token, perPage, offset, signal }: { token: string, perPage: number, offset: number, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await CompanyService.getCompanyMassUnits(token, perPage, offset, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data.status)
  }
})

const companySlice = createSlice({
  name: 'dotnet/company',
  initialState,
  reducers: {
    resetCompanyError: (state) => {
      state.error = null
    },
    resetCompanyMessage: (state) => {
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyTaxRates.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getCompanyTaxRates.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.taxRates = action.payload.pageCollection
        state.metadata = {
          page: (action.payload.pageIndex / action.payload.pageSize) + 1,
          pageCount: Math.ceil(action.payload.itemsCount / action.payload.pageSize),
          perPage: action.payload.pageSize,
          total: action.payload.itemsCount
        }
      })
      .addCase(getCompanyTaxRates.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getMassUnits.pending, (state) => {
        state.isLoading = true
        state.message = null
      })
      .addCase(getMassUnits.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.massUnits = action.payload.pageCollection
        state.metadata = {
          page: (action.payload.pageIndex / action.payload.pageSize) + 1,
          pageCount: Math.ceil(action.payload.itemsCount / action.payload.pageSize),
          perPage: action.payload.pageSize,
          total: action.payload.itemsCount
        }
      })
      .addCase(getMassUnits.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCompanyError, resetCompanyMessage } = companySlice.actions

const { reducer } = companySlice
export default reducer
