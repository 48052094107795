import React, { useState } from 'react'
import { SketchPicker, type ColorResult } from 'react-color'
import { ThemeColorName } from '../../types'

interface ColorPickerProps {
  onChange: Function
  color: string
  name: ThemeColorName
  index: number
}

const ColorPicker = ({ onChange, color, name, index }: ColorPickerProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)

  const openColorPicker = () => {
    setDisplayColorPicker(true)
  }

  const closeColorPicker = () => {
    setDisplayColorPicker(false)
  }

  const handleColorChange = (newColor: ColorResult) => {
    setDisplayColorPicker(true)
    onChange(newColor.hex, index, name)
  }

  const themeColorObject = {
    primaryColor: 'Primary Color - (Buttons & Loaders)',
    secondaryColor: 'Secondary Color',
    backgroundColor: 'Background Color - (Navigation bar)',
    foregroundColor: 'Foreground Color - (Navigation bar)',
    accentColor: 'Accent Color'
  }

  return (
    <div>
      <div className='d-flex py-1'>
        <div className='swatch'>
          <div className='colorPicker' style={{ background: color }} onClick={openColorPicker} />
        </div>
        <div className='ps-2'>
          <span className='text-secondary text-capitalize small'>{themeColorObject[name]}</span>
        </div>
      </div>
      {displayColorPicker
        ? (
          <div className='popover-custom'>
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px'
              }} onClick={closeColorPicker}
            />
            <SketchPicker color={color} onChangeComplete={handleColorChange}/>
          </div>
          )
        : null
      }
    </div>
  )
}

export default ColorPicker
