import CompanyService from '../services/api/CompanyService'
import { Company } from '../types'

const mapCompanies = (companies: Array<Company>) => {
  return companies.map(company => ({
    value: company.id,
    label: `${company.name} (${(company.domain || '')})`
  }))
}

export const loadCompanyOptions = async (page: number, perPage: number, token: string, inputValue: string) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token) {
    try {
      const res = await CompanyService.getCompanies(token, perPage, page, signal, inputValue)
      return mapCompanies(res.data.companies)
    } catch (error) {
      return mapCompanies([])
    }
  }

  return mapCompanies([])
}
