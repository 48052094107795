import React, { useState, useEffect } from 'react'
import { Bundle, stocks } from '../../../types'
import { TrashIcon } from '../../icons/TrashIcon'
import BundleService from '../../../services/api/BundleService'
import { useAppSelector } from '../../../store/hooks'

interface ActiveBundleRowProps {
  activeBundle: Bundle
  removeBundle: (activeBundle: Bundle) => void
  updateBundle: (activeBundle: Bundle, quantity: number) => void
  isExceedStockEnabled: boolean
}

const ActiveBundleRow = ({ activeBundle, removeBundle, updateBundle, isExceedStockEnabled }: ActiveBundleRowProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const [stock, setStock] = useState<stocks.Stock | null>(null)
  const [isLoadingStock, setIsLoadingStock] = useState(false)

  const maximumQuantity = 1000

  const getStock = async (
    bundleId: string,
    signal: AbortSignal
  ) => {
    try {
      setIsLoadingStock(true)
      const { data: { stock } }: { data: { statusCode: number, success: boolean, stock: stocks.Stock } } =
        await BundleService.getBundleStock(String(currentUser?.token), bundleId, signal)
      setStock(stock)
      setIsLoadingStock(false)
    } catch (error: any) {
      if (error.message !== 'canceled') {
        setIsLoadingStock(false)
      }
    }
  }

  useEffect(() => {
    if (!isExceedStockEnabled) {
      const controller = new AbortController()
      const signal = controller.signal
      getStock(activeBundle.id, signal)

      return () => {
        controller.abort()
      }
    }
  }, [])

  return (
    <tr>
      <td className="col-8">{activeBundle.name}</td>
      <td className="text-center col-2">
        {
          isExceedStockEnabled
            ? ('')
            : (
                isLoadingStock
                  ? (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border spinner-border-sm text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    )
                  : (
                    <span>
                      {stock?.stockLevel || 0}/{(stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0)}
                    </span>
                    )
              )
        }
      </td>
      <td className="col-2">
        <div className="form-group">
          <input
            type="number"
            min={1}
            value={activeBundle.quantity || 1}
            onChange={(e) => {
              const max = (stock?.stockLevel || 0) - (stock?.stockLevelReserved || 0) || 1
              const value = Number(e.target.value)
              const quantity = isExceedStockEnabled ? Math.max(Math.min(value, maximumQuantity), 1) : Math.max(Math.min(value, max), 1)
              updateBundle(activeBundle, quantity)
            }}
            id={activeBundle.jfsku}
            name="quantity"
            className="form-control"
            aria-label="Bundle Quantity"
            autoComplete="off"
            disabled={isLoadingStock}
          />
        </div>
      </td>
      <td className="col-2 text-end">
        <div className="btn-group" role="group" aria-label="Item Action Buttons">
          <button
            type="button"
            title="Remove Bundle"
            className="btn btn-outline-danger btn-round-sm"
            onClick={() => {
              removeBundle(activeBundle)
            }}
          >
            <TrashIcon />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default ActiveBundleRow
