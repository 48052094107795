import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CustomerService from '../../../../services/api/dotnet/CustomerService'
import { DotnetApiCustomerState } from '../../../../types'

const initialState: DotnetApiCustomerState = {
  isLoading: false,
  error: null,
  customer: null,
  customers: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getCustomers = createAsyncThunk('dotnet/api/customers', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string}, { rejectWithValue }) => {
  try {
    const res = await CustomerService.getCustomers(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const customerSlice = createSlice({
  name: 'dotnet/api/customers',
  initialState,
  reducers: {
    resetCustomerError: (state) => {
      state.error = null
    },
    resetCustomerMessage: (state) => {
      state.message = null
    },
    resetCustomerData: (state) => {
      state.error = null
      state.customer = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.customers = []
        state.isLoading = true
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isLoading = false
        state.customers = action.payload.customers
        state.metadata = action.payload.meta
        state.error = null
      })
      .addCase(getCustomers.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCustomerError, resetCustomerMessage, resetCustomerData } = customerSlice.actions

const { reducer } = customerSlice
export default reducer
