import React from 'react'
import { Bundle, Campaign, Product } from '../../../types'
import AdditionalArticleRow from '../AdditionalArticleViewer/AdditionalArticleRow'
import SelectedBundleItem from './SelectedBundleItem'

interface CartProps {
  selectedAdditionalArticles: Product[]
  selectedBundles: Bundle[]
  removeAdditionalArticle: (product: Product) => void
  updateAdditionalArticleQuantity: (product: Product, quantity: number) => void
  deleteItem: (jfsku: string, bundle: Bundle) => void
  setItemDeletedStatus: (activeBundle: Bundle, jfsku: string, isDeleted: boolean) => void
  updateItemQuantity: (activeBundle: Bundle, jfsku: string, quantity: number) => void
  setSelectedCartBundle: React.Dispatch<React.SetStateAction<Bundle | null>>
  updateBundle: (activeBundle: Bundle, quantity: number) => void
  campaign: Campaign
}

const Cart = ({
  selectedBundles,
  selectedAdditionalArticles,
  removeAdditionalArticle,
  updateAdditionalArticleQuantity,
  deleteItem,
  setItemDeletedStatus,
  updateItemQuantity,
  setSelectedCartBundle,
  updateBundle,
  campaign
}: CartProps) => {
  const showBillOfMaterialsStock = false
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <p className="fw-bold mb-2">Selected Bundles</p>
        <div className="accordion-custom order-cart" id="accordionCart">
          {
            selectedBundles.length > 0
              ? (
                  selectedBundles.map(selectedBundle => (
                    <SelectedBundleItem
                      key={selectedBundle.id}
                      selectedBundle={selectedBundle}
                      showBillOfMaterialsStock={showBillOfMaterialsStock}
                      updateBundle={updateBundle}
                      setSelectedCartBundle={setSelectedCartBundle}
                      campaign={campaign}
                      deleteItem={deleteItem}
                      setItemDeletedStatus={setItemDeletedStatus}
                      updateItemQuantity={updateItemQuantity}
                      isExceedStockEnabled={campaign.isExceedStockEnabled}
                    />
                  ))
                )
              : (
                <div className="text-center">
                  No bundles selected
                </div>
                )
          }
        </div>
      </div>
      <div className="text-secondary p-0">
        <hr />
      </div>
      <div className="col-12">
        <div className="mb-3">
          <div className="p-0 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Additional Articles</th>
                  <th scope="col">In Stock / Available</th>
                  <th scope="col" className="col-2">Quantity</th>
                  <th scope="col" className="col-1 text-end">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  selectedAdditionalArticles.length > 0
                    ? (
                        selectedAdditionalArticles.map(additionalArticle => (
                          <AdditionalArticleRow
                            key={additionalArticle.id}
                            additionalArticle={additionalArticle}
                            removeAdditionalArticle={removeAdditionalArticle}
                            updateAdditionalArticleQuantity={updateAdditionalArticleQuantity}
                            module="Cart"
                          />
                        ))
                      )
                    : (
                      <tr>
                        <td colSpan={4}><div className="text-center"><span>No additional articles selected</span></div></td>
                      </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
