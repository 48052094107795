import UserService from '../services/api/UserService'
import { User } from '../types'

export const mapUsers = (users: Array<User>) => {
  return users.map((user) => ({
    value: user.id,
    label: `${(user.firstName || '')} ${(user.lastName || '')} <${user.email}>`
  }))
}

export const loadUserOptions = async (page: number, perPage: number, token: string, inputValue: string) => {
  const controller = new AbortController()
  const signal = controller.signal

  if (token) {
    try {
      const res = await UserService.getAllUsers(token, perPage, page, signal, inputValue)
      return mapUsers(res.data.users)
    } catch (error) {
      return mapUsers([])
    }
  } else {
    return mapUsers([])
  }
}
