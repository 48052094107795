import React from 'react'
import Placeholder from '../../assets/images/placeholder.png'

const ShopItemLoader = ({ repetition }: { repetition: number }) => {
  return (
    <>
      {Array.from(Array(repetition).keys()).map((n: number) => (
        <div className="col" key={n}>
          <div role="button"
            tabIndex={0}
            aria-label="Product Loader"
            className="card h-100 product-card"
          >
            <img src={Placeholder} alt="Placeholder thumbnail" className="card-img-top" style={{ objectFit: 'cover', height: 300 }}/>
            <div className="h-100 d-flex align-content-end flex-wrap">
              <div className="card-body p-2">
                <h6 className="card-title placeholder-glow">
                  <span className="placeholder col-10"></span>
                </h6>
                <h6 className="card-title placeholder-glow">
                  <span className="placeholder col-6"></span>
                </h6>
                <div className="border-top">
                  <div className="row">
                    <div className="col-7">
                      <p className="card-text placeholder-glow">
                        <span className="placeholder col-12"></span>
                      </p>
                    </div>
                    <div className="col">
                      <p className="card-text placeholder-glow">
                        <span className="placeholder col-12"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mx-2 mb-2">
              <button className="btn btn-outline-primary me-1" disabled>
                Details
              </button>
              <button className="btn btn-primary" disabled>
                <i className="bi bi-cart"></i>
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default ShopItemLoader
