import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ShippingMethodService from '../../../services/api/ShippingMethodService'
import { ApiShippingMethodState } from '../../../types'
import { SHIPPING_METHOD_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiShippingMethodState = {
  isLoading: false,
  error: null,
  shippingMethod: null,
  shippingMethods: [],
  jtlShippingMethods: [],
  jtlMetadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  },
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getAllShippingMethods = createAsyncThunk('api/shippingMethods/get', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ShippingMethodService.getAllShippingMethods(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const getAllJtlShippingMethods = createAsyncThunk('api/jtlShippingMethods/get', async ({ token, perPage, page, signal }: { token: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ShippingMethodService.getAllJtlShippingMethods(token, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const addShippingMethod = createAsyncThunk('api/shippingMethods/add', async ({ token, shippingMethod, signal }: { token: string, shippingMethod: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ShippingMethodService.addShippingMethod(token, shippingMethod, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const editAShippingMethodById = createAsyncThunk('api/shippingMethods/edit', async ({ token, salutationId, shippingMethod, signal }: { token: string, salutationId: any, shippingMethod: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ShippingMethodService.editShippingMethodById(token, salutationId, shippingMethod, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

export const deleteAShippingMethodById = createAsyncThunk('api/shippingMethods/delete', async ({ token, salutationId, signal }: { token: string, salutationId: any, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await ShippingMethodService.deleteShippingMethodById(token, salutationId, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const salutationSlice = createSlice({
  name: 'api/shippingMethod',
  initialState,
  reducers: {
    resetSalutationError: (state) => {
      state.error = null
    },
    resetSalutationMessage: (state) => {
      state.message = null
    },
    resetSalutationData: (state) => {
      state.error = null
      state.shippingMethod = null
      state.shippingMethods = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllShippingMethods.pending, (state) => {
        state.shippingMethods = []
        state.isLoading = true
      })
      .addCase(getAllShippingMethods.fulfilled, (state, action) => {
        state.isLoading = false
        state.shippingMethods = action.payload.shippingMethods
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllShippingMethods.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(getAllJtlShippingMethods.pending, (state) => {
        state.jtlShippingMethods = []
        state.isLoading = true
      })
      .addCase(getAllJtlShippingMethods.fulfilled, (state, action) => {
        state.isLoading = false
        state.jtlShippingMethods = action.payload.jtlShippingMethods
        state.jtlMetadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getAllJtlShippingMethods.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(addShippingMethod.pending, (state) => {
        state.shippingMethods = []
        state.isLoading = true
      })
      .addCase(addShippingMethod.fulfilled, (state, action) => {
        state.isLoading = false
        state.shippingMethod = action.payload.shippingMethod
        state.error = null
        state.message = null
      })
      .addCase(addShippingMethod.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(editAShippingMethodById.pending, (state) => {
        state.shippingMethods = []
        state.isLoading = true
      })
      .addCase(editAShippingMethodById.fulfilled, (state, action) => {
        state.isLoading = false
        state.shippingMethod = action.payload.shippingMethod
        state.error = null
        state.message = null
      })
      .addCase(editAShippingMethodById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deleteAShippingMethodById.pending, (state) => {
        state.shippingMethods = []
        state.isLoading = true
      })
      .addCase(deleteAShippingMethodById.fulfilled, (state, action) => {
        state.isLoading = false
        state.shippingMethod = null
        state.error = null
        state.message = SHIPPING_METHOD_DELETION_MESSAGE
      })
      .addCase(deleteAShippingMethodById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetSalutationError, resetSalutationMessage, resetSalutationData } = salutationSlice.actions

const { reducer } = salutationSlice
export default reducer
