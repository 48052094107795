import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CampaignOrderLimitService from '../../../services/api/CampaignOrderLimitService'
import { ApiCampaignOrderLimitState } from '../../../types'
import { ORDER_LIMIT_DELETION_MESSAGE } from '../../../constants/messages'

const initialState: ApiCampaignOrderLimitState = {
  isLoading: false,
  error: null,
  message: null
}

export const deleteCampaignOrderLimit = createAsyncThunk('api/campaign-order-limit/delete', async ({ id, token, signal }: { id: string, token: string, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await CampaignOrderLimitService.deleteCampaignOrderLimit(id, token, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error.response.data)
  }
})

const CampaignOrderLimitSlice = createSlice({
  name: 'api/campaign-order-limit',
  initialState,
  reducers: {
    resetCampaignOrderLimitError: (state) => {
      state.error = null
    },
    resetCampaignOrderLimitMessage: (state) => {
      state.message = null
    },
    resetCampaignOrderLimitData: (state) => {
      state.error = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCampaignOrderLimit.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteCampaignOrderLimit.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
        state.message = ORDER_LIMIT_DELETION_MESSAGE
      })
      .addCase(deleteCampaignOrderLimit.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetCampaignOrderLimitError, resetCampaignOrderLimitMessage, resetCampaignOrderLimitData } = CampaignOrderLimitSlice.actions

const { reducer } = CampaignOrderLimitSlice
export default reducer
