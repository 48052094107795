import { CSSProperties } from 'react'

const GREY = '#CCC'
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)'
const GREY_DIM = '#686868'

export const styles = {
  zone: {
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: GREY,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
    cursor: 'pointer'
  } as CSSProperties,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 16,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center'
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 4,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex'
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 4,
    fontSize: 12,
    marginBottom: '0.5em'
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM
  } as CSSProperties,
  default: {
    borderColor: GREY
  } as CSSProperties,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23
  } as CSSProperties
}
