import React, { ChangeEvent } from 'react'
import dayjs from 'dayjs'
import { Bundle, Product } from '../../../types'
import Pagination from '../../Pagination'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import ActiveBundleRow from './ActiveBundleRow'
import { openModal } from '../../../utils/openModal'
import { dismissModal } from '../../../utils/dismissModal'
import AdditionalArticleRow from '../AdditionalArticleViewer/AdditionalArticleRow'
import { setToast } from '../../../store/reducers/toastReducer'
import BundleSelectorItem from './BundleSelectorItem'

interface BundleSelectorProps {
  selectableBundles: Bundle[]
  activeBundles: Bundle[]
  additionalArticles: Product[]
  addBundle: (bundle: Bundle) => void
  removeBundle: (bundle: Bundle) => void
  updateBundleQuantity: (activeBundle: Bundle, quantity: number) => void
  pageBundle: number
  perPageBundle: number
  handleBundlePageChange: (page: number) => void
  handleBundleShowEntries: (event: ChangeEvent<HTMLSelectElement>) => void
  setCancelPressed: React.Dispatch<React.SetStateAction<boolean>>
  removeAdditionalArticle: (product: Product) => void
  updateAdditionalArticleQuantity: (product: Product, quantity: number) => void
  isBundleWriteAllowed: boolean
  isExceedStockEnabled: boolean
}

const BundleSelector = ({
  selectableBundles,
  activeBundles,
  additionalArticles,
  addBundle,
  removeBundle,
  pageBundle,
  perPageBundle,
  handleBundlePageChange,
  handleBundleShowEntries,
  updateBundleQuantity,
  setCancelPressed,
  removeAdditionalArticle,
  updateAdditionalArticleQuantity,
  isBundleWriteAllowed,
  isExceedStockEnabled
}: BundleSelectorProps) => {
  const isLoadingCampaignBundles = useAppSelector((state) => state.apiCampaign.isLoadingBundles)
  const bundlesMetadata = useAppSelector((state) => state.apiCampaign.bundlesMetadata)

  const dispatch = useAppDispatch()

  return (
    <div className="row">
      <div className="col">
        <div className="p-0">
          <div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4" id="bundles">
              {
                selectableBundles.length > 0
                  ? (
                      selectableBundles.map((bundle) => (
                        <BundleSelectorItem
                          key={bundle.id}
                          bundle={bundle}
                          activeBundles={activeBundles}
                          removeBundle={removeBundle}
                          addBundle={addBundle}
                          isExceedStockEnabled={isExceedStockEnabled}
                        />
                      ))
                    )
                  : (
                    <div className="col">
                      <div className={'card h-100 bundle-card'} role="button">
                        <div
                          onClick={() => {
                            setCancelPressed(true)
                            if (isBundleWriteAllowed) {
                              openModal('billOfMaterialModal')
                              dismissModal('createOrderModal')
                            } else {
                              const payload = {
                                title: 'Info',
                                message: 'Contact the administrator to add bundles',
                                isVisible: true,
                                timestamp: dayjs().format('LT'),
                                type: 'info'
                              }
                              dispatch(setToast(payload))
                            }
                          }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              setCancelPressed(true)
                              if (isBundleWriteAllowed) {
                                openModal('billOfMaterialModal')
                                dismissModal('createOrderModal')
                              } else {
                                const payload = {
                                  title: 'Info',
                                  message: 'Contact the administrator to add bundles',
                                  isVisible: true,
                                  timestamp: dayjs().format('LT'),
                                  type: 'info'
                                }
                                dispatch(setToast(payload))
                              }
                            }
                          }}
                          role="button"
                          tabIndex={0}
                          aria-label="Add Bundle To Campaign"
                          className="h-100"
                        >
                          <div className="card-body py-5">
                            <div className="text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                              </svg>
                            </div>
                            <h6 className="card-title text-center text-primary mt-3">
                              Add a bundle to the campaign to get started
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    )
              }
              <div
                className="col"
              >
                <div className={'card h-100 bundle-card'} role="button">
                  <div
                    onClick={() => {
                      openModal('additionalArticleViewerModal')
                      dismissModal('createOrderModal')
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        openModal('additionalArticleViewerModal')
                        dismissModal('createOrderModal')
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label="Select Additional Articles"
                    className="h-100 d-flex align-items-center"
                  >
                    <div className="card-body py-5">
                      <div className="text-center py-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-plus-circle text-primary" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                        </svg>
                      </div>
                      <h6 className="card-title text-center text-primary mt-3">
                        Select Additional Articles
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <Pagination
                isLoading={isLoadingCampaignBundles}
                metadata={{
                  limit: bundlesMetadata.perPage,
                  total: bundlesMetadata.total,
                  offset: ((bundlesMetadata.page - 1) * (bundlesMetadata.perPage))
                }}
                page={pageBundle}
                perPage={perPageBundle}
                handlePageChange={handleBundlePageChange}
                handleShowEntries={handleBundleShowEntries}
                perPageOptions={[5, 10, 25]}
                module="bundleSelector"
              />
            </div>
          </div>

          <div className="col-12 mt-4">
            <div className="mb-3">
              <div className="p-0 table-responsive">
                <table className="table table-hover table-centered table-nowrap align-middle">
                  <thead>
                    <tr>
                      <th scope="col">Selected Bundles</th>
                      <th scope="col">In Stock / Available</th>
                      <th scope="col" className="col-2">Quantity</th>
                      <th scope="col" className="text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      activeBundles.length > 0
                        ? (
                            activeBundles.map(activeBundle => (
                              <ActiveBundleRow
                                key={activeBundle.id}
                                activeBundle={activeBundle}
                                removeBundle={removeBundle}
                                updateBundle={updateBundleQuantity}
                                isExceedStockEnabled={isExceedStockEnabled}
                              />
                            ))
                          )
                        : (
                            <tr>
                              <td colSpan={4}><div className="text-center"><span>No bundles selected</span></div></td>
                            </tr>
                          )
                    }
                  </tbody>
                  <tbody>
                    <tr>
                      <th scope="col">Additional Articles</th>
                      <th scope="col">In Stock / Available</th>
                      <th scope="col" className="col-2">Quantity</th>
                      <th scope="col" className="text-end">Actions</th>
                    </tr>
                    {
                      additionalArticles.length > 0
                        ? (
                            additionalArticles.map(additionalArticle => (
                              <AdditionalArticleRow
                                key={additionalArticle.id}
                                additionalArticle={additionalArticle}
                                updateAdditionalArticleQuantity={updateAdditionalArticleQuantity}
                                removeAdditionalArticle={removeAdditionalArticle}
                                module="BundleSelector"
                              />
                            ))
                          )
                        : (
                          <tr>
                            <td colSpan={4}><div className="text-center"><span>No additional articles selected</span></div></td>
                          </tr>
                          )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setCancelPressed(true)
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dismissModal('createOrderModal')
                  if (activeBundles.length > 0) {
                    openModal('itemViewerModal')
                  } else {
                    openModal('cartModal')
                  }
                }}
                disabled={activeBundles.length === 0 && additionalArticles.length === 0}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BundleSelector
