import React, { ChangeEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Progress from '../../components/loaders/Progress'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  createACompany,
  deleteCompanyById,
  getCompanies, requestDomainVerification,
  resetCompanyError, resetCompanyMessage, updateCompanyById,
  updateCompanyDomainVerificationStatus, verifyDomain
} from '../../store/reducers/api/companyReducer'
import { setToast } from '../../store/reducers/toastReducer'

import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { Company } from '../../types'
import CompanyEditor from '../../components/MyCompany/CompanyEditor'
import { Link, useNavigate } from 'react-router-dom'
import * as userRoles from '../../constants/userRoles'
import CompanyDomainStatusEditor from '../../components/MyCompany/CompanyDomainStatusEditor'
import { errorStatusCodes } from '../../constants/statusCodes'
import Pagination from '../../components/Pagination'
import DeleteConfirmationModal from '../../components/modals/DeleteConfirmationModal'
import { dismissModal } from '../../utils/dismissModal'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { VerifiedIcon } from '../../components/icons/VerifiedIcon'
import useDebounce from '../../utils/hooks/useDebounce'

const Companies = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoading)
  const error = useAppSelector((state) => state.apiCompany.error)
  const createdCompany = useAppSelector((state) => state.apiCompany.createdCompany)
  const companies = useAppSelector((state) => state.apiCompany.companies)
  const company = useAppSelector((state) => state.apiCompany.company)
  const message = useAppSelector((state) => state.apiCompany.message)
  const metadata = useAppSelector((state) => state.apiCompany.metadata)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 800)

  const [initialCompany, setInitialCompany] = useState<Partial<Company>>({
    name: '',
    suffix: '',
    email: '',
    domain: '',
    vat: ''
  })

  const token = currentUser?.token

  const supportEmail = process.env.REACT_APP_VERIFICATION_EMAIL

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
    localStorage.setItem('perPageCompanies', event.target.value)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    localStorage.setItem('pageCompanies', String(page))
  }

  const handleCompaniesRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      dispatch(getCompanies({ token, perPage, page, signal, search: debouncedSearchTerm }))
    }
  }

  useEffect(() => {
    if (error && error?.errors?.message) {
      const payload = {
        title: errorStatusCodes[error.statusCode],
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [error])

  useEffect(() => {
    if (createdCompany) {
      const payload = {
        title: 'Success',
        message: 'Company set up successfully',
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('companyModal')
    }
  }, [createdCompany])

  useEffect(() => {
    if (message) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dispatch(resetCompanyMessage())
      dismissModal('editModal')
      dismissModal('confirmationModal')
      dismissModal('verifyModal')
      dismissModal('domainVerificationModal')
    }
  }, [message])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token) {
      dispatch(getCompanies({ token, perPage, page, signal, search: debouncedSearchTerm }))
    }
    if (company) {
      setInitialCompany(company)
    }

    return () => {
      controller.abort()
    }
  }, [page, perPage, createdCompany, company, message, debouncedSearchTerm])

  return (
    <main>
      <div className="container-fluid px-4 py-4">
        <div className="card">
          <div className="m-4">
            <div className="navbar navbar-expand mb-3">
              <p className="h5"><i className="bi bi-building me-1"></i> All Companies</p>
              {
                (
                  <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
                    <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                      <Formik
                        validationSchema={searchSchema}
                        enableReinitialize
                        initialValues={{
                          search: ''
                        }}
                        onSubmit={({ search }, actions) => {
                          setSearchTerm(search)
                          if (page !== 1) {
                            setPage(1)
                            navigate('')
                          }

                          actions.setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="input-group">
                              <input
                                onChange={(event) => {
                                  const search = event.target.value
                                  handleChange(event)
                                  setSearchTerm(search)
                                  if (page !== 1) {
                                    setPage(1)
                                    navigate('')
                                  }
                                }}
                                maxLength={24}
                                onBlur={handleBlur}
                                value={values.search}
                                className={`form-control ${
                                  errors.search &&
                                  touched.search &&
                                  errors.search
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                type="text"
                                placeholder="Search..."
                                aria-label="Search"
                                aria-describedby="btnNavbarCompaniesSearch"
                                name="search"
                                autoComplete="on"
                              />
                              <button
                                className="btn btn-outline-dark"
                                id="btnNavbarCompaniesSearch"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      title="Add Company"
                      aria-label="Add Company"
                      data-bs-toggle="modal"
                      data-bs-target="#companyModal"
                      onClick={() => {
                        setInitialCompany({
                          name: '',
                          suffix: '',
                          email: '',
                          domain: '',
                          vat: ''
                        })
                      }}
                    >
                      <i className="bi bi-plus-circle"></i>
                      <span className="ms-1 d-none d-md-inline-block">Add Company</span>
                    </button>
                    <button
                      type="button"
                      title="Refresh"
                      aria-label="Refresh"
                      className="btn btn-outline-dark ms-2 ms-md-3"
                      onClick={() => handleCompaniesRefresh()}
                    >
                      <i className="fas fa-redo"></i>
                    </button>
                  </ul>
                )
              }
            </div>
            {isLoading ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col" className="text-nowrap">Company ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col" className="text-nowrap">Date Created</th>
                    <th scope="col">Verified</th>
                    <th scope="col" className="text-center">
                      <div className="float-end">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    isLoading
                      ? (
                          Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={6} actionQuantity={3} />)
                        )
                      : (
                          companies.length > 0
                            ? (companies.map((company: Company) => (
                              <tr key={company.id} className={initialCompany.id === company.id ? 'table-primary' : ''}>
                                <td className="user-select-all" onClick={() => navigator?.clipboard.writeText(String(company.id))}>
                                  {(String(company.id)).substring(0, 8)}
                                </td>
                                <td>
                                  <Link className="text-decoration-none" to={`/companies/${company.id}`}>
                                    {`${company.name}`}
                                  </Link>
                                </td>
                                <td>{company.email}</td>
                                <td className="text-nowrap">{dayjs(company.createdAt).format('ll')}</td>
                                <td className="text-center">
                                  <span>
                                    {company.isDomainVerified ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>}
                                  </span>
                                  {(company.isDomainVerified === false && currentUser?.role !== userRoles.ADMIN) && <button
                                    className="ms-1 btn"
                                    type="button"
                                    title="Verify Domain"
                                    data-bs-toggle="modal"
                                    data-bs-target="#verifyModal"
                                    onClick={() => {
                                      setInitialCompany(company)
                                    }}
                                  >
                                    <i className="bi bi-shield-check text-secondary"></i>
                                  </button>}
                                </td>
                                <td className="text-center">
                                  <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                    <button
                                      className="btn btn-outline-dark btn-round me-2"
                                      type="button"
                                      title="Edit Company"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editModal"
                                      onClick={() => {
                                        setInitialCompany(company)
                                      }}
                                    >
                                      <PencilIcon/>
                                    </button>
                                    {
                                      currentUser?.role === userRoles.ADMIN && (
                                        <button
                                          className="btn btn-outline-dark btn-round me-2"
                                          type="button"
                                          title="Update Company Domain Verification"
                                          data-bs-toggle="modal"
                                          data-bs-target="#domainVerificationModal"
                                          onClick={() => {
                                            setInitialCompany(company)
                                          }}
                                        >
                                          <VerifiedIcon/>
                                        </button>
                                      )
                                    }
                                    <button
                                      className="btn btn-outline-danger btn-round"
                                      type="button"
                                      title="Delete Company"
                                      data-bs-toggle="modal"
                                      data-bs-target="#confirmationModal"
                                      onClick={() => {
                                        setInitialCompany(company)
                                      }}
                                    >
                                      <TrashIcon/>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              ))
                              )
                            : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  No companies available yet
                                </td>
                              </tr>
                              )
                        )
                  }
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              metadata={{
                limit: metadata.perPage,
                total: metadata.total,
                offset: ((metadata.page - 1) * (metadata.perPage))
              }}
              page={page}
              perPage={perPage}
              handlePageChange={handlePageChange}
              handleShowEntries={handleShowEntries}
              isTrackingPage
            />
          </div>
        </div>
      </div>

      <div className="modal fade" id="companyModal" tabIndex={-1} aria-labelledby="companyModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="companyModalLabel">
                <i className={'bi bi-plus-circle me-1'}></i>{' '}
                {'Add Company'}
              </h5>
              <button type="button"
                onClick={() => dispatch(resetCompanyError())}
                className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              >
              </button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <CompanyEditor
                id=""
                initialCompany={initialCompany}
                save={createACompany}
              />
            </div>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        isLoading = {isLoading}
        deleteById={deleteCompanyById}
        id={String(initialCompany?.id)}
        name={<><span className="fw-bold">{`'${initialCompany?.name}'`}</span> company</>}
        token={String(token)}
        autoDismiss={false}
      />

      <div className="modal fade" id="editModal" tabIndex={-1} aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="editModalLabel">
                <i className="bi bi-pencil-square me-2"></i>Edit Company
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <p>
                Edit <b>{`'${initialCompany.name}'`}</b>
              </p>
              <CompanyEditor
                id={String(initialCompany.id)}
                initialCompany={initialCompany}
                save={updateCompanyById}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="verifyModal" tabIndex={-1} aria-labelledby="verifyModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="verifyModalLabel">
                <i className="bi bi-pencil-square me-2"></i>Verify Company Domain
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <p>
                Domain: <b>{`${initialCompany.domain || ''}`}</b><br />
                Verification Status: <span className="fw-bold">{String(initialCompany.isDomainVerified)}</span>
              </p>
              <p>
                <span className="fw-bold">Domain Verification Code: </span>{initialCompany.domainVerificationCode?.value ?? 'Not Requested'}
                {initialCompany.domainVerificationCode?.value && <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(String(initialCompany.domainVerificationCode?.value))
                  }}
                  className="btn btn-outline-secondary btn-sm ms-1"
                >
                  <i className="fa fa-clone" title="copy to clipboard"></i>
                </button>}
              </p>
              <div className="d-grid gap-2 d-md-flex">
                <button
                  className="btn btn-primary me-md-2"
                  type="button"
                  onClick={() => {
                    if (initialCompany?.id && token) {
                      const controller = new AbortController()
                      const signal = controller.signal
                      dispatch(requestDomainVerification({ id: initialCompany?.id, token, signal }))
                    }
                  }}
                >
                  Get Verification Code
                </button>
              </div>
              <hr />
              <div className="mt-2">
                <h4>Automated Verification</h4>
                <h5>DNS Record</h5>
                <p>
                  Verify the ownership of your domain by adding a TXT record to you DNS records under the DNS Settings
                </p>
                <h6>Steps</h6>
                <ol>
                  <li>Request a domain verification code by clicking the <span className="fw-bold">Get Verification Code</span> button above.</li>
                  <li>Once you receive the verification code, add this code as a TXT record to your DNS Records.</li>
                  <li>Click the <span className="fw-bold">Verify Domain</span> button. Note: If your domain is not verified immediately keep trying at intervals.</li>
                </ol>
                <div className="d-grid gap-2 d-md-flex">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      if (initialCompany?.id && token) {
                        const controller = new AbortController()
                        const signal = controller.signal
                        dispatch(verifyDomain({ id: initialCompany?.id, token, signal }))
                      }
                    }}
                  >
                    Verify Domain
                  </button>
                </div>
              </div>
              <hr />
              <div>
                <h4>Manual Verification</h4>
                <h6>Steps</h6>
                <ol>
                  <li>Request a domain verification code by clicking the <span className="fw-bold">Get Verification Code</span> button above.</li>
                  <li>Choose any of the methods below to verify your company domain.</li>
                </ol>
                <p>
                  <span className="fw-bold">Domain Verification Code: </span>
                  {(initialCompany.domainVerificationCode?.value) ? (initialCompany.domainVerificationCode?.value)?.split('=').pop() : 'Not Requested'}
                  {initialCompany.domainVerificationCode?.value && <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(String((initialCompany.domainVerificationCode?.value)?.split('=').pop()))
                    }}
                    className="btn btn-outline-secondary btn-sm ms-1"
                  >
                    <i className="fa fa-clone" title="copy to clipboard"></i>
                  </button>}
                </p>
                <h5>Telephone</h5>
                <p>For a manual verification call us. Please have your domain verification code ready.<br />
                  <span className="h6">Tel: 089 - 20092033</span>
                </p>
                <h5>Email</h5>
                <p>For a manual verification you can also send us an email. Please have your domain verification code ready.<br />
                  <span className="h6">Email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="domainVerificationModal" tabIndex={-1} aria-labelledby="domainConfirmationModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="domainConfirmationModalLabel">
                <i className="bi bi-shield-check me-2"></i>Update Domain Verification Status
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {isLoading && <Progress />}
            <div className="modal-body">
              <p>
                Edit <b>{`'${initialCompany.name}'`}</b>
              </p>
              <CompanyDomainStatusEditor
                companyId={String(initialCompany.id)}
                initialCompany={{ isDomainVerified: Boolean(initialCompany?.isDomainVerified) }}
                save={updateCompanyDomainVerificationStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Companies
