import React from 'react'
import { Item, Order } from '../../../types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Link } from 'react-router-dom'
dayjs.extend(utc)

const OutboundOrder = ({ outbound }: { outbound: Order }) => {
  const quantity = outbound.items.filter((item: Item) => item.itemType === 'BillOfMaterials').reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0) || Math.max(...outbound.items.map(item => item.quantity))
  const date = outbound.shipped ? dayjs.utc(outbound.shipped).local().format('LL') : '---'

  return (
    <tr>
      <td>
        <Link title="View Order Details" className="text-decoration-none" to={`/all-orders/${outbound.outboundId}`}>
          {outbound.outboundId}
        </Link>
      </td>
      <td className="text-center"><span className="badge text-bg-secondary">{quantity}</span></td>
      <td>{date}</td>
      <td>{outbound.createdByFullName || '--'}</td>
    </tr>
  )
}

export default OutboundOrder
