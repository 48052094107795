import { createSlice } from '@reduxjs/toolkit'
import { PreMadeBox } from '../../types'

const initialState: PreMadeBox = {
  preMadeBox: []
}

const preMadeBoxSlice = createSlice({
  name: 'preMadeBox',
  initialState,
  reducers: {
    addToPreMadeBox: (state, action) => {
      if (!state.preMadeBox.includes(action.payload)) {
        state.preMadeBox.push(action.payload)
      }
    },
    removeFromPreMadeBox: (state, action) => {
      state.preMadeBox = state.preMadeBox.filter(item => item !== action.payload)
    },
    resetPreMadeBox: (state) => {
      state.preMadeBox = []
    }
  }
})
export const { addToPreMadeBox, removeFromPreMadeBox, resetPreMadeBox } = preMadeBoxSlice.actions
const { reducer } = preMadeBoxSlice
export default reducer
