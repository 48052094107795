import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Logo from '../../components/Logo'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom'
import { loginUser, resetApiAuthError } from '../../store/reducers/api/authReducer'
import Progress from '../../components/loaders/Progress'
import { resetUserMessage } from '../../store/reducers/api/usersReducer'

const Login = () => {
  const isLoading = useAppSelector((state) => state.apiAuth.isLoading)
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const error = useAppSelector((state) => state.apiAuth.error)
  const userMessage = useAppSelector((state) => state.apiUsers.message)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { state }: { state: any } = useLocation()

  const loginSchema = object({
    email: string().email('Enter a valid email')
      .required('Email is required'),
    password: string()
      .required('Password is required').min(4, 'Password must be at least 4 characters')
  })

  useEffect(() => {
    if (currentUser?.token) {
      dispatch(resetApiAuthError())
      dispatch(resetUserMessage())
      const origin = state?.from?.pathname || '/'
      navigate(origin, { })
    }
  }, [currentUser])

  if (currentUser?.token) {
    const origin = state?.from?.pathname || '/'
    return <Navigate to={origin} />
  }
  return (
    <main className="bg-dark">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <Logo isWhite />
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header">
                      <h3 className="text-center font-weight-light my-4">
                        Login
                      </h3>
                    </div>
                    {isLoading && <Progress />}
                    <div className="card-body">
                      {
                        (state?.message || userMessage) && (
                          <div className="alert alert-success" role="alert">
                            {state?.message || userMessage}
                          </div>
                        )
                      }
                      {
                        (error?.statusCode === 503 || error?.statusCode === 500 || error?.statusCode === 400) && (
                          <div className="alert alert-danger" role="alert">
                            {`Kindly try again later, ${error?.errors?.message}`}
                          </div>
                        )
                      }
                      <Formik
                        validationSchema={loginSchema}
                        initialValues={{ email: state?.email || '', password: '' }}
                        onSubmit={({ email, password }, actions) => {
                          const controller = new AbortController()
                          const signal = controller.signal
                          const user = {
                            email, password
                          }
                          dispatch(loginUser({ user, signal }))
                          actions.setSubmitting(false)
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-floating mb-3">
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className={`form-control ${((errors.email && touched.email && errors.email) || error?.statusCode === 404) ? 'is-invalid' : ''}`}
                                id="inputEmail"
                                type="email"
                                name="email"
                                aria-describedby="validationEmailFeedback"
                              />
                              <label htmlFor="inputEmail">Email address</label>
                              <div id="validationEmailFeedback" className="invalid-feedback">
                                {errors.email || error?.errors?.message}
                              </div>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                className={`form-control ${((errors.password && touched.password && errors.password) || error?.statusCode === 401) ? 'is-invalid' : ''}`}
                                id="inputPassword"
                                type="password"
                                name="password"
                                aria-describedby="validationPasswordFeedback"
                              />
                              <label htmlFor="inputPassword">Password</label>
                              <div id="validationPasswordFeedback" className="invalid-feedback">
                                {errors.password || error?.errors?.message}
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                              <Link className="small" to="/forgot-password">
                                Forgot Password?
                              </Link>
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={isSubmitting || isLoading}
                              >
                                Login
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    <div className="card-footer text-center py-3">
                      <div className="small">
                        <Link to="/register">Need an account? Sign up!</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </main>
  )
}

export default Login
