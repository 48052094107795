import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PendingOrderService from '../../../../services/api/dotnet/PendingOrderService'
import { DotnetApiPendingOrderState } from '../../../../types'
import { PENDING_ORDER_DELETION_MESSAGE, PENDING_ORDER_UPDATE_MESSAGE } from '../../../../constants/messages'

const initialState: DotnetApiPendingOrderState = {
  isLoading: false,
  error: null,
  pendingOrder: null,
  pendingOrders: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getPendingOrders = createAsyncThunk('dotnet/api/pending-orders', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search: string }, { rejectWithValue }) => {
  try {
    const res = await PendingOrderService.getPendingOrders(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const deletePendingOrderById = createAsyncThunk('dotnet/api/pending-orders/delete', async ({ token, id, signal }: { token: string, id: string, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await PendingOrderService.deletePendingOrderById(token, id, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

export const updatePendingOrderById = createAsyncThunk('dotnet/api/pending-orders/update', async ({ token, id, pendingOrder, signal }: { token: string, id: string, pendingOrder: any, signal: AbortSignal }, { rejectWithValue }) => {
  try {
    const res = await PendingOrderService.updatePendingOrderById(token, id, pendingOrder, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const pendingOrderSlice = createSlice({
  name: 'dotnet/api/pending-orders',
  initialState,
  reducers: {
    resetPendingOrderError: (state) => {
      state.error = null
    },
    resetPendingOrderMessage: (state) => {
      state.message = null
    },
    resetPendingOrderData: (state) => {
      state.error = null
      state.pendingOrder = null
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPendingOrders.pending, (state) => {
        state.pendingOrders = []
        state.isLoading = true
      })
      .addCase(getPendingOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.pendingOrders = action.payload.pendingOrders
        state.metadata = action.payload.meta
        state.error = null
      })
      .addCase(getPendingOrders.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(deletePendingOrderById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePendingOrderById.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PENDING_ORDER_DELETION_MESSAGE
        state.error = null
      })
      .addCase(deletePendingOrderById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
    builder
      .addCase(updatePendingOrderById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePendingOrderById.fulfilled, (state, action) => {
        state.isLoading = false
        state.message = PENDING_ORDER_UPDATE_MESSAGE
        state.error = null
      })
      .addCase(updatePendingOrderById.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetPendingOrderError, resetPendingOrderMessage, resetPendingOrderData } = pendingOrderSlice.actions

const { reducer } = pendingOrderSlice
export default reducer
