import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import _ from 'lodash'
import Select from 'react-select'
import { countriesObject } from '../../utils/countries'
import { AddressType, UserCompany, AddressMode, Address, CompanyMode } from '../../types'
import { useAppDispatch } from '../../store/hooks'
import { addCompanyAddress } from '../../store/reducers/api/companyReducer'
import { phoneValidationPattern } from '../../constants/regexPatterns'

interface OnboardingAddressEditorProps {
  setMode: Function
  setTitle: Function
  company: UserCompany | null | undefined
  token: string | null
  addressType: AddressType | null
  isBillingAndDeliveryAddress: boolean
  setIsBillingAndDeliveryAddress: Function
  addressTitle: string
  addressMode: AddressMode
  setAddressMode: Function
  setCompanyMode: React.Dispatch<React.SetStateAction<CompanyMode>>
}

const OnboardingAddressEditor = ({
  setMode,
  setTitle,
  company,
  token,
  addressType,
  isBillingAndDeliveryAddress,
  setIsBillingAndDeliveryAddress,
  addressTitle,
  addressMode,
  setAddressMode,
  setCompanyMode
} : OnboardingAddressEditorProps) => {
  const [shippingAddress, setShippingAddress] = useState<Address | undefined | null>(null)
  const dispatch = useAppDispatch()

  const countries = countriesObject.map(country => ({ value: country.country, label: country.country }))
  const [billingAndDeliveryAddress] = useState<Address | undefined | null>(company?.addresses && company?.addresses.find((address) => address.type === 'billingAndDelivery'))
  const [billingAddress] = useState<Address | undefined | null>(company?.addresses && company?.addresses.find((address) => address.type === 'billing'))
  const [deliveryAddress] = useState<Address | undefined | null>(company?.addresses && company?.addresses.find((address) => address.type === 'delivery'))

  const addressHasChanges = (originalAddress: Address, savedAddress: Address) => {
    return !(_.isEqual(_.pick(originalAddress, ['addressAddition', 'city', 'country', 'phone', 'street', 'zip']), savedAddress))
  }

  const addressSchema = object({
    country: string().required('Country is required').oneOf(countries.map(country => country.value)),
    city: string()
      .required('City is required')
      .max(32, 'City name is too long'),
    street: string()
      .required('Street and House Number are required')
      .max(64, 'Street and House Number are too long'),
    zip: string()
      .required('Zip is required')
      .max(16, 'Zip is too long'),
    phone: string()
      .nullable()
      .matches(phoneValidationPattern, 'Enter a valid phone number'),
    addressAddition: string()
      .nullable()
      .max(255, 'Address Addition is too long')
  })

  useEffect(() => {
    if (billingAddress && addressMode === 'billingAddress') {
      setShippingAddress(billingAddress)
    }
    if (deliveryAddress && addressMode === 'deliveryAddress') {
      setShippingAddress(deliveryAddress)
    }
    if (isBillingAndDeliveryAddress) {
      if (billingAndDeliveryAddress) {
        setShippingAddress(billingAndDeliveryAddress)
      } else {
        setShippingAddress(null)
      }
    } else {
      if (addressMode === 'billingAddress' && !billingAddress) {
        setShippingAddress(null)
      }
      if (addressMode === 'deliveryAddress' && !deliveryAddress) {
        setShippingAddress(null)
      }
    }
  }, [addressMode, isBillingAndDeliveryAddress])

  useEffect(() => {
    if (isBillingAndDeliveryAddress) {
      setShippingAddress(company?.addresses && company?.addresses.find((address) => address.type === 'billingAndDelivery'))
    } else {
      if (addressMode === 'billingAddress') {
        setShippingAddress(company?.addresses && company?.addresses.find((address) => address.type === 'billing'))
      }
      if (addressMode === 'deliveryAddress') {
        setShippingAddress(company?.addresses && company?.addresses.find((address) => address.type === 'delivery'))
      }
    }
  }, [company])

  return (
    <div>
      <Formik
        validationSchema={addressSchema}
        enableReinitialize
        initialValues={{
          country: shippingAddress?.country || '',
          city: shippingAddress?.city || '',
          street: shippingAddress?.street || '',
          zip: shippingAddress?.zip || '',
          phone: shippingAddress?.phone || '',
          addressAddition: shippingAddress?.addressAddition || ''
        }}
        onSubmit={(
          { country, city, street, zip, phone, addressAddition },
          actions
        ) => {
          const companyId = company?.id
          const controller = new AbortController()
          const signal = controller.signal
          const address = {
            country,
            city,
            street,
            zip,
            phone,
            addressAddition,
            type: isBillingAndDeliveryAddress ? 'billingAndDelivery' : addressType,
            affiliation: 'company'
          }
          if (token && companyId) {
            dispatch(addCompanyAddress({ id: companyId, token, address, signal }))
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <p className="mb-3 h6">
                {`${isBillingAndDeliveryAddress ? 'Billing and Delivery' : addressTitle} Address`}
              </p>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="billingAddressStreet" className="form-label">
                      Street and House Number
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.street}
                      type="text"
                      className={`form-control ${
                        (errors.street && touched.street)
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="billingAddressStreet"
                      name="street"
                      placeholder="Enter street and House Number"
                      autoComplete="on"
                    />
                    <div
                      id="validationBillingAddressStreetFeedback"
                      className="invalid-feedback"
                    >
                      {errors.street}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="billingAddressZip" className="form-label">
                      Zip
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zip}
                      type="text"
                      className={`form-control ${
                        (errors.zip && touched.zip)
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="billingAddressZip"
                      name="zip"
                      placeholder="Enter zip"
                      autoComplete="on"
                    />
                    <div
                      id="validationBillingAddressZipFeedback"
                      className="invalid-feedback"
                    >
                      {errors.zip}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="billingAddressCity" className="form-label">
                      City
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className={`form-control ${
                        (errors.city && touched.city)
                          ? 'is-invalid'
                          : ''
                      }`}
                      value={values.city}
                      id="billingAddressCity"
                      name="city"
                      placeholder="Enter city"
                      autoComplete="on"
                    />
                    <div
                      id="validationBillingAddressCityFeedback"
                      className="invalid-feedback"
                    >
                      {errors.city}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="billingAddressAddressAddition" className="form-label">
                      Address Addition
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.addressAddition}
                      type="text"
                      className={`form-control ${
                        (errors.addressAddition &&
                        touched.addressAddition)
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="billingAddressAddressAddition"
                      name="addressAddition"
                      placeholder="Enter Address Addition"
                      autoComplete="on"
                    />
                    <div
                      id="validationBillingAddressAddressAdditionFeedback"
                      className="invalid-feedback"
                    >
                      {errors.addressAddition}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="billingAddressPhone" className="form-label">
                      Phone
                    </label>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      type="text"
                      className={`form-control ${
                        (errors.phone && touched.phone)
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="billingAddressPhone"
                      name="phone"
                      placeholder="Enter Phone Number"
                      autoComplete="on"
                    />
                    <div
                      id="validationBillingAddressPhoneFeedback"
                      className="invalid-feedback"
                    >
                      {errors.phone}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="billingAddressCountry" className="form-label">
                      Country
                    </label>
                    <Select
                      className={`${
                        touched.country &&
                        errors.country
                          ? 'is-invalid'
                          : ''
                      }`}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: (errors.country && touched?.country) ? '#dc3545' : provided.borderColor,
                          '&:hover': {
                            borderColor: (errors.country && touched?.country) ? '#dc3545' : provided.borderColor
                          }
                        })
                      }}
                      isClearable
                      inputId="billingAddressCountry"
                      name="country"
                      aria-label="Country"
                      options={countries}
                      getOptionLabel={(country) => `${country.value}`}
                      getOptionValue={(country) => String(country.value)}
                      onChange={(selectedOption) => {
                        const selectedCountry = selectedOption?.value ?? ''
                        setFieldValue('country', selectedCountry)
                      }}
                      onBlur={handleBlur}
                      value={countries.find((country) => country.value === values.country)}
                    />
                    <div
                      id="validationBillingAddressCountryFeedback"
                      className="invalid-feedback"
                    >
                      {errors.country}
                    </div>
                  </div>
                </div>
              </div>

              {
                addressMode === 'billingAddress' && (
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <p>
                          Does your billing address correspond to your delivery address?
                          {!isBillingAndDeliveryAddress && <><br /><span className="small text-primary fw-semibold">
                            Press {!shippingAddress || (shippingAddress && addressHasChanges(shippingAddress, values as Address)) ? "'Save' then" : ''} &apos;Next&apos; to edit the delivery address</span></>}
                        </p>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="editAddresses"
                            id="inlineRadioYes"
                            onChange={() => {
                              setAddressMode('billingAddress')
                              localStorage.setItem('isBillingAndDeliveryAddress', 'yes')
                              setIsBillingAndDeliveryAddress(true)
                            }}
                            value="yes"
                            checked={isBillingAndDeliveryAddress}
                            autoComplete="off"
                          />
                          <label className="form-check-label" htmlFor="inlineRadioYes">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="editAddresses"
                            id="inlineRadioNo"
                            onChange={() => {
                              localStorage.setItem('isBillingAndDeliveryAddress', 'no')
                              setIsBillingAndDeliveryAddress(false)
                            }}
                            value="no"
                            checked={!isBillingAndDeliveryAddress}
                            autoComplete="off"
                          />
                          <label className="form-check-label" htmlFor="inlineRadioNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary"
                onClick={() => {
                  if (addressMode === 'deliveryAddress') {
                    setAddressMode('billingAddress')
                  } else {
                    setMode('assignOrCreateCompany')
                    setCompanyMode('companyCreation')
                  }
                }}
              >
                Back
              </button>
              {
                !shippingAddress || (shippingAddress && addressHasChanges(shippingAddress, values as Address))
                  ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                    )
                  : (
                    <button type="button" className="btn btn-secondary"
                      onClick={() => {
                        if (isBillingAndDeliveryAddress) {
                          setMode('updateProfile')
                          setTitle('Profile Information')
                        } else {
                          if (addressMode === 'billingAddress') {
                            setAddressMode('deliveryAddress')
                          }
                          if (addressMode === 'deliveryAddress') {
                            setMode('updateProfile')
                            setTitle('Profile Information')
                          }
                        }
                      }}
                    >
                      Next
                    </button>
                    )
              }
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default OnboardingAddressEditor
