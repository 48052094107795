export const detectOS = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  const osLookup = {
    windows: { os: 'Windows', encoding: 'ISO-8859-1' },
    mac: { os: 'Mac', encoding: 'UTF-8' },
    linux: { os: 'Linux', encoding: 'UTF-8' },
    iphone: { os: 'iOS', encoding: 'UTF-8' },
    ipad: { os: 'iOS', encoding: 'UTF-8' },
    ipod: { os: 'iOS', encoding: 'UTF-8' },
    android: { os: 'Android', encoding: 'UTF-8' }
  }

  for (const [key, { os, encoding }] of Object.entries(osLookup)) {
    if (userAgent.includes(key)) {
      return { os, encoding }
    }
  }

  return { os: 'Unknown', encoding: 'UTF-8' }
}
