import React from 'react'
import { AddressMode, UserCompany, CompanyMode } from '../../../types'
import OnboardingAddressEditor from '../OnboardingAddressEditor'

interface BillingAddressCreationProps {
  setMode: Function
  setTitle: Function
  company: UserCompany | null | undefined
  token: string | null
  isBillingAndDeliveryAddress: boolean
  setIsBillingAndDeliveryAddress: Function
  addressMode: AddressMode
  setAddressMode: Function
  setCompanyMode: React.Dispatch<React.SetStateAction<CompanyMode>>
}

const BillingAddressCreation = ({
  setMode, setTitle, company, token, isBillingAndDeliveryAddress,
  setIsBillingAndDeliveryAddress, addressMode, setAddressMode,
  setCompanyMode
} : BillingAddressCreationProps) => {
  return (
    <OnboardingAddressEditor
      setMode={setMode}
      setTitle={setTitle}
      company={company}
      token={token}
      addressType={'billing'}
      isBillingAndDeliveryAddress={isBillingAndDeliveryAddress}
      setIsBillingAndDeliveryAddress={setIsBillingAndDeliveryAddress}
      addressTitle="Billing"
      addressMode={addressMode}
      setAddressMode={setAddressMode}
      setCompanyMode={setCompanyMode}
    />
  )
}

export default BillingAddressCreation
