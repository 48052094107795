import React from 'react'

interface TrackingProgressbarProps { orderStatus: string, shippedOrderStatus: string }
type Progress = {
  [key: string]: number,
  Canceled: number;
  Acknowledged: number;
  Pickprocess: number;
  Shipped: number;
}

type ShipmentProgress = {
  [key: string]: number,
  'pre-transit': number;
  transit: number;
  delivered: number;
  failure: number;
  returned: number;
}

const TrackingProgressbar = ({ orderStatus, shippedOrderStatus }: TrackingProgressbarProps) => {
  const progress: Progress = {
    Canceled: 0,
    Acknowledged: 0,
    Pickprocess: 33,
    Shipped: 66
  }

  const ShipmentProgress: ShipmentProgress = {
    'pre-transit': 10,
    transit: 20,
    delivered: 34,
    failure: 34,
    returned: 34
  }

  const processLinePercentage = (progress[orderStatus] + (ShipmentProgress[shippedOrderStatus] || 0))

  return (
    <div className="row justify-content-center m-0">
      <div className="col-lg-7 col-md-10 col-sm-11">
        <div className="horizontal-steps mt-4 mb-4 pb-5" id="tooltip-container">
          <div className="horizontal-steps-content">
            <div
              className={`step-item ${orderStatus === 'Acknowledged' ? 'current' : ''} ${orderStatus === 'Canceled' ? 'text-danger' : ''}`}
            >
              <span
                data-bs-container="#tooltip-container"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title=""
                data-bs-title=""
              >
                {orderStatus === 'Canceled' ? 'Cancelled' : 'Confirmed'}
              </span>
            </div>
            <div className={`step-item ${orderStatus === 'Pickprocess' ? 'current' : ''}`}>
              <span
                data-bs-container="#tooltip-container"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title=""
                data-bs-title=""
              >
                In Progress
              </span>
            </div>
            <div
              className={`step-item ${(orderStatus === 'Shipped' && (!(['delivered', 'failure', 'returned'].includes(shippedOrderStatus)))) ? 'current' : ''}`}
            >
              <span>Shipped</span>
            </div>
            {
              shippedOrderStatus === 'returned'
                ? (
                  <div
                    className={'step-item current text-warning'}
                  >
                    <span>Returned</span>
                  </div>
                  )
                : (
                  <div
                    className={`step-item ${(shippedOrderStatus === 'delivered') && 'current'} ${shippedOrderStatus === 'failure' && 'current text-danger'}`}
                  >
                    <span>{shippedOrderStatus === 'failure' ? 'Failure' : 'Delivered'}</span>
                  </div>
                  )
            }
          </div>
          <div className="process-line" style={{ width: `${processLinePercentage}%` }}></div>
        </div>
      </div>
    </div>
  )
}

export default TrackingProgressbar
