import './theme/custom.scss'
// import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap-icons/font/bootstrap-icons.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { store } from './store/store'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import GenericError from './pages/Errors/GenericError'
import { setHasUpdate } from './store/reducers/toastReducer'

dayjs.extend(utc)

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: String(process.env.REACT_APP_BUGSNAG_API_KEY),
    plugins: [new BugsnagPluginReact()]
  })
}

let ErrorBoundary

const bugsnagPlugin = Bugsnag.getPlugin('react')

if (bugsnagPlugin) {
  ErrorBoundary = bugsnagPlugin.createErrorBoundary(React)
} else {
  // If Bugsnag is not available, create a basic fallback error boundary
  ErrorBoundary = function MyErrorBoundary ({ children }: any) {
    return <>{children}</>
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={GenericError}>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (reg: any) => store.dispatch(setHasUpdate(reg))
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log)
