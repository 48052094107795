import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductSizeService from '../../../services/api/ProductSizeService'
import { ApiProductSizeState } from '../../../types'

const initialState: ApiProductSizeState = {
  isLoading: false,
  error: null,
  productSize: null,
  productSizes: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getProductSizes = createAsyncThunk('api/product-sizes', async ({ token, perPage, page, signal, search }: { token: string, perPage: number, page: number, signal: AbortSignal, search?: string }, { rejectWithValue }) => {
  try {
    const res = await ProductSizeService.getProductSizes(token, perPage, page, signal, search)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const productSizeSlice = createSlice({
  name: 'api/product-size',
  initialState,
  reducers: {
    resetProductSizeError: (state) => {
      state.error = null
    },
    resetProductSizeMessage: (state) => {
      state.message = null
    },
    resetProductSizeData: (state) => {
      state.error = null
      state.productSize = null
      state.productSizes = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductSizes.pending, (state) => {
        state.productSizes = []
        state.isLoading = true
      })
      .addCase(getProductSizes.fulfilled, (state, action) => {
        state.isLoading = false
        state.productSizes = action.payload.productSizes
        state.metadata = action.payload.meta
        state.error = null
        state.message = null
      })
      .addCase(getProductSizes.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetProductSizeError, resetProductSizeMessage, resetProductSizeData } = productSizeSlice.actions

const { reducer } = productSizeSlice
export default reducer
