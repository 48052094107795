import axios from 'axios'
import { Address, CampaignOrderLimit, CardSetting, CardTemplate, Order } from '../../types'

const baseURL = process.env.REACT_APP_API_URL

const apiClient: any = axios.create({
  baseURL: `${baseURL}/api`,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 20000
})

export default {
  getAllCampaigns (token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get('/campaigns', config)
  },

  addCampaignRecipient (id: string, token: string, recipient: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/recipients`, { recipient }, config)
  },

  getCampaignRecipients (id: string, token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/campaigns/${id}/recipients`, config)
  },

  getCampaignById (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/campaigns/${id}`, config)
  },

  updateCampaignById (id: string, token: string, campaign: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.put(`/campaigns/${id}`, { campaign }, config)
  },

  deleteCampaignById (id: string, token: string, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.delete(`/campaigns/${id}`, config)
  },

  createCampaignBundle (id: string, token: string, bundle: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/bundles`, { bundle }, config)
  },

  getCampaignBundles (id: string, token: string, perPage: number, page: number, signal: AbortSignal, search?: string) {
    const config = {
      params: {
        limit: perPage,
        page,
        search
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/campaigns/${id}/bundles`, config)
  },

  getCampaignOrders (id: string, token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page,
        search: search || undefined
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/campaigns/${id}/orders?${filter}`, config)
  },

  getCampaignOrdersForBundle (id: string, jfsku: string, token: string, perPage: number, page: number, search: string, filter: string, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page,
        search: search || undefined
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/campaigns/${id}/orders/${jfsku}?${filter}`, config)
  },

  createBulkOrders (id: string, token: string, pendingOrders: any, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/pending-orders`, { pendingOrders }, config)
  },

  createCampaignCardTemplate (id: string, token: string, cardTemplate: Partial<CardTemplate>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/card-templates`, { cardTemplate }, config)
  },

  getCampaignCardTemplates (id: string, token: string, perPage: number, page: number, signal: AbortSignal) {
    const config = {
      params: {
        limit: perPage,
        page
      },
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.get(`/campaigns/${id}/card-templates`, config)
  },

  createCampaignCardSetting (id: string, token: string, cardSetting: Partial<CardSetting>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/card-settings`, { cardSetting }, config)
  },

  createCampaignShippingDestination (id: string, token: string, campaignShippingDestinations: string[], signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/shipping-destinations`, { campaignShippingDestinations }, config)
  },

  createCampaignOrderLimit (id:string, token: string, campaignOrderLimit: Partial<CampaignOrderLimit>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/order-limits`, { campaignOrderLimit }, config)
  },

  createCampaignAddress (id:string, token: string, campaignAddresses: Array<Partial<Address>>, signal: AbortSignal) {
    const config = {
      signal
    }
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return apiClient.post(`/campaigns/${id}/addresses`, { campaignAddresses }, config)
  },
  async getTotalCampaignOrders (id: string, token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    let page = 1
    let pageCount = 2
    const accumulated: Array<Order> = []
    do {
      const { data } = await apiClient.get(`campaigns/${id}/orders?limit=${2500}&page=${page}`, signal)
      accumulated.push(...data.orders)
      pageCount = data.meta.pageCount
      page++
    } while (page <= pageCount)
    return accumulated
  },

  async getTotalCampaignOrdersForBundle (id: string, jfsku: string, token: string, signal: AbortSignal) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
    let page = 1
    let pageCount = 2
    const accumulated: Array<Order> = []
    do {
      const { data } = await apiClient.get(`campaigns/${id}/orders/${jfsku}?limit=${2500}&page=${page}`, signal)
      accumulated.push(...data.orders)
      pageCount = data.meta.pageCount
      page++
    } while (page <= pageCount)
    return accumulated
  }
}
