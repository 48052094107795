import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import StockProductService from '../../../../services/api/dotnet/StockProductService'
import { DotnetApiStockProductState } from '../../../../types'

const initialState: DotnetApiStockProductState = {
  isLoading: false,
  error: null,
  stockProducts: [],
  message: null,
  metadata: {
    page: 1,
    pageCount: 1,
    perPage: 20,
    total: 0
  }
}

export const getOrderStockProductsByMerchantOutboundNumber = createAsyncThunk('dotnet/api/stockproducts', async ({ token, merchantOutboundNumber, perPage, page, signal }: { token: string, merchantOutboundNumber: string, perPage: number, page: number, signal: AbortSignal}, { rejectWithValue }) => {
  try {
    const res = await StockProductService.getOrderStockProductsByMerchantOutboundNumber(token, merchantOutboundNumber, perPage, page, signal)
    return res.data
  } catch (error: any) {
    if (!error.response) {
      throw error
    }
    return rejectWithValue(error?.response.data)
  }
})

const stockProductSlice = createSlice({
  name: 'dotnet/api/stock-products',
  initialState,
  reducers: {
    resetStockProductError: (state) => {
      state.error = null
    },
    resetStockProductMessage: (state) => {
      state.message = null
    },
    resetStockProductData: (state) => {
      state.error = null
      state.stockProducts = []
      state.message = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderStockProductsByMerchantOutboundNumber.pending, (state) => {
        state.stockProducts = []
        state.isLoading = true
      })
      .addCase(getOrderStockProductsByMerchantOutboundNumber.fulfilled, (state, action) => {
        state.isLoading = false
        state.stockProducts = action.payload.stockProducts
        state.metadata = action.payload.meta
        state.error = null
      })
      .addCase(getOrderStockProductsByMerchantOutboundNumber.rejected, (state, action) => {
        if (action.payload !== undefined) {
          state.isLoading = false
        }
        state.error = action.payload
      })
  }
})

export const { resetStockProductError, resetStockProductMessage, resetStockProductData } = stockProductSlice.actions

const { reducer } = stockProductSlice
export default reducer
