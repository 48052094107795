import React from 'react'
import { useFormik } from 'formik'
import { object, number } from 'yup'
import { ProductGraduatedPrice } from '../../../types'
import { addGraduatedPriceToProduct } from '../../../store/reducers/api/productReducer'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { editProductGraduatedPriceById } from '../../../store/reducers/api/productGraduatedPriceReducer'

interface GraduatedPriceEditorProps {
  initialGraduatedPrice: Omit<ProductGraduatedPrice, 'createdAt' | 'updatedAt'>
  isLoading: boolean
  productId: string | undefined
  editMode: boolean
}
const GraduatedPriceEditor = ({ initialGraduatedPrice, isLoading, productId, editMode }: GraduatedPriceEditorProps) => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)

  const token = currentUser?.token
  const dispatch = useAppDispatch()
  const graduatedPriceSchema = object({
    price: number().label('Price').positive().typeError('Price must be a number').required(),
    firstUnit: number().label('First Unit').positive().typeError('First Unit must be a number').required(),
    lastUnit: number().label('Last Unit').positive().typeError('Last Unit must be a number')
      .required()
      .test('is-greater', 'Last Unit must be greater than First Unit', function (value, object) {
        const { firstUnit } = object.parent
        return (value || 0) > firstUnit
      })
  })

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    errors,
    touched,
    isSubmitting,
    values
  } = useFormik({
    initialValues: {
      ...initialGraduatedPrice
    },
    onSubmit: (
      {
        price,
        firstUnit,
        lastUnit
      },
      actions
    ) => {
      const controller = new AbortController()
      const signal = controller.signal

      const productGraduatedPrice = {
        price, firstUnit, lastUnit
      }

      if (token) {
        if (editMode) {
          const productGraduatedPriceId = initialGraduatedPrice.id
          productGraduatedPriceId && dispatch(editProductGraduatedPriceById({ token, productGraduatedPriceId, productGraduatedPrice, signal }))
        } else {
          productId && dispatch(addGraduatedPriceToProduct({ token, productId, productGraduatedPrice, signal }))
        }
      }
      actions.setSubmitting(false)
    },
    validationSchema: graduatedPriceSchema,
    enableReinitialize: true
  })

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="graduatedPrice"
                className="form-label"
              >
                Price
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
                type="text"
                className={`form-control ${
                  (touched.price &&
                  errors.price)
                    ? 'is-invalid'
                    : ''
                }`}
                id="graduatedPrice"
                name="price"
                placeholder=""
                autoComplete="off"
              />
              <div
                id="validationGraduatedPriceFeedback"
                className="invalid-feedback"
              >
                {errors.price}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="graduatedPriceFirstUnit"
                className="form-label"
              >
                First Unit
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstUnit}
                type="number"
                className={`form-control ${
                  (touched.firstUnit &&
                  errors.firstUnit)
                    ? 'is-invalid'
                    : ''
                }`}
                id="graduatedPriceFirstUnit"
                name="firstUnit"
                placeholder=""
                autoComplete="off"
              />
              <div
                id="validationGraduatedPriceFirstUnitFeedback"
                className="invalid-feedback"
              >
                {errors.firstUnit}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label
                htmlFor="graduatedPriceLastUnit"
                className="form-label"
              >
                Last Unit
              </label>
              <input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastUnit}
                type="number"
                className={`form-control ${
                  (touched.lastUnit &&
                  errors.lastUnit)
                    ? 'is-invalid'
                    : ''
                }`}
                id="graduatedPriceLastUnit"
                name="lastUnit"
                placeholder=""
                autoComplete="off"
              />
              <div
                id="validationGraduatedPriceLastUnitFeedback"
                className="invalid-feedback"
              >
                {errors.lastUnit}
              </div>
            </div>
          </div>
        </div>

        <div className="text-end">
          <button
            type="submit"
            className="btn btn-primary mt-2"
            disabled={
              isSubmitting || isLoading
            }
          >
            <i className="bi bi-save"></i> Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default GraduatedPriceEditor
